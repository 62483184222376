import './App.css';
import Footer from './components/footer/Footer'
import Header from './components/header/Header'
import { scrollToElement } from 'scroll-to-element'
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom"

import Home from './pages/home/Home'
import Blogs from './pages/blogs/Blogs'
import Gallery13Ulu from './components/gallery/Gallery13Ulu'
import Articles from './components/articles/Articles'
import Gallery12Ulu from './components/gallery/Gallery12Ulu'
import { useEffect } from 'react'
import GalleryPhoto from './components/gallery/GalleryPhoto'
import PhotoVoice from './components/gallery/PhotoVoice'
import VideoList from './components/videos/VideoList'
import Survey from './components/survey/Survey'
import VlogList from './components/vlogs/VlogList'
import PetaStatic from './components/petastatic/PetaStatic'
import Ebook from './components/ebook/Ebook';
import Admin from './pages/admin'

function App() {
  //trick supaya bisa scrowdown lewat java karena kalau tidak dengan javascript
  //dengan ID saat masuk  ke halaman yg berbeda dan kembali stuck di TOP home
  //tidak FLY ke Section ID tersebut

  useEffect(()=>{
    // Decode entities in the URL
    // Sometimes a URL like #/foo#bar will be encoded as #/foo%23bar
   
    // window.location.hash = window.decodeURIComponent(window.location.hash);
    // const scrollToAnchor = () => {
    //   const hashParts = window.location.hash.split('#');
    //   if(hashParts[1]){
    //     window.location.href = `/#${hashParts[1]}` // hasPart[0] ='', hasPart[1] ='team
    //   }
    // };
    // scrollToAnchor();

    // / Decode entities in the URL
  // Sometimes a URL like #/foo#bar will be encoded as #/foo%23bar
    // window.location.hash = window.decodeURIComponent(window.location.hash);
    // const scrollToAnchor = () => {
    //   const hashParts = window.location.hash.split('#');
    //   if (hashParts.length > 2) {
    //     const hash = hashParts.slice(-1)[0];
    //     const element = document.querySelector(`#${hash}`);
    //     if (element) {
    //         window.scrollTo(0, 0);
    //         element.scrollIntoView();
    //     }
    //   } else {
    //       window.scrollTo(0, 0);
    //   }
    // };
    // scrollToAnchor();
    // window.onhashchange = scrollToAnchor;
  },[])
 

  return ( 
    <BrowserRouter>
      {/* <Navbar/> */}
       <Header /> 
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route  path='/#about' element={<Home/>}/>

        <Route path='/blogs' element={<Blogs/>}/>
        <Route path='/blogs?id=1' element={<Gallery13Ulu/>}/>
        <Route path='/blogs?id=2' element={<Gallery12Ulu/>}/>
        {/* <Route path='/blogs?id=3' element={<Gallery15Ulu/>}/> */}
        <Route path='/blogs?artikelId:artikelId' element={<Articles/>}/>

        <Route path='/blogs/galleryphoto' element={<GalleryPhoto />}/>
        <Route path='/blogs/photovoice' element={<PhotoVoice />}/>
        <Route path='/blogs/artikel' element={<Articles />}/>
        <Route path='/blogs/ebook' element={<Ebook />}/>
        <Route path='/blogs/videolist' element={<VideoList />}/>
        <Route path='/blogs/vloglist' element={<VlogList />}/>
        <Route path='/blogs/petainterpretasi' element={<PetaStatic />}/>
        <Route path='/blogs/hasilsurvey' element={<Survey />}/>
        {/* <Route path="/tentang" element={<TentangKami/>}/>
        <Route path="/kontak" element={<Kontak/>}/> */}
        <Route path='/admin' element={<Admin />}/>
      </Routes>
      <Footer />
  </BrowserRouter>
  )
}

export default App;
