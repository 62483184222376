import { useState } from 'react'
import './petaStatic.css'

const images = [
  {id:'1',title:'Sebaran Persentase Ibu hamil yang diberi TTD berdasarkan wilayah kerja', desc:'Pada peta diatas, sebaran persentase rendah ditunjukan dengan legenda bewarna putih, dilanjutkan pada sebaran persentase sedang ditunjuukan dengan legenda bewarna kuning, dan pada sebaran persentase tinggi ditunjukkan pada legenda bewarna hijau. Dapat diketahui bahwa Wilayah Kerja Puskesmas Alang-Alang Lebar merupakan wilayah rendah sebaran persentase ibu hamil yang mendapat TTD, yaitu sebanyak 78%. Adapun pada wilayah sedang sebaran ibu hamil yang di beri TTD terdapat pada wilayah kerja Puskesmas 23 Ilir, yaitu sebanyak 92%. Sedangkan 40 wilayah Kerja Puskesmas lainnya di Kota Palembang memiliki sebaran persentase ibu hamil yang diberi TTD dengan kategori tinggi, yaitu lebih dari 92%.  Dari distribusi sebaran diatas, dapat ditarik kesimpulan bahwa mayoritas Ibu hamil di Kota Palembang mendapat TTD sesuai standar yang ditandai dengan dominannya wilayah kerja puskesmas yang memiliki sebaran diatas 92%.',imageName: require('../../assets/images/petastatic/wilayahkerja/3.wilayahkerjaHamilTTDPersentase.png'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'2',title:'Sebaran Persentase Ibu hamil yang mengalami anemia per wilayah puskesmas', desc:'Pada peta diatas, sebaran persentase rendah ditunjukan dengan legenda bewarna putih, dilanjutkan pada sebaran persentase sedang ditunjuukan dengan legenda bewarna orange, dan pada sebaran persentase tinggi ditunjukkan pada legenda bewarna maroon. Dapat diketahui bahwa Wilayah Kerja Puskesmas Keramasan (18%) dan 7 Ulu (15%) merupakan wilayah tinggi  sebaran persentase ibu hamil yang mengalami anemia. Adapun pada wilayah sedang sebaran ibu hamil yang mengalami Anemia tersebar di 8 wilayah kerja puskesmas, yaitu pada wilayah kerja puskesmas karya jaya (11%), puskesmas 4 Ulu (10%), Puskesmas Pembina (105), Puskesmas Plaju (115), Puskesmas Sei Selincah (7%), Puskesmas 11 iIlir (7%), Puskesmas Sukarami (7%) dan puskesmas Alang-alang lebar (8%). Sedangkan 30 wilayah Kerja Puskesmas lainnya di Kota Palembang memiliki sebaran persentase ibu hamil yang mengalami anemia dengan kategori rendah, yaitu kurang dari 6%.  Dari distribusi sebaran diatas, dapat ditarik kesimpulan bahwa mayoritas Ibu hamil di Kota Palembang memiliki kadar Hb dan zat besi yang cukup yang ditandai dengan dominannya wilayah kerja yang memiliki sebaran kurang dari 6%.',imageName: require('../../assets/images/petastatic/wilayahkerja/4.wilayahkerjaibuhamilanemiapersentase.png'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'3',title:'Sebaran Persentase Ibu hamil yang mengalami Kek', desc:'Pada peta diatas, sebaran persentase rendah ditunjukan dengan legenda bewarna putih, dilanjutkan pada sebaran persentase sedang ditunjuukan dengan legenda bewarna biru muda, dan pada sebaran persentase tinggi ditunjukkan pada legenda bewarna biru tua dongker. Dapat diketahui bahwa 11 Wilayah Kerja Puskesmas yang memiliki sebaran rendah ibu hamil yang mengalami Kekurangan energi kronis dan mendapat Makanan Tambahan, yaitu pada wilayah kerja talang Jambe (0%), Punti Kayu (0%), Pakjo (0%), Sei Baung (0%), Talang Ratu (0%), Basuki Rahmat (0%), Ariodillah (0%), 23 Ilir (0%), Dempo (0%), Taman Bacaan (0%), dan kalidoni (0%). 11 wilayah puskesmas ini juga mengindikasikan tidakadanya sebaran ibu hamil yang mengalami Kekurangan energi Kronis Pada wilayah Tersebut. Adapun 31 wilayah puskesmas lainnya merupakan wilayah tinggi sebaran ibu hamil yang mengalami KEK dan diberikan Makanan Tambahan. 31 Puskesmas tersebut mengindikasikan bahwa seluruh ibu hamil dengan KEK Semuanya telah tertangani. ',imageName: require('../../assets/images/petastatic/wilayahkerja/5.wialayhkerjahamilkekpersentase.png'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'4',title:'Sebaran Persentase Bayi yang diberi IMD', desc:'Pada peta diatas, sebaran persentase rendah ditunjukan dengan legenda bewarna putih, dilanjutkan pada sebaran persentase sedang ditunjuukan dengan legenda bewarna hijau muda, dan pada sebaran persentase tinggi ditunjukkan pada legenda bewarna hijau tua. Dapat diketahui bahwa Wilayah Kerja Puskesmas padang Selasa (11%) merupakan wilayah rendah sebaran persentase bayi yang diberi Inisasi Menyusui Dasar. Adapun pada wilayah sedang sebaran persentase bayi yang diberi Inisasi Menyusui Dasar terdapat pada wilayah kerja puskesmas Sekip (58%) dan 5 Ilir (58%). Sedangkan 39 wilayah Kerja Puskesmas lainnya memiliki sebaran persentase bayi yang diberi Inisasi Menyusui Dasar dengan kategori tinggi, yaitu lebih dari 70%.  Dari distribusi sebaran diatas, dapat ditarik kesimpulan bahwa mayoritas bayi di Kota Palembang diberikan IMD pada saat lahir, ditandai dengan dominannya wilayah kerja yang memiliki sebaran lebih dari 70%.',imageName: require('../../assets/images/petastatic/wilayahkerja/6.wilayahkerjabayiimdpersentase.png'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'5',title:'Sebaran Persentase Bayi yang diberi Asi eksklusif berdasarkan wilayah kerja', desc:'Pada peta diatas, sebaran persentase rendah ditunjukan dengan legenda bewarna putih, dilanjutkan pada sebaran persentase sedang ditunjuukan dengan legenda bewarna hijau muda, dan pada sebaran persentase tinggi ditunjukkan pada legenda bewarna hijau tua. Dapat diketahui bahwa Wilayah Kerja Puskesmas Sukarami (40%), Talang ratu (42%), dan Plaju (60%) merupakan wilayah rendah sebaran persentase bayi yang diberi Asi Ekslusif. Adapun pada wilayah sedang sebaran persentase bayi yang diberi Asi Ekslusif tersebar pada 23 wilayah kerja puskesmas dan di dominasi pada wilayah utara dan timur kota Palembang. Sedangkan 16 wilayah Kerja Puskesmas lainnya di Kota Palembang memiliki sebaran persentase bayi yang Asi Eksluisf dengan kategori tinggi, yaitu lebih dari 80%, yaitu terdapat pada wilayah kerja Padang Selasa (100%), Gandus (93%), Keramasan (100%), Karya Jaya (84%), Sei Baung (100%), Kertapati (81%), 4 Ulu (100%), OPI (95%), Ariodillah (100%), Nagaswidak (86%), Boom baru (81%), 5 Ilir (100%), Kalidoni (88%), Sosial (94%), dan Multiwahana (86%). Dari distribusi sebaran diatas, dapat ditarik kesimpulan bahwa mayoritas bayi di Kota Palembang diberikan Asi Ekslusif sampai dengan umur 6 bulan, ditandai dengan dominannya wilayah kerja yang memiliki sebaran lebih dari 80%.',imageName: require('../../assets/images/petastatic/wilayahkerja/7.wilayahkerjaasiekslusifpersentase.png'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'6',title:'Sebaran Persentase Baduta yang di beri MP Asi berdasarkan wilayah kerja', desc:'Pada peta diatas, sebaran persentase rendah ditunjukan dengan legenda bewarna putih, dilanjutkan pada sebaran persentase sedang ditunjuukan dengan legenda bewarna biru muda, dan pada sebaran persentase tinggi ditunjukkan pada legenda bewarna biru tua. Dapat diketahui bahwa Wilayah Kerja Puskesmas Keramasan (18%), Boom baru (0%), dan Sosial (14%) merupakan wilayah rendah sebaran persentase Baduta yang diberi MP-ASI. Adapun pada wilayah sedang sebaran persentase Baduta yang diberi MP-ASI tersebar pada 6 wilayah kerja puskesmas, yaitu Puskesmas talang Betutu (66%), Alang-Alang Lebar (51%), Punti Kayu (63%), 23 Ilir (61%), dan kalidoni (68%). Sedangkan 33 wilayah Kerja Puskesmas lainnya memiliki sebaran tinggi Baduta yang diberi MP-ASI. Dari distribusi sebaran diatas, dapat ditarik kesimpulan bahwa mayoritas Bayi Dua Tahun di Kota Palembang diberikan Makanan Pendamping ASI sampai dengan umur 2 Tahun, ditandai dengan dominannya wilayah kerja yang memiliki sebaran lebih dari 69%.',imageName: require('../../assets/images/petastatic/wilayahkerja/8.wilayahkerjabadutaMPASIpersentase.png'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'7',title:'Sebaran Persentase Rematri yang diberi TTD berdasarkan wilayah puskesmas', desc:'Pada peta diatas, sebaran persentase rendah ditunjukan dengan legenda bewarna putih, dilanjutkan pada sebaran persentase sedang ditunjukan dengan legenda bewarna pink, dan pada sebaran persentase tinggi ditunjukkan pada legenda bewarna ungu. Dapat diketahui bahwa 8 Wilayah Kerja Puskesmas, yaitupdang Selasa (10%), Pakjo (34%), Kampus (43%), Karya Jaya (335), Basuki Rahmat (17%), 11 Ilir (26%), Pembina (12%), Taman Bacaan (20%), dan Tegal Binangun merupakan wilayah rendah sebaran persentase Remaja Putri yang diberi Tablet Tambah Darah Sesuai Standar. Adapun pada wilayah sedang sebaran persentase Remaja Putri yang diberi Tablet Tambah Darah Sesuai Standar tersebar pada 8 wilayah kerja puskesmas, yaitu Puskesmas OPI (61%), Plaju (48%), 5 Ilir (56%), Talang Ratu (76%), Bukit Sangkal (60%), Sako (71%), Sosial (78%) dan Sukarami (645). Sedangkan 26 wilayah Kerja Puskesmas lainnya di Kota Palembang memiliki sebaran Baduta yang diberi MP-ASI. Dari distribusi sebaran diatas, dapat ditarik kesimpulan bahwa mayoritas Remaja Putri di Kota Palembang diberikan Tablet Tambah Darah sesuai standar, ditandai dengan dominannya wilayah kerja yang memiliki sebaran lebih dari 79%.',imageName: require('../../assets/images/petastatic/wilayahkerja/9.wialayhkerjarematriTTDpersentase.png'), tag: 'posyandu angrek bulan 12 ulu'},

]

const DeterminanWilayahKerjaPersentase =()=> {
  const [noItem, setNoItem] = useState(4)
  const sliceItems = images.slice(0, noItem)

  const loadMore =()=> setNoItem(noItem + noItem)
  return(
   <> 
    <h3>2. Berdasarkan Wilayah Kerja Puskesmas</h3>
    <div className="row row-cols-2 g-3">
      {sliceItems.map(item=>(
        <div className="col" key={item.title}>
          <div className="card" style={{boxShadow:'none'}}>
            <h4 className='m-2 ps-2'>{item.title}</h4>
            <a href={`${item.imageName}`}>
              <img src={item.imageName} 
                className={`cursor-pointer card-img-top zoom`}  
                alt="" loading='lazy'/>
            </a>
            <div className="card-body">
              {/* <h5 className="card-title">{item.title}</h5> */}
              <p className='card-text' dangerouslySetInnerHTML={{__html:item.desc}} />
            </div>
          </div>
        </div>
      ))}
    </div>
    <div className='text-center d-block mt-5 mb-5'>
      {noItem < images.length &&
        <button 
          className='btn btn-success'
          onClick={()=>loadMore()}>
            Load More Determinan Wilayah Kerja
        </button>
      }
    </div>
  </>
  )
}

export default DeterminanWilayahKerjaPersentase