import twitter from '../../assets/images/twitter.png'
import instagram from '../../assets/images/instagram.png'
import facebook from '../../assets/images/facebook.png'
import bootstrap from '../../assets/images/bootstrap.png'
import logo from '../../assets/images/logo.png'
import './footer.css'
import { useLocation } from 'react-router-dom'

const Footer = () => {
  const location = useLocation().pathname.split('/')[1]


  return (
    <>
     {location !=='admin' &&  
    <div className="container" style={{marginTop:'-25px'}}>
      <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
        <div className="col-md-4 d-flex align-items-center">
          <a href="/" className="mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1">
              <img src={logo} alt='twitter' width={40} height={40}/>
          </a>
          <span className="mb-3 mb-md-0 text-muted">&copy; 2023 KampungBebasStunting</span>
        </div> 

        <ul className="nav justify-content-center" style={{fontSize:'13px'}}>
          <li className="nav-item"><a href="/#whatwedo" className="nav-link px-2 text-muted">What We Do</a></li>
          <li className="nav-item"><a href="/#peta" className="nav-link px-2 text-muted">Peta Sebaran Stunting</a></li>
          <li className="nav-item"><a href="/blogs" className="nav-link px-2 text-muted">Blog & Artikel</a></li>
        </ul>
      
        <ul className="nav col-md-4 justify-content-end list-unstyled d-flex">
          <li className="ms-3"><a className="text-muted" href="/">
            <img src={twitter} alt='twitter'  width={20} height={20}/></a></li>
          <li className="ms-3"><a className="text-muted" href="/">
            <img src={instagram} alt='instagram'  width={20} height={20}/></a></li>
          <li className="ms-3"><a className="text-muted" href="/">
            <img src={facebook} alt='facebook'  width={20} height={20}/></a></li>
        </ul>
      </footer>
    </div>
    }
    </>
  )
}

export default Footer