import './videoList.css'
// import srcVideo from '../../assets/images/videos/kata_mereka.mp4'
import { useState } from 'react'

const VideoList = () => {
  // const [src, setSrc] = useState(srcVideo)
  const [title, setTitle] = useState('Video Diskusi bersama ibu-ibu')

  const videos = [
    {id:'1',title:'MEWUJUDKAN KAMPUNG BEBAS BUDAK STUNTING DAN WASTING',subtitle:'Bersama Ibu dr Fenty Aprina, M.Kes,  Kepala Dinas Kesehatan Palembang', desc:'Susahnyo jelaske apo itu stunting ke cek maria jadi nak bu kepala dinas kesehatan nian yang jelasken sekalian kito melok penyuluhan keliling dari FKM UNSRI samo tin mahasiswa dan anak-anak di Bantaran Sungai Musi.', vidName: <iframe width="1440" height="614" src="https://www.youtube.com/embed/KzX7sLqXkYU?rel=0" title="APODIO YANG DIRIBUTKE INI?? STUNTING??" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="allowfullscreen"></iframe>    , tag: '13 ulu lrg agung 1'},
    {id:'2',title:'APA ITU WASTING ?',desc:'Ibu-ibu ada yang tau dak apa Wasting itu beserta pencegahannya ?', vidName: <iframe width="1440" height="614" src="https://www.youtube.com/embed/rBQU8zGZa9g?rel=0" title="WASTING?? ANAK KURANG TIMBANGAN??" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="allowfullscreen"></iframe>, tag: '13 ulu lrg agung 1'},
    {id:'3',title:'YUKK INTIP PENGETAHUAN MEREKA SOAL STUNTING',desc:'Video kompilasi ini menyoroti upaya tim riset dalam menanyakan apa itu stunting dan wasting kepada masyarakat sekitar wilayah kerja mereka. Banyak responden menyebutkan stunting sebagai kondisi anak yang pendek dan mengalami kurang pertumbuhan. Namun, menariknya, terdapat kesadaran yang rendah tentang wasting sebagai masalah kesehatan yang juga signifikan. Dalam video ini, tim riset menyajikan teori dari WHO tentang stunting dan wasting, memberikan penjelasan yang mendalam tentang kedua masalah ini. Lebih lanjut, video ini menyajikan informasi tentang pencegahan dan tindakan yang dapat diambil untuk mengurangi angka stunting dan wasting di wilayah tersebut, menjadikan edukasi sebagai langkah awal menuju generasi masa depan yang lebih sehat dan berkualitas.', vidName:<iframe width="1440" height="614" src="https://www.youtube.com/embed/RLpxEcf_XCA?rel=0" title="STUNTING??!! EMANG PENTING??" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="allowfullscreen"></iframe>, tag: '13 ulu lrg agung 1'},
    {id:'4',title:'PERAN IBU KADER POSYANDU SEBAGAI UJUNG TOMBAK MENCEGAH STUNTING DAN WWASTING',desc:'Potret Posyandu 12Ulu, Posyandu 15Ulu, Posyandu Stoberi (16 Ulu)', vidName: <iframe width="1440" height="614" src="https://www.youtube.com/embed/0Yu_Gck_ltc?rel=0" title="KADER POSYANDU SEBAGAI UJUNG TOMBAK? KOK BISA SIH??!!" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="allowfullscreen"></iframe>, tag: '13 ulu lrg agung 1'},
    {id:'5',title:'IBU DENGAN ANAK PENYINTAS STUNTING : ANAKKU KU BERI NAMA JOKOWI',desc:'wawancara antara seorang ibu dan anaknya yang merupakan penyintas stunting. Anak tersebut adalah anak ke-3 dari ibu tersebut. Ibu ini berhasil melewati masa-masa sulit dan mengatasi tantangan stunting dengan sangat gigih. Dalam video, ibu tersebut menceritakan bagaimana dia berhasil menyembuhkan anaknya dengan sering membawa anaknya untuk diperiksa di posyandu. Kehadiran ibu ini sangat penting karena memungkinkan petugas kesehatan untuk mendeteksi pertumbuhan yang lambat pada anaknya dengan cepat dan memberikan bantuan serta pengarahan yang tepat agar tidak terlambat. Meskipun keluarganya menghadapi keterbatasan ekonomi, ibu ini tidak pernah menyia-nyiakan kesempatan untuk menjaga kesehatan anaknya. Dedikasinya untuk kesehatan anak dan keteguhan hatinya adalah inspirasi bagi banyak orang tentang pentingnya kesadaran dan perhatian terhadap kesehatan anak-anak, terlepas dari segala keterbatasan yang dimiliki', vidName:  <iframe width="1440" height="614" src="https://www.youtube.com/embed/KAZYC6RRy_U" title="Ibu dengan anak penyintas stunting : anakku ku beri nama jokowi" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="allowfullscreen"></iframe>, tag: '13 ulu lrg agung 1'},
    {id:'6',title:'PROSES PENGUMPULAN DATA KAMPUNG BEBAS BUDAK (ANAK) STUNTING DAN WASTING',desc:'Proses penelitian dalam pengumpulan data dan suara dari kader posyandu hingga ibu baduta di bantaran Sungai Musi Kota Palembang', vidName: <iframe width="1440" height="614" src="https://www.youtube.com/embed/KWqAP7hBxto?rel=0" title="Proses Pengumpulan Data Kampung Bebas Budak (Anak) Stunting dan Wasting" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="allowfullscreen"></iframe>, tag: '13 ulu lrg agung 1'},
    {id:'7',title:'PODCAST ONE DAY ONE INNOVATION',desc:'Website Edukasi Stunting & Wasting Interaktif berbasis kearifan lokal', vidName:<iframe width="1440" height="614" src="https://www.youtube.com/embed/GqzY9D8wSIM?rel=0" title="WEBSITE EDUKASI STUNTING &amp; WASTING INTERAKTIF BERBASIS KEARIFAN LOKAL" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="allowfullscreen"></iframe>, tag: '13 ulu lrg agung 1'},
    {id:'8',title:'IBU DENGAN ANAK PENYINTAS STUNTING : KISAH NYATA IBU YOGI',desc:'Cerita inspiratif dari seorang Ibu yang memiliki anak penyinta stunting', vidName:<iframe width="1440" height="614" src="https://www.youtube.com/embed/at8F7p9T8ls?rel=0" title="ibu dengan anak penyintas stunting :  kisah nyata ibu Yogi" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="allowfullscreen"></iframe>, tag: '13 ulu lrg agung 1'},
    {id:'9',title:'APA ITU STUNTING DAN WASTING',desc:'Bertanya kepada ibu-ibu mengenai stunting dan wasting', vidName:<iframe width="1440" height="614" src="https://www.youtube.com/embed/FXRqj36eK24?rel=0" title="Apa itu stunting dan wasting?!" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="allowfullscreen"></iframe>, tag: '13 ulu lrg agung 1'},
    {id:'10',title:'SUNGAI KEDOKAN, JADI PILIH YANG MANA, YANG KUMUH ATAU YANG SEHAT???',desc:'Foto sungai kedokan yang kumuh dan banyak sampahnya.', vidName:<iframe width="1440" height="614" src="https://www.youtube.com/embed/XhXJFWh-snU?rel=0" title="SUNGAI KEDOKAN, JADI PILIH YANG MANA, YANG KUMUH ATAU YANG SEHAT???" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="allowfullscreen"></iframe>, tag: '13 ulu lrg agung 1'},
    {id:'11',title:'JANGAN BUANG SAMPAH KESUNGAI!!!',desc:'Jangan lagi membuang sampah di sungai buanglah ditempat sampah', vidName:<iframe width="368" height="655" src="https://www.youtube.com/embed/GSFl1dREuQo?rel=0" title="Jangan buang sampah kesungai!!!!" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="allowfullscreen"></iframe>, tag: '13 ulu lrg agung 1'},
    {id:'12',title:'BINCANG-BINCANG HANGAT TERKAIT ISU STUNTING BERSAMA KEPALA CAMAT SU I',desc:'Berbincang mengenai program apa saja di kecamatan Seberang Ulu I dalam mengatasi stunting', vidName:<iframe width="1440" height="610" src="https://www.youtube.com/embed/LrFg_LkFYl0?rel=0" title="Bincang- Bincang Hangat Terkait Isu Stunting bersama Kepala camat SU I" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="allowfullscreen"></iframe>, tag: '13 ulu lrg agung 1'},
    {id:'13',title:'KAMU PILIH YANG MANA?? TEPI SUNGAI SEHAT ATAU KUMUH?!!',desc:'Kamu pilih yang mana tepi sungai Kedokan yang kumuh atau yang sehat ?', vidName:<iframe width="1440" height="610" src="https://www.youtube.com/embed/QEV39443EDo?rel=0" title="KAMU PILIH YANG MANA?? TEPI SUNGAI SEHAT ATAU KUMUH?!!" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="allowfullscreen"></iframe>, tag: '13 ulu lrg agung 1'},
    {id:'14',title:'SUNGAI KEDOKAN, JADI PILIH YANG MANA, YANG KUMUH ATAU YANG SEHAT???',desc:'Ibu-ibu ini gambar apa bu ? ini foto tepian sungai Kedokan yang kotor, kumuh dan banyak sampahnya', vidName:<iframe width="1440" height="610" src="https://www.youtube.com/embed/XhXJFWh-snU?rel=0" title="SUNGAI KEDOKAN, JADI PILIH YANG MANA, YANG KUMUH ATAU YANG SEHAT???" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="allowfullscreen"></iframe>, tag: '13 ulu lrg agung 1'},
    {id:'15',title:'CERITA INSPIRATIF IBU : PENGARUH TEKNOLOGI BAGI GIZI SI KECIL',desc:'Cerita inspiratif seorang ibu yang memanfaatkan teknologi dan media sosial dalam memperbaiki gizi anaknya.', vidName:<iframe width="1440" height="610" src="https://www.youtube.com/embed/qiVXadbfi6Y?rel=0" title="Cerita Inspiratif Ibu : Pengaruh Teknologi Bagi Gizi Si Kecil" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="allowfullscreen"></iframe>, tag: '13 ulu lrg agung 1'},
    {id:'16',title:'BINCANG HANGAT BERSAMA CAMAT SU II | APA SIH GEROBOK SUDU !??',desc:'Berbincang bersama Bapak Purba Sanjaya Camat Seberang Ulu II seputar bahasan stunting.', vidName:<iframe width="1440" height="610" src="https://www.youtube.com/embed/_u2lFsrbLzE?rel=0" title="Berbincang Hangat Bersama Camat SU II | Apa sih Gerobok Sudu ?!!" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="allowfullscreen"></iframe>, tag: '13 ulu lrg agung 1'},
  ].sort().reverse() //sort descending

  const [noItem, setNoItem] = useState(8)
  const sliceItems = videos.slice(0, noItem)

  const loadMore =()=> setNoItem(noItem + noItem)

  return (
    <div className='container pt-5' style={{maxWidth:'80%', margin:'auto'}}>
      <h1 className='mb-5 mt-5' style={{paddingTop:'15px'}}>Video List</h1>
      <div className='row row-cols-2 g-3'>
        {sliceItems.map(video=>(
          <div className='col video-main' key={video.id}> 
            {/* <video width="400" controls>
              <source src={video.vidName} type="video/mp4"/>
              Your browser does not support HTML5 video.
            </video> */}
            {video.vidName}
          <h5><strong>{video.title}</strong><br/>
            <span className='h6'><strong>{video.subtitle}</strong></span>
          </h5>
          <p className='video-desc pt-2'>{video.desc}</p>
        </div>
      ))}
      </div> 

      <div className='text-center d-block mt-5 mb-5'>
      {noItem < videos.length &&
        <button 
          className='btn btn-success'
          onClick={()=>loadMore()}>
            Load More
        </button>
      }
    </div>    
    </div>
  )
}

export default VideoList