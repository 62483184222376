import { useEffect, useState } from 'react'
import './petaStatic.css'
import Stunting from './Stunting'
import Wasting from './Wasting'
import DeterminanKecamatan from './DeterminanKecamatan'
import DeterminanWilayahKerja from './DeterminanWilayahKerja'
import DeterminanKecamatanPersentase from './DeterminanKecamatanPersentase'
import DeterminanWilayahKerjaPersentase from './DeterminanWilayahKerjaPersentase'

const PetaStatic =()=> {
  const [activeTab, setActiveTab] = useState('1')
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return(
    <div className='container pt-5 mt-5' style={{maxWidth:'80%'}}>
    <h1 className='mb-5'>Peta & Interpretasi<br/>
      <span style={{fontSize:'14px'}}>(Dekatkan/Klik mouse untuk zooming peta)</span>
    </h1>

 
    <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist" >
      <li className='nav-item'  onClick={()=>setActiveTab('1')}>
        <a  className={`nav-link btn btn-success ${ activeTab === '1' ? 'active':''}`} 
        style={{background:activeTab ==='1' ? 'green':'', color:activeTab==='1' ? '#fff':''}} 
        id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Per Jumlah Sebaran</a>
      </li>
      <li className="nav-item" onClick={()=>setActiveTab('2')}>
        <a className={`nav-link btn btn-success ${ activeTab === '2' ? 'active':''}`} 
        style={{background:activeTab ==='2' ? 'green':'', color:activeTab==='2' ? '#fff':''}} 
        id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Per Persentase Sebaran</a>
      </li>
    </ul>
    <div className="tab-content mt-5" id="pills-tabContent">
      <div className={`tab-pane fade show  ${activeTab ==='1'? 'active':''}`} id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
          <Stunting />
          <Wasting />
          <DeterminanKecamatan />
          <DeterminanWilayahKerja/>
      </div>
      <div className={`tab-pane fade show ${activeTab ==='2'? 'active':''}`} id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
        <DeterminanKecamatanPersentase />
        <DeterminanWilayahKerjaPersentase />
      </div>
    </div>
   
  </div>
  )
}

export default PetaStatic