import React from 'react';
import { saveAs } from 'file-saver'

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/zoom';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './poster.css'

import {Zoom, Autoplay, EffectCoverflow, Pagination, Navigation } from 'swiper/modules';

import slide_image_1 from '../../assets/images/posters/budak_beresiko.png';
import slide_image_2 from '../../assets/images/posters/budak_kurus.png';
import slide_image_3 from '../../assets/images/posters/nafsu_makan_dan_pemberian_makan.png';
import slide_image_4 from '../../assets/images/posters/stunting_wasting.png';
import slide_image_5 from '../../assets/images/posters/makanan_tidak_sehat.png';
import slide_image_6 from '../../assets/images/posters/poster_cucitangan_3_4ulu_A5.png';
import slide_image_7 from '../../assets/images/posters/poster_Guno_Banyu_Bersih3_4_ulu_A5.png';
import slide_image_8 from '../../assets/images/posters/Poster_Jamban_Sehat3_4_ulu_A5.png';
import slide_image_9 from '../../assets/images/posters/Poster_Ruten_Pos_yandu_A5.png';
import slide_image_10 from '../../assets/images/posters/Poster_Pola_Asuh_A5.png';
import slide_image_11 from '../../assets/images/posters/Poster_Isi_Piringku3_4_ulu.png';
import slide_image_12 from '../../assets/images/posters/Poster_Cegah_Stunting_Dari_Ibu_Bonteng.png';
import slide_image_13 from '../../assets/images/posters/Poster_Cegah_Stunting_Dari_Bayi.png';
import slide_image_14 from '../../assets/images/posters/Poster_Cegah_Stunting_Dari_Gades_Beranjak.png';

 
function Poster() {

  const downloadImage = (e) => {
    e.preventDefault()

    saveAs(slide_image_1, 'budak_beresiko.png')
    saveAs(slide_image_2, 'budak_kurus.png')
    saveAs(slide_image_3, 'nafsu_makan_dan_pemberian_makan.png')
    saveAs(slide_image_4, 'stunting_wasting.png')
    saveAs(slide_image_5, 'makanan_tidak_sehat.png')
    saveAs(slide_image_6, 'poster_cucitangan_3_4ulu_A5.png')
    saveAs(slide_image_7, 'poster_Guno_Banyu_Bersih3_4_ulu_A5.png')
    saveAs(slide_image_8, 'Poster_Jamban_Sehat3_4_ulu_A5.png')
    saveAs(slide_image_9, 'Poster_Ruten_Pos_yandu_A5.png')
    saveAs(slide_image_10, 'Poster_Pola_Asuh_A5.png')
    saveAs(slide_image_11, 'Poster_Isi_Piringku3_4_ulu.png')
    saveAs(slide_image_12, 'Poster_Cegah_Stunting_Dari_Ibu_Bonteng.png')
    saveAs(slide_image_13, 'Poster_Cegah_Stunting_Dari_Bayi.png')
    saveAs(slide_image_14, 'Poster_Cegah_Stunting_Dari_Gades_Beranjak.png')
  }
 
const swiperSlide =()=>{
  let swipperList = [] 

  for (let index = 1; index < 7; index++) {

    swipperList.push(<SwiperSlide>
      <img src={require(`slide_image_${index}`).default} alt="slide_image" />
    </SwiperSlide>)
  }
  return <tbody>{swipperList}</tbody> 
}

  return (
    <div className="container" style={{position:'relative'}}>
      <h1 className="heading">Poster</h1>
      <Swiper
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        loop={true}
        zoom={true}
        autoplay={true}
        slidesPerView={'auto'}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 2.5,
        }}
        pagination={{ el: '.swiper-pagination', clickable: true }}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
          clickable: true,
        }}
        modules={[Zoom, Autoplay, EffectCoverflow, Pagination, Navigation]}
        className="swiper_container"
      >
        <SwiperSlide>
          <img src={slide_image_1} alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide_image_2} alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide_image_3} alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide_image_4} alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide_image_5} alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide_image_6} alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide_image_7} alt="slide_image" />
        </SwiperSlide> 
        <SwiperSlide>
          <img src={slide_image_8} alt="slide_image" />
        </SwiperSlide> 
        <SwiperSlide>
          <img src={slide_image_9} alt="slide_image" />
        </SwiperSlide> 
        <SwiperSlide>
          <img src={slide_image_10} alt="slide_image" />
        </SwiperSlide> 
        <SwiperSlide>
          <img src={slide_image_11} alt="slide_image" />
        </SwiperSlide> 
        <SwiperSlide>
          <img src={slide_image_12} alt="slide_image" />
        </SwiperSlide> 
        <SwiperSlide>
          <img src={slide_image_13} alt="slide_image" />
        </SwiperSlide> 
        <SwiperSlide>
          <img src={slide_image_14} alt="slide_image" />
        </SwiperSlide> 



        <div className="slider-controler">
          <div className="swiper-button-prev slider-arrow">
            <ion-icon name="arrow-back-outline"></ion-icon>
          </div>
          <div className="swiper-button-next slider-arrow">
            <ion-icon name="arrow-forward-outline"></ion-icon>
          </div>
          <div className="swiper-pagination"></div>
        </div>
      </Swiper>
      <div>
        <button onClick={downloadImage} className='btn btn-success rounded-0 mt-2 mb-2'>
          Unduh Poster
        </button>
      </div>
    </div>

  );
}
export default Poster