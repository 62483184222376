import './vlogList.css'
import { useState } from 'react'

const VlogList = () => {
  const videos = [
    // {id:'1',title:'Bebas Stunting dan Wasting',desc:'Vlog Bebas Stunting dan Wasting di Bantaran Sungai Musi', vidName: require('../../assets/videos/vlogs/bebas_stunting_wasting.mp4'), tag: '13 ulu lrg agung 1'},
    {id:'1',title:'Bebas Stunting dan Wasting',desc:'Vlog Bebas Stunting dan Wasting di Bantaran Sungai Musi', vidName:<iframe title='x' src="https://drive.google.com/file/d/1lk75gbPswwhGT5y4Xs1ll6Pe9s7WcJG4/preview" width="640" height="480" allow="autoplay" allowFullScreen></iframe>, tag: '13 ulu lrg agung 1'},
    {id:'2',title:'Mini Vlog: Hari Anak Nasional',desc:'Proses penelitian dalam pengumpulan data dan suara dari kader posyandu hingga ibu baduta di bantaran Sungai Musi Kota Palembang', vidName: <iframe width="1440" height="614" src="https://www.youtube.com/embed/G8Memj4Us4w?list=PL7jVrcaJoX5rr5UliYc0fPeGzBRynQBkm" title="MINI VLOG : HARI ANAK NASIONAL !!!" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="allowfullscreen"></iframe>, tag: '13 ulu lrg agung 1'},
  ]

  const [noItem, setNoItem] = useState(6)
  const sliceItems = videos.slice(0, noItem)

  const loadMore =()=> setNoItem(noItem + noItem)
  
  return (
    <div className='container pt-5 mt-5 mb-5' style={{maxWidth:'80%'}}>
    <h1 className='mb-5'>Vlog List</h1>
    <div className="row row-cols-3 g-3">
      {sliceItems.map(item=>(
        <div className="col" key={item.id}>
          <div className="card" style={{boxShadow:'none'}}>
            {/* <video src={item.vidName} controls className='card-img-top'/> */}
            {item.vidName}
            <div className="card-body">
              <h5 className="card-title">{item.title}</h5>
              <p className='card-text' dangerouslySetInnerHTML={{__html:item.desc}} />
            </div>
          </div>
        </div>
      ))}
    </div>
    <div className='text-center mt-5 mb-5'>
      {noItem < videos.length &&  
        <button className='btn btn-primary' onClick={()=>loadMore()}>
          Load More
        </button>
      }
    </div>
  </div>
  )
}

export default VlogList