import React from 'react';
import { saveAs } from 'file-saver'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './leaflet.css'

import {Autoplay, EffectCoverflow, Pagination, Navigation } from 'swiper/modules';

import slide_image_1 from '../../assets/images/photo_leaflet/apa_itu_stanting.png'
import slide_image_2 from '../../assets/images/photo_leaflet/apa_itu_wasting.png'
import slide_image_3 from '../../assets/images/photo_leaflet/menu_sehat_mudah.png'
import slide_image_4 from '../../assets/images/photo_leaflet/kampung_bebas_stunting_wasting.png'
import slide_image_5 from '../../assets/images/photo_leaflet/kampung_bebas_budak_stunting_wasting.png'

 
function Leaflet() {

  const downloadImage = (e) => {
    e.preventDefault()

    saveAs(slide_image_1, 'pa_itu_stanting.png')
    saveAs(slide_image_2, 'apa_itu_wasting.png')
    saveAs(slide_image_3, 'menu_sehat_mudah.png')
    saveAs(slide_image_4, 'kampung_bebas_stunting_wasting.png')
    saveAs(slide_image_5, 'kampung_bebas_budak_stunting_wasting.png')
  }

  return (
    <div className="container leaflet">
      <h4 className='display-5 mt-5' style={{lineHeight:0.8}}>Leaflet<br></br>
            <span className='aboutus-text' style={{fontWeight:'600'}}>Leaflet & Poster tentang Apo itu Stunting, Apo itu Wasting, Menu Sehat & Mudah, Kampung Bebas Stunting & Wasting</span>
          </h4>
          <hr/>
      <Swiper
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        loop={true}
        autoplay={true}
        slidesPerView={'auto'}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 2.5,
        }}
        pagination={{ el: '.swiper-pagination', clickable: true }}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
          clickable: true,
        }}
        modules={[Autoplay, EffectCoverflow, Pagination, Navigation]}
        className="swiper_container"
      >
        <SwiperSlide>
          <img src={slide_image_1} alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide_image_2} alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide_image_3} alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide_image_4} alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide_image_5} alt="slide_image" />
        </SwiperSlide>
        
        <div className="slider-controler">
          <div className="swiper-button-prev slider-arrow">
            <ion-icon name="arrow-back-outline"></ion-icon>
          </div>
          <div className="swiper-button-next slider-arrow">
            <ion-icon name="arrow-forward-outline"></ion-icon>
          </div>
          <div className="swiper-pagination"></div>
        </div>
      </Swiper>
      <div>
        <button onClick={downloadImage} className='btn btn-success rounded-0 mt-0 mb-4'>
          Unduh Leaflet
        </button>
      </div>
    </div>
  )
}
export default Leaflet