import React, {  useState } from 'react'
import './galleryPhoto.css'


const GalleryPhoto = () => {
  const images = [
    {id:'1',title:'Caption one',desc:'Lorem ipsum description 1', imageName: require('../../assets/images/photos/13ululrgagung1/1.jpg'), tag: '13 ulu lrg agung 1'},
    {id:'2',title:'Caption one',desc:'Lorem ipsum description 1', imageName: require('../../assets/images/photos/13ululrgagung1/3.jpg'), tag: '13 ulu lrg agung 1'},
    {id:'3',title:'Caption one',desc:'Lorem ipsum description 1', imageName: require('../../assets/images/photos/13ululrgagung1/4.jpg'), tag: '13 ulu lrg agung 1'},
    {id:'4',title:'Caption one',desc:'Lorem ipsum description 1', imageName: require('../../assets/images/photos/13ululrgagung1/5.jpg'), tag: '13 ulu lrg agung 1'},
    {id:'5',title:'Caption one',desc:'Lorem ipsum description 1', imageName: require('../../assets/images/photos/13ululrgagung1/6.jpg'), tag: '13 ulu lrg agung 1'},
    {id:'6',title:'Caption one',desc:'Lorem ipsum description 1', imageName: require('../../assets/images/photos/13ululrgagung1/7.jpg'), tag: '13 ulu lrg agung 1'},
    {id:'7',title:'Caption one',desc:'Lorem ipsum description 1', imageName: require('../../assets/images/photos/13ululrgagung1/8.jpg'), tag: '13 ulu lrg agung 1'},
    {id:'8',title:'Caption one',desc:'Lorem ipsum description 1', imageName: require('../../assets/images/photos/13ululrgagung1/9.jpg'), tag: '13 ulu lrg agung 1'},
    {id:'9',title:'Caption one',desc:'Lorem ipsum description 1', imageName: require('../../assets/images/photos/13ululrgagung1/10.jpg'), tag: '13 ulu lrg agung 1'},
    {id:'10',title:'Caption one',desc:'Lorem ipsum description 1', imageName: require('../../assets/images/photos/13ululrgagung1/11.jpg'), tag: '13 ulu lrg agung 1'},
    {id:'11',title:'Caption two', desc:'Tempat maen anak disekitar',imageName: require('../../assets/images/photos/Posyandu15ulu/1.jpg'), tag: 'posyandu 15 ulu'},
    {id:'13',title:'Caption two', desc:'',imageName: require('../../assets/images/photos/Posyandu15ulu/3.jpg'), tag: 'posyandu 15 ulu'},
    {id:'12',title:'Caption two', desc:'Salah satu sumber penghasilan di 15 ulu, menjual ikan gabus yang didapat dari sungai buaya',imageName: require('../../assets/images/photos/Posyandu15ulu/2.jpg'), tag: 'posyandu 15 ulu'},
    {id:'14',title:'Caption two', desc:'"Nenek ikut bantu ngasuh anak, nyuapi anaknyo makan mie campur nasi nuruti kesukaan anaknyo"',imageName: require('../../assets/images/photos/Posyandu15ulu/4.jpg'), tag: 'posyandu 15 ulu'},
    {id:'15',title:'Caption two', desc:'',imageName: require('../../assets/images/photos/Posyandu15ulu/5.jpg'), tag: 'posyandu 15 ulu'},
    {id:'16',title:'Caption two', desc:'Kalau sore ibu-ibunya kumpul depan rumah sambil berdiskusi dan ngobrol ngeliat anak main, main hp, ado yang makan. Kalo ngumpul biasa sambil makan nasi atau model (Palembang traditional food) atau jajan-jajanan. Kayak es, pempek seribu, model-modelan. Kareno disini cuma itulah jajanannyo',imageName: require('../../assets/images/photos/Posyandu15ulu/6.jpg'), tag: 'posyandu 15 ulu'},
    {id:'17',title:'Caption two', desc:'',imageName: require('../../assets/images/photos/Posyandu15ulu/7.jpg'), tag: 'posyandu 15 ulu'},
    {id:'18',title:'Caption two', desc:'<p>Neneng : ini anak abis tidur siang jadi maen sore sore, biasa main kejar kejarran, putak umpet, layangan. biasa jam 5 sore.</p><p>NA : kalo di tempat saya banyak anak ngumpul sambil main hp nge game, disini ado juga bu?</p><p>NN : dak ado mba, kalo maen hp di rumah. Kalo di luar maen samo kawannyo tulah.</p><p>NA : ini adeknyo minum apo bu?</p><p>NN : ini minum es mba, es teh seribuan. Ini yang lg sunat anak saya mba</p><p>NA : kalo setelah sunat biasa makanannya diberi apo bu?</p><p>NN : tahu, tempe, ikan gabus. Disini jual ikan gabusnyo keliling</p>',imageName: require('../../assets/images/photos/Posyandu15ulu/8.jpg'), tag: 'posyandu 15 ulu'},
    
    {id:'19',title:'Caption two', desc:'Lorem ipsum description 2',imageName: require('../../assets/images/photos/PosyanduAngrrekBulan12ulu/1.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
    {id:'20',title:'Caption two', desc:'Lorem ipsum description 2',imageName: require('../../assets/images/photos/PosyanduAngrrekBulan12ulu/2.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
    {id:'21',title:'Caption two', desc:'Lorem ipsum description 2',imageName: require('../../assets/images/photos/PosyanduAngrrekBulan12ulu/3.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
    {id:'22',title:'Caption two', desc:'Lorem ipsum description 2',imageName: require('../../assets/images/photos/PosyanduAngrrekBulan12ulu/4.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
    {id:'23',title:'Caption two', desc:'Lorem ipsum description 2',imageName: require('../../assets/images/photos/PosyanduAngrrekBulan12ulu/5.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
    {id:'24',title:'Caption two', desc:'Lorem ipsum description 2',imageName: require('../../assets/images/photos/PosyanduAngrrekBulan12ulu/6.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
    {id:'25',title:'Caption two', desc:'Lorem ipsum description 2',imageName: require('../../assets/images/photos/PosyanduAngrrekBulan12ulu/7.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
    {id:'26',title:'Caption two', desc:'Lorem ipsum description 2',imageName: require('../../assets/images/photos/PosyanduAngrrekBulan12ulu/8.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
    {id:'27',title:'Caption two', desc:'Lorem ipsum description 2',imageName: require('../../assets/images/photos/PosyanduAngrrekBulan12ulu/9.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
    {id:'28',title:'Caption two', desc:'Lorem ipsum description 2',imageName: require('../../assets/images/photos/PosyanduAngrrekBulan12ulu/10.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
    {id:'29',title:'Caption two', desc:'Lorem ipsum description 2',imageName: require('../../assets/images/photos/PosyanduAngrrekBulan12ulu/11.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
    {id:'30',title:'Caption two', desc:'Lorem ipsum description 2',imageName: require('../../assets/images/photos/PosyanduAngrrekBulan12ulu/12.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
    {id:'31',title:'Caption two', desc:'Lorem ipsum description 2',imageName: require('../../assets/images/photos/PosyanduAngrrekBulan12ulu/13.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
    {id:'32',title:'Caption two', desc:'Lorem ipsum description 2',imageName: require('../../assets/images/photos/PosyanduAngrrekBulan12ulu/14.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
    {id:'33',title:'Caption two', desc:'Lorem ipsum description 2',imageName: require('../../assets/images/photos/PosyanduAngrrekBulan12ulu/15.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
    {id:'34',title:'Caption two', desc:'Lorem ipsum description 2',imageName: require('../../assets/images/photos/PosyanduAngrrekBulan12ulu/16.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
    {id:'35',title:'Caption two', desc:'Lorem ipsum description 2',imageName: require('../../assets/images/photos/PosyanduAngrrekBulan12ulu/17.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
  ]
  const [noItem, setNoItem] = useState(8)
  const sliceItems = images.slice(0, noItem)

  const loadMore =()=> setNoItem(noItem + noItem)
   
  return (
    <div className='container pt-5 mt-5' style={{maxWidth:'80%'}}>
        <h1 className='mb-5'>Foto Galeri</h1>
        <div className="row row-cols-4 g-2 photo-galleries">
          {sliceItems.map(item=>(
            <div className="col">
            <div className="card">
             <a href={`${item.imageName}`} >
                <img src={item.imageName} className="card-img-top gallery-photo" alt="..."/>
              </a>
              {/* <div className="card-body">
                <h5 className="card-title">Card title</h5>
                <p className="card-text">
                  This is a longer card with supporting text below as a natural lead-in to
                  additional content. This content is a little bit longer.
                </p>
              </div> */}
            </div>
          </div>
          ))}
        </div>     
        <div className='text-center mt-5 mb-5 d-block'>
          {noItem < images.length &&  
            <button 
              className='btn btn-success'
              onClick={()=>loadMore()}>
                  Load More
            </button>
          }
        </div>
         
    </div>
  )
}

export default GalleryPhoto