import React, { useEffect } from 'react'
import './survey.css'
import graph_stunting from '../../assets/images/survey/grap_stunting.png'
import graph_wasting from '../../assets/images/survey/grap_wasting.png'

const Survey = () => {
  const table1 = [
    {
      no:1,
      question: 'Stunting is a child who is short compared to children his age',
      correct: 75,
      wrong:15 
    },
    {
      no:2,
      question: 'A quiet child is a stunted child',
      correct: 44,
      wrong:43 
    },
    {
      no:3,
      question: 'Toddlers experience obesity and height Excessive exercise is also part of the symptoms of stunting.',
      correct: 56,
      wrong:31 
    },
    {
      no:4,
      question: 'Calculating stunting by comparing height and weight based on age',
      correct: 77,
      wrong:10 
    },
    {
      no:5,
      question: 'Lack of blood (anemia) is one of the causes of stunting',
      correct: 50,
      wrong:37 
    },
    {
      no:6,
      question: 'Stunting can occur starting from a pregnancy where there is a lack of nutrients',
      correct: 44,
      wrong:43 
    },
    {
      no:7,
      question: 'Maternal age influences the occurrence of stunting',
      correct: 42,
      wrong:45 
    },
    {
      no:8,
      question: 'The function of nutritious food is to ensure normal child growth according to children of their age',
      correct: 83,
      wrong:4 
    },
    {
      no:9,
      question: 'The impact of stunting can cause children to get sick easily.',
      correct: 70,
      wrong:17
    },
    {
      no:10,
      question: 'Children aged less than 6 months should only be given breast milk',
      correct: 78,
      wrong:9 
    },
    {
      no:11,
      question: 'The long-term impact of stunting can cause diabetes',
      correct: 47,
      wrong:40 
    },
    {
      no:12,
      question: 'Food for babies less than 6 months is porridge and mashed bananas.',
      correct: 49,
      wrong:38 
    },
    {
      no:13,
      question: 'Children 7 months old can eat family food',
      correct: 68,
      wrong:19 
    },
    {
      no:14,
      question: 'Breast milk should be given until the child is 5 years old.',
      correct: 73,
      wrong:14 
    },
    {
      no:15,
      question: 'Provide complete basic immunization and vitamin A is one form of stunting prevention',
      correct: 82,
      wrong:5 
    },
    {
      no:16,
      question: 'To help children`s growth and development, food with a variety of nutrients is needed',
      correct: 87,
      wrong:0 
    },
  ]
  const table2 = [
    {
      no:1,
      question: 'Wasting is a child who is overweight',
      correct: 36,
      wrong:51 
    },
    {
      no:2,
      question: 'A child is said to be wasting if the body weight does not match the child`s age',
      correct: 74,
      wrong:13 
    },
    {
      no:3,
      question: 'Diarrhea does not cause wasting in children',
      correct: 45,
      wrong:52 
    },
    {
      no:4,
      question: 'The long-term impact of wasting can affect children`s intelligence',
      correct: 72,
      wrong:15 
    },
    {
      no:5,
      question: 'Toddler nutrition depends on the amount of food they eat.',
      correct: 14,
      wrong:73 
    },
    {
      no:6,
      question: 'Protein, fat, carbohydrates, minerals, vitamins and water are food substances for children`s growth and development',
      correct: 86,
      wrong:1 
    },
    {
      no:7,
      question: 'Fish, meat, eggs are sources of protein',
      correct: 85,
      wrong:2 
    },
    {
      no:8,
      question: 'Malnutrition is characterized by a child`s lack of appetite',
      correct: 76,
      wrong:11 
    },
    {
      no:9,
      question: 'Wasting is a genetic/hereditary factor',
      correct: 41,
      wrong:46
    },
    {
      no:10,
      question: 'Exclusive breastfeeding can increase the baby`s immunity against disease',
      correct: 84,
      wrong:3 
    },
    {
      no:11,
      question: 'Insufficient food intake does not affect children`s growth and development',
      correct: 48,
      wrong:39 
    },
    {
      no:12,
      question: 'Wasting can be caused by repeated infections',
      correct: 66,
      wrong:21 
    },
    {
      no:13,
      question: 'Environmental cleanliness indirectly prevents wasting',
      correct: 79,
      wrong:8 
    },
    {
      no:14,
      question: 'Fulfillment of children`s nutrition is provided so that children are fat',
      correct: 21,
      wrong:66 
    },
    {
      no:15,
      question: 'Maintaining children`s health is done by frequently checking children`s growth and providing hard-textured foods',
      correct: 36,
      wrong:51 
    },
    {
      no:16,
      question: 'Respiratory tract infections (ARI) cannot affect a child`s weight',
      correct: 45,
      wrong:42 
    },
  ]
  
  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])

  return (
    <div className='container mt-5 pt-5 mb-5' style={{maxWidth:'80%'}}>
      <h1>Hasil Survey <br/>
          <span className='h4'>Pengetahuan Sikap dan Perilaku Stunting dan Wasting</span>
    </h1>
    <h4 className='mt-5'>Pengetahuan Ibu Tentang Stunting</h4>
      <table class="table table-striped border">
        <thead>
        <tr>
            <th rowspan="2" colspan="1" style={{borderRight:'1px solid #ccc'}}>
               NO
            </th>
            <th rowspan="2" colspan="4" className='text-center'>
                QUESTION
            </th>
            <th rowspan="1" colspan="2" className=' text-center' style={{borderLeft:'1px solid #ccc'}}>
               ANSWER
            </th>
        </tr>
        <tr className='border'>
            <th style={{borderLeft:'1px solid #ccc'}}>Correct</th>
            <th>Wrong</th>
        </tr>
    </thead>
        <tbody>
          {table1.map(item=>(
                <tr>
                 <th scope="row">{item.no}</th>
                 <td colSpan={4}>{item.question}</td>
                 <td>{item.correct}</td>
                 <td>{item.wrong}</td>
               </tr>
          ))}
        </tbody>
      </table>
        
      <div className='mt-5 py-5'>
        <img src={graph_stunting} alt='' width={'70%'}  style={{marginLeft:'12%'}}/>
        <p>
        Berdasarkan grafik pengetahuan ibu terhadap stunting, dari total 87 responden hanya 1 pertanyaan yang dapat di jawab responden dengan benar, 12 pertanyaan sudah dapat di jawab benar lebih dari 50 responden, dan 4 pertanyaan lainnya dijawab benar oleh kurang dari 5 responden. Hal ini menunjukan bahwa ibu sudah mulai menegtahui ap aitu stunting dan hal-hal yang berhubungan dengan stunting, tetapi tidak menutup kemungkinan bahwa masih banyak juga ibu yang tidak mengetahui perihal stunting. 
        </p>
      </div>
      <h4 className='mt-5'>Pengetahuan Ibu Tentang Wasting</h4>
     
     <table class="table table-striped border">
       <thead>
       <tr>
           <th rowspan="2" colspan="1" style={{borderRight:'1px solid #ccc'}} >
              NO
           </th>
           <th rowspan="2" colspan="4" className='text-center'>
               QUESTION
           </th>
           <th rowspan="1" colspan="2" className=' text-center' style={{borderLeft:'1px solid #ccc'}}>
              ANSWER
           </th>
       </tr>
       <tr className='border'>
           <th style={{borderLeft:'1px solid #ccc'}}>Correct</th>
           <th>Wrong</th>
       </tr>
   </thead>
       <tbody>
         {table2.map(item=>(
               <tr>
                <th scope="row">{item.no}</th>
                <td colSpan={4}>{item.question}</td>
                <td>{item.correct}</td>
                <td>{item.wrong}</td>
              </tr>
         ))}
       </tbody>
     </table>
     <div className='pb-5 py-5 mt-5'>
      <img src={graph_wasting} alt='' width={'70%'}  style={{marginLeft:'12%'}}/>
        <p className='mb-5'>
        Berdasarakan grafik pengetahuan ibu tentang wasting, dari 87 responden belum ada 1 pertanyaan yang di jawab benar oleh semua responden, terdapat 8 pertanyaan yang sudah dijawab benar lebih dari 50 responden, dan 8 pertayaan dijawab benar oleh kurang dari 50 responden, masih banyak responden yang tidak mengetahui apa itu wasting dan hal-hal lainnya yang berkaitan dengan wasting, lebih banyak ibu yang tidak mengetahui apa itu wasting jika dibandingkan dengan pengetahuan itu terhadap stunting. 
      </p>
     </div>
    </div>
  )
}

export default Survey