import React, { useState } from 'react'
import './ebook.css'

const Ebook = () => {
  const ebook = [
    {  
      "Name": "Najmah, SKM., MPH., Ph.D | Citrasari, SKM, MKM | Erlita Aisyah, SKM | Muhammad Ridwan Anshori | Sylpi Kharisma Afrisae | Citra Ayu Ar Rahma",
      "Title": "Kampung Bebas Budak (Anak) Stunting",
      "SubTitle": '"Catatan Kader-Kader Posyandu Sumatera Selatan"',
      "Src":require("../../assets/ebook/cover/kampung_bebas_budak_stunting.png"),
      "Desc":"<p>Buku Kampung Bebas Budak (Anak) Stunting&quot; adalah buku yang dibuat dengan tujuan untuk menurunkan angka stunting pada anak di Indonesia, dengan menyajikan data dan informasi menarik dan mudah untuk dipelajari terkait stunting yang terjadi di Sumatera Selatan dan terdiri dari beberapa bab yakni:</p>1.Motivasi Menjadi Kader Posyandu<br/>2.Prevalensi Stunting di Indonesia<br/>3.Apa Itu Stunting? <br/>4.Apa Itu Keluarga Beresiko Stunting?<br>5.Interseksi Penyebab Stunting di Indonesia<br>6.Kebijakan Terkait Stunting<br>7.Kasus Stunting Yang Sulit Dideteksi<br>8.Suara Kader: Pengalaman Pendampingan Keluarga Beresiko Stunting diLapangan<br>9.Strategi Untuk Menormalisasi Deteksi Dini Anak Beresiko Stunting<br/>10.Strategi Pencegahan Stunting Pada Remaja, Catin dan Ibu Hamil<br/> 11.Nilai Gizi Makanan Bingen Khas Sumatera Selatan",
      "pdf": require('../../assets/ebook/Kampung bebas budak (anak) Stunting ISBN FIXX A4.pdf')
    },
    {  
      "Name": "Najmah, SKM, MPH., Ph.D",
      "Title": "Kampung Bebas Budak (Anak) Stunting & Wasting",
      "Src":require("../../assets/ebook/cover/kampung_bebas_budak_(anak)_stunting_wasting.png"),
      "Desc":"Kita harus rajin pergi ke posyandu biar kita tahu berat badan budak tadi tuh apa naik, Nah sudah tinggibadannya Kita harus rajin pergi ke posyandu biar kita tahu berat badan budak tadi tuh apa naik, Nah sudah tinggi badannya tadi apa naik aku udah kan tiap bulan kita rajin rajin ke posyandu terus apabila anak tadi demam suhu tinggi cepat lah kita nih bawa ke dokter jangan sampai menyesal kemudian ya ini terus tuh rumah sehat bebas rokok ini nih di dalam rumah tadi tuh harus ada kotak sampah. Aliran rumah tadi bersih, sungai musinya sampah ada. Pokoknya macam macam lah dalam itu. Jangan sampai suaminya ngerokok, suruh keluar. Nah itu itu rumah rumah sehat bebas rokok amin.",
      "pdf": require('../../assets/ebook/kampung bebas budak (anak) stunting dan wasting.pdf')
    },
    {  
      "Name": "Najmah, Ph.D, Riska Isnaini, S.Gz, Hizeria Apriansyari, S.Gz Sita Nariswari, S.Gz, Syafira, Nanda Irwantika,Haniah Rafifah, Fadhilah Nur Arafah Aisyah Oktarina, Febi Ananta Indah Cahyani, Aisyah Rizqiah Khairunnisa, Happy Mira Jordanti",
      "Title": "Palembang Cuisines: Heritage & Authentic Recipes",
      "Src":require("../../assets/ebook/cover/palembang_cuisines_heritage_and_authentic_recipes.png"),
      "Desc":"Buku ini merupakan buku kedua (Buku 1: Palembang heritage food: The Insider stories, Salim Media, 2021) diharapkan mampu menjadi bagian dari karya yang besar dalam pelestarian makanan bingen di Kota Palembang karena memberikan informasi yang bermanfaat bagi masyarakat luas mengenai makanan bingen khas Palembang, nilai-nilai gizi dari makanan bingen, refleksi keilmuan gizi yang dikaitkan dengan kondisi di lapangan dan upaya pelestarian makanan bingen yang dikemas dengan Bahasa sederhana dan mudah dipahami.",
      "pdf": require('../../assets/ebook/Palembang Cuisines_ Heritage Food and Authentic Recipes.pdf')
    },
    {  
      "Name": "Najmah - Citra Ayu Ar Rahma - Shinta Azizah - Anisa Malfiro - Yunida Zhafirah - Mala Ramawati - Erni Iqlima - Melika Susilawati Mahwa Savira - Deva Putri Aliza",
      "Title": "Aku Sayang Lingkungan, Lingkungan Sayang Aku",
      "SubTitle":"Kelompok Pendidikan Anak Usia Dini dan Sekolah Dasar",
      "Src":require("../../assets/ebook/cover/aku_sayang_lingkungan.png"),
      "Desc":'Buku yang ditulis dengan tema "Aku Sayang Lingkungan, Lingkungan sayang aku" bertujuan untuk memberikan pendidikan mengenai hidup bersih dan sehat kepada anak-anak agar mereka mencintai dan merawat diri dan lingkungan di sekitarnya. Dengan menyajikan materi mengenai lingkungan, kesehatan, kebersihan, dan pendidikan matematika dasar yang menarik dan menyenangkan untuk dipelajari oleh anak-anak.',
      "pdf": require('../../assets/ebook/Aku Sayang Lingkungan, Lingkungan Sayang Aku ISBN.pdf')
    },
    {  
      "Name": "NAJMAH, SKM., M.P.H., Ph.D | DITIA FITRI ARINDA, S.GZ., M.P.H | NANDA IRWANTIKA | HANIAH RAFIFAH | NITA APRIANI | KOMARIAH ",
      "Title": "KULINER BERBAHAN DASAR TEMPE DAN IKAN",
      "SubTitle":"",
      "Src":require("../../assets/ebook/cover/kuliner_berbahan_dasar_tempe_dan_ikan.png"),
      "Desc":'Pola makan yang sehat harus diberikan sedini mungkin agar menjadi kebiasaan yang baik dalam fase tumbuh kembang anak. Jika anak dan seluruh anggota keluarga terbiasa mengonsumsi makanan yang seimbang dan sehat, akan membantu mengurangi resiko kejadian penyakit degeneratif seperti darah tinggi, diabetes mellitus, penyakit jantung koroner, obesitas, kolesterol, gagal ginjal, kanker dan lain sebagainya. Pola makan yang baik dan seimbang perlu memperhatikan Jumlah asupan yang dimakan, Frekuensi seberapa sering makanan tersebut dimakan, dan Jenis makanannya apakah bervariasi atau tidak.',
      "pdf": require('../../assets/ebook/Buku Resep.pdf')
    },
    {  
      "Name": "NAJMAH, SKM., M.P.H., Ph.D | Dr.FENTY APRINA, M.Kes | ZARIANIS, SKM, M.Si | ARPANSAH, SKM, M.Si ",
      "Title": "BUKU SAKU STUNTING KOTA PALEMBANG",
      "SubTitle":"",
      "Src":require("../../assets/ebook/cover/buku_saku_stunting.png"),
      "Desc":'Amplop Stunting adalah media edukasi yang berisikan informasi-informasi penting terkait apa itu Stunting hingga bagaimana kita bisa mencegah stunting disemua kalangan. amplop Stunting berisikan lembaran-lembaran poster yang telah dikemas dengan bahasa lokal Palembang dan telah di design menarik secara padat, singkat dan jelas agar para pembaca dapat menerima informasi dengan mudah.',
      "pdf": require('../../assets/ebook/amplop stunting _20240102_145533_0000.pdf')
    },
    {  
      "Name": "NAJMAH, SKM., M.P.H., Ph.D",
      "Title": "KAMPUNG BEBAS BUDAK (ANAK) STUNTING & WASTING 15 ULU",
      "SubTitle":"",
      "Src":require("../../assets/ebook/cover/kampung_bebas_stunting_wasting_15ulu.png"),
      "Desc":'Untuk mewujudkan daerah yang bebas dari anak berisiki stunting dan wasting, maka diperlukannya semua peran dalam pencapaian visi ini baik itu dari tenaga kesehatan, kader posyandu, ibu, keluarga, hingga lingkungan yang mendukung. Buku ini menggambarkan sudut pandang masyarakat 15 Ulu terkait stunting dan wasting, selain itu upaya-upaya yang telah dilakukan dalam perwujudan visi tersebut, mulai dari lingkungan, pendapatan, aktivitas, pola asuh, pola makan, hingga nutrisi makanan anak.',
      "pdf": require('../../assets/ebook/Kampung Bebas Budak (Anak) Stunting & Wasting_20231224_132336_0000.pdf')
    },
    {  
      "Name": "NAJMAH, S.KM,. M.PH,. Ph.D | AZRA ASYIFA KAMILA | RISMA AZRIANTI | NUR HASANAH | NURIL FIJRIAH | PUTRI SAVA ELFINA | MAULIDYA FAZALIKA | SHABRINA PUTRI JOHANI | MURIARA WAHYULIANA ",
      "Title": "ENGLISH IS FUN, MY FOOD MY SUPERPOWER - (SERIES 3)",
      "SubTitle":"",
      "Src":require("../../assets/ebook/cover/english_fun_ser3.png"),
      "Desc":'My Food My Superpower merupakan buku edukasi yang berfokus kepada pengenalan makanan yang baik untuk tubuh dan hal-hal yang harus kita lakukan dalam menjaga kesehatan terutama gizi untuk diri sendiri. Selain itu didalam buku My Food My Superpower juga diselipkan juga sex education melalui lagu-lagu. My Food My Superpower juga memiliki games yang attractive, space untuk mewarnai hingga teka-teki terkait topik pembahasan.',
      "pdf": require('../../assets/ebook/series 3 English is Fun_My Food My Superpower.pdf')
    },
    {  
      "Name": "NAJMAH, S.KM., M.Ph., PhD | SAKIRA SEPTY | ZAHIDAH MURSYIDAH | BELLA RINO SAPHIRA | SITI NUR HUMAIRAH | NAJMA HOIRUNNISA | TIUR ANGGELINA | NADYA SELFA",
      "Title": "ENGLISH IS FUN, MY MANNERS GUIDE - (SERIES 2)",
      "SubTitle":"",
      "Src":require("../../assets/ebook/cover/english_fun_ser2.png"),
      "Desc":'My Manners Guide merupakan buku edukasi yang memiliki fokus membahas adab dan tata cara anak-anak kepada orang yang lebih tua, teman hingga yang lebih mudah, selain itu juga membahas tata cara anak-anak terhadap lingkungan nya bagaimana seperti menjaga lingkungannya. Selain itu didalam buku My Manners Guide ini juga diselipkan juga sex education melalui lagu-lagu. My Manners Guide juga memiliki games yang attractive, space untuk mewarnai hingga teka-teki terkait topik pembahasan.',
      "pdf": require('../../assets/ebook/Series 2 English is Fun_My Manners Guide.pdf')
    },
    {  
      "Name": "NAJMAH | AULIA GHANIYA PUTRI | AURELLIA RAHMA | AZZANAURAH | CLARESTA FLOREAN FADHILLAH | HANIM FADHILAH | KHAFIFAH HAZNARTI REPALIA | WULAN MARINDA HUTAJALU  ",
      "Title": "ENGLISH IS FUN, I'M THE BOSS OF MY BODY - (SERIES 1)",
      "SubTitle":"",
      "Src":require("../../assets/ebook/cover/english_fun_ser1.png"),
      "Desc":'I’m The Boss of My Body merupakan buku edukasi yang berfokus kepada pengenalan anggota tubuh kita dan sex education yang sudah dikemas sesuai dengan porsi anak-anak. Sex education diberikan melalui lagu “Sentuhan Boleh dan Sentuhan Tidak Boleh” dan tentunya dilengkapi dengan banyak game attractive dan space mewarnai bagi anak-anak sehingga tidak akan membuat jenuh, tak lupa juga selalu disisipkan materi PHBS seperti mencuci tangan hingga menjaga lingkungan disetiap bukunya.',
      "pdf": require('../../assets/ebook/Series 1 English is Fun_IM The Boss of My Body.pdf')
    },
].sort().reverse()
const [noItem, setNoItem] = useState(3)
const sliceItems = ebook.slice(0, noItem)

const loadMore =()=> setNoItem(noItem + noItem)

  return (
    <div className='container  pt-5 mt-5 mb-5' style={{maxWidth:'80%'}} >
       <h1>Ebook Gratis</h1>
       <div className='row teams-card'>
      {sliceItems.map(book =>(
        <div className="card border-0 card-no-hover gap-5 border-bottom mb-5" key={book.Title}>
            <div>
              <img src={book.Src} className="card-img-top ebook-img" alt='ebook 1' />
              <a href={book.pdf} download className='btn btn-success rounded-0 mt-2 mb-2'>Download</a>
            </div>
            <div className="card-body">
              <h5 className='card-title text-uppercase book-title'>{book.Title}<br/> </h5>
              <p className='fw-bold'>{book.SubTitle}</p>
              <h6 className="text-success fw-bold">{book.Name} <span className='fw-normal'>(Penulis)</span></h6>
              <h4 className='fst-italic pt-2'>Sinopsis</h4>
              <p className="card-text" dangerouslySetInnerHTML={{__html:book.Desc}}></p>
              {/* <a href="/#" className="btn btn-primary">Go somewhere</a> */}
            </div>
         </div>
      ))}
      </div>  
      <div className='text-center d-block mt-5 mb-5'>
      {noItem < ebook.length &&
        <button 
          className='btn btn-success'
          onClick={()=>loadMore()}>
            Load More
        </button>
      }
    </div>     
    </div>
  )
}

export default Ebook