import './team.css'


const Teams = () => {
  const images = [
    {
      "Name":"dr. H. Trisnawarman, M.Kes., Sp.KKLP",
      "Title":"Advisory Boards",
      "Src":require("../../assets/images/teams/trisnawarman.png"),
      "Desc":"dr. H. Trisnawarman, M.Kes., Sp.KKLP, lahir di Palembang tanggal 9 September 1966. Beliau menempuh pendidikan S-1 Kedokteran di Universitas Unsyiah Banda Aceh kemudian melanjutkan S-2 ilmu kesehatan masyarakat di STIKES Bina Husada Palembang dan Spesialis Kedokteran Keluarga Layanan Primer dari KIKKI Pusat . Beliau memiliki riwayat jabatan sebagai Kepala Puskesmas Payaraman Kab. Ogan Ilir (2004-2006), Kepala Puskesmas Sungai Lebung Pemulutan Selatan Kab. Ogan Ilir (2006-2009), Kepala Puskesmas Pemulutan Induk Kab. Ogan Ilir (2009-2010), Kepala Seksi Pelayanan Kesehatan Dinas Kesehatan Kab. Ogan Ilir (2010-2011), Kepala UPTD RSK Paru Dinas Kesehatan Prov. Sumsel (2011-2014), Kepala Bidang Pelayanan Kesehatan Dinas Kesehatan Prov. Sumsel (2014-2016) dan Sekretaris Dinas Kesehatan Prov. Sumsel (2016-2022). Saat ini beliau diamanahkan jabatan sebagai Kepala Dinas Kesehatan Prov. Sumsel (2022 s.d. sekarang)."
    }, 
    {
      "Name":"H. Fery Fahrizal, S.KM., M.KM",
      "Title":"Advisory Boards",
      "Src":require("../../assets/images/teams/fery.png"),
      "Desc":"H. Fery FahrizaL, S.KM., M.KM, lahir di Belitung, 19 Februari 1968. Fery menempuh jenjang pendidikan D3 AKPER DEPKES Palembang, kemudian melanjutkan S1 di Universitas Sumatera Utara, dan S2 di Universitas Indonesia. Memiliki riwayat jabatan Kepala Seksi Perencanaan, Kepala Bidang Bina Program, dan Kepala Bidang Kesehatan Masyarakat Dinas Kesehatan Provinsi Sumatera Selatan. Fery juga merupakan ketua PPPKMI Sumatera Selatan, saat ini Fery menjabat sebagai Sekretaris Dinas Kesehatan Provinsi Sumatera Selatan."
    }, 
    {
      "Name":"Ibu dr. Fenty Aprina, M. Kes",
      "Title":"Advisory Boards",
      "Src":require("../../assets/images/teams/fenty.png"),
      "Desc":"Ibu dr. Fenty Aprina, M. Kes merupakan Kepala Dinas Kesehatan Kota Palembang periode ini. Beliau lahir di Plaju, 1 April 1967. Adapun riwayat jabatan yang pernah diamanahkan antara lain, seagai Kepala Puskesmas Karya Maju, Puskesmas Sungai Lilin, Puskesmas Sembawa; Kepala Seksi Kesehatan Keluarga Dinas Kesehatan Banyuasin; Kepala Seksi pelayanan kesehatan rujukan dan pembiayaan kesehatan Dinas Kesehatan Banyuasin; Kepala Seksi Perencanaan Dinas Kesehatan Banyuasin; Kepala Bidang Pelayanan Kesehatan Dinas Kesehatan Provinsi Sumsel; Pelaksana Tugas Kepala Dinas Kesehatan Provinsi Banyuasin; Kepala UPTD Bapelkes Provinsi Sumatera Selatan; Kepala Bagian Pengembangan RS Ernaldi Bahar Provinsi Sumsel; Wakil Dirut Umum dan Keuangan RS Ernaldi Bahar Provinsi Sumatera Selatan dan sekarang diamanahkan menjadi. Kepala Dinas Kesehatan Kota Palembang. Contact person ibu Fenty melalui aprinafenty01@gmail.com dan https://www.facebook.com/fenty.aprina."
    }, 
    {
      "Name":"Iwan Stiabudi, SKM, M.Kes",
      "Title":"Advisory Boards",
      "Src":require("../../assets/images/teams/iwan.png"),
      "Desc":"Iwan Stiabudi, SKM, M.Kes adalah dosen di Fakultas Kesehatan Masyarakat Universitas Sriwijaya, Sumatera Selatan, Indonesia dan  pakar kebijakan kesehatan masyarakat terkemuka. Dia menyelesaikan Program Magister dari Universitas Airlangga Indonesia pada tahun 2011 dan kini sedang menempuh Program Doktor di Universitas Sriwijaya. Pengalamannya menjadi Pembina penyusunan perencanaan strategis di Dinas Kesehatan Kabupaten Sumatera Selatan; program pembinaan ini dilaksanakan karena Fakultas Kesehatan Masyarakat Universitas Sriwijaya bekerjasama dengan Kementerian Kesehatan RI pada tahun 2021-2022 mengenai pencegahan dan pengendalian masalah kesehatan masyarakat. (email: iwans1977@gmail.com/iwans_stia@fkm.unsri.ac.id)"
    }, 
    {
    "Name": "Najmah",
    "Title": "CEO, Researcher, Lecturer",
    "Src":require("../../assets/images/teams/najmah.png"),
    "Desc":"<p>Najmah adalah dosen Fakultas Kesehatan Masyarakat Universitas Sriwijaya, Sumatera Selatan, Indonesia. Najmah adalah pakar HIV dan COVID-19 terkemuka di Indonesia. Najmah memperoleh Beasiswa bergengsi Selandia Baru untuk studi doktoralnya dan lulus dari Auckland University of Technology pada tahun 2020. Najmah juga memiliki gelar dari University of Melbourne, di mana ia belajar dengan Beasiswa Kemitraan Australia &nbsp;(AusAID) dan gelar sarjananya di Universitas Sriwijaya (Unsri), Sumatera Selatan. Hasil penelitian Najmah dapat diakses oleh audiens Indonesia dan non akademik. Minat penelitian Najmah adalah pemberdayaan perempuan, HIV, COVID-19, stunting dan wasting.&nbsp;</p><p>(email: najem240783@yahoo.com/najmah@fkm.unsri.ac.id/instagram: najmah.usman.7)</p>"
  },
    {
      "Name": "Nanda Irwantika",
      "Title": "Core Research Assistants",
      "Src": require("../../assets/images/teams/nandairwantika.png"),
      "Desc":"Nanda Irwantika lahir di Palembang pada tanggal 31 Maret 2004. Ia merupakan seorang mahasiswi Program Studi Gizi di Fakultas Kesehatan Masyarakat Universitas Sriwijaya dan Alumni SMK Kesehatan Athalla Putra. Sejak tahun 2021, Nanda aktif menjadi relawan dalam kegiatan mengajar Bahasa Inggris dan pengembangan soft skill. Nanda telah menjadi relawan di Kampung Pandai Sumatera Selatan, English Camps, serta Tim Etnografi Makanan Bingen Khas Palembang. Ikut serta dalam beberapa kepenulisan buku yakni “Palembang Heritage Food” dan buku resep “Kuliner Berbahan Dasar Tempe dan Ikan” yang dimana pada masing-masing buku tersebut dilengkapi nilai gizi."
    },
    {
      "Name": "Novia Sri Anda Yani",
      "Title": "Core Research Assistants",
      "Src":require("../../assets/images/teams/noviasriandayani.png"),
      "Desc":"<p>Novia Sri Anda Yani lahir di Palembang pada tanggal 20 November 2004. Ia merupakan seorang mahasiswi Program Studi Kesehatan Masyarakat di Fakultas Kesehatan Masyarakat Universitas Sriwijaya dan Alumni SMA YPI Tunas Bangs. Sejak tahun 2022, ia aktif menjadi relawan di Kampung Pandai Indralaya, salah satu penulis buku &quot;I&apos;M The Boss of My Body&quot;, Editor buku &quot;I&apos;M The Boss of My Body&quot;, &quot;My Manners Guide&quot;, dan &quot;My Food My Superpower&quot; serta salah satu relawan dalam KSR PMI Universitas Sriwijaya.&nbsp;</p>"
    },
    {
      "Name": "Linda Fitrisusanti",
      "Title": "Core Research Assistants",
      "Src": require("../../assets/images/teams/lindafitriastuti.png"),
      "Desc": "<p>Linda Fitrisusanti, lahir di Pulau Gemantung 14 Mei 2002 merupakan mahasiswa semester 6 &nbsp;Program Studi Kesehatan Masyarakat Universitas Sriwijaya, Pemitanatan Epidemiologi. Menjadi salah satu penulis buku &ldquo;Aku, Keluargaku, dan Kehidupan Sosialku (Perjuangan Tanpa Jeda bagi Waria di Kota Palembang)&rdquo; dan salah satu penulis artikel &ldquo;Benarkah Sosial Media Bisa Menjadi Pemicu Bunuh Diri?: Menyelisik Keterkaitan Sosial Media terhadap Kesehatan Mental Remaja.&rdquo; Serta pernah menjadi Volunteer dalam Penulisan &ldquo;Profil Stunting Dinas Kesehatan Provinsi Sumatera Selatan.</p>"
    },
    {
      "Name": "Mawaddah Dyah Popita, S.KM",
      "Title": "Core Research Assistants",
      "Src": require("../../assets/images/teams/mawaddah.png"),
      "Desc":"<p>Mawaddah Dyah Popita, S.KM, alumi FKM UNSRI 2022, berfokus pada Biostatistika dan Informasi Kesehatan di Prodi Ilmu Kesehatan Masyarakat Fakultas Kesehatan Masyarakat Universitas Sriwijaya. Selama studinya, berkontribusi dalam penulisan chapter dalam buku &quot;BUKU ILMU KESEHATAN MASYARAKAT: Interaksi Media, Partisipasi, dan Seni&quot; tentang aplikasi Sistem Informasi Geografis di kesehatan. Aktif dalam webinar Analisis Data Multivariat: Logistik dan Linier oleh Mahasiswa &nbsp;Biostatistik 2018, serta terlibat dalam tim analisis data Profil HIV Kota Palembang (2018-2021) dengan Dinas Kesehatan Kota Palembang dan FKM Unsri. Pengalaman kerjanya termasuk menjadi Co-Trainer Pelatihan SPSS (Batch 1 &amp; 2) dan Co-Trainer Workshop &quot;Pengolahan Analisis Data Kesehatan,&quot; serta tim pendukung kriteria 9 Akreditasi S2 Kesehatan Masyarakat Universitas Sriwijaya.</p>"
    },
    {
      "Name": "Dedis Oktaviani",
      "Title": "Supporting Teams",
      "Src": require("../../assets/images/teams/dedisoktaviani.png"),
      "Desc":"<p>Dedis Oktaviani, S.KM lahir di Palembang, 23 Mei 2002 merupakan alumni dari Universitas Sriwijaya jurusan Kesehatan Masyarakat peminatan Epidemiologi. Pernah menjadi kontributor penulisan buku &ldquo;HIV: Persepsi, Resiliensi, dan Pencegahan&rdquo; dan laporan penelitian untuk Profil PIS-PK Provinsi Sumatera Selatan. Selain itu pernah terlibat dalam kegiatan pengumpulan data untuk penelitian tesis dengan topik stunting, menjadi tim fasilitator dalam kegiatan ToT mahasiswa FKM Unsri untuk survei Kedaireka BKKBN tahun 2022, serta menjadi pemateri pelatihan SPSS dasar untuk mahasiswa FKM Unsri dalam projek &ldquo;Kampung Pandai Indralaya&rdquo;.</p>"
    },
    {
      "Name": "Shinta Azizah",
      "Title": "Supporting Teams",
      "Src": require("../../assets/images/teams/shintaazizah.png"),
      "Desc":"<p>Shinta Azizah lahir di Palembang, 09 Mei 2003 merupakan seorang mahasiswi aktif Fakultas Kesehatan Masyarakat Program Studi Kesehatan Lingkungan. Saat ini aktif mengikuti kegiatan kerelawanan yaitu English Camps dan Sanitary Camps serta juga menjadi bagian dari Kampung pandai Sumatera Selatan. Shinta juga menjadi bagian penulis beberapa buku yang telah diterbitkan, diantaranya &ldquo;Aku Sayang Lingkungan, Lingkungan Sayang Aku&rdquo;, &ldquo;English is Fun : Around The World&rdquo;, dan &ldquo;Mengenal 33 Jenis Toga di Arboretum Tanaman Obat Keluarga&rdquo;. Shinta juga menjadi bagian dalam penulisan dan analisis data &ldquo;Profil PIS-PK Kota Palembang 2023&rdquo;</p>"
    },
    {
      "Name": "Nurul Rahma",
      "Title": "Supporting Teams",
      "Src": require("../../assets/images/teams/nurulrahma.png"),
      "Desc":"<p>Nurul Rahma lahir pada tanggal 24 Oktober 2004 di Palembang, merupakan mahasiswi Program Studi Kesehatan Masyarakat di Universitas Sriwijaya. Ia telah menorehkan prestasi sebagai penulis dengan karya-karya seperti &quot;Desa Burai dan Kearifan Lokalnya&quot; dan &quot;My Manners Guide&quot;. Selain itu, pernah menjadi CEO di Kampung Pandai Indralaya season 2 &nbsp;terkait isu stunting di Desa Burai. Ia juga berkontribusi dalam Kampung Pandai Indralaya season 1 di SD Negeri 1 Indralaya Utara. Prestasi akademisnya juga gemilang, pernah meraih medali emas di Kompetisi Sains Bahasa Indonesia tingkat nasional 2023, dan medali perak di Kompetisi Madrasah Terintegrasi tingkat nasional 2022 dalam bidang Matematika."
    },
  ]

  const getImages =()=>{
    const listImages = images.map((item,index)=>{  
      let list = ''

      if(index % 2 === 0){
        list =  
        <div className='container teams-card' key={index}>
        <div className="card border-0 card-no-hover gap-5">
              <img src={item.Src} className="card-img-top" alt='' />
              <div className="card-body">
                <p>{item.Title}</p>
                <h5 className="card-title">{item.Name}</h5>
                <p className="card-text" dangerouslySetInnerHTML={{__html:item.Desc}}>
                </p>
                {/* <a href="/#" className="btn btn-primary">Go somewhere</a> */}
              </div>
              </div>
              </div>
      }else{
        list =  
        <div className='container' key={index}>
        <div className="card border-0 card-no-hover gap-5">
            <div className="card-body" style={{marginLeft:'-15px'}}>
              <p>{item.Title}</p>
              <h5 className="card-title">{item.Name}</h5>
              <p className="card-text"
              dangerouslySetInnerHTML={{__html:item.Desc}}>
              </p>
              {/* <a href="/#" className="btn btn-primary">Go somewhere</a> */}
            </div>
              <img src={item.Src} className="card-img-top" alt='' />
          </div>
          </div>
      }
        return list
      }
    )

    return listImages
  }

  return (
    <div className="container pb-5">
    <div className="row teams-card">
       { getImages () }
    </div>
  </div>
  )
}

export default Teams