import './gallery13Ulu.css'

const images = [
  {id:'1',title:'Kegiatan : Ibu bekerja/menambah penghasilan keluarga', desc:'<p>AA : &ldquo;<em>Eee.. apo e? Dio lagi&nbsp;</em><em>ngocek bawang, iyo buat tambah-tambah jajan anak lah</em><em>. Jadi kan,&nbsp;</em><em>cari duet</em><em>&rdquo;</em></p><p>NA : <em>&ldquo;Ibu nyari duet?&rdquo;</em></p><p>AA : <em>&ldquo;Iyo&rdquo;</em></p>',imageName: require('../../assets/images/photos/PosyanduAngrrekBulan12ulu/1.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'2',title:'Potret : Dampak stunting mengurangi kecerdasan', desc:'<p>AA : <em>&ldquo;Hmm.. dio itu..&nbsp;</em><em>sejenis stunting lah e</em><em>. Ee..&nbsp;</em><em>kurang gizinyo</em><em>&nbsp;bikin dio&nbsp;</em><em>mengurangi kecerdasannyo&rdquo;</em></p>',imageName: require('../../assets/images/photos/PosyanduAngrrekBulan12ulu/2.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'3',title:'Caption two', desc:'<p>BB : <em>&ldquo;</em><em>Bawak penyakit</em><em>&nbsp;hehehe&rdquo;</em</p><ul><li><strong>Sungai : Sumber penyakit</strong></li></ul>',imageName: require('../../assets/images/photos/PosyanduAngrrekBulan12ulu/3.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'4',title:'Caption two', desc:'<p>NA : <em>&ldquo;Apa makna ini bu, foto ini bu?&rdquo;</em></p><p>AA :<em>&ldquo;Maknanya,&nbsp;</em><em>lingkungan sekitarnya kurang bersih</em<em>,&nbsp;</em><em>membuat anak</em><em>&nbsp;apo e..&nbsp;</em<em>wasting</em><em>, membuat anak&nbsp;</em><em>diare-diare mak itu</em><em>&rdquo;</em></p><ul><li><strong>Halaman perumahan : Kurang bersih menyebabkan wasting dan diare</strong></li></ul>',imageName: require('../../assets/images/photos/PosyanduAngrrekBulan12ulu/4.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'5',title:'Caption two', desc:'',imageName: require('../../assets/images/photos/PosyanduAngrrekBulan12ulu/5.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'6',title:'Caption two', desc:'',imageName: require('../../assets/images/photos/PosyanduAngrrekBulan12ulu/6.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'7',title:'Caption two', desc:'',imageName: require('../../assets/images/photos/PosyanduAngrrekBulan12ulu/7.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'8',title:'Caption two', desc:'',imageName: require('../../assets/images/photos/PosyanduAngrrekBulan12ulu/8.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'9',title:'Caption two', desc:'',imageName: require('../../assets/images/photos/PosyanduAngrrekBulan12ulu/9.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'10',title:'Caption two', desc:'',imageName: require('../../assets/images/photos/PosyanduAngrrekBulan12ulu/10.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'11',title:'Caption two', desc:'',imageName: require('../../assets/images/photos/PosyanduAngrrekBulan12ulu/11.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'12',title:'Caption two', desc:'',imageName: require('../../assets/images/photos/PosyanduAngrrekBulan12ulu/12.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'13',title:'Caption two', desc:'',imageName: require('../../assets/images/photos/PosyanduAngrrekBulan12ulu/13.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'14',title:'Caption two', desc:'',imageName: require('../../assets/images/photos/PosyanduAngrrekBulan12ulu/14.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'15',title:'Caption two', desc:'',imageName: require('../../assets/images/photos/PosyanduAngrrekBulan12ulu/15.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'16',title:'Caption two', desc:'',imageName: require('../../assets/images/photos/PosyanduAngrrekBulan12ulu/16.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'17',title:'Caption two', desc:'',imageName: require('../../assets/images/photos/PosyanduAngrrekBulan12ulu/17.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'18',title:'Caption two', desc:'',imageName: require('../../assets/images/photos/PosyanduAngrrekBulan12ulu/18.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'19',title:'Caption two', desc:'',imageName: require('../../assets/images/photos/PosyanduAngrrekBulan12ulu/19.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'20',title:'Caption two', desc:'',imageName: require('../../assets/images/photos/PosyanduAngrrekBulan12ulu/20.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'21',title:'Caption two', desc:'',imageName: require('../../assets/images/photos/PosyanduAngrrekBulan12ulu/21.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'22',title:'Caption two', desc:'',imageName: require('../../assets/images/photos/PosyanduAngrrekBulan12ulu/22.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'23',title:'Caption two', desc:'',imageName: require('../../assets/images/photos/PosyanduAngrrekBulan12ulu/23.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'24',title:'Caption two', desc:'',imageName: require('../../assets/images/photos/PosyanduAngrrekBulan12ulu/24.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'25',title:'Caption two', desc:'',imageName: require('../../assets/images/photos/PosyanduAngrrekBulan12ulu/25.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'26',title:'Caption two', desc:'',imageName: require('../../assets/images/photos/PosyanduAngrrekBulan12ulu/26.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'27',title:'Caption two', desc:'',imageName: require('../../assets/images/photos/PosyanduAngrrekBulan12ulu/27.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'28',title:'Caption two', desc:'',imageName: require('../../assets/images/photos/PosyanduAngrrekBulan12ulu/28.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'29',title:'Caption two', desc:'',imageName: require('../../assets/images/photos/PosyanduAngrrekBulan12ulu/29.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'30',title:'Caption two', desc:'',imageName: require('../../assets/images/photos/PosyanduAngrrekBulan12ulu/30.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
  
]

const Gallery12Ulu =()=> {
  return(
    <div className='container'>
      <h3>Posyandu Anggrek Bulan 12 Ulu</h3>
      <div className="row row-cols-2 g-3">
        {images.map(item=>(
          <div className="col">
            <div className="card" style={{boxShadow:'none'}}>
              <img src={item.imageName} className="card-img-top"
                alt="" />
              <div className="card-body">
                <h5 className="card-title">{item.title}</h5>
                <p className='card-text' dangerouslySetInnerHTML={{__html:item.desc}} />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Gallery12Ulu