import { useState } from 'react'
import './petaStatic.css'

const images = [
  {id:'1',title:'Sebaran distribusi ibu hamil yang mendapat TTD', desc:'<p>Dari hasil pemetaan diatas, dapat diketahui bahwa sebaran ibu hamil yang diberi TTD minimal 90 tablet dengan kategori tinggi terdapat pada wilayah Kecamatan Ilir Barat I dan Kecamatan Sukarami, dilanjutkan pada daerah sebaran kategori sedang sebaran ibu hamil yang diberi TTD terdapat pada Kecamatan Seberang Ulu I, Kecamatan Jakabaring, Kecamatan Plaju, Kecamatan Kalidoni, dan Kecamatan Sako, sedangkan 10 kecamatan lainnya tergolong wilayah dengan kategori rendah.</p>',imageName: require('../../assets/images/petastatic/kecamatan/3.hamilttd.png'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'2',title:'Sebaran Ibu Hamil yang mengalami Anemia saat hamil', desc:'<p>Dari pemetaan diatas, dapat diketahui bahwa wilayah tinggi sebaran ibu hamil yang mengalami anemia terdapat pada Kecamatan Kertapati, Kecamatan Plaju, dan Kecamatan Seberang ULu I. Dilanjutkan pada wilayah sedang sebaran ibu hamil yang mengalami anemia terdapat pada Kecamatan Ilir Barat I, Kecamatan Alang-Alang Lebar, Kecamatan Jakabaring, Kecamatan Seberang Ulu II, Kecamatan Ilir Barat II, Kecamatan Sukarami dan Kecamatan Kalidoni. Sedangkan 8 Kecamatan Lainnya merupakan wilayah rendah sebaran Ibu hamil yang mengalami Anemia.</p>',imageName: require('../../assets/images/petastatic/kecamatan/4.ibu_anemia.png'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'3',title:'Sebaran Ibu Hamil yang mengalami KEK', desc:'Dari Pemetaan diatas dapat diketahui bahwa wilayah tinggi sebaran ibu hamil yang mengalami KEK terdapat pada Kecamatan Kertapati, dilanjutkan wilayah sedang sebaran Ibu hamil yang mengalami KEK terdapat pada Kecamatan Plaju, Kecamatan Sako, dan kecamatan Sukarami. Sedangkan 14 Kecamatan Lainnya merupakan wilayah rendah sebaran ibu hamil yang mengalami KEK.',imageName: require('../../assets/images/petastatic/kecamatan/5.ibukek.png'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'4',title:'Sebaran Bayi yang mendapatkan IMD', desc:'Dari Pemetaan Diatas dapat diketahui bahwa sebaran tinggi Bayi yang diberi IMD adalah Kecamatan Sukarami dengan jumlah 773 bayi. dilanjutkan pada wilayah sedang sebaran bayi yang diberi IMD terdapat di Kecamatan Alang-Alang Lebar, Kecamatan Kertapati, Kecamatan Seberang Ulu I, Kecamatan Seberang Ulu II, Kecamatan Sako dan Kecamatan Kalidoni. sedangkan 11 kecamatan lainnya merupakan wilayah rendah sebaran bayi yang diberi IMD.',imageName: require('../../assets/images/petastatic/kecamatan/6.IMD.png'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'5',title:'Sebaran Bayi yang diberikan Asi Eksklusif', desc:'Dari Pemetaan Diatas, dapat diketahui bahwa sebaran wilayah tinggi Bayi yang diberi Asi EKsklusif adalah Kecamatan Sukarami, dilanjutkan wilaya sedang sebaran bayi yang diberi Asi Eksklusif terdapat pada kecamatan Gandus, Kecamatan Jakabaring, Kecamatan Kalidoni, dan Kecamatan Sematang Borang. Sedangkan 13 Kecamatan Lainnya merupakan wilayah rendah sebaran Bayi yang diberi Asi Eksklusif',imageName: require('../../assets/images/petastatic/kecamatan/7.ekslusif.png'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'6',title:'Sebaran Baduta MP ASI', desc:'Dari Pemetaan diatas, dapat diketahui bahwa wilayah tinggi sebaran baduta yang diberikan MP-ASI terdapat pada Kecamatan Ilir Barat I, Kecamatan Seberang Ulu II, Kecamatan Kalidoni, Kecamatan Sako, dan Kecamatan Sukarami. dilanjutkan daerah kategori sedang terdapat pada Kecamatan Gandus, Kecamatan Seberang Ulu I, Kecamatan Jakabaring, Kecamatan Plaju, Kecamatan Ilir Timur I, Kecamatan Kemuning, Kecamatan Ilir Timur III, dan Kecamatan Semantang Borang. Sedangkan 4 Kecamatan Lainnya merupakan wilayah rendah sebaran Baduta yang diberi MP ASI.',imageName: require('../../assets/images/petastatic/kecamatan/8.bagdutaMPASi.png'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'7',title:'Sebaran Rematri yang diberi TTD', desc:'Dari pemetaan diatas, dapat diketahui bahwa wilayah tinggi sebaran rematri yang diberikan TTD terdapat pada Kecamatan Sukarami, dilanjutkan wilayah sedang terdapat pada Kecamatan Kertapati, Kecamatan Ilir Timur I, Kecamatan Ilir Timur III, Kecamatan Kemuning, dan Kecamatan Kalidoni. Sedangkan 12 Kecamatan Lainnya merupakan wilayah rendah sebaran rematri yang diberikan TTD.',imageName: require('../../assets/images/petastatic/kecamatan/9.rematriTTD.png'), tag: 'posyandu angrek bulan 12 ulu'},
  ]

const DeterminanKecamatan =()=> {
  const [noItem, setNoItem] = useState(4)
  const sliceItems = images.slice(0, noItem)

  const loadMore =()=> setNoItem(noItem + noItem)
  return(
   <> 
    <h2><strong>Determinan & Faktor Resiko</strong></h2>
    <h3>1. Berdasarkan Wilayah Kecamatan</h3>
    <div className="row row-cols-2 g-3">
      {sliceItems.map(item=>(
        <div className="col" key={item.title}>
          <div className="card" style={{boxShadow:'none'}}>
            <h4 className='m-2 ps-2'>{item.title}</h4>
            <a href={`${item.imageName}`}>
              <img src={item.imageName} 
                className={`cursor-pointer card-img-top zoom`}  
                alt="" loading='lazy'/>
            </a>
            <div className="card-body">
              {/* <h5 className="card-title">{item.title}</h5> */}
              <p className='card-text' dangerouslySetInnerHTML={{__html:item.desc}} />
            </div>
          </div>
        </div>
      ))}
    </div>
    <div className='text-center d-block mt-5 mb-5'>
      {noItem < images.length &&
        <button 
          className='btn btn-success'
          onClick={()=>loadMore()}>
            Load More Determinan Kecamatan
        </button>
      }
    </div>
  </>
  )
}

export default DeterminanKecamatan