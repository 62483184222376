import { useEffect } from 'react'
import './aboutus.css'

const AboutUs = () => {
  

  return (
    <div className='container py-5 pt-5 aboutus-section' style={{maxWidth:'80%', marginTop:'50px'}}> 
      <h1 className='mb-5'>What We Do</h1>
      {/* <h2 className='pb-2 pt-2'>M.U.S.I</h2> */}
      <p className='aboutus-text'>Akronim "M.U.S.I" dihubungkan dengan kegiatan penelitian "PEMODELAN KAMPUNG BEBAS STUNTING DAN WASTING DI BANTARAN SUNGAI MUSI" sebagai berikut:</p>
      <div className="row row-cols-4 g-2 about-row">
          <div className="col">
            <div className="card border-0 bg-warning" style={{boxShadow:'none'}}>
              {/* <img src={item.imageName} className="card-img-top" alt="" /> */}
              <div className="card-body">
                <h1 className="card-title text-center text-danger" style={{fontWeight:'900'}}>M</h1>
                <h4 className='pb-2 pt-2 text-center'>Mindfulness</h4>
                <p className='card-text'><strong>Mindfulness</strong> : Fokus saling bertukar pikiran dan informasi yang tepat dan akurat tentang stunting dan wasting kepada masyarakat bersama kader posyandu</p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card border-0 bg-danger" style={{boxShadow:'none'}}>
              {/* <img src={item.imageName} className="card-img-top" alt="" /> */}
              <div className="card-body">
                <h1 className="card-title text-center text-warning" style={{fontWeight:'900'}}>U</h1>
                <h4 className='pb-2 pt-2 text-center'>United for Children</h4>
                <p className='card-text'><strong>United for Children :</strong> Bersatu dalam tujuan bersama untuk mencegah stunting dan wasting pada anak-anak di sekitar Sungai Musi.</p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card border-0 bg-success" style={{boxShadow:'none'}}>
              {/* <img src={item.imageName} className="card-img-top" alt="" /> */}
              <div className="card-body">
                <h1 className="card-title text-center text-info" style={{fontWeight:'900'}}>S</h1>
                <h4 className='pb-2 pt-2 text-center'>Socialisation</h4>
                <p className='card-text'><strong>Socialize and mingle with local community :</strong> Dengan adanya "Penyuluhan Keliling." Tim riset berusaha untuk menyosialisasikan informasi tentang stunting dan wasting secara luas kepada masyarakat sekitar melalui kegiatan penyuluhan keliling bersama mahasiswa, kader posyandu, dan CSR perusahaan dan pemerintah</p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card border-0 bg-info" style={{boxShadow:'none'}}>
              {/* <img src={item.imageName} className="card-img-top" alt="" /> */}
              <div className="card-body">
                <h1 className="card-title text-center text-success" style={{fontWeight:'900'}}>I</h1>
                <h4 className='pb-2 pt-2 text-center'>Inspiring </h4>
                <p className='card-text'><strong>Inspiring changes through photovoice :</strong> Melalui photovoice, tim riset memberikan kesempatan pada masyarakat untuk berbicara melalui gambar tentang permasalahan stunting dan wasting. Selain itu, ide pengembangan media seperti leaflet dan poster berbahasa lokal yang menginspirasi  orang untuk menyebarkan informasi lebih luas dan mudah dimengerti dan mengambil tindakan yang positif dalam mencegah stunting dan wasting pada anak-anak.</p>
              </div>
            </div>
          </div>

      </div>
    </div>
  )
    
   
}

export default AboutUs