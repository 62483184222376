import { useState } from 'react'
import './PhotoVoice.css'

const images = [
    
  {id:'1',tema:'Tema 1: LINGKUNGANKU DI BANTARAN SUNGAI MUSI', title:'', desc:'<p>Ini adalah pabrik yang terbengkalai, si empunya pabrik sudah meninggal dunia dan dikebumikan di Pulau Jawa. Kemungkinan keluarga dari pemilik pabrik tidak mengetahui bahwa sang suami mempunyai asset di Kota Palembang.&nbsp;</p><p>Pabrik ini cukup terbengkalai, dan menjadi tempat pembuangan sampah masyarakat. Disini juga tempat anak-anak bermain, termasuk anak saya. Ini dia lagi memperagakan bermain kapal-kapalan, dengan meletakkan kardus di tubuhnya&hellip;</p><p><br></p><p>(Irfa, kader Posyadu Anggrek, 15 Ulu)</p>',imageName: require('../../assets/images/photos/photovoice/1.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'2',title:'', desc:'Lingkungan sekitar kurang bersih, bisa membuat anak wasting, bikin anak diare-diare (Catatan Peneliti)',imageName: require('../../assets/images/photos/photovoice/2.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'3',title:'', desc:'<p>Ini daerah ini, pinggiran sungai musi dan dalemnyo ado anak sungai atau disebut wong kanal-kanal. Kadang tu daerah ini banyak sampah termasuk jugo daerah kumuh. Kalo air pasang, ketek (perahu kecil) bisa masuk ke anak Sungai ini.&nbsp;</p><p>Ada beberapa anak beresiko stunting juga disekitar lokasi ini, namun sudah pemulihan setelah dideteksi dini, diberi susu dan biskuit oleh puskesmas dan diminta rajin datang ke posyandu</p><p>(Karimah, Kader Posyandu Stoberi, Tangka Takat)</p>',imageName: require('../../assets/images/photos/photovoice/3.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'4',title:'', desc:'<p>Dibantaran sungai Musi, mandi di Sungai Musi menjadi salah satu kesukaan anak-anak disani, mereka senang dan bahagia saat dapat berenang dan mandi di sungai Musi bersama teman-teman mereka. Mandi disungai itu jadi kayak salah satu permainan mereka kalau lagi main bersama kawan-kawannya. Sang ibu sering menemani sang anak untuk keamanan sang anak</p><p><br></p><p>(Yogi, Ibu dengan empat anak, 15 Ulu)</p>',imageName: require('../../assets/images/photos/photovoice/4.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'5',title:'', desc:'<p>&ldquo; Ibu mencuci pakaian langsung di sungai, airnya banyak</p><p>Catatan Peneliti</p>',imageName: require('../../assets/images/photos/photovoice/5.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'6',title:'', desc:'<p>Sampah harus dikarung, ado yang buang sampah yang dikarung ini di tempat pembuangan sampa di pasar 10 Ulu, tapi ado jugo yang buangnyo langsung di Sungai, dan ado jugo yang dibakar&rdquo;</p><p>(Nenti, Ibu dengan satu anak, Posyandu Anggrek 12 Ulu)</p>',imageName: require('../../assets/images/photos/photovoice/6.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'7',title:'', desc:'<p>Lingkungannyo kotor, terus banyak sampah&rdquo;</p><p>-Nenti</p>',imageName: require('../../assets/images/photos/photovoice/7.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'8',title:'', desc:'<p>&ldquo;ini selokannyo bau jadi harus ditutup&rdquo;</p><p>&nbsp;-Yeni</p>',imageName: require('../../assets/images/photos/photovoice/8.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'9',title:'', desc:'<p>Air bersih PDAM di bantaran Sungai Musi mudah diakses oleh ibu-ibu</p><p>(Catatan peneliti)</p>',imageName: require('../../assets/images/photos/photovoice/9.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'10',title:'', desc:'<p>WC cemplung didalam rumah ibu yang tinggal dibantaran Sungai Musi</p><p>(Catatan peneliti)</p>',imageName: require('../../assets/images/photos/photovoice/10.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'11',title:'', desc:'<p>WC cemplung yang berada bantaran sungai Musi rumah ibu yang tinggal dibantaran Sungai Musi</p><p>(Catatan peneliti)</p>',imageName: require('../../assets/images/photos/photovoice/11.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'12',title:'', desc:'<p>WC bersama di bantaran Sungai Musi, terlihat tidak terawatt</p><p>(Nenti, Ibu dengan 1 anak)</p>',imageName: require('../../assets/images/photos/photovoice/12.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'13',title:'', desc:'<p>Ini adalah gambar Sungai Kedukan yang kotor dan kumuh, banyak sampahnya, karena warga di wilayahnya membuang sampah di sungai (Ibu Huzaimah, Kader Posyandu Lavender, Puskesmas 4 Ulu)</p>',imageName: require('../../assets/images/photos/photovoice/12-1.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'14',title:'', desc:'<p>Sisi hijau di bantaran Sungai Kedukan, 4 Ulu, Seberang Ulu I, ada banyak tanaman toga (tanaman obat keluarga) di halaman rumah warga. TOGa bisa bermanfaat sebagai pertolongan pertama dalam pengobatan berbagai macam penyakit, seperti batuk dan demam (Catatan peneliti)</p>',imageName: require('../../assets/images/photos/photovoice/14-1.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
  
  {id:'51',tema:'Tema 2: ISI PIRINGKU',title:'', desc:'<p>Nenek ikut bantu ngasuh anak, nyuapi anaknyo makan mie campor nasi nuruti kesukaan cucungnyo. Asal galak (mau) makan dulu, neneknya bilang, di bantaran Sungai Musi, anginnya sepoi-sepoi, anak makan sambil bermain &nbsp;</p><p>(Irfa, Kader Posyandu Anggrek 15 Ulu)</p>',imageName: require('../../assets/images/photos/photovoice/13.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'52',title:'', desc:'<p>Ini masak pisang kepok sama nasi, pisangnya direbus lalu dicampur dengan nasi, ini makanan sehari hari anak saya dan ini kates (pepaya) agar kotorannya ga keras dan ini jeruk vitamin c agar anak tidak mudah sariawan</p><p>(Ibu Anti, 25 tahun, dengan 1 anak usia 8 bulan)</p>',imageName: require('../../assets/images/photos/photovoice/14.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'53',title:'', desc:'<p>Nasi Cuma kadang samo sayur itu kadang samo tempe, nyiapke dewe tapi kadang dak dikasih soalnyo kenyang itu kali&rdquo;</p><p>(Ibu Tati, 30 tahun dengan anak 2 tahun)</p>',imageName: require('../../assets/images/photos/photovoice/15.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'54',title:'', desc:'<p>Setelah ibu selesai memasak, ibu menyiapkan makanan bergizi untuk anak. Sang anak melahap makanan yang disiapkan si ibu. Sering kali sang ayah membantu menyuapi sang anak. Menu hari ini Nasi, tempe, ayam dan sayur katu dan tahu</p><p>(Catatan Lapangan peneliti)</p>',imageName: require('../../assets/images/photos/photovoice/16.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'55',title:'', desc:'<p>Ini makanan sofa sehari hari, lauk pauknya disini ado ikan sama tempe dan ado buah buahannya pisang, untuk melengkapi gizi anak saya</p><p>(Ibu Irva, kader Posyandu Anggrek 15 Ulu)</p>',imageName: require('../../assets/images/photos/photovoice/17.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'56',title:'', desc:'<p>Ibu bercerita saya baru pulang dari Puskesmas 4 Ulu, suaminya meminta sang ibu mengajak sang anak ke Puskesmas karena anaknya tidak nafsu makan dalam 2 minggu ini. Ini anak ke empat sang ibu. Di Puskesmas, anaknya diperiksa dan saya diberikan konsultasi gizi. Sebelum pulang, petugas puskesmas memberikan Makanan Tambahan berupa biskuit untuk anak kurus. "Alhamdulillah dilayani dengan baik," ujar sang ibu (Catatan Peneliti)</p>',imageName: require('../../assets/images/photos/photovoice/56.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
  
  {id:'101',tema: 'Tema 3: IBU PEJUANG DENGAN ANAK PENYINTAS STUNTING', title:'', desc:'<p>Iya dia dari umur. 2 bulan sih ya diare sakit ya. Kita obat ini lah sampai sekarang sampai umuran 2 tahun. baru biso jalan umur 3 tahun. Yogi dikasih obat, warung, obat oralit, jamu jamu. &nbsp;Dulunyo dibilang gizi buruk, yo sudah kito jalani bae.</p><p>Paling bapaknya tuh ya kita jalani berdua lah sama suami.</p><p>Sanak saudara juga ya dukung juga ya ngobati yang ngurusin. .</p><p>Dari ibu ibu kader posyandu ya kita jalan bae, Lebih ini lah lebih waspada &nbsp;untuk anak mungkin beresiko stunting. Alhamdulillah, yogi sudah pulih dari stunting dan usianya sudah 5 tahun lebih</p><p><br></p><p>(Ibu Yogi, Ibu dengan 4 anak, 15 Ulu)</p>',imageName: require('../../assets/images/photos/photovoice/18.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'102',title:'', desc:'<p>Yo waktu kemarin itu mulai dio lagi stunting tuh ya kita sedih ya. Terus yo sering berobat ke bidan puskesmas. Setiap bulan juga bidan datang ke sini kan ya. Terima kasih juga kan atas bidannya sering berkunjung &nbsp;ngassih susu dan vitamin. Ya alhamdulillah ya. Sekarang ini anak aku beguyur lah gak sembuh lah sudah ya 90% lah agak sembuh sudah dulu.</p><p><br></p><p>(Ibu Jokowi, Ibu dengan 4 anak, 15 Ulu)</p>',imageName: require('../../assets/images/photos/photovoice/19.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
  
  {id:'151',tema:'Tema 4: MATA PENCAHARIAN IBU DAN AYAHKU', title:'', desc:'<p>Turun dari perahu ketek di bantaran Sungai Musi, sang bapak membawa dua baskom besar. Satu baskom isinya ikan lele, dan baskom lainnya ikan gabus. Bapak berkeliling di kampung di bantarn Sungai Musi dan mulai menjaja</p><p><br>(Catatan Lapangan, Juli 2023)</p>',imageName: require('../../assets/images/photos/photovoice/20.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'152',title:'', desc:'<p>&ldquo;lagi ngupas bawang buat tambahan jajan anak lah, jadi kan ibu ikut nyari duit&rdquo;</p><p>(Anti, Ibu rumah tangga)</p>',imageName: require('../../assets/images/photos/photovoice/21.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'153',title:'', desc:'<p><em><span>&ldquo;Bapak pembuat terpal. Dia buat terpal kayak buat di panggung, kayak buat tenda-tenda&rdquo;</span></em></p><p><span>-Wati</span></p>',imageName: require('../../assets/images/photos/photovoice/22.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'154',title:'', desc:'<p><span>&ldquo;Ibu-ibu sedang manggang kemplang buat dijual lagi, kemplang nya enak-enak&rdquo;&nbsp;</span></p><p><span >Catatan Peneliti </span></p>',imageName: require('../../assets/images/photos/photovoice/23.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'155',title:'', desc:'<p><span>&ldquo;Bapak lagi ambil galon kosong buat di isi lagi terus di anter kerumah yang pesen"&nbsp;</span></p><p><span>Catatan Peneliti </span></p>',imageName: require('../../assets/images/photos/photovoice/24.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
  
  {id:'201',tema:'Tema 5: AKU, AYAHKU, IBUKU DAN NENEKKU',title:'', desc:'<p>&ldquo;Istrinya lagi masak, bapaknya yang ngurus anak. Terus bapaknya kerja, istrinya ngurus anak&rdquo;</p><p>-Wati</p>',imageName: require('../../assets/images/photos/photovoice/25.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'202',title:'', desc:'<p>&ldquo;Nenek ikut bantu ngasuh anak, nyuapi anaknyo makan&nbsp;mie campor nasi nuruti kesukaan anaknyo&rdquo; </p><br/><p>-Susi</p>',imageName: require('../../assets/images/photos/photovoice/26.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'203',title:'', desc:'<p>&ldquo;Adek minum susu sendiri sambil nonton tv karno ibunyo lagi manggang kemplang&ldquo;&nbsp;</p><p>Catatan Peneliti</p>',imageName: require('../../assets/images/photos/photovoice/27.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'204',title:'', desc:'<p>&ldquo;Kalau sarapan gaalak ibu kasih bubur ayam biar kenyang, siangnyo makan yang lain&ldquo;&nbsp;</p><p>&nbsp;</p><p>Ibu Razi</p>',imageName: require('../../assets/images/photos/photovoice/28.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'205',title:'', desc:'<p>&ldquo;Iya karena MPASI diberikan pada saat anak usia di atas 6 bulan keatas oleh sebab itu bayi yang umurnya masih 0 sampai 6 bulan biar harus diberikan ASI saja karena lapar dan haus yang bayi 0-6 bulan bisa menampung ASI saja. secara rutin kalau diberikan makanan khusus bayi sepertinya belum bisa mencernanya dengan baik maka dari itu bayi dibawah 6 bulan harus dikasih ASI saja&rdquo;</p><p>(Ibu E, 30 tahun)</p>',imageName: require('../../assets/images/photos/photovoice/29.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'206',title:'', desc:'<p><span style="font-weight: 400;">Jika diare, aku bawa anak aku cepet berobat ke bidan, untuk apo sayang duit, untuk anak harus segera dibawa berobat</span></p><p>&nbsp;</p><p>(Ibu K, 29 Tahun)</p>',imageName: require('../../assets/images/photos/photovoice/30.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'207',title:'', desc:'<p>Ibu sibuk mengupas bawang, bapak membantu menyuapi anak makan, suami pengertian</p><p>(Bunda Rayhan, 30 tahun)</p>',imageName: require('../../assets/images/photos/photovoice/31.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'208',title:'', desc:'<p><em>&ldquo;Merokok jangan di dalam rumah, harus diluar rumah, ya karena asepnya itu, namun tidak semua bapak mau merokok diluar rumah&rdquo;</em></p><p><em>-Susi</em></p>',imageName: require('../../assets/images/photos/photovoice/32.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'209',title:'', desc:'<p>Anak-anak beli es, jajan, air yang digunakan air biasa, airgallon, sehat, tapi demen-demen (sering-sering) batuk jugo, sesekali be</p><p>&nbsp;</p><p>(Yeni, ibu dengan satu anak, 12 Ulu)</p>',imageName: require('../../assets/images/photos/photovoice/33.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'210',title:'', desc:'<p>Dia ini sampai 3 bulan tidak naik naik badannya, kalau lahirnya bagus berat badannya,waktu itu lahirnya 2,9 kg panjang 50 cm, cuma selama 3 bulan tuh enggak naik naikmalah turun, pas berobat ke puskesmas cuma dikasih tau dokter suruh banyak makan sayur biar ASI nyo banyak langsung ke dokter spesialis anak tumbuh kembangnya spesialis anak, ngurus bpjs terus rujuk ke rs langsung dirawat selama bulan. Bolak balik rs pokoknya.</p><p><br></p><p>(ibu Sana, ibu dengan 3 anak, 16 ulu)</p>',imageName: require('../../assets/images/photos/photovoice/34.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'211',title:'', desc:'<p>Dulu waktu dia belum sembuh tuh berjalan sempoyongan sakingkurus badannya kan,berobat terus ke puskesmas dan bidan puskesmas juga datang dan berkunjung ya alhamdulillah kan dikasih saran harus makan vitamin dan cakmano supaya anak mau makan.</p><p><br></p><p>(ibu Lina, ibu dengan 4 anak, 15 ulu)</p>',imageName: require('../../assets/images/photos/photovoice/35.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'212',title:'', desc:'<p>Karena ibunya lagi kerja dan saya lagi libur jadi saya yang ngerawat anak, kebetulan hari ini ada posyandu jadi kesempatan saya untuk bawa anak ke posyandu walaupun ibunya lagi ga ada di rumah</p><p><br></p><p>(pak Anton, Ayah dengan 1 anak, 16 ulu)</p>',imageName: require('../../assets/images/photos/photovoice/36.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'213',title:'', desc:'<p>Setiap hari, saya selalu memasak air sendiri untuk kebutuhan sehari-hari, lebih sehat untuk anak-anak dan keluarga. Pernah membeli air galon kemasan, pernah ada uget-uget (jentik nyamuk), tidak higienis. Menjadi ibu, kita harus menjaga kesehatan anak dimulai dengan apa yang mereka minum sehari-hari.</p> <p><br></p><p> (Ibu Ratih, 4 Ulu, Palembang)</p>',imageName: require('../../assets/images/photos/photovoice/213.jpg'), tag: 'posyandu angrek bulan 12 ulu'},
]

const PhotoVoice =()=> {
  const [noItem, setNoItem] = useState(8)
  const sliceItems = images.slice(0, noItem)

  const loadMore =()=> setNoItem(noItem + noItem)
  return(
    <div className='container pt-5 mt-5' style={{maxWidth:'80%'}}>
    <h1 className='mb-5'>Photo Voice<br/>
    <p style={{fontSize:'20px'}}>Bantaran Sungai Musi <br/>Etnografi Juni-Juli 2023
</p>
    </h1>
    <div className="row photo-voice">
      {sliceItems.map((item,index)=>(
        <>
          {index < 51 && <h3 style={{marginLeft:'-10px'}}>{item.tema}</h3>}
          {(index > 50 && index < 101) && <h3 className='mt-5 p-0'>{item.tema}<br/></h3>}
          {(index > 100  && index < 151) && <h3 className='mt-5 p-0'>{item.tema}<br/></h3>}
          {(index > 150 && index < 201) && <h3 className='mt-5 p-0'>{item.tema}<br/></h3>}
          {(index > 200 && index < 251) && <h3 className='mt-5 p-0'>{item.tema}<br/></h3>}
          <div className="card mb-1">
            <img src={item.imageName} alt='' className="card-img-top" style={{marginLeft:'-12px'}} loading='lazy'/>
            <div className="card-body">
              <h5 className="card-title">{item.title}</h5>
              <p className='card-text' dangerouslySetInnerHTML={{__html:item.desc}} />
            </div>
          </div>
        </>
      ))}
    </div>
    <div className='text-center d-block mt-5 mb-5'>
      {noItem < images.length &&
        <button 
          className='btn btn-success'
          onClick={()=>loadMore()}>
            Load More
        </button>
      }
    </div>
  </div>
  )
}

export default PhotoVoice