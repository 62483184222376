import { useState } from 'react'
import './petaStatic.css'

const images = [
  {id:'1',title:'Sebaran Ibu Hamil yang diberi TTD berdasarkan wilayah kerja', desc:'<p>Dari pemetaan diatas dapat diketahui bahwa wilayah tinggi sebaran ibu hamil yang diberi TTD terdapat pada wilayah kerja puskesmas Gandus, Puskesmas Makrayu, Puskesmas Padang Selasa, Puskesmas OPI, Puskesmas Taman Bacaan, dan Puskesmas Sosial. Dilanjutkan wilayah sedang terdapat pada wilayah Puskesmas Kertapati, Puskesmas 4 Ulu, Puskesmas Pembina, Puskesmas Nagaswidak, Puskesmas Tegal Binangun, Puskesmas Sei Selincah, Puskesmas Sabokingking, Puskesmas Kenten, Puskesmas Sematang Borang, Puskesmas Sako, Puskesmas Multiwahana, Puskesmas Sekip, Puskesmas Sukarami, Puskesmas Punti kayu, dan Puskesmas Talang Betutu. Sedangkan 21 Puskesmas Lainnya merupakan wilayah rendah sebaran ibu hamil yang diberi TTD.</p>',imageName: require('../../assets/images/petastatic/wilayahkerja/3.wilayahkerjaHamilTTD.png'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'2',title:'Sebaran ibu hamil yang mengalami anemia per wilayah puskesmas', desc:'<p>Dari Pemetaan diatas dapat diketahui bahwa wilayah tinggi ibu hamil yang mengalami anemia terdapat pada wilayah kerja Puskesmas Keramasan, Puskesmas Padang Selasa, Puskesmas 4 Ulu, Puskesmas Pembina, dan Puskesmas Plaju. Dilanjutkan pada wilayah kategori sedang terdapat pada Puskesmas Gandus, Puskesmas Makrayu, Puskesmas 7 Ulu, Puskesmas Taman Bacaan, Puskesmas Tegal Binangun, Puskesmas Sei Selincah, Puskesmas Sematang Borang, Puskesmas Sekip, Puskesmas Sukarami, Puskesmas Talang Betutu, Puskesmas Alang-Alang Lebar. Sedangkan 26 Wilayah kerja lainnya tergolong wilayah rendah.</p>',imageName: require('../../assets/images/petastatic/wilayahkerja/4.wilayahkerjaibuhamilanemia.png'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'3',title:'Sebaran ibu hamil yang mengalami Kek', desc:'<p>Dari Pemetaan diatas, dapat diketahui bahwa wilayah tinggi sebaran ibu hamil yang mengalami KEK terdapat pada Puskesmas Kertapati, dilanjutkan wilayah sedang terdapat pada Puskesmas Makrayu, Puskesmas Pembina dan Puskesmas Tegal Binangun. Sedangkan 38 Puskesmas lainnya merupakan wilayah rendah.</p>',imageName: require('../../assets/images/petastatic/wilayahkerja/5.wialayhkerjahamilkek.png'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'4',title:'Sebaran Bayi yang diberi IMD', desc:'<p>Dari pemetaan diatas, dapat diketahui bahwa wilayah tinggi sebaran bayi yang diberi IMD terdapat pada Puskesmas Gandus, Puskesmas Makrayu, Puskesmas Taman Bacaan, Puskesmas Sematang Borang, Puskesmas Sako, Puskesmas Sosial, dan Puskesmas Sukarami. Dilanjutkan pada wilayah sedang terdapat pada Puskesmas keramasan, Puskesmas Kertapati, Puskesmas OPi, Puskesmas 4 Ulu, Puskesmas Pembina, Puskesmas Tegal Binangun, Puskesmas Nagaswidak, Puskesmas Plaju, Puskesmas Sei Selincah, Puskesmas Sabokingking, Puskesmas Kalidoni, Puskesmas Kenten,Puskesmas 11 Ilir, Puskesmas Bukit Sangkal, Puskesmas Multiwahana, Puskesmas Basuki Rahmat, Puskesmas Kampus, Puskesmas Punti Kayu, Puskesmas Alang-Alang Lebar, dan Puskesmas Talang Betutu. Sedangkan 15 kecamatan lainnya merupakan wilayah rendah.</p>',imageName: require('../../assets/images/petastatic/wilayahkerja/6.wilayahkerjabayiimd.png'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'5',title:'Sebaran Bayi yang diberi Asi eksklusif berdasarkan wilayah kerja', desc:'<p>Dari pemetaan diatas, dapat diketahui bahwa Wilayah Tinggi Sebaran Bayi yang diberi Asi eksklusif terdapat pada Puskesmas gandus, Puskesmas Sosial, dan Puskesmas Sematang borang. Dilanjutkan pada wilayah sedang terdapat pada puskesmas OPI, Puskesmas Sei Selincah, dan Puskesmas Bukit Sangkal. Sedangkan 36 Puskesmas lainnya merupakan wilayah rendah sebaran bayi yang diberi Asi Eksklusif.</p>',imageName: require('../../assets/images/petastatic/wilayahkerja/7.wilayahkerjaasiekslusif.png'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'6',title:'Sebaran Baduta yang di beri MP Asi berdasarkan wilayah kerja', desc:'<p>Dari pemetaan diatas, dapat diketahui bahwa wilayah tinggi sebaran baduta yang diberi MP ASi terdapat pada Puskesmas Sematang Borang dan Puskesmas Multiwahana. Dilanjutkan pada Wilayah sedang terdapat pada Puskesmas Gandus, Puskesmas 4 Ulu, Puskesmas Taman Bacaan, Puskesmas plaju, Puskesmas Sei Selincah, Puskesmas Bukit Sangkal, Puskesmas Basuki Rahmat, dan Puskesmas Sukarami. Sedangkan 32 Puskesmas lainnya merupakan wilayah rendah sebaran Baduta yang diberi MP ASI.</p>',imageName: require('../../assets/images/petastatic/wilayahkerja/8.wilayahkerjabadutaMPASI.png'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'7',title:'Sebaran Rematri yang diberi TTD berdasarkan wilayah puskesmas', desc:'<p>Dari Pemetaan diatas dapat diketahui bahwa wilayah tinggi sebaran Rematri yang diberi TTd terdapat pada Puskesmas Sekip dan Puskesmas Kenten. Dilanjutkan Wilayah sedang terdapat pada Puskesmas Gandus, Puskesmas Kramasa, Puskesmas Kertapati, Puskesmas Makrayu, Puskesmas 11 ilir , Puskesmas Sabokingking, Puskesmas Kalidoni, Puskesmas Sei Selincah, Puskesmas Sako, Puskesmas Sosial, Puskesmas Sukarami, Puskesmas Talang Betutu, dan Puskesmas Alang-Alang Lebar. Sedangkan 29 Puskesmas Lainnya merupakan Wilayah Rendah sebaran rematri yang diberi TTD.</p>',imageName: require('../../assets/images/petastatic/wilayahkerja/9.wialayhkerjarematriTTD.png'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'8',title:'Sebaran diagram Wasting dan Stunting pada daerah Bantaran Sungai Musi Palembang', desc:'<p>Dari Peta diatas dapat dilihat bahwa hampir keseluruhan daerah wilayah Selatan Kota Palembang berada di bantaran Sungai Musi Palembang. Mayoritas dari daerah tersebut lebih didominasi pada kasus stunting.</p>',imageName: require('../../assets/images/petastatic/wilayahkerja/StuntingWastingAliranSungai.png'), tag: 'posyandu angrek bulan 12 ulu'},

]

const DeterminanWilayahKerja =()=> {
  const [noItem, setNoItem] = useState(4)
  const sliceItems = images.slice(0, noItem)

  const loadMore =()=> setNoItem(noItem + noItem)
  return(
   <> 
    <h3>2. Berdasarkan Wilayah Kerja Puskesmas</h3>
    <div className="row row-cols-2 g-3">
      {sliceItems.map(item=>(
        <div className="col" key={item.title}>
          <div className="card" style={{boxShadow:'none'}}>
            <h4 className='m-2 ps-2'>{item.title}</h4>
            <a href={`${item.imageName}`}>
              <img src={item.imageName} 
                className={`cursor-pointer card-img-top zoom`}  
                alt="" loading='lazy'/>
            </a>
            <div className="card-body">
              {/* <h5 className="card-title">{item.title}</h5> */}
              <p className='card-text' dangerouslySetInnerHTML={{__html:item.desc}} />
            </div>
          </div>
        </div>
      ))}
    </div>
    <div className='text-center d-block mt-5 mb-5'>
      {noItem < images.length &&
        <button 
          className='btn btn-success'
          onClick={()=>loadMore()}>
            Load More Determinan Wilayah Kerja
        </button>
      }
    </div>
  </>
  )
}

export default DeterminanWilayahKerja