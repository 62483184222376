import { useState } from 'react'
import './petaStatic.css'

const images = [
  {id:'1',title:'Sebaran Persentase ibu hamil yang mendapat TTD', desc:'<p>Pada peta diatas, sebaran persentase rendah ditunjukan dengan legenda bewarna putih, dilanjutkan pada sebaran persentase sedang ditunjukkan dengan legenda bewarna hijau muda, dan pada sebaran persentase tinggi ditunjukkan pada legenda bewarna hijau tua. Dapat diketahui bahwa Kecamatan Alang-alang lebar merupakan wilayah rendah sebaran persentase ibu hamil yang mendapat TTD, yaitu sebanyak 78%. Adapun pada wilayah sedang sebaran ibu hamil yang di beri TTD terdapat pada Kecamatan Bukit Kecil, yaitu sebanyak 96,2%. Sedangkan 16 Kecamatan lainnya di Kota Palembang memiliki sebaran persentase ibu hamil yang diberi TTD dengan kategori tinggi, yaitu lebih dari 96,3%. &nbsp;Dari distribusi sebaran diatas, dapat ditarik kesimpulan bahwa mayoritas Ibu hamil di Kota Palembang mendapat TTD sesuai standar yang ditandai dengan dominanya wilayah Kecamatan yang memiliki sebaran diatas 96,3%.</p>',imageName: require('../../assets/images/petastatic/kecamatan/3.hamilpersenttd.png'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'2',title:'Sebaran Persentase Ibu Hamil yang Mengalami Anemia', desc:'<p>Pada peta diatas, sebaran persentase rendah ditunjukan dengan legenda bewarna putih, dilanjutkan pada sebaran persentase sedang ditunjuukan dengan legenda bewarna orange, dan pada sebaran persentase tinggi ditunjukkan pada legenda bewarna maroon. Dapat diketahui bahwa Kecamatan Kertapati (10,25), Seberang Ulu I (9,2%) dan Plaju (8,2%) wilayah tinggi &nbsp;sebaran persentase ibu hamil yang mengalami anemia. Adapun pada wilayah sedang sebaran ibu hamil yang mengalami Anemia tersebar di 5 wilayah Kecamatan, yaitu pada wilayah kerja puskesmas Jakabaring (4,9%), Ilir Barat II (4,9), Alang-Alang Lebar (4%), Ilir Timur II (4%), dan Semantang Borang (3,8%). Sedangkan 10 wilayah Kecamatan lainnya di Kota Palembang memiliki sebaran persentase ibu hamil yang mengalami anemia dengan kategori rendah, yaitu kurang dari 3,4%. &nbsp;Dari distribusi sebaran diatas, dapat ditarik kesimpulan bahwa mayoritas Ibu hamil di Kota Palembang memiliki kadar Hb dan zat besi yang cukup yang ditandai dengan dominannya Kecamatan yang memiliki sebaran kurang dari 3,4%.</p>',imageName: require('../../assets/images/petastatic/kecamatan/4.ibu_persentaseanemia.png'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'3',title:'Sebaran Persentase Ibu Hamil dengan KEK dan mendapat MT', desc:'<p>Pada peta diatas, sebaran persentase rendah ditunjukan dengan legenda bewarna putih, dilanjutkan pada sebaran persentase sedang ditunjuukan dengan legenda bewarna biru muda, dan pada sebaran persentase tinggi ditunjukkan pada legenda bewarna biru tua dongker. Dapat diketahui bahwa &nbsp;Kecamatan Ilir Timur Ilir Timur I (0%) merupakan sebaran rendah ibu hamil yang mengalami Kekurangan energi kronis dan mendapat Makanan Tambahan. wilayah Kecamatan ini juga mengindikasikan tidakadanya sebaran ibu hamil yang mengalami Kekurangan energi Kronis Pada wilayah Tersebut. Selanjutnya terdapat 5 Kecamatan dengan sebaran rendah Ibu Hamil yang mengalami KEK dan Mendapat MT terdapat pada Kecamatan Ilir Barat I (50%), Alang-Alang Lebar (50%), Bukit Kecil (50%), Seberang Ulu II (50%), dan Kemuning (50%). Adapun 16 wilayah puskesmas lainnya merupakan wilayah tinggi sebaran ibu hamil yang mengalami KEK dan diberikan Makanan Tambahan. 16 Kecamatan tersebut mengindikasikan bahwa seluruh ibu hamil dengan KEK Semuanya telah tertangani.&nbsp;</p>',imageName: require('../../assets/images/petastatic/kecamatan/5.ibupersentasekek.png'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'4',title:'Sebaran Persentase Bayi yang diberi IMD', desc:'Pada peta diatas, sebaran persentase rendah ditunjukan dengan legenda bewarna putih, dilanjutkan pada sebaran persentase sedang ditunjuukan dengan legenda bewarna hijau muda, dan pada sebaran persentase tinggi ditunjukkan pada legenda bewarna hijau tua. Dapat diketahui bahwa Wilayah KecamatanIlir Barat I (72,8%) dan Kemuning (76,2%) merupakan wilayah rendah sebaran persentase bayi yang diberi Inisasi Menyusui Dasar. Adapun pada wilayah sedang sebaran persentase bayi yang diberi Inisasi Menyusui Dasar terdapat pada Kecamatan Ilir Timur II (84,1%). Sedangkan 15 wilayah Kecamatan lainnya di Kota Palembang memiliki sebaran persentase bayi yang diberi Inisasi Menyusui Dasar dengan kategori tinggi, yaitu lebih dari 90,9%.  Dari distribusi sebaran diatas, dapat ditarik kesimpulan bahwa mayoritas bayi di Kota Palembang diberikan IMD pada saat lahir, ditandai dengan dominannya kecamatan yang memiliki sebaran lebih dari 90,9%.',imageName: require('../../assets/images/petastatic/kecamatan/6.persentaseIMD.png'), tag: 'posyandu angrek bulan 12 ulu'},
  
  {id:'5',title:'Sebaran Persentase Bayi yang diberikan Asi Eksklusif', desc:'Pada peta diatas, sebaran persentase rendah ditunjukan dengan legenda bewarna hijau muda, dilanjutkan pada sebaran persentase sedang ditunjuukan dengan legenda bewarna hijau muda, dan pada sebaran persentase tinggi ditunjukkan pada legenda bewarna hijau tua. Dapat diketahui bahwa terdapat 8 Kecamatan yang merupakan wilayah rendah sebaran persentase bayi yang diberi Asi Ekslusif, yaitu Sukarami (69,5%), Alang-Alang lebar (71,8%), Kemuning (73,4%), Ilir Timur I (72,4%), Ilir Barat II (68,4%), Ilir Timur III (67,7%), Seberang Ulu II (73,6%), dan plaju (70%). Adapun 5 wilayah Kecamatan di Kota Palembang merupakan sebaran sedang persentase bayi yang diberi Asi Ekslusif, yaitu Seberang Ulu I (77%), Bukit Kecil 76,2%), Kalidoni (78,6%), Semantang Borang (79,6%), dan Sako (81,6%). Sedangkan 5 Kecamatan Lainnya merupakan daerah tinggi sebaran persentase bayi yang diberi Asi Ekslusif. Dari distribusi sebaran diatas, dapat ditarik kesimpulan bahwa mayoritas bayi di Kota Palembang diberikan Asi Ekslusif sampai dengan umur 6 bulan, ditandai dengan dominannya Kecamatan yang memiliki sebaran lebih dari rata-rata persentase sebesar 77,8%.',imageName: require('../../assets/images/petastatic/kecamatan/7.ekslusifpersentase.png'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'6',title:'Sebaran Persentase Baduta MP ASI', desc:'Pada peta diatas, sebaran persentase rendah ditunjukan dengan legenda bewarna putih, dilanjutkan pada sebaran persentase sedang ditunjuukan dengan legenda bewarna biru muda, dan pada sebaran persentase tinggi ditunjukkan pada legenda bewarna biru tua. Dapat diketahui bahwa Kecamatan alang-Alang Lebar (57,2%), Sukarami (66,5%), Ilir Timur II (55,7%), dan Kertapati (61,8%) merupakan wilayah rendah sebaran persentase Baduta yang diberi MP-ASI. Adapun pada wilayah sedang sebaran persentase Baduta yang diberi MP-ASI tersebar pada 5 Kecamatan, yaitu kecamatan Ilir Barat I (78,85), Ilir Timur I (77,75), Bukit Kecil (76,5%), Seberang Ulu II (82,3%), dan Sako (75%). Dan 9 Kecamatan lainnya merupakan sebaran tinggi sebaran Persentase Baduta yang diberi MP-ASI. Dari distribusi sebaran diatas, dapat ditarik kesimpulan bahwa mayoritas Bayi Dua Tahun di Kota Palembang diberikan Makanan Pendamping ASI sampai dengan umur 2 Tahun, ditandai dengan dominannya Kecamatan yang memiliki sebaran lebih dari 82%.',imageName: require('../../assets/images/petastatic/kecamatan/8.bagdutaMPASipersentase.png'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'7',title:'Sebaran Persentase Rematri yang diberi TTD', desc:'Pada peta diatas, sebaran persentase rendah ditunjukan dengan legenda bewarna putih, dilanjutkan pada sebaran persentase sedang ditunjukan dengan legenda bewarna pink, dan pada sebaran persentase tinggi ditunjukkan pada legenda bewarna ungu. Dapat diketahui bahwa 4 Kecamatan, yaitu Ilir Barat I (46%), Jakabaring (36,4%), Plaju (40,8%), dan Kemuning (58,5%) merupakan wilayah rendah sebaran persentase Remaja Putri yang diberi Tablet Tambah Darah Sesuai Standar. Adapun pada wilayah sedang sebaran persentase Remaja Putri yang diberi Tablet Tambah Darah Sesuai Standar tersebar pada 5 Kecamatan, yaitu Kecamatan Kertapati (71,2%), Seberang Ulu (61,4%), Ilir Timur II (81,650, Ilir Timur III (62,8%), dan Sukarami (83,8%). Sedangkan 9 Kecamatan lainnya di Kota Palembang memiliki sebaran Baduta yang diberi MP-ASI. Dari distribusi sebaran diatas, dapat ditarik kesimpulan bahwa mayoritas Remaja Putri di Kota Palembang diberikan Tablet Tambah Darah sesuai standar, ditandai dengan dominannya Kecamatan yang memiliki sebaran lebih dari 84%.',imageName: require('../../assets/images/petastatic/kecamatan/9.rematriTTDpersentase.png'), tag: 'posyandu angrek bulan 12 ulu'},
  ]

const DeterminanKecamatanPersentase =()=> {
  const [noItem, setNoItem] = useState(4)
  const sliceItems = images.slice(0, noItem)

  const loadMore =()=> setNoItem(noItem + noItem)
  return(
   <> 
    <h2><strong>Persentase Determinan & Faktor Resiko </strong></h2>
    <h3>1. Berdasarkan Wilayah Kecamatan</h3>
    <div className="row row-cols-2 g-3">
      {sliceItems.map(item=>(
        <div className="col" key={item.title}>
          <div className="card" style={{boxShadow:'none'}}>
            <h4 className='m-2 ps-2'>{item.title}</h4>
            <a href={`${item.imageName}`}>
              <img src={item.imageName} 
                className={`cursor-pointer card-img-top zoom`}  
                alt="" loading='lazy'/>
            </a>
            <div className="card-body">
              {/* <h5 className="card-title">{item.title}</h5> */}
              <p className='card-text' dangerouslySetInnerHTML={{__html:item.desc}} />
            </div>
          </div>
        </div>
      ))}
    </div>
    <div className='text-center d-block mt-5 mb-5'>
      {noItem < images.length &&
        <button 
          className='btn btn-success'
          onClick={()=>loadMore()}>
            Load More Determinan Kecamatan
        </button>
      }
    </div>
  </>
  )
}

export default DeterminanKecamatanPersentase