import { useState } from 'react'
import './petaStatic.css'

const images = [
  {id:'1',title:'Sebaran kasus wasting wilayah kecamatan', desc:'<p>Wilayah sebaran kasus wasting dibagi menjadi 3 kategori, dimana masing-masing kategori disimbolkan dengan gradasi warna. Daerah yang sangat tinggi disimbolkan dengan warna yang lebih pekat/gelap sedangkan daerah yang rendah disimbolkan dengan warna yang lebih terang/pudar. Dari pemetaan diatas dapat dilihat sebaran kasus stunting pada masing-masing kecamatan di Kota Palembang. Daerah yang merupakan kategori tinggi sebaran stunting adalah kecamatan sukarami dengan total kasus sebesar 398 kasus, sedangkan 17 Kecamatan Lainnya merupakan daerah lainnya merupakan daerah dengan kategori sebaran rendah.</p>',imageName: require('../../assets/images/petastatic/kecamatan/2.wasting.png'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'2',title:'Sebaran kasus wasting berdasarkan wilayah kerja', desc:'<p>Dari pemetaan diatas, dapat diketahui bahwa wilayah tinggi sebaran kasus wasting terdapat pada wilayah kerja Puskesmas Sosial, sedangkan 41 wilayah puskesmas lainnya merupakan daerah rendah sebaran wasting.</p>',imageName: require('../../assets/images/petastatic/wilayahkerja/2.wilayahkerjawasting.png'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'3',title:'Sebaran Prevalensi Wasting berdasarkan Wilayah Kerja', desc:'Pemetaan diatas merupakan prevalensi terjadinya wasting berdasarkan perhitungan kasus per jumlah balita yang diukur pada masing-masing puskesmas. Dari pemetaan tersebut dapat diketahui bahwa Wilayah Puskesmas yang memiliki Prevalensi Wasting tinggi terdapat pada Puskesmas Sosial, dilanjutkan pada wilayah sedang terdapat pada Puskesmas Boom Baru. sedangkan 40 Puskesmas lainnya merupakan wilayah rendah prevalensi Wasting.',imageName: require('../../assets/images/petastatic/wilayahkerja/Update8JuliWasting.png'), tag: 'posyandu angrek bulan 12 ulu'},
  ]

const Wasting =()=> {
  const [noItem, setNoItem] = useState(3)
  const sliceItems = images.slice(0, noItem)

  const loadMore =()=> setNoItem(noItem + noItem)
  return(
   <> 
    <h2><strong>Wasting</strong></h2>
    <div className="row row-cols-2 g-3">
      {sliceItems.map(item=>(
        <div className="col" key={item.title}>
          <div className="card" style={{boxShadow:'none'}}>
            <h4 className='m-2 ps-2'>{item.title}</h4>
            <a href={`${item.imageName}`}>
              <img src={item.imageName} 
                className={`cursor-pointer card-img-top zoom`}  
                alt="" loading='lazy'/>
            </a>
            <div className="card-body">
              <p className='card-text' dangerouslySetInnerHTML={{__html:item.desc}} />
            </div>
          </div>
        </div>
      ))}
    </div>
    <div className='text-center d-block mt-5 mb-5'>
      {noItem < images.length &&
        <button 
          className='btn btn-success'
          onClick={()=>loadMore()}>
            Load More Wasting
        </button>
      }
    </div>
  </>
  )
}

export default Wasting