import {  useState } from 'react';
import L from 'leaflet'
import { MapContainer, TileLayer, Marker, ZoomControl } from "react-leaflet"
import GeoJsonWithUpdates from './MapInfo';
import 'leaflet/dist/leaflet.css'
import './leafletMap.css';

const LeafletMap = () => {
  var data = {
    "type": "FeatureCollection",
    "features": [
      {
        "type": "Feature",
        "properties": {
          "NAME_3": "Sukarami",
          "NAME_4": "Kebun Bunga",
          "ID": 1,
          "STUNTING": 27,
          "WASTING":398,
          "LAT": 104.711794,
          "LONG": -2.90193
        },
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [
                  104.73397064100004,
                  -2.95218706199995
                ],
                [
                  104.73375701900017,
                  -2.951629400999877
                ],
                [
                  104.73288726800001,
                  -2.949169157999904
                ],
                [
                  104.73199462900016,
                  -2.946974754999871
                ],
                [
                  104.73165130500018,
                  -2.946275709999952
                ],
                [
                  104.73139953600003,
                  -2.94583272899996
                ],
                [
                  104.73124694900014,
                  -2.945562838999933
                ],
                [
                  104.73056030400005,
                  -2.944640397999933
                ],
                [
                  104.72998046900011,
                  -2.943983316999891
                ],
                [
                  104.72940826500002,
                  -2.943368195999881
                ],
                [
                  104.72849273700012,
                  -2.942669152999883
                ],
                [
                  104.72827911400015,
                  -2.942524670999944
                ],
                [
                  104.72794342100019,
                  -2.942678450999949
                ],
                [
                  104.72402191200013,
                  -2.940563916999963
                ],
                [
                  104.72225189200009,
                  -2.939601181999876
                ],
                [
                  104.72143554700011,
                  -2.938969611999937
                ],
                [
                  104.72104644800015,
                  -2.938502072999881
                ],
                [
                  104.7202758790001,
                  -2.937577724999869
                ],
                [
                  104.71971130300011,
                  -2.93707084599987
                ],
                [
                  104.71910095200013,
                  -2.93612050899992
                ],
                [
                  104.71859741100013,
                  -2.935267686999964
                ],
                [
                  104.71789550800003,
                  -2.93364620299991
                ],
                [
                  104.71632385200002,
                  -2.929075478999948
                ],
                [
                  104.71556854200009,
                  -2.927537916999881
                ],
                [
                  104.71533203100012,
                  -2.927147150999872
                ],
                [
                  104.71512603800005,
                  -2.926937578999969
                ],
                [
                  104.71449279800004,
                  -2.926299810999865
                ],
                [
                  104.71441650500003,
                  -2.926172017999932
                ],
                [
                  104.7141723630001,
                  -2.925972937999973
                ],
                [
                  104.71378326500007,
                  -2.925580262999915
                ],
                [
                  104.71220397900015,
                  -2.924216030999958
                ],
                [
                  104.71135711700003,
                  -2.923406600999954
                ],
                [
                  104.71141052200011,
                  -2.923262833999956
                ],
                [
                  104.7094955440001,
                  -2.921486137999921
                ],
                [
                  104.70803833100007,
                  -2.920340298999974
                ],
                [
                  104.70531463600003,
                  -2.918209074999936
                ],
                [
                  104.70512390200008,
                  -2.918031930999916
                ],
                [
                  104.70500946100009,
                  -2.917909382999937
                ],
                [
                  104.70498657300016,
                  -2.918015002999937
                ],
                [
                  104.70484924400006,
                  -2.917867420999926
                ],
                [
                  104.70030975399999,
                  -2.915941952999958
                ],
                [
                  104.69934844900013,
                  -2.915513992999877
                ],
                [
                  104.69763183600014,
                  -2.914815425999905
                ],
                [
                  104.6949768070001,
                  -2.913692235999974
                ],
                [
                  104.69475555399998,
                  -2.913645742999847
                ],
                [
                  104.69396972700008,
                  -2.913586139999893
                ],
                [
                  104.6916351320001,
                  -2.91365051299988
                ],
                [
                  104.68685150100015,
                  -2.913758515999916
                ],
                [
                  104.6846542350001,
                  -2.913789509999845
                ],
                [
                  104.6784744260001,
                  -2.91398715899993
                ],
                [
                  104.67848968400011,
                  -2.913875818999941
                ],
                [
                  104.67878723100011,
                  -2.911668537999958
                ],
                [
                  104.67869567899999,
                  -2.911177157999873
                ],
                [
                  104.67859649700011,
                  -2.910666940999931
                ],
                [
                  104.67850494500004,
                  -2.910422324999956
                ],
                [
                  104.67835235500019,
                  -2.910345553999889
                ],
                [
                  104.678146363,
                  -2.910282610999957
                ],
                [
                  104.67825317300014,
                  -2.909765481999955
                ],
                [
                  104.67821502600009,
                  -2.909297466999874
                ],
                [
                  104.678146363,
                  -2.908738373999881
                ],
                [
                  104.67816162100002,
                  -2.908584593999933
                ],
                [
                  104.67829895000011,
                  -2.908228396999959
                ],
                [
                  104.6782836920001,
                  -2.907962798999904
                ],
                [
                  104.67835235500019,
                  -2.9077112679999
                ],
                [
                  104.67845916800007,
                  -2.90752267899984
                ],
                [
                  104.67869567899999,
                  -2.90747380199997
                ],
                [
                  104.67875671400014,
                  -2.907229422999933
                ],
                [
                  104.67887115400003,
                  -2.907082557999843
                ],
                [
                  104.67892456000004,
                  -2.906963824999877
                ],
                [
                  104.6788635260001,
                  -2.906768320999959
                ],
                [
                  104.67893219000013,
                  -2.90667033099993
                ],
                [
                  104.67907714900014,
                  -2.906398057999979
                ],
                [
                  104.67872619500002,
                  -2.906209229999945
                ],
                [
                  104.67729187000009,
                  -2.905436753999822
                ],
                [
                  104.6746063220001,
                  -2.903644561999954
                ],
                [
                  104.67469787600004,
                  -2.903273581999883
                ],
                [
                  104.67498779300018,
                  -2.90279841499995
                ],
                [
                  104.67523193400001,
                  -2.902190683999891
                ],
                [
                  104.67511749300007,
                  -2.901911020999933
                ],
                [
                  104.67520141500006,
                  -2.901853560999825
                ],
                [
                  104.67578888000008,
                  -2.901471137999977
                ],
                [
                  104.676658631,
                  -2.900830267999879
                ],
                [
                  104.67668914700005,
                  -2.899650096999949
                ],
                [
                  104.67682647600014,
                  -2.899487017999945
                ],
                [
                  104.6770019540001,
                  -2.89942431399993
                ],
                [
                  104.67746734600007,
                  -2.899096011999916
                ],
                [
                  104.67735290600018,
                  -2.899010657999952
                ],
                [
                  104.67709350600006,
                  -2.898527144999946
                ],
                [
                  104.67684173700007,
                  -2.898592709999946
                ],
                [
                  104.67662048400018,
                  -2.897956847999978
                ],
                [
                  104.67608642600004,
                  -2.8977122309999
                ],
                [
                  104.67588806200013,
                  -2.897509574999845
                ],
                [
                  104.67532348600014,
                  -2.896249532999946
                ],
                [
                  104.67548370300017,
                  -2.89623021999995
                ],
                [
                  104.67578888000008,
                  -2.895666122999899
                ],
                [
                  104.67581176800002,
                  -2.89550471299998
                ],
                [
                  104.6762924190001,
                  -2.894673586999943
                ],
                [
                  104.6763534540001,
                  -2.894226788999902
                ],
                [
                  104.67551422200012,
                  -2.893519399999832
                ],
                [
                  104.67519378700013,
                  -2.893370389999916
                ],
                [
                  104.67510986300016,
                  -2.8932027809999
                ],
                [
                  104.67497253400006,
                  -2.89314079199994
                ],
                [
                  104.67482757500005,
                  -2.89303517299993
                ],
                [
                  104.674743653,
                  -2.892843007999886
                ],
                [
                  104.67459869400005,
                  -2.892793178999966
                ],
                [
                  104.67455291700009,
                  -2.892588613999976
                ],
                [
                  104.67395019600019,
                  -2.891465425999968
                ],
                [
                  104.67395019600019,
                  -2.891056059999926
                ],
                [
                  104.67371368500005,
                  -2.89040517699982
                ],
                [
                  104.67334747300015,
                  -2.890129803999969
                ],
                [
                  104.67296600300006,
                  -2.889945267999963
                ],
                [
                  104.67241668700007,
                  -2.889771221999922
                ],
                [
                  104.67121124300013,
                  -2.889808653999921
                ],
                [
                  104.67118072400001,
                  -2.888790130999951
                ],
                [
                  104.67099762000015,
                  -2.888560055999903
                ],
                [
                  104.66941070600012,
                  -2.888722657999949
                ],
                [
                  104.66929626500013,
                  -2.888304710999932
                ],
                [
                  104.66941070600012,
                  -2.887805461999903
                ],
                [
                  104.66917419499998,
                  -2.887530326999979
                ],
                [
                  104.66876220800009,
                  -2.887214183999959
                ],
                [
                  104.66874694800009,
                  -2.886409281999931
                ],
                [
                  104.66880035500014,
                  -2.886276720999888
                ],
                [
                  104.66802215600018,
                  -2.886011600999893
                ],
                [
                  104.66752624500009,
                  -2.884533881999971
                ],
                [
                  104.66793823200015,
                  -2.883820770999932
                ],
                [
                  104.66684722900004,
                  -2.883372066999925
                ],
                [
                  104.6676712040001,
                  -2.881375788999947
                ],
                [
                  104.66899108900014,
                  -2.876867054999934
                ],
                [
                  104.66944885300018,
                  -2.87610983899998
                ],
                [
                  104.67124939000018,
                  -2.873081444999968
                ],
                [
                  104.6718139640002,
                  -2.872117756999899
                ],
                [
                  104.67250061100009,
                  -2.871481179999876
                ],
                [
                  104.67335510300006,
                  -2.871395348999897
                ],
                [
                  104.67385101400015,
                  -2.871051311999963
                ],
                [
                  104.67445373500004,
                  -2.871154784999874
                ],
                [
                  104.67463684100005,
                  -2.870500801999981
                ],
                [
                  104.67478942800017,
                  -2.870157717999973
                ],
                [
                  104.6747360230001,
                  -2.870030879999888
                ],
                [
                  104.6747360230001,
                  -2.869931459999918
                ],
                [
                  104.67481231700003,
                  -2.869809150999913
                ],
                [
                  104.6747360230001,
                  -2.869587420999949
                ],
                [
                  104.67480468700012,
                  -2.869528770999921
                ],
                [
                  104.6748657220001,
                  -2.869429110999931
                ],
                [
                  104.67497253400006,
                  -2.869352339999978
                ],
                [
                  104.6749420180002,
                  -2.868746041999884
                ],
                [
                  104.67503356900005,
                  -2.868601321999904
                ],
                [
                  104.67510223300002,
                  -2.867976902999942
                ],
                [
                  104.67505645800009,
                  -2.867805002999944
                ],
                [
                  104.67498779300018,
                  -2.867474794999964
                ],
                [
                  104.67501068100012,
                  -2.867330072999891
                ],
                [
                  104.6749954230001,
                  -2.867144346999964
                ],
                [
                  104.67503356900005,
                  -2.866976975999876
                ],
                [
                  104.675086975,
                  -2.866868495999881
                ],
                [
                  104.67502593900014,
                  -2.866764306999869
                ],
                [
                  104.67507934600019,
                  -2.866714715999876
                ],
                [
                  104.67504882800017,
                  -2.866615056999876
                ],
                [
                  104.67507934600019,
                  -2.866538285999923
                ],
                [
                  104.67507934600019,
                  -2.866461276999928
                ],
                [
                  104.67512512100018,
                  -2.86638903599993
                ],
                [
                  104.67518615700004,
                  -2.866180896999936
                ],
                [
                  104.6752395640001,
                  -2.865918396999973
                ],
                [
                  104.67525482200011,
                  -2.865764616999968
                ],
                [
                  104.67515564000013,
                  -2.865683077999961
                ],
                [
                  104.67514038200011,
                  -2.865592718999949
                ],
                [
                  104.67503356900005,
                  -2.865547418999938
                ],
                [
                  104.6749267570001,
                  -2.865443229999926
                ],
                [
                  104.6814727790001,
                  -2.8649792679999
                ],
                [
                  104.683746338,
                  -2.865028857999903
                ],
                [
                  104.68675994900002,
                  -2.865437506999967
                ],
                [
                  104.68877410900006,
                  -2.865503309999895
                ],
                [
                  104.69065856900005,
                  -2.865210055999967
                ],
                [
                  104.69208526600005,
                  -2.865112543999828
                ],
                [
                  104.693641662,
                  -2.864917277999837
                ],
                [
                  104.69544982900015,
                  -2.864733933999958
                ],
                [
                  104.69995880200008,
                  -2.864901065999902
                ],
                [
                  104.7003173820001,
                  -2.864945410999951
                ],
                [
                  104.7006530750001,
                  -2.86517596199991
                ],
                [
                  104.70117187400012,
                  -2.865096807999919
                ],
                [
                  104.70124054000013,
                  -2.864842653999972
                ],
                [
                  104.70143127400007,
                  -2.864810227999953
                ],
                [
                  104.70185852100019,
                  -2.864804744999958
                ],
                [
                  104.70222473100011,
                  -2.864737033999916
                ],
                [
                  104.7023925790001,
                  -2.864452837999863
                ],
                [
                  104.70252227800012,
                  -2.864374636999969
                ],
                [
                  104.70268249500015,
                  -2.864447354999925
                ],
                [
                  104.70309448200004,
                  -2.864855526999975
                ],
                [
                  104.70333099300012,
                  -2.864936827999941
                ],
                [
                  104.70357513400018,
                  -2.864905833999956
                ],
                [
                  104.703804015,
                  -2.864799736999942
                ],
                [
                  104.70422363200004,
                  -2.864693163999959
                ],
                [
                  104.70443725500019,
                  -2.864839075999953
                ],
                [
                  104.70458984400011,
                  -2.864934205999816
                ],
                [
                  104.7046356190001,
                  -2.865051507999908
                ],
                [
                  104.7043762200002,
                  -2.865862131999961
                ],
                [
                  104.70462799100017,
                  -2.866259097999944
                ],
                [
                  104.70495605400004,
                  -2.866812465999942
                ],
                [
                  104.70504760800014,
                  -2.867164611999954
                ],
                [
                  104.70498657300016,
                  -2.86778521399998
                ],
                [
                  104.70498657300016,
                  -2.867958544999851
                ],
                [
                  104.7050704950002,
                  -2.86817097699992
                ],
                [
                  104.70528411900011,
                  -2.868534326999963
                ],
                [
                  104.70534515499997,
                  -2.8687858589999
                ],
                [
                  104.70537567100001,
                  -2.869026421999934
                ],
                [
                  104.705299378,
                  -2.869143724999958
                ],
                [
                  104.70526885900006,
                  -2.869362830999933
                ],
                [
                  104.70662689200009,
                  -2.870017765999876
                ],
                [
                  104.70787048300002,
                  -2.870794534999845
                ],
                [
                  104.70821380700005,
                  -2.871326922999913
                ],
                [
                  104.70893859800003,
                  -2.8720147599999
                ],
                [
                  104.70975494400011,
                  -2.872702596999886
                ],
                [
                  104.70999145500019,
                  -2.87307977699993
                ],
                [
                  104.71038055400015,
                  -2.873167753999894
                ],
                [
                  104.71345520000017,
                  -2.873857258999919
                ],
                [
                  104.71459960900012,
                  -2.874090431999946
                ],
                [
                  104.71554565500003,
                  -2.874220132999938
                ],
                [
                  104.71611022900004,
                  -2.874323605999962
                ],
                [
                  104.71726989800015,
                  -2.874290703999918
                ],
                [
                  104.71727752700014,
                  -2.874457119999931
                ],
                [
                  104.71922302300004,
                  -2.874723671999959
                ],
                [
                  104.72189331000004,
                  -2.875451325999961
                ],
                [
                  104.72277069100011,
                  -2.875689506999947
                ],
                [
                  104.72470092700013,
                  -2.876266956999928
                ],
                [
                  104.72486114500003,
                  -2.876932382999939
                ],
                [
                  104.72488403300014,
                  -2.877020119999884
                ],
                [
                  104.72512817400019,
                  -2.877052783999943
                ],
                [
                  104.72978210500008,
                  -2.877708673999848
                ],
                [
                  104.7300949100001,
                  -2.877752780999856
                ],
                [
                  104.7301788330002,
                  -2.882781504999912
                ],
                [
                  104.73069763300015,
                  -2.883069037999974
                ],
                [
                  104.73227691700009,
                  -2.883941173999915
                ],
                [
                  104.73200988700017,
                  -2.884623050999949
                ],
                [
                  104.73287201,
                  -2.884640216999969
                ],
                [
                  104.73463440000017,
                  -2.88516902899994
                ],
                [
                  104.73455047600015,
                  -2.886396408999872
                ],
                [
                  104.73448181200018,
                  -2.888249397999971
                ],
                [
                  104.73448181200018,
                  -2.888322828999833
                ],
                [
                  104.7353439330002,
                  -2.889124153999944
                ],
                [
                  104.73521423400018,
                  -2.890129803999969
                ],
                [
                  104.73549652000008,
                  -2.890675306999981
                ],
                [
                  104.73699188300009,
                  -2.892321348999928
                ],
                [
                  104.73715210000012,
                  -2.89250063999998
                ],
                [
                  104.73715210000012,
                  -2.893563509999922
                ],
                [
                  104.73715210000012,
                  -2.894478319999962
                ],
                [
                  104.73742675800014,
                  -2.895105599999965
                ],
                [
                  104.73790741099998,
                  -2.895406483999921
                ],
                [
                  104.73821258500004,
                  -2.895676373999947
                ],
                [
                  104.73785400300005,
                  -2.895982025999842
                ],
                [
                  104.737922669,
                  -2.896134852999978
                ],
                [
                  104.73780822800006,
                  -2.896395444999939
                ],
                [
                  104.73795318600014,
                  -2.896593331999952
                ],
                [
                  104.73803710900006,
                  -2.896836280999935
                ],
                [
                  104.73786163400001,
                  -2.897042989999875
                ],
                [
                  104.73759460400015,
                  -2.897352934999958
                ],
                [
                  104.73714447000003,
                  -2.897571562999929
                ],
                [
                  104.73639679000019,
                  -2.897940159999962
                ],
                [
                  104.73573303200016,
                  -2.898254631999919
                ],
                [
                  104.73574829200015,
                  -2.898430584999915
                ],
                [
                  104.73535919100004,
                  -2.898615836999966
                ],
                [
                  104.73537445000011,
                  -2.898831605999874
                ],
                [
                  104.73526000900017,
                  -2.898894546999941
                ],
                [
                  104.73511505200014,
                  -2.899334907999958
                ],
                [
                  104.73516082700013,
                  -2.89946079099991
                ],
                [
                  104.73535919100004,
                  -2.899649857999918
                ],
                [
                  104.73535919100004,
                  -2.899834393999924
                ],
                [
                  104.7352066040001,
                  -2.899917839999887
                ],
                [
                  104.73496246300004,
                  -2.900106668999967
                ],
                [
                  104.73495483500011,
                  -2.90021204899989
                ],
                [
                  104.73483276400009,
                  -2.900279044999934
                ],
                [
                  104.73458862300004,
                  -2.900467872999911
                ],
                [
                  104.73457336400008,
                  -2.900647638999942
                ],
                [
                  104.73448181200018,
                  -2.90081834699987
                ],
                [
                  104.7342681880001,
                  -2.901150942999948
                ],
                [
                  104.7339172360002,
                  -2.902092694999965
                ],
                [
                  104.73376464900008,
                  -2.903442382999913
                ],
                [
                  104.74124145500019,
                  -2.906113384999855
                ],
                [
                  104.75379181000005,
                  -2.915580033999845
                ],
                [
                  104.75643157900004,
                  -2.915259360999869
                ],
                [
                  104.75646209799999,
                  -2.915867089999949
                ],
                [
                  104.75678253300015,
                  -2.920292376999896
                ],
                [
                  104.756477356,
                  -2.921077966999974
                ],
                [
                  104.75583648600013,
                  -2.923129557999914
                ],
                [
                  104.75566864000007,
                  -2.92311024699984
                ],
                [
                  104.75564575200013,
                  -2.923181532999877
                ],
                [
                  104.75508117800013,
                  -2.923040390999915
                ],
                [
                  104.75476074300019,
                  -2.923002481999958
                ],
                [
                  104.75379943800016,
                  -2.922934770999973
                ],
                [
                  104.75379181000005,
                  -2.923124074999919
                ],
                [
                  104.75379181000005,
                  -2.923502682999981
                ],
                [
                  104.75355529900008,
                  -2.923399685999868
                ],
                [
                  104.75344848600008,
                  -2.923399685999868
                ],
                [
                  104.75337982100018,
                  -2.923866271999941
                ],
                [
                  104.753479003,
                  -2.923993110999959
                ],
                [
                  104.753623962,
                  -2.924058913999886
                ],
                [
                  104.75361633200009,
                  -2.924161672999958
                ],
                [
                  104.75381469600018,
                  -2.924407957999904
                ],
                [
                  104.75386047300015,
                  -2.924465654999892
                ],
                [
                  104.75403594900007,
                  -2.924430130999951
                ],
                [
                  104.75405120900012,
                  -2.924512147999906
                ],
                [
                  104.754188538,
                  -2.924507139999946
                ],
                [
                  104.75422668500005,
                  -2.925564049999934
                ],
                [
                  104.75466156100015,
                  -2.925800799999877
                ],
                [
                  104.75465393100006,
                  -2.925902366999935
                ],
                [
                  104.7547531130001,
                  -2.925963877999948
                ],
                [
                  104.75443267800011,
                  -2.929598092999925
                ],
                [
                  104.75503540099999,
                  -2.9296257499999
                ],
                [
                  104.75697326700009,
                  -2.929701565999949
                ],
                [
                  104.75698852500011,
                  -2.930028915999969
                ],
                [
                  104.75699615500008,
                  -2.930400847999977
                ],
                [
                  104.75756836000005,
                  -2.930707930999972
                ],
                [
                  104.75720977800006,
                  -2.932898997999928
                ],
                [
                  104.75713348400012,
                  -2.933648824999977
                ],
                [
                  104.75705719000007,
                  -2.934168337999949
                ],
                [
                  104.75689697300004,
                  -2.935064076999936
                ],
                [
                  104.75705719000007,
                  -2.935081719999971
                ],
                [
                  104.75765991200018,
                  -2.935136794999892
                ],
                [
                  104.75800323500005,
                  -2.935013293999873
                ],
                [
                  104.75828552300015,
                  -2.934774160999893
                ],
                [
                  104.75888061600011,
                  -2.934937237999975
                ],
                [
                  104.75914764400017,
                  -2.935010671999919
                ],
                [
                  104.75877380400016,
                  -2.935196637999979
                ],
                [
                  104.75849151500017,
                  -2.935338496999975
                ],
                [
                  104.758453369,
                  -2.935497521999878
                ],
                [
                  104.75839233300013,
                  -2.935655354999881
                ],
                [
                  104.75778961200007,
                  -2.935668944999975
                ],
                [
                  104.75768280000011,
                  -2.935693740999966
                ],
                [
                  104.7569351200001,
                  -2.935792922999894
                ],
                [
                  104.7563858040001,
                  -2.936039447999974
                ],
                [
                  104.75627136100013,
                  -2.936053275999939
                ],
                [
                  104.75592041000016,
                  -2.936094282999875
                ],
                [
                  104.75421905500014,
                  -2.935929536999936
                ],
                [
                  104.75356292700002,
                  -2.936423300999934
                ],
                [
                  104.75346374499998,
                  -2.936351774999878
                ],
                [
                  104.7532043450002,
                  -2.936270475999891
                ],
                [
                  104.75259399400005,
                  -2.936322688999951
                ],
                [
                  104.75242614800004,
                  -2.936356782999951
                ],
                [
                  104.75204467800006,
                  -2.93643927599993
                ],
                [
                  104.7517776480002,
                  -2.937958239999944
                ],
                [
                  104.75130462600015,
                  -2.937885521999931
                ],
                [
                  104.75116729700005,
                  -2.937885521999931
                ],
                [
                  104.75108337300003,
                  -2.937978028999964
                ],
                [
                  104.75097656300011,
                  -2.938153266999961
                ],
                [
                  104.75076294000007,
                  -2.938279151999836
                ],
                [
                  104.75053405700004,
                  -2.938210248999894
                ],
                [
                  104.75025940000017,
                  -2.938090800999873
                ],
                [
                  104.75014495900001,
                  -2.938138483999921
                ],
                [
                  104.74978637700019,
                  -2.938273906999939
                ],
                [
                  104.74954223600014,
                  -2.938316583999892
                ],
                [
                  104.74942779600008,
                  -2.93835568399993
                ],
                [
                  104.74926757800017,
                  -2.938923834999969
                ],
                [
                  104.74925232000015,
                  -2.93931460399989
                ],
                [
                  104.74926757800017,
                  -2.939567802999875
                ],
                [
                  104.74923706100003,
                  -2.939678907999905
                ],
                [
                  104.74918365400015,
                  -2.939896105999935
                ],
                [
                  104.74903869700017,
                  -2.940445423999904
                ],
                [
                  104.74800109900008,
                  -2.941009520999955
                ],
                [
                  104.74771118199999,
                  -2.941166399999872
                ],
                [
                  104.74737548900004,
                  -2.941051482999967
                ],
                [
                  104.74736785900012,
                  -2.941256046999854
                ],
                [
                  104.74738311700014,
                  -2.941454886999963
                ],
                [
                  104.74743652300009,
                  -2.941726206999931
                ],
                [
                  104.74754333500005,
                  -2.942138195999917
                ],
                [
                  104.74675750800014,
                  -2.942318676999946
                ],
                [
                  104.74680328300013,
                  -2.942531346999942
                ],
                [
                  104.74686431800012,
                  -2.942826985999886
                ],
                [
                  104.74689483700007,
                  -2.943009376999953
                ],
                [
                  104.74708557100018,
                  -2.944119690999912
                ],
                [
                  104.74726867700008,
                  -2.944165228999964
                ],
                [
                  104.747444153,
                  -2.944232701999852
                ],
                [
                  104.74745941100002,
                  -2.944384335999871
                ],
                [
                  104.74745941100002,
                  -2.944804430999966
                ],
                [
                  104.74716186500012,
                  -2.944703340999979
                ],
                [
                  104.74605560300006,
                  -2.944293021999954
                ],
                [
                  104.74651336700009,
                  -2.945266007999976
                ],
                [
                  104.74652862500011,
                  -2.945812462999925
                ],
                [
                  104.74661254900013,
                  -2.945978880999974
                ],
                [
                  104.74691009500009,
                  -2.946550607999939
                ],
                [
                  104.74647522000004,
                  -2.946697711999946
                ],
                [
                  104.74606323300014,
                  -2.946903466999913
                ],
                [
                  104.74607086100008,
                  -2.946997403999887
                ],
                [
                  104.7456665040001,
                  -2.947091817999876
                ],
                [
                  104.74504852300004,
                  -2.947214840999948
                ],
                [
                  104.74504089300012,
                  -2.947086809999917
                ],
                [
                  104.74483490000006,
                  -2.947092294999948
                ],
                [
                  104.7438049320001,
                  -2.947178363999967
                ],
                [
                  104.74360656800019,
                  -2.947170256999811
                ],
                [
                  104.74349975500013,
                  -2.947944879999966
                ],
                [
                  104.74287414600019,
                  -2.948449373999949
                ],
                [
                  104.74270629800014,
                  -2.948627232999911
                ],
                [
                  104.74255371100003,
                  -2.94882488199994
                ],
                [
                  104.74189758300008,
                  -2.949424981999925
                ],
                [
                  104.74153900100009,
                  -2.949946403999888
                ],
                [
                  104.74158477800006,
                  -2.950016497999854
                ],
                [
                  104.74151611300016,
                  -2.950128077999921
                ],
                [
                  104.7411804190001,
                  -2.950420855999823
                ],
                [
                  104.74108123700006,
                  -2.950483559999952
                ],
                [
                  104.74081420900018,
                  -2.950635193999972
                ],
                [
                  104.74037933400018,
                  -2.950847863999968
                ],
                [
                  104.73996734700012,
                  -2.950847863999968
                ],
                [
                  104.73924255300017,
                  -2.950829505999877
                ],
                [
                  104.73821258500004,
                  -2.950818061999826
                ],
                [
                  104.73751831000004,
                  -2.951146125999969
                ],
                [
                  104.73696899400005,
                  -2.951479910999979
                ],
                [
                  104.73716735900007,
                  -2.952147482999976
                ],
                [
                  104.73597717299998,
                  -2.953046081999958
                ],
                [
                  104.73588562100008,
                  -2.953113794999979
                ],
                [
                  104.73554992800007,
                  -2.95246982499998
                ],
                [
                  104.73489379900008,
                  -2.952875851999977
                ],
                [
                  104.73468017500016,
                  -2.952983378999932
                ],
                [
                  104.73443603500004,
                  -2.953157186999931
                ],
                [
                  104.73436737000009,
                  -2.953203916999882
                ],
                [
                  104.73397064100004,
                  -2.95218706199995
                ]
              ]
            ]
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "NAME_3": "Alang Alang Lebar",
          "NAME_4": "Alang Alang Lebar",
          "ID": 2,
          "STUNTING": 25,
          "WASTING":19,
          "LAT": 104.697327,
          "LONG": -2.939716,
        },
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [
                  104.72827911400015,
                  -2.942524670999944
                ],
                [
                  104.72849273700012,
                  -2.942669152999883
                ],
                [
                  104.72940826500002,
                  -2.943368195999881
                ],
                [
                  104.72998046900011,
                  -2.943983316999891
                ],
                [
                  104.73056030400005,
                  -2.944640397999933
                ],
                [
                  104.73124694900014,
                  -2.945562838999933
                ],
                [
                  104.73139953600003,
                  -2.94583272899996
                ],
                [
                  104.73165130500018,
                  -2.946275709999952
                ],
                [
                  104.73199462900016,
                  -2.946974754999871
                ],
                [
                  104.73288726800001,
                  -2.949169157999904
                ],
                [
                  104.73375701900017,
                  -2.951629400999877
                ],
                [
                  104.73397064100004,
                  -2.95218706199995
                ],
                [
                  104.73436737000009,
                  -2.953203916999882
                ],
                [
                  104.73443603500004,
                  -2.953157186999931
                ],
                [
                  104.73464202800011,
                  -2.953499315999977
                ],
                [
                  104.73435974200004,
                  -2.953596352999909
                ],
                [
                  104.73348999100006,
                  -2.953898666999976
                ],
                [
                  104.73262024000013,
                  -2.953838823999831
                ],
                [
                  104.730880737,
                  -2.954111336999972
                ],
                [
                  104.72978973300019,
                  -2.955141067999932
                ],
                [
                  104.7285461420002,
                  -2.955627679999964
                ],
                [
                  104.72745513900009,
                  -2.956033944999888
                ],
                [
                  104.72612762400013,
                  -2.957309006999878
                ],
                [
                  104.72563171300004,
                  -2.957665205999945
                ],
                [
                  104.72467041000016,
                  -2.958415268999943
                ],
                [
                  104.72402191200013,
                  -2.958820342999957
                ],
                [
                  104.72325897200011,
                  -2.959367512999961
                ],
                [
                  104.72315979000007,
                  -2.959409237999921
                ],
                [
                  104.72257995500013,
                  -2.959646938999867
                ],
                [
                  104.72240447899998,
                  -2.959946392999882
                ],
                [
                  104.72208404500014,
                  -2.960593222999933
                ],
                [
                  104.72098541300016,
                  -2.960761070999979
                ],
                [
                  104.72086334200009,
                  -2.960745810999867
                ],
                [
                  104.71997070300006,
                  -2.960665463999874
                ],
                [
                  104.71897125300012,
                  -2.960721731999911
                ],
                [
                  104.71888732900015,
                  -2.96067619199988
                ],
                [
                  104.71870422300009,
                  -2.960620879999908
                ],
                [
                  104.71844482400019,
                  -2.960640429999955
                ],
                [
                  104.71836853000008,
                  -2.960652112999924
                ],
                [
                  104.71833801400004,
                  -2.960551260999921
                ],
                [
                  104.71827697800018,
                  -2.960469959999955
                ],
                [
                  104.7180175790001,
                  -2.960462569999947
                ],
                [
                  104.71792602500017,
                  -2.960459946999947
                ],
                [
                  104.71783447300004,
                  -2.960268019999944
                ],
                [
                  104.71774292100014,
                  -2.959975241999871
                ],
                [
                  104.7173690790001,
                  -2.960002660999919
                ],
                [
                  104.7170257570001,
                  -2.960010050999927
                ],
                [
                  104.71701812700019,
                  -2.959919928999909
                ],
                [
                  104.71700286900017,
                  -2.959816216999968
                ],
                [
                  104.71697998000013,
                  -2.959715126999924
                ],
                [
                  104.71619415300006,
                  -2.959884642999953
                ],
                [
                  104.71575927800006,
                  -2.959876298999973
                ],
                [
                  104.71502685600018,
                  -2.959880829999975
                ],
                [
                  104.71466827400019,
                  -2.960176467999929
                ],
                [
                  104.71461486900012,
                  -2.960229395999932
                ],
                [
                  104.71450042700008,
                  -2.960252046999869
                ],
                [
                  104.71403503500011,
                  -2.96034431399994
                ],
                [
                  104.71242523200004,
                  -2.960446356999967
                ],
                [
                  104.71136474700012,
                  -2.960501431999887
                ],
                [
                  104.7100601210002,
                  -2.960555553999939
                ],
                [
                  104.71006774900013,
                  -2.960813520999977
                ],
                [
                  104.7100448600001,
                  -2.961785078999981
                ],
                [
                  104.70886230500003,
                  -2.962563990999911
                ],
                [
                  104.7056427010001,
                  -2.965462445999947
                ],
                [
                  104.69720459100006,
                  -2.97133159599997
                ],
                [
                  104.68672943000007,
                  -2.967337608999912
                ],
                [
                  104.68474578900003,
                  -2.963863371999878
                ],
                [
                  104.68223571700014,
                  -2.960729598999933
                ],
                [
                  104.67801666200006,
                  -2.953500985999938
                ],
                [
                  104.67801666200006,
                  -2.951549291999925
                ],
                [
                  104.67800140400004,
                  -2.948395966999954
                ],
                [
                  104.67750549300013,
                  -2.945984125999814
                ],
                [
                  104.67760467500017,
                  -2.944476602999941
                ],
                [
                  104.67730712900004,
                  -2.942969082999923
                ],
                [
                  104.67720794700017,
                  -2.941562175999934
                ],
                [
                  104.67710876400008,
                  -2.939049958999931
                ],
                [
                  104.67676544100016,
                  -2.93553972199993
                ],
                [
                  104.67626953200005,
                  -2.930381297999872
                ],
                [
                  104.67581176800002,
                  -2.929195879999952
                ],
                [
                  104.67563629100016,
                  -2.928743600999894
                ],
                [
                  104.67502593900014,
                  -2.927633045999869
                ],
                [
                  104.67477417000015,
                  -2.927058458999909
                ],
                [
                  104.6763076790001,
                  -2.926533936999874
                ],
                [
                  104.67552948000014,
                  -2.924504041999967
                ],
                [
                  104.67500305099998,
                  -2.923345327999925
                ],
                [
                  104.67546081500006,
                  -2.923137187999941
                ],
                [
                  104.67541504000008,
                  -2.921736239999916
                ],
                [
                  104.67539977900014,
                  -2.921508073999973
                ],
                [
                  104.67511749300007,
                  -2.916971443999955
                ],
                [
                  104.67555999700005,
                  -2.916842460999931
                ],
                [
                  104.67578125000017,
                  -2.916445493999959
                ],
                [
                  104.67596435600007,
                  -2.916346311999916
                ],
                [
                  104.67618560800008,
                  -2.916088342999956
                ],
                [
                  104.67622375500008,
                  -2.915929554999877
                ],
                [
                  104.67630004900019,
                  -2.915760754999951
                ],
                [
                  104.6764984130001,
                  -2.915591954999854
                ],
                [
                  104.67691803000008,
                  -2.915544746999956
                ],
                [
                  104.67749786500019,
                  -2.91508603099993
                ],
                [
                  104.67852783300015,
                  -2.914679526999919
                ],
                [
                  104.6784744260001,
                  -2.91398715899993
                ],
                [
                  104.6846542350001,
                  -2.913789509999845
                ],
                [
                  104.68685150100015,
                  -2.913758515999916
                ],
                [
                  104.6916351320001,
                  -2.91365051299988
                ],
                [
                  104.69396972700008,
                  -2.913586139999893
                ],
                [
                  104.69475555399998,
                  -2.913645742999847
                ],
                [
                  104.6949768070001,
                  -2.913692235999974
                ],
                [
                  104.69763183600014,
                  -2.914815425999905
                ],
                [
                  104.69934844900013,
                  -2.915513992999877
                ],
                [
                  104.70030975399999,
                  -2.915941952999958
                ],
                [
                  104.70484924400006,
                  -2.917867420999926
                ],
                [
                  104.70498657300016,
                  -2.918015002999937
                ],
                [
                  104.70500946100009,
                  -2.917909382999937
                ],
                [
                  104.70512390200008,
                  -2.918031930999916
                ],
                [
                  104.70531463600003,
                  -2.918209074999936
                ],
                [
                  104.70803833100007,
                  -2.920340298999974
                ],
                [
                  104.7094955440001,
                  -2.921486137999921
                ],
                [
                  104.71141052200011,
                  -2.923262833999956
                ],
                [
                  104.71135711700003,
                  -2.923406600999954
                ],
                [
                  104.71220397900015,
                  -2.924216030999958
                ],
                [
                  104.71378326500007,
                  -2.925580262999915
                ],
                [
                  104.7141723630001,
                  -2.925972937999973
                ],
                [
                  104.71441650500003,
                  -2.926172017999932
                ],
                [
                  104.71449279800004,
                  -2.926299810999865
                ],
                [
                  104.71512603800005,
                  -2.926937578999969
                ],
                [
                  104.71533203100012,
                  -2.927147150999872
                ],
                [
                  104.71556854200009,
                  -2.927537916999881
                ],
                [
                  104.71632385200002,
                  -2.929075478999948
                ],
                [
                  104.71789550800003,
                  -2.93364620299991
                ],
                [
                  104.71859741100013,
                  -2.935267686999964
                ],
                [
                  104.71910095200013,
                  -2.93612050899992
                ],
                [
                  104.71971130300011,
                  -2.93707084599987
                ],
                [
                  104.7202758790001,
                  -2.937577724999869
                ],
                [
                  104.72104644800015,
                  -2.938502072999881
                ],
                [
                  104.72143554700011,
                  -2.938969611999937
                ],
                [
                  104.72225189200009,
                  -2.939601181999876
                ],
                [
                  104.72402191200013,
                  -2.940563916999963
                ],
                [
                  104.72794342100019,
                  -2.942678450999949
                ],
                [
                  104.72827911400015,
                  -2.942524670999944
                ]
              ]
            ]
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "NAME_3": "Ilir Barat I",
          "NAME_4": "26 Ilir I",
          "ID": 3,
          "STUNTING": 42,
          "WASTING":22,
          "LAT": 104.669756,
          "LONG": -2.969637
        },
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [
                  104.74459075900018,
                  -2.984295605999876
                ],
                [
                  104.74433899000007,
                  -2.986099957999954
                ],
                [
                  104.7442398070001,
                  -2.986629962999928
                ],
                [
                  104.74423217700019,
                  -2.98673772799998
                ],
                [
                  104.74415588400018,
                  -2.987271070999952
                ],
                [
                  104.742530822,
                  -2.987317322999957
                ],
                [
                  104.74237060500019,
                  -2.987329243999966
                ],
                [
                  104.74173736500018,
                  -2.987478494999891
                ],
                [
                  104.73996734700012,
                  -2.987949369999967
                ],
                [
                  104.73979949900007,
                  -2.988041401999851
                ],
                [
                  104.73791503900009,
                  -2.989578485999914
                ],
                [
                  104.73785400300005,
                  -2.989497184999948
                ],
                [
                  104.73736572300015,
                  -2.989679335999938
                ],
                [
                  104.73719024700017,
                  -2.989735125999914
                ],
                [
                  104.73666381800007,
                  -2.989835023999944
                ],
                [
                  104.73622131400003,
                  -2.989894628999878
                ],
                [
                  104.73585510200013,
                  -2.989805696999895
                ],
                [
                  104.7354354850001,
                  -2.989670752999928
                ],
                [
                  104.73484802200011,
                  -2.991113423999934
                ],
                [
                  104.73458862300004,
                  -2.991814613999964
                ],
                [
                  104.73454284600007,
                  -2.99187040399994
                ],
                [
                  104.73392486600011,
                  -2.992610930999945
                ],
                [
                  104.7334594720001,
                  -2.992907284999944
                ],
                [
                  104.73336029000012,
                  -2.993981122999969
                ],
                [
                  104.73352813800011,
                  -2.994841575999942
                ],
                [
                  104.73355865500008,
                  -2.994967936999899
                ],
                [
                  104.73355865500008,
                  -2.995070218999842
                ],
                [
                  104.73360443200005,
                  -2.995771168999909
                ],
                [
                  104.73358154300018,
                  -2.996068000999969
                ],
                [
                  104.7335891730001,
                  -2.996494769999913
                ],
                [
                  104.73350524900007,
                  -2.996790886999918
                ],
                [
                  104.73371887200011,
                  -2.996908902999905
                ],
                [
                  104.73388672000016,
                  -2.996985673999973
                ],
                [
                  104.73452758700012,
                  -2.99737453399996
                ],
                [
                  104.73482513400018,
                  -2.998181581999972
                ],
                [
                  104.73540496800018,
                  -2.998678444999939
                ],
                [
                  104.73586273200004,
                  -2.998770474999844
                ],
                [
                  104.73685455400005,
                  -2.999437092999926
                ],
                [
                  104.7371978750001,
                  -2.999778269999922
                ],
                [
                  104.73764801100003,
                  -2.998842715999956
                ],
                [
                  104.73777771000005,
                  -2.999017953999896
                ],
                [
                  104.73799896300011,
                  -2.999138116999973
                ],
                [
                  104.7382736200002,
                  -2.99925112699998
                ],
                [
                  104.73829650900007,
                  -2.99933266599993
                ],
                [
                  104.73844909600012,
                  -2.999346016999937
                ],
                [
                  104.73851776200013,
                  -2.999459026999887
                ],
                [
                  104.73854065000006,
                  -2.999617336999904
                ],
                [
                  104.73849487300009,
                  -2.999691724999934
                ],
                [
                  104.73857116700003,
                  -3.000058174999879
                ],
                [
                  104.73858642500005,
                  -3.000189303999889
                ],
                [
                  104.73851776200013,
                  -3.000279902999921
                ],
                [
                  104.73859405500014,
                  -3.000351665999915
                ],
                [
                  104.73867034900007,
                  -3.000401018999924
                ],
                [
                  104.73873901500008,
                  -3.000439642999936
                ],
                [
                  104.73876190100003,
                  -3.000585794999949
                ],
                [
                  104.73873138400006,
                  -3.000679731999981
                ],
                [
                  104.73878479000001,
                  -3.000836610999897
                ],
                [
                  104.73885345500014,
                  -3.000870704999954
                ],
                [
                  104.73895263700018,
                  -3.000781058999848
                ],
                [
                  104.73899841200011,
                  -3.000702857999954
                ],
                [
                  104.73908233600014,
                  -3.000718355999879
                ],
                [
                  104.73921203700013,
                  -3.000810144999946
                ],
                [
                  104.73941802900009,
                  -3.000793218999945
                ],
                [
                  104.73957824700005,
                  -3.000861167999972
                ],
                [
                  104.73973846400008,
                  -3.000900744999967
                ],
                [
                  104.73985290500002,
                  -3.000946043999818
                ],
                [
                  104.73998260500014,
                  -3.001030920999824
                ],
                [
                  104.74005889900002,
                  -3.00113272599998
                ],
                [
                  104.740119934,
                  -3.001076219999845
                ],
                [
                  104.74021148600013,
                  -3.001047848999974
                ],
                [
                  104.74031066800018,
                  -3.001098870999954
                ],
                [
                  104.7403945920002,
                  -3.001127004999944
                ],
                [
                  104.74054718000019,
                  -3.001093148999928
                ],
                [
                  104.74066925000017,
                  -3.001110313999902
                ],
                [
                  104.74063873400013,
                  -3.00121188199995
                ],
                [
                  104.7404861440001,
                  -3.00143766399998
                ],
                [
                  104.74050903300014,
                  -3.001556872999913
                ],
                [
                  104.74050140500003,
                  -3.001628398999969
                ],
                [
                  104.74053955099998,
                  -3.001849173999915
                ],
                [
                  104.74058532600014,
                  -3.001936912999895
                ],
                [
                  104.7405548100001,
                  -3.002031564999925
                ],
                [
                  104.7404861440001,
                  -3.002059935999966
                ],
                [
                  104.74038696200006,
                  -3.002064465999979
                ],
                [
                  104.74028778000007,
                  -3.002089977999958
                ],
                [
                  104.74026489300019,
                  -3.002324342999884
                ],
                [
                  104.74033355700004,
                  -3.002491473999953
                ],
                [
                  104.74032592700007,
                  -3.002599715999963
                ],
                [
                  104.74054718000019,
                  -3.002708911999946
                ],
                [
                  104.74071502700014,
                  -3.002916811999967
                ],
                [
                  104.74082946800013,
                  -3.003060577999975
                ],
                [
                  104.74085998400017,
                  -3.003152607999937
                ],
                [
                  104.74088287299998,
                  -3.003221035999957
                ],
                [
                  104.7408065790001,
                  -3.003341673999955
                ],
                [
                  104.74073791600017,
                  -3.003454923999925
                ],
                [
                  104.74069976900012,
                  -3.003759860999935
                ],
                [
                  104.74065399200015,
                  -3.003863572999876
                ],
                [
                  104.74056244000002,
                  -3.003978490999884
                ],
                [
                  104.740417481,
                  -3.003917215999877
                ],
                [
                  104.7403182990002,
                  -3.003980635999881
                ],
                [
                  104.7402572630001,
                  -3.004022835999933
                ],
                [
                  104.74015808100006,
                  -3.004087685999878
                ],
                [
                  104.73995208700006,
                  -3.004065512999887
                ],
                [
                  104.73965454000006,
                  -3.00404357899987
                ],
                [
                  104.73902130100015,
                  -3.003990648999945
                ],
                [
                  104.73888397200005,
                  -3.004473208999968
                ],
                [
                  104.73880767800017,
                  -3.004553794999879
                ],
                [
                  104.73874664300018,
                  -3.004679918999955
                ],
                [
                  104.73887634300007,
                  -3.005574702999979
                ],
                [
                  104.738990784,
                  -3.005829810999955
                ],
                [
                  104.7390594470001,
                  -3.006004570999949
                ],
                [
                  104.7391281130001,
                  -3.006242512999904
                ],
                [
                  104.73876190100003,
                  -3.006244659999822
                ],
                [
                  104.73823547400008,
                  -3.006196737999858
                ],
                [
                  104.73814392200012,
                  -3.006631135999896
                ],
                [
                  104.73800659300002,
                  -3.007187366999915
                ],
                [
                  104.73880004800003,
                  -3.009318113999939
                ],
                [
                  104.73838043300009,
                  -3.009644268999978
                ],
                [
                  104.73822021500013,
                  -3.009697675999973
                ],
                [
                  104.72867584300019,
                  -3.012906074999876
                ],
                [
                  104.72911834700005,
                  -3.011712073999945
                ],
                [
                  104.72914886500007,
                  -3.011611460999973
                ],
                [
                  104.72919464000006,
                  -3.011482952999813
                ],
                [
                  104.72890472400002,
                  -3.009653566999873
                ],
                [
                  104.72834777800011,
                  -3.009011506999968
                ],
                [
                  104.7269058230001,
                  -3.009003876999941
                ],
                [
                  104.72686767600004,
                  -3.007376192999914
                ],
                [
                  104.72602081400015,
                  -3.007165192999935
                ],
                [
                  104.72554016100008,
                  -3.006810903999963
                ],
                [
                  104.7238464350001,
                  -3.005661249999946
                ],
                [
                  104.72303009100006,
                  -3.005708931999891
                ],
                [
                  104.72220611500012,
                  -3.005852460999961
                ],
                [
                  104.72072601300005,
                  -3.006160020999914
                ],
                [
                  104.719444275,
                  -3.006302356999868
                ],
                [
                  104.71256256100014,
                  -3.007549285999858
                ],
                [
                  104.71166992100018,
                  -3.00762295699991
                ],
                [
                  104.71163940500014,
                  -3.004458665999948
                ],
                [
                  104.71160888600019,
                  -3.003189563999911
                ],
                [
                  104.71157073900014,
                  -3.000335692999954
                ],
                [
                  104.71166992100018,
                  -3.000335692999954
                ],
                [
                  104.71166229300007,
                  -3.000261306999903
                ],
                [
                  104.71099090700011,
                  -2.999797342999898
                ],
                [
                  104.71035766500012,
                  -2.998754500999951
                ],
                [
                  104.70969390900012,
                  -2.998233556999935
                ],
                [
                  104.7090682990002,
                  -2.99817705199996
                ],
                [
                  104.7083358770002,
                  -2.997648954999931
                ],
                [
                  104.70745086600004,
                  -2.99707484299995
                ],
                [
                  104.70641326999998,
                  -2.996756314999971
                ],
                [
                  104.70405578700007,
                  -2.996663330999922
                ],
                [
                  104.70296478300003,
                  -2.99624466899985
                ],
                [
                  104.70230865500008,
                  -2.995993374999841
                ],
                [
                  104.7013244640001,
                  -2.995054482999933
                ],
                [
                  104.70014953600008,
                  -2.989630936999902
                ],
                [
                  104.69921875000017,
                  -2.989516972999979
                ],
                [
                  104.69815063500016,
                  -2.989602087999913
                ],
                [
                  104.69721984900008,
                  -2.988430499999879
                ],
                [
                  104.69616699200009,
                  -2.987200974999951
                ],
                [
                  104.69608306800006,
                  -2.986273765999954
                ],
                [
                  104.69493103000013,
                  -2.98482608899991
                ],
                [
                  104.69316864000012,
                  -2.984391212999981
                ],
                [
                  104.69141388000008,
                  -2.984419584999955
                ],
                [
                  104.69035339300018,
                  -2.983435153999949
                ],
                [
                  104.688796997,
                  -2.982971428999917
                ],
                [
                  104.68729400700016,
                  -2.983029126999895
                ],
                [
                  104.68605804400016,
                  -2.982708929999887
                ],
                [
                  104.68551635800009,
                  -2.982452869999918
                ],
                [
                  104.68491363500004,
                  -2.981564282999955
                ],
                [
                  104.68482208300009,
                  -2.981008766999878
                ],
                [
                  104.68332672100018,
                  -2.981693743999926
                ],
                [
                  104.68274688600007,
                  -2.981781958999932
                ],
                [
                  104.681625366,
                  -2.983316897999941
                ],
                [
                  104.68035888700012,
                  -2.983750105999945
                ],
                [
                  104.67800140400004,
                  -2.982388496999931
                ],
                [
                  104.67523193400001,
                  -2.982794283999908
                ],
                [
                  104.67396545400004,
                  -2.982214211999974
                ],
                [
                  104.67250061100009,
                  -2.981201170999839
                ],
                [
                  104.67172241200007,
                  -2.981808899999919
                ],
                [
                  104.67034149200003,
                  -2.980795859999944
                ],
                [
                  104.6690979010001,
                  -2.981083392999949
                ],
                [
                  104.66731262300016,
                  -2.982270478999851
                ],
                [
                  104.66486358600014,
                  -2.985367773999883
                ],
                [
                  104.66187286300004,
                  -2.984305381999889
                ],
                [
                  104.66175079400011,
                  -2.984334945999933
                ],
                [
                  104.66168212900004,
                  -2.984436272999972
                ],
                [
                  104.65879821900018,
                  -2.98343944599992
                ],
                [
                  104.65802765000012,
                  -2.983174323999947
                ],
                [
                  104.65647888200004,
                  -2.980480669999906
                ],
                [
                  104.65504455500013,
                  -2.978879927999969
                ],
                [
                  104.65110015800013,
                  -2.976843833999908
                ],
                [
                  104.65113067700008,
                  -2.976729631999945
                ],
                [
                  104.64217376700009,
                  -2.974963903999878
                ],
                [
                  104.63932037400019,
                  -2.972605465999948
                ],
                [
                  104.63854217500017,
                  -2.97196316599991
                ],
                [
                  104.6322250360002,
                  -2.966734647999942
                ],
                [
                  104.62903594900001,
                  -2.963603018999947
                ],
                [
                  104.62907409600007,
                  -2.961602447999951
                ],
                [
                  104.62918853900004,
                  -2.9541423309999
                ],
                [
                  104.63366699100004,
                  -2.936342238999885
                ],
                [
                  104.63525390600017,
                  -2.933233260999941
                ],
                [
                  104.63731384200008,
                  -2.930702209999879
                ],
                [
                  104.6377792360002,
                  -2.930133103999935
                ],
                [
                  104.64297485300011,
                  -2.932392596999932
                ],
                [
                  104.64299774100004,
                  -2.930969237999932
                ],
                [
                  104.64405059800004,
                  -2.926118372999952
                ],
                [
                  104.644096375,
                  -2.925896166999962
                ],
                [
                  104.64499664200014,
                  -2.924990176999927
                ],
                [
                  104.64769744900008,
                  -2.922300100999962
                ],
                [
                  104.65080261200006,
                  -2.921471117999943
                ],
                [
                  104.651702881,
                  -2.921211242999902
                ],
                [
                  104.65190124500009,
                  -2.922177075999969
                ],
                [
                  104.65619659400011,
                  -2.922854184999949
                ],
                [
                  104.66220092800006,
                  -2.928338051999845
                ],
                [
                  104.66539764300018,
                  -2.929980278999835
                ],
                [
                  104.67676544100016,
                  -2.93553972199993
                ],
                [
                  104.67710876400008,
                  -2.939049958999931
                ],
                [
                  104.67720794700017,
                  -2.941562175999934
                ],
                [
                  104.67730712900004,
                  -2.942969082999923
                ],
                [
                  104.67760467500017,
                  -2.944476602999941
                ],
                [
                  104.67750549300013,
                  -2.945984125999814
                ],
                [
                  104.67800140400004,
                  -2.948395966999954
                ],
                [
                  104.67801666200006,
                  -2.951549291999925
                ],
                [
                  104.67801666200006,
                  -2.953500985999938
                ],
                [
                  104.68223571700014,
                  -2.960729598999933
                ],
                [
                  104.68474578900003,
                  -2.963863371999878
                ],
                [
                  104.68672943000007,
                  -2.967337608999912
                ],
                [
                  104.69720459100006,
                  -2.97133159599997
                ],
                [
                  104.7056427010001,
                  -2.965462445999947
                ],
                [
                  104.70886230500003,
                  -2.962563990999911
                ],
                [
                  104.7100448600001,
                  -2.961785078999981
                ],
                [
                  104.71006774900013,
                  -2.960813520999977
                ],
                [
                  104.7100601210002,
                  -2.960555553999939
                ],
                [
                  104.71136474700012,
                  -2.960501431999887
                ],
                [
                  104.71242523200004,
                  -2.960446356999967
                ],
                [
                  104.71403503500011,
                  -2.96034431399994
                ],
                [
                  104.71450042700008,
                  -2.960252046999869
                ],
                [
                  104.71461486900012,
                  -2.960229395999932
                ],
                [
                  104.71466827400019,
                  -2.960176467999929
                ],
                [
                  104.71502685600018,
                  -2.959880829999975
                ],
                [
                  104.71575927800006,
                  -2.959876298999973
                ],
                [
                  104.71619415300006,
                  -2.959884642999953
                ],
                [
                  104.71697998000013,
                  -2.959715126999924
                ],
                [
                  104.71700286900017,
                  -2.959816216999968
                ],
                [
                  104.71701812700019,
                  -2.959919928999909
                ],
                [
                  104.7170257570001,
                  -2.960010050999927
                ],
                [
                  104.7173690790001,
                  -2.960002660999919
                ],
                [
                  104.71774292100014,
                  -2.959975241999871
                ],
                [
                  104.71783447300004,
                  -2.960268019999944
                ],
                [
                  104.71792602500017,
                  -2.960459946999947
                ],
                [
                  104.7180175790001,
                  -2.960462569999947
                ],
                [
                  104.71827697800018,
                  -2.960469959999955
                ],
                [
                  104.71833801400004,
                  -2.960551260999921
                ],
                [
                  104.71836853000008,
                  -2.960652112999924
                ],
                [
                  104.71844482400019,
                  -2.960640429999955
                ],
                [
                  104.71870422300009,
                  -2.960620879999908
                ],
                [
                  104.71888732900015,
                  -2.96067619199988
                ],
                [
                  104.71897125300012,
                  -2.960721731999911
                ],
                [
                  104.71997070300006,
                  -2.960665463999874
                ],
                [
                  104.72086334200009,
                  -2.960745810999867
                ],
                [
                  104.72098541300016,
                  -2.960761070999979
                ],
                [
                  104.72208404500014,
                  -2.960593222999933
                ],
                [
                  104.72240447899998,
                  -2.959946392999882
                ],
                [
                  104.72257995500013,
                  -2.959646938999867
                ],
                [
                  104.72315979000007,
                  -2.959409237999921
                ],
                [
                  104.72325897200011,
                  -2.959367512999961
                ],
                [
                  104.72415161100014,
                  -2.959922313999925
                ],
                [
                  104.72436523400012,
                  -2.960015295999881
                ],
                [
                  104.72466278000007,
                  -2.960207462999904
                ],
                [
                  104.72552490300006,
                  -2.959567546999892
                ],
                [
                  104.72564697400014,
                  -2.959705828999972
                ],
                [
                  104.7258148190001,
                  -2.959886789999928
                ],
                [
                  104.72600555300005,
                  -2.960070608999956
                ],
                [
                  104.72655487100002,
                  -2.959841012999959
                ],
                [
                  104.72665405300006,
                  -2.959813356999973
                ],
                [
                  104.72677612400014,
                  -2.960021495999968
                ],
                [
                  104.72808837800017,
                  -2.959491968999941
                ],
                [
                  104.72853088400018,
                  -2.959909915999958
                ],
                [
                  104.72924804700011,
                  -2.959403991999864
                ],
                [
                  104.73023986900012,
                  -2.958873032999975
                ],
                [
                  104.73051452700008,
                  -2.958993672999952
                ],
                [
                  104.73063659700006,
                  -2.959082126999874
                ],
                [
                  104.73086547800011,
                  -2.95929694199998
                ],
                [
                  104.73107147300016,
                  -2.959162473999868
                ],
                [
                  104.73199462900016,
                  -2.959299087999966
                ],
                [
                  104.73307800200018,
                  -2.959516047999898
                ],
                [
                  104.7332916270002,
                  -2.959805488999962
                ],
                [
                  104.73365020900019,
                  -2.959737777999862
                ],
                [
                  104.73382568400007,
                  -2.959633825999958
                ],
                [
                  104.73405456500006,
                  -2.959931611999821
                ],
                [
                  104.73420715300011,
                  -2.960135221999963
                ],
                [
                  104.73427581800019,
                  -2.960471867999956
                ],
                [
                  104.73434448199998,
                  -2.961393117999933
                ],
                [
                  104.7343063350001,
                  -2.96165370999995
                ],
                [
                  104.734992982,
                  -2.961622953999949
                ],
                [
                  104.73502349800003,
                  -2.961838960999899
                ],
                [
                  104.73504638700007,
                  -2.962581633999946
                ],
                [
                  104.73503875700015,
                  -2.962725877999901
                ],
                [
                  104.73516845700004,
                  -2.962739466999949
                ],
                [
                  104.73527526800007,
                  -2.963236331999951
                ],
                [
                  104.73546600400005,
                  -2.963213920999976
                ],
                [
                  104.73661041300016,
                  -2.963062285999968
                ],
                [
                  104.73612213200016,
                  -2.964020728999913
                ],
                [
                  104.73482513400018,
                  -2.966494797999928
                ],
                [
                  104.73468017500016,
                  -2.966781377999894
                ],
                [
                  104.73433685300017,
                  -2.967436073999977
                ],
                [
                  104.73429870700005,
                  -2.967510699999934
                ],
                [
                  104.73583984400011,
                  -2.967825889999915
                ],
                [
                  104.737419128,
                  -2.967725037999912
                ],
                [
                  104.73764801100003,
                  -2.967716454999902
                ],
                [
                  104.73863220200019,
                  -2.967646835999915
                ],
                [
                  104.73888397200005,
                  -2.967613219999976
                ],
                [
                  104.74034881500006,
                  -2.967886924999902
                ],
                [
                  104.74111938400011,
                  -2.968016147999947
                ],
                [
                  104.74164581300005,
                  -2.968130825999935
                ],
                [
                  104.74183654700005,
                  -2.968166112999825
                ],
                [
                  104.74195098799999,
                  -2.96819710699998
                ],
                [
                  104.7419128410001,
                  -2.968364954999913
                ],
                [
                  104.74227142300015,
                  -2.968775986999901
                ],
                [
                  104.74269867000004,
                  -2.969616888999951
                ],
                [
                  104.74316406200018,
                  -2.970541953999941
                ],
                [
                  104.74364471500007,
                  -2.971370935999971
                ],
                [
                  104.74439239500009,
                  -2.971863508999945
                ],
                [
                  104.744880675,
                  -2.97244024299988
                ],
                [
                  104.7449340820001,
                  -2.972968577999893
                ],
                [
                  104.74508667000009,
                  -2.973545312999931
                ],
                [
                  104.74513244700006,
                  -2.973645686999873
                ],
                [
                  104.74544525100004,
                  -2.974302052999917
                ],
                [
                  104.74616241500019,
                  -2.974866866999946
                ],
                [
                  104.7458038330002,
                  -2.975741862999939
                ],
                [
                  104.745529175,
                  -2.976125240999977
                ],
                [
                  104.74534606900016,
                  -2.976744651999979
                ],
                [
                  104.74517822200005,
                  -2.976994991999959
                ],
                [
                  104.74558258000008,
                  -2.977203368999881
                ],
                [
                  104.74575805600006,
                  -2.977426528999956
                ],
                [
                  104.74604034400011,
                  -2.97771453799993
                ],
                [
                  104.74640655400003,
                  -2.978244304999976
                ],
                [
                  104.74666595400004,
                  -2.979170320999913
                ],
                [
                  104.7467956550002,
                  -2.979528664999918
                ],
                [
                  104.74687194800003,
                  -2.979754923999963
                ],
                [
                  104.74630737200005,
                  -2.980212687999938
                ],
                [
                  104.74531555300013,
                  -2.981978892999962
                ],
                [
                  104.74483490000006,
                  -2.983395813999948
                ],
                [
                  104.74459075900018,
                  -2.984295605999876
                ]
              ]
            ]
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "NAME_3": "Gandus",
          "NAME_4": "36 Ilir",
          "ID": 4,
          "STUNTING": 19,
          "WASTING":12,
          "LAT": 104.667726,
          "LONG": -3.007572
        },
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [
                  104.745872497,
                  -3.017887114999894
                ],
                [
                  104.74500274600007,
                  -3.018854855999962
                ],
                [
                  104.74269867000004,
                  -3.021740435999959
                ],
                [
                  104.74216461300017,
                  -3.022120475999941
                ],
                [
                  104.7419128410001,
                  -3.022299526999916
                ],
                [
                  104.74170684900014,
                  -3.022520541999938
                ],
                [
                  104.7411270140002,
                  -3.02286410399995
                ],
                [
                  104.7394943230002,
                  -3.02394795399988
                ],
                [
                  104.73940277100007,
                  -3.023974180999858
                ],
                [
                  104.7393188470001,
                  -3.023879528999942
                ],
                [
                  104.73405456500006,
                  -3.023982762999935
                ],
                [
                  104.72868347100012,
                  -3.02243375799992
                ],
                [
                  104.72705841000004,
                  -3.02114415099993
                ],
                [
                  104.72373199400016,
                  -3.018510101999937
                ],
                [
                  104.7162933350001,
                  -3.015825270999926
                ],
                [
                  104.71135711700003,
                  -3.015061139999943
                ],
                [
                  104.70761871400009,
                  -3.014482975999954
                ],
                [
                  104.69812011700009,
                  -3.015722035999886
                ],
                [
                  104.6894454950002,
                  -3.020471810999879
                ],
                [
                  104.68905639700017,
                  -3.020796298999926
                ],
                [
                  104.68594360300017,
                  -3.023376939999821
                ],
                [
                  104.68572235100015,
                  -3.02351045599994
                ],
                [
                  104.68515014600018,
                  -3.02402067099996
                ],
                [
                  104.68304443300008,
                  -3.025777339999934
                ],
                [
                  104.68267822299998,
                  -3.025997877999941
                ],
                [
                  104.68228149400011,
                  -3.026853799999969
                ],
                [
                  104.6822052010001,
                  -3.026974678999977
                ],
                [
                  104.68144226000015,
                  -3.027522801999908
                ],
                [
                  104.68114471400003,
                  -3.028000353999914
                ],
                [
                  104.68090820200018,
                  -3.028597354999931
                ],
                [
                  104.68054962100007,
                  -3.029015301999948
                ],
                [
                  104.68025207400007,
                  -3.029492853999955
                ],
                [
                  104.68007659900002,
                  -3.029910801999961
                ],
                [
                  104.67965698100011,
                  -3.030209064999951
                ],
                [
                  104.67918395900006,
                  -3.030627011999911
                ],
                [
                  104.67858886600015,
                  -3.031164169999954
                ],
                [
                  104.67836761600012,
                  -3.031455278999942
                ],
                [
                  104.67803192200006,
                  -3.031598329999895
                ],
                [
                  104.67750549300013,
                  -3.032061814999963
                ],
                [
                  104.6769866940001,
                  -3.032298087999948
                ],
                [
                  104.67645263600014,
                  -3.03271579799997
                ],
                [
                  104.67593383700012,
                  -3.03300023099996
                ],
                [
                  104.675590516,
                  -3.033115148999968
                ],
                [
                  104.67520141500006,
                  -3.033193109999957
                ],
                [
                  104.6746063220001,
                  -3.033372163999957
                ],
                [
                  104.67383575400015,
                  -3.033610819999979
                ],
                [
                  104.67342376700009,
                  -3.033730029999958
                ],
                [
                  104.67282867400013,
                  -3.033968686999913
                ],
                [
                  104.67216491700003,
                  -3.034378527999877
                ],
                [
                  104.67192840600006,
                  -3.034502028999896
                ],
                [
                  104.67151641700019,
                  -3.03468465799989
                ],
                [
                  104.67129516700015,
                  -3.034837244999835
                ],
                [
                  104.67002868700018,
                  -3.035503387999881
                ],
                [
                  104.66922760000006,
                  -3.036076782999885
                ],
                [
                  104.66902160600006,
                  -3.036176681999905
                ],
                [
                  104.66846466100009,
                  -3.036619662999954
                ],
                [
                  104.66705322300004,
                  -3.037629842999877
                ],
                [
                  104.66400909500004,
                  -3.040815591999944
                ],
                [
                  104.66395568800016,
                  -3.04090380699995
                ],
                [
                  104.66370391900017,
                  -3.041371105999815
                ],
                [
                  104.65478515600006,
                  -3.032319306999966
                ],
                [
                  104.65410613900008,
                  -3.031853436999938
                ],
                [
                  104.64836120700011,
                  -3.027885673999947
                ],
                [
                  104.64494323700012,
                  -3.025448799999936
                ],
                [
                  104.64437905600005,
                  -3.024629393999874
                ],
                [
                  104.64388200000013,
                  -3.023906999999895
                ],
                [
                  104.643180756,
                  -3.022889006999947
                ],
                [
                  104.64086151100008,
                  -3.019528149999871
                ],
                [
                  104.6384964,
                  -3.016095160999953
                ],
                [
                  104.6349029540001,
                  -3.011759280999911
                ],
                [
                  104.63179779100011,
                  -3.007921218999968
                ],
                [
                  104.62580108700007,
                  -3.000581263999834
                ],
                [
                  104.62509918200016,
                  -2.998662231999958
                ],
                [
                  104.62480163600009,
                  -2.997716187999913
                ],
                [
                  104.62390136599998,
                  -2.995377062999978
                ],
                [
                  104.62300109900002,
                  -2.991339205999964
                ],
                [
                  104.6226959220001,
                  -2.9800372119999
                ],
                [
                  104.62359619200015,
                  -2.974960088999921
                ],
                [
                  104.62470245400004,
                  -2.970556259999967
                ],
                [
                  104.62740326000005,
                  -2.965287207999836
                ],
                [
                  104.62719726500012,
                  -2.962212085999965
                ],
                [
                  104.62907409600007,
                  -2.961602447999951
                ],
                [
                  104.62903594900001,
                  -2.963603018999947
                ],
                [
                  104.6322250360002,
                  -2.966734647999942
                ],
                [
                  104.63854217500017,
                  -2.97196316599991
                ],
                [
                  104.63932037400019,
                  -2.972605465999948
                ],
                [
                  104.64217376700009,
                  -2.974963903999878
                ],
                [
                  104.65113067700008,
                  -2.976729631999945
                ],
                [
                  104.65110015800013,
                  -2.976843833999908
                ],
                [
                  104.65504455500013,
                  -2.978879927999969
                ],
                [
                  104.65647888200004,
                  -2.980480669999906
                ],
                [
                  104.65802765000012,
                  -2.983174323999947
                ],
                [
                  104.65879821900018,
                  -2.98343944599992
                ],
                [
                  104.66168212900004,
                  -2.984436272999972
                ],
                [
                  104.66175079400011,
                  -2.984334945999933
                ],
                [
                  104.66187286300004,
                  -2.984305381999889
                ],
                [
                  104.66486358600014,
                  -2.985367773999883
                ],
                [
                  104.66731262300016,
                  -2.982270478999851
                ],
                [
                  104.6690979010001,
                  -2.981083392999949
                ],
                [
                  104.67034149200003,
                  -2.980795859999944
                ],
                [
                  104.67172241200007,
                  -2.981808899999919
                ],
                [
                  104.67250061100009,
                  -2.981201170999839
                ],
                [
                  104.67396545400004,
                  -2.982214211999974
                ],
                [
                  104.67523193400001,
                  -2.982794283999908
                ],
                [
                  104.67800140400004,
                  -2.982388496999931
                ],
                [
                  104.68035888700012,
                  -2.983750105999945
                ],
                [
                  104.681625366,
                  -2.983316897999941
                ],
                [
                  104.68274688600007,
                  -2.981781958999932
                ],
                [
                  104.68332672100018,
                  -2.981693743999926
                ],
                [
                  104.68482208300009,
                  -2.981008766999878
                ],
                [
                  104.68491363500004,
                  -2.981564282999955
                ],
                [
                  104.68551635800009,
                  -2.982452869999918
                ],
                [
                  104.68605804400016,
                  -2.982708929999887
                ],
                [
                  104.68729400700016,
                  -2.983029126999895
                ],
                [
                  104.688796997,
                  -2.982971428999917
                ],
                [
                  104.69035339300018,
                  -2.983435153999949
                ],
                [
                  104.69141388000008,
                  -2.984419584999955
                ],
                [
                  104.69316864000012,
                  -2.984391212999981
                ],
                [
                  104.69493103000013,
                  -2.98482608899991
                ],
                [
                  104.69608306800006,
                  -2.986273765999954
                ],
                [
                  104.69616699200009,
                  -2.987200974999951
                ],
                [
                  104.69721984900008,
                  -2.988430499999879
                ],
                [
                  104.69815063500016,
                  -2.989602087999913
                ],
                [
                  104.69921875000017,
                  -2.989516972999979
                ],
                [
                  104.70014953600008,
                  -2.989630936999902
                ],
                [
                  104.7013244640001,
                  -2.995054482999933
                ],
                [
                  104.70230865500008,
                  -2.995993374999841
                ],
                [
                  104.70296478300003,
                  -2.99624466899985
                ],
                [
                  104.70405578700007,
                  -2.996663330999922
                ],
                [
                  104.70641326999998,
                  -2.996756314999971
                ],
                [
                  104.70745086600004,
                  -2.99707484299995
                ],
                [
                  104.7083358770002,
                  -2.997648954999931
                ],
                [
                  104.7090682990002,
                  -2.99817705199996
                ],
                [
                  104.70969390900012,
                  -2.998233556999935
                ],
                [
                  104.71035766500012,
                  -2.998754500999951
                ],
                [
                  104.71099090700011,
                  -2.999797342999898
                ],
                [
                  104.71166229300007,
                  -3.000261306999903
                ],
                [
                  104.71166992100018,
                  -3.000335692999954
                ],
                [
                  104.71157073900014,
                  -3.000335692999954
                ],
                [
                  104.71160888600019,
                  -3.003189563999911
                ],
                [
                  104.71163940500014,
                  -3.004458665999948
                ],
                [
                  104.71166992100018,
                  -3.00762295699991
                ],
                [
                  104.71256256100014,
                  -3.007549285999858
                ],
                [
                  104.719444275,
                  -3.006302356999868
                ],
                [
                  104.72072601300005,
                  -3.006160020999914
                ],
                [
                  104.72220611500012,
                  -3.005852460999961
                ],
                [
                  104.72303009100006,
                  -3.005708931999891
                ],
                [
                  104.7238464350001,
                  -3.005661249999946
                ],
                [
                  104.72554016100008,
                  -3.006810903999963
                ],
                [
                  104.72602081400015,
                  -3.007165192999935
                ],
                [
                  104.72686767600004,
                  -3.007376192999914
                ],
                [
                  104.7269058230001,
                  -3.009003876999941
                ],
                [
                  104.72834777800011,
                  -3.009011506999968
                ],
                [
                  104.72890472400002,
                  -3.009653566999873
                ],
                [
                  104.72919464000006,
                  -3.011482952999813
                ],
                [
                  104.72914886500007,
                  -3.011611460999973
                ],
                [
                  104.72911834700005,
                  -3.011712073999945
                ],
                [
                  104.72867584300019,
                  -3.012906074999876
                ],
                [
                  104.73822021500013,
                  -3.009697675999973
                ],
                [
                  104.73838043300009,
                  -3.009644268999978
                ],
                [
                  104.73880004800003,
                  -3.009318113999939
                ],
                [
                  104.73886871300016,
                  -3.009505271999956
                ],
                [
                  104.74447631900011,
                  -3.007167815999821
                ],
                [
                  104.7443771360002,
                  -3.006674527999962
                ],
                [
                  104.74465179400016,
                  -3.006526707999967
                ],
                [
                  104.74494171100008,
                  -3.007059335999884
                ],
                [
                  104.74623107800011,
                  -3.006714104999958
                ],
                [
                  104.74767303600004,
                  -3.007394550999948
                ],
                [
                  104.74980163600014,
                  -3.008167981999975
                ],
                [
                  104.75038909800008,
                  -3.008471248999911
                ],
                [
                  104.75087738100007,
                  -3.00867414399994
                ],
                [
                  104.75031280500008,
                  -3.009600639999917
                ],
                [
                  104.74977874700011,
                  -3.010506391999911
                ],
                [
                  104.74942779600008,
                  -3.011301516999879
                ],
                [
                  104.749008178,
                  -3.011947869999972
                ],
                [
                  104.74868011500013,
                  -3.012448072999973
                ],
                [
                  104.7484817510001,
                  -3.012852191999968
                ],
                [
                  104.74868011500013,
                  -3.012909889999946
                ],
                [
                  104.74626922500016,
                  -3.017184972999871
                ],
                [
                  104.74609374900001,
                  -3.017489431999934
                ],
                [
                  104.745872497,
                  -3.017887114999894
                ]
              ]
            ]
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "NAME_3": "Kertapati",
          "NAME_4": "Karya Jaya",
          "ID": 5,
          "STUNTING": 52,
          "WASTING":36,
          "LAT": 104.712629,
          "LONG": -3.043607
        },
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [
                  104.67350769100011,
                  -3.046735524999917
                ],
                [
                  104.6732406610002,
                  -3.046625613999879
                ],
                [
                  104.66432952800017,
                  -3.041826486999923
                ],
                [
                  104.6640701290001,
                  -3.041748523999956
                ],
                [
                  104.66370391900017,
                  -3.041371105999815
                ],
                [
                  104.66395568800016,
                  -3.04090380699995
                ],
                [
                  104.66400909500004,
                  -3.040815591999944
                ],
                [
                  104.66705322300004,
                  -3.037629842999877
                ],
                [
                  104.66846466100009,
                  -3.036619662999954
                ],
                [
                  104.66902160600006,
                  -3.036176681999905
                ],
                [
                  104.66922760000006,
                  -3.036076782999885
                ],
                [
                  104.67002868700018,
                  -3.035503387999881
                ],
                [
                  104.67129516700015,
                  -3.034837244999835
                ],
                [
                  104.67151641700019,
                  -3.03468465799989
                ],
                [
                  104.67192840600006,
                  -3.034502028999896
                ],
                [
                  104.67216491700003,
                  -3.034378527999877
                ],
                [
                  104.67282867400013,
                  -3.033968686999913
                ],
                [
                  104.67342376700009,
                  -3.033730029999958
                ],
                [
                  104.67383575400015,
                  -3.033610819999979
                ],
                [
                  104.6746063220001,
                  -3.033372163999957
                ],
                [
                  104.67520141500006,
                  -3.033193109999957
                ],
                [
                  104.675590516,
                  -3.033115148999968
                ],
                [
                  104.67593383700012,
                  -3.03300023099996
                ],
                [
                  104.67645263600014,
                  -3.03271579799997
                ],
                [
                  104.6769866940001,
                  -3.032298087999948
                ],
                [
                  104.67750549300013,
                  -3.032061814999963
                ],
                [
                  104.67803192200006,
                  -3.031598329999895
                ],
                [
                  104.67836761600012,
                  -3.031455278999942
                ],
                [
                  104.67858886600015,
                  -3.031164169999954
                ],
                [
                  104.67918395900006,
                  -3.030627011999911
                ],
                [
                  104.67965698100011,
                  -3.030209064999951
                ],
                [
                  104.68007659900002,
                  -3.029910801999961
                ],
                [
                  104.68025207400007,
                  -3.029492853999955
                ],
                [
                  104.68054962100007,
                  -3.029015301999948
                ],
                [
                  104.68090820200018,
                  -3.028597354999931
                ],
                [
                  104.68114471400003,
                  -3.028000353999914
                ],
                [
                  104.68144226000015,
                  -3.027522801999908
                ],
                [
                  104.6822052010001,
                  -3.026974678999977
                ],
                [
                  104.68228149400011,
                  -3.026853799999969
                ],
                [
                  104.68267822299998,
                  -3.025997877999941
                ],
                [
                  104.68304443300008,
                  -3.025777339999934
                ],
                [
                  104.68515014600018,
                  -3.02402067099996
                ],
                [
                  104.68572235100015,
                  -3.02351045599994
                ],
                [
                  104.68594360300017,
                  -3.023376939999821
                ],
                [
                  104.68905639700017,
                  -3.020796298999926
                ],
                [
                  104.6894454950002,
                  -3.020471810999879
                ],
                [
                  104.69812011700009,
                  -3.015722035999886
                ],
                [
                  104.70761871400009,
                  -3.014482975999954
                ],
                [
                  104.71135711700003,
                  -3.015061139999943
                ],
                [
                  104.7162933350001,
                  -3.015825270999926
                ],
                [
                  104.72373199400016,
                  -3.018510101999937
                ],
                [
                  104.72705841000004,
                  -3.02114415099993
                ],
                [
                  104.72868347100012,
                  -3.02243375799992
                ],
                [
                  104.73405456500006,
                  -3.023982762999935
                ],
                [
                  104.7393188470001,
                  -3.023879528999942
                ],
                [
                  104.73940277100007,
                  -3.023974180999858
                ],
                [
                  104.7394943230002,
                  -3.02394795399988
                ],
                [
                  104.7411270140002,
                  -3.02286410399995
                ],
                [
                  104.74170684900014,
                  -3.022520541999938
                ],
                [
                  104.7419128410001,
                  -3.022299526999916
                ],
                [
                  104.74216461300017,
                  -3.022120475999941
                ],
                [
                  104.74269867000004,
                  -3.021740435999959
                ],
                [
                  104.74500274600007,
                  -3.018854855999962
                ],
                [
                  104.745872497,
                  -3.017887114999894
                ],
                [
                  104.74609374900001,
                  -3.017489431999934
                ],
                [
                  104.74626922500016,
                  -3.017184972999871
                ],
                [
                  104.74868011500013,
                  -3.012909889999946
                ],
                [
                  104.74876403700017,
                  -3.012933015999977
                ],
                [
                  104.74912261900016,
                  -3.013038157999972
                ],
                [
                  104.75009155200013,
                  -3.013145685999973
                ],
                [
                  104.75058746300004,
                  -3.013371466999899
                ],
                [
                  104.7507171630001,
                  -3.013530491999916
                ],
                [
                  104.75098419099999,
                  -3.013915061999967
                ],
                [
                  104.75134277300003,
                  -3.014322280999977
                ],
                [
                  104.75161743100017,
                  -3.014706848999936
                ],
                [
                  104.75199890100009,
                  -3.015272377999906
                ],
                [
                  104.75218200700016,
                  -3.015547990999892
                ],
                [
                  104.75267791800007,
                  -3.016245125999944
                ],
                [
                  104.75290679900007,
                  -3.016532658999893
                ],
                [
                  104.75411987300009,
                  -3.018040894999899
                ],
                [
                  104.75413513100011,
                  -3.018208742999946
                ],
                [
                  104.75421142500005,
                  -3.018769026999962
                ],
                [
                  104.75464630000005,
                  -3.019459008999945
                ],
                [
                  104.75508117800013,
                  -3.020106314999907
                ],
                [
                  104.7556915290001,
                  -3.02066397599998
                ],
                [
                  104.75576782300004,
                  -3.020693063999886
                ],
                [
                  104.7561264040001,
                  -3.020986555999912
                ],
                [
                  104.75646972600009,
                  -3.021258115999899
                ],
                [
                  104.75671386700014,
                  -3.021393774999979
                ],
                [
                  104.7570495600001,
                  -3.021529673999908
                ],
                [
                  104.75740814200014,
                  -3.021710633999874
                ],
                [
                  104.7580413820001,
                  -3.021936892999975
                ],
                [
                  104.75846862700001,
                  -3.022095441999909
                ],
                [
                  104.75905609200004,
                  -3.022276400999885
                ],
                [
                  104.75963592600004,
                  -3.022321937999948
                ],
                [
                  104.7600021360002,
                  -3.022231338999973
                ],
                [
                  104.76053619400011,
                  -3.022208927999884
                ],
                [
                  104.76103210400015,
                  -3.022163867999893
                ],
                [
                  104.76134490900017,
                  -3.022163867999893
                ],
                [
                  104.76184082000009,
                  -3.022095917999877
                ],
                [
                  104.76194763200004,
                  -3.022091149999937
                ],
                [
                  104.7629241950001,
                  -3.022102832999906
                ],
                [
                  104.76390838700019,
                  -3.022129058999951
                ],
                [
                  104.76488495000007,
                  -3.022155522999924
                ],
                [
                  104.76582336400008,
                  -3.022167920999948
                ],
                [
                  104.76638794000007,
                  -3.02234268199993
                ],
                [
                  104.76804351800007,
                  -3.023038386999872
                ],
                [
                  104.77157592800017,
                  -3.025889872999926
                ],
                [
                  104.77163696400004,
                  -3.025981664999961
                ],
                [
                  104.7719421380001,
                  -3.026189088999956
                ],
                [
                  104.77211761400002,
                  -3.026331185999936
                ],
                [
                  104.77236175500008,
                  -3.026718616999915
                ],
                [
                  104.77241516000015,
                  -3.027162312999963
                ],
                [
                  104.77386474600019,
                  -3.02970385399982
                ],
                [
                  104.77403259300013,
                  -3.030798434999951
                ],
                [
                  104.77413177600005,
                  -3.031976937999957
                ],
                [
                  104.77426147400013,
                  -3.032401560999915
                ],
                [
                  104.77219391000011,
                  -3.035478114999933
                ],
                [
                  104.77214813300014,
                  -3.035541772999863
                ],
                [
                  104.77110290600012,
                  -3.037100552999959
                ],
                [
                  104.77079772900015,
                  -3.037549256999966
                ],
                [
                  104.7705307,
                  -3.037955282999974
                ],
                [
                  104.76673889200015,
                  -3.038222788999974
                ],
                [
                  104.76537323000014,
                  -3.038319587999922
                ],
                [
                  104.7647857680002,
                  -3.038361071999873
                ],
                [
                  104.76038360600012,
                  -3.038625954999873
                ],
                [
                  104.7579498300002,
                  -3.038840531999938
                ],
                [
                  104.75756836000005,
                  -3.038870810999867
                ],
                [
                  104.7546081540001,
                  -3.039107321999893
                ],
                [
                  104.75074768000007,
                  -3.039415596999902
                ],
                [
                  104.75028991600004,
                  -3.040725945999895
                ],
                [
                  104.74993896500001,
                  -3.041610479999974
                ],
                [
                  104.74959564100004,
                  -3.042075156999886
                ],
                [
                  104.74931335500014,
                  -3.042611360999956
                ],
                [
                  104.74904632500005,
                  -3.04309892699996
                ],
                [
                  104.7486572270002,
                  -3.043695926999874
                ],
                [
                  104.748367309,
                  -3.044268606999935
                ],
                [
                  104.74797058000013,
                  -3.045097350999924
                ],
                [
                  104.746452331,
                  -3.046267032999879
                ],
                [
                  104.74517822200005,
                  -3.047034500999871
                ],
                [
                  104.74487304700011,
                  -3.047351360999926
                ],
                [
                  104.74479675300017,
                  -3.047523258999831
                ],
                [
                  104.74424743600014,
                  -3.046977518999881
                ],
                [
                  104.74388885600013,
                  -3.047401905999891
                ],
                [
                  104.7436828610002,
                  -3.047953366999877
                ],
                [
                  104.74344635000011,
                  -3.048868416999937
                ],
                [
                  104.74316406200018,
                  -3.050147531999869
                ],
                [
                  104.74301910300017,
                  -3.051309108999874
                ],
                [
                  104.74301147500006,
                  -3.051470278999886
                ],
                [
                  104.74300384500015,
                  -3.052341699999886
                ],
                [
                  104.74306488000013,
                  -3.05322170099987
                ],
                [
                  104.74333191000005,
                  -3.054758785999866
                ],
                [
                  104.74377441300015,
                  -3.05549812299995
                ],
                [
                  104.74452209600008,
                  -3.056659936999949
                ],
                [
                  104.7447280880001,
                  -3.057082414999911
                ],
                [
                  104.74481201200012,
                  -3.057868718999941
                ],
                [
                  104.74472046000017,
                  -3.059910296999931
                ],
                [
                  104.74469757100013,
                  -3.060060261999922
                ],
                [
                  104.74436950600011,
                  -3.062292335999871
                ],
                [
                  104.74377441300015,
                  -3.063688516999946
                ],
                [
                  104.74282836900005,
                  -3.065256117999979
                ],
                [
                  104.74196624800004,
                  -3.06620216399989
                ],
                [
                  104.7409896850001,
                  -3.067025422999961
                ],
                [
                  104.73822784300006,
                  -3.068615197999975
                ],
                [
                  104.73771667400013,
                  -3.068727969999884
                ],
                [
                  104.73672485300006,
                  -3.068483113999889
                ],
                [
                  104.73567199600006,
                  -3.068379401999834
                ],
                [
                  104.73439025900012,
                  -3.068501234999871
                ],
                [
                  104.7335891730001,
                  -3.068614004999972
                ],
                [
                  104.73292541500007,
                  -3.069122075999928
                ],
                [
                  104.73253631600011,
                  -3.069921730999965
                ],
                [
                  104.73220825100009,
                  -3.070702790999974
                ],
                [
                  104.73210144100011,
                  -3.071380376999969
                ],
                [
                  104.73194122400008,
                  -3.072057723999876
                ],
                [
                  104.7317276010001,
                  -3.072537660999899
                ],
                [
                  104.73100280699998,
                  -3.073008060999825
                ],
                [
                  104.730453491,
                  -3.073334693999925
                ],
                [
                  104.72998046900011,
                  -3.073497055999951
                ],
                [
                  104.72933197100014,
                  -3.07370829599995
                ],
                [
                  104.72853088400018,
                  -3.074052334999976
                ],
                [
                  104.72825622600004,
                  -3.074579953999944
                ],
                [
                  104.72794342100019,
                  -3.075790880999932
                ],
                [
                  104.72792053300003,
                  -3.075891493999904
                ],
                [
                  104.72773742700019,
                  -3.076783657999954
                ],
                [
                  104.7274856570001,
                  -3.077885626999887
                ],
                [
                  104.72731018100018,
                  -3.078768967999963
                ],
                [
                  104.72723388600019,
                  -3.079712151999956
                ],
                [
                  104.7271118170001,
                  -3.080785512999853
                ],
                [
                  104.72708129900008,
                  -3.082135915999913
                ],
                [
                  104.72724914600019,
                  -3.082793951999918
                ],
                [
                  104.72742462200011,
                  -3.083400009999878
                ],
                [
                  104.7276000980001,
                  -3.083976267999958
                ],
                [
                  104.7274856570001,
                  -3.086355685999933
                ],
                [
                  104.727333068,
                  -3.086955546999945
                ],
                [
                  104.72670745800013,
                  -3.088426112999912
                ],
                [
                  104.72571563700006,
                  -3.089740752999887
                ],
                [
                  104.72550201400003,
                  -3.089945076999925
                ],
                [
                  104.72473144600008,
                  -3.090717792999897
                ],
                [
                  104.72418213000009,
                  -3.091318130999923
                ],
                [
                  104.72267913700017,
                  -3.09243512099988
                ],
                [
                  104.72151184000012,
                  -3.093291520999969
                ],
                [
                  104.71939849800003,
                  -3.094524143999877
                ],
                [
                  104.71781921400014,
                  -3.091125964999947
                ],
                [
                  104.71889495800013,
                  -3.090218544999971
                ],
                [
                  104.71820068500017,
                  -3.088356732999955
                ],
                [
                  104.71759796200013,
                  -3.086820840999962
                ],
                [
                  104.71765899700011,
                  -3.086240767999925
                ],
                [
                  104.7181549080002,
                  -3.085564373999944
                ],
                [
                  104.71598053100013,
                  -3.08086347599982
                ],
                [
                  104.71640014600013,
                  -3.080351589999907
                ],
                [
                  104.71548462000004,
                  -3.079268931999934
                ],
                [
                  104.71619415300006,
                  -3.077846049999891
                ],
                [
                  104.7182083130001,
                  -3.075734138999962
                ],
                [
                  104.71981048500015,
                  -3.073817728999927
                ],
                [
                  104.71911621000015,
                  -3.073196649999886
                ],
                [
                  104.71876525900018,
                  -3.072595833999912
                ],
                [
                  104.71854400600006,
                  -3.071684120999976
                ],
                [
                  104.71791076600005,
                  -3.069621561999952
                ],
                [
                  104.71714782800018,
                  -3.068727254999942
                ],
                [
                  104.716308594,
                  -3.067950008999901
                ],
                [
                  104.713958741,
                  -3.066927432999933
                ],
                [
                  104.71158599800009,
                  -3.066468953999959
                ],
                [
                  104.70964050400005,
                  -3.066543102999958
                ],
                [
                  104.69045257500005,
                  -3.056024074999925
                ],
                [
                  104.68403625400003,
                  -3.052507162999973
                ],
                [
                  104.67350769100011,
                  -3.046735524999917
                ]
              ]
            ]
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "NAME_3": "Plaju",
          "NAME_4": "Bagus Kuning",
          "ID": 6,
          "STUNTING": 35,
          "WASTING":14,
          "LAT": 104.811146,
          "LONG": -3.015691
        },
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [
                  104.80989799900004,
                  -3.038965
                ],
                [
                  104.80910495100017,
                  -3.038331022999898
                ],
                [
                  104.80837249800004,
                  -3.038778543999967
                ],
                [
                  104.80821228000013,
                  -3.038336515999902
                ],
                [
                  104.80727034600017,
                  -3.038472156999944
                ],
                [
                  104.80651900000015,
                  -3.038579998999978
                ],
                [
                  104.806556999,
                  -3.03799
                ],
                [
                  104.806664,
                  -3.036429999999882
                ],
                [
                  104.80657990500015,
                  -3.036386062999895
                ],
                [
                  104.80644556600015,
                  -3.036476996999966
                ],
                [
                  104.80622099900012,
                  -3.036629000999937
                ],
                [
                  104.800964,
                  -3.037122
                ],
                [
                  104.80013299900014,
                  -3.036468998999908
                ],
                [
                  104.79972800000013,
                  -3.036099999999863
                ],
                [
                  104.799132999,
                  -3.035691000999861
                ],
                [
                  104.79735597800016,
                  -3.034777040999927
                ],
                [
                  104.7964698040002,
                  -3.036446510999951
                ],
                [
                  104.79561600100016,
                  -3.038054999999872
                ],
                [
                  104.79292884800014,
                  -3.034215302999883
                ],
                [
                  104.79264799900011,
                  -3.033813998999904
                ],
                [
                  104.792604069,
                  -3.033753863999891
                ],
                [
                  104.79126,
                  -3.031913998999812
                ],
                [
                  104.79145350800013,
                  -3.031732990999956
                ],
                [
                  104.79174000000017,
                  -3.031464998999979
                ],
                [
                  104.791824,
                  -3.031267
                ],
                [
                  104.7918107440002,
                  -3.031245875999957
                ],
                [
                  104.79173309200013,
                  -3.031122146999905
                ],
                [
                  104.7920608510002,
                  -3.030799387999934
                ],
                [
                  104.79224395900007,
                  -3.03050911999992
                ],
                [
                  104.79216799900018,
                  -3.030292000999964
                ],
                [
                  104.792304876,
                  -3.030213983999943
                ],
                [
                  104.79099896500009,
                  -3.030051355999888
                ],
                [
                  104.78910100100006,
                  -3.029814999999871
                ],
                [
                  104.78798162800007,
                  -3.029831142999967
                ],
                [
                  104.78667427099998,
                  -3.029849921999926
                ],
                [
                  104.7871856700001,
                  -3.029229163999958
                ],
                [
                  104.79389953700007,
                  -3.021093367999867
                ],
                [
                  104.79570770200002,
                  -3.020359753999912
                ],
                [
                  104.79608917200017,
                  -3.020203588999891
                ],
                [
                  104.7959213270002,
                  -3.019778966999922
                ],
                [
                  104.79629516700004,
                  -3.019345760999954
                ],
                [
                  104.79816436700014,
                  -3.019417761999875
                ],
                [
                  104.79932403600009,
                  -3.019167184999958
                ],
                [
                  104.79914856000016,
                  -3.018261432999964
                ],
                [
                  104.7998428350001,
                  -3.017896175999908
                ],
                [
                  104.80088806200018,
                  -3.017788885999948
                ],
                [
                  104.8019638070001,
                  -3.017513035999855
                ],
                [
                  104.80240631100014,
                  -3.017162560999964
                ],
                [
                  104.8030776970001,
                  -3.016983747999916
                ],
                [
                  104.80326080300011,
                  -3.016482351999912
                ],
                [
                  104.80367279,
                  -3.016170024999951
                ],
                [
                  104.80376434400017,
                  -3.015570639999964
                ],
                [
                  104.80398559500009,
                  -3.014214515999868
                ],
                [
                  104.80403137200005,
                  -3.013921736999976
                ],
                [
                  104.8043823230002,
                  -3.013921974999903
                ],
                [
                  104.80438995400004,
                  -3.010261297999875
                ],
                [
                  104.80438995400004,
                  -3.009452342999907
                ],
                [
                  104.80476379400005,
                  -3.009350537999921
                ],
                [
                  104.80457306000011,
                  -3.008497475999945
                ],
                [
                  104.80498504600007,
                  -3.008455752999964
                ],
                [
                  104.80495453000003,
                  -3.008232115999931
                ],
                [
                  104.80468750000017,
                  -3.007179975999918
                ],
                [
                  104.8044509890002,
                  -3.007068155999946
                ],
                [
                  104.80412292400013,
                  -3.007055282999943
                ],
                [
                  104.80412292400013,
                  -3.006568907999906
                ],
                [
                  104.80412292400013,
                  -3.006228208999971
                ],
                [
                  104.80430603000019,
                  -3.00624132199988
                ],
                [
                  104.80461120600006,
                  -3.006289719999927
                ],
                [
                  104.80494690000012,
                  -3.006462334999981
                ],
                [
                  104.80509948700006,
                  -3.00653266799992
                ],
                [
                  104.80509948700006,
                  -3.005908726999905
                ],
                [
                  104.80498504600007,
                  -3.005802391999907
                ],
                [
                  104.80538177500011,
                  -3.005441904999941
                ],
                [
                  104.80410003700013,
                  -3.005199670999957
                ],
                [
                  104.80413818400018,
                  -3.004357338999966
                ],
                [
                  104.8041534420002,
                  -3.004094123999948
                ],
                [
                  104.8041687010001,
                  -3.003854750999949
                ],
                [
                  104.80417633100006,
                  -3.003244161999874
                ],
                [
                  104.80417633100006,
                  -3.002691505999906
                ],
                [
                  104.80418395900011,
                  -3.002048015999947
                ],
                [
                  104.80419921900017,
                  -3.001539468999965
                ],
                [
                  104.80411529600008,
                  -3.001404285999968
                ],
                [
                  104.80411529600008,
                  -3.001122951999889
                ],
                [
                  104.80412292400013,
                  -3.000787733999971
                ],
                [
                  104.80413818400018,
                  -3.000109194999936
                ],
                [
                  104.80414581200012,
                  -2.999540090999915
                ],
                [
                  104.80416107300005,
                  -2.998277901999927
                ],
                [
                  104.80412292400013,
                  -2.998067616999947
                ],
                [
                  104.80422973600008,
                  -2.997316836999914
                ],
                [
                  104.80428314099998,
                  -2.996829985999966
                ],
                [
                  104.80438995400004,
                  -2.994632242999842
                ],
                [
                  104.80258178700007,
                  -2.994191884999907
                ],
                [
                  104.8016128540001,
                  -2.994017361999965
                ],
                [
                  104.80177307100013,
                  -2.993389129999912
                ],
                [
                  104.80158996500012,
                  -2.993346212999938
                ],
                [
                  104.80229950000012,
                  -2.990478038999925
                ],
                [
                  104.80328369199998,
                  -2.98614120499991
                ],
                [
                  104.80327606200007,
                  -2.986002920999852
                ],
                [
                  104.80326080300011,
                  -2.985560892999956
                ],
                [
                  104.81514740000017,
                  -2.985702751999952
                ],
                [
                  104.81793975800008,
                  -2.985792873999969
                ],
                [
                  104.81803894100005,
                  -2.985792873999969
                ],
                [
                  104.81818390000007,
                  -2.985798596999928
                ],
                [
                  104.82040405200007,
                  -2.985883950999948
                ],
                [
                  104.82103729300013,
                  -2.985773085999938
                ],
                [
                  104.82169342000014,
                  -2.985790490999932
                ],
                [
                  104.82164764500004,
                  -2.986063478999881
                ],
                [
                  104.82164764500004,
                  -2.987391708999894
                ],
                [
                  104.82192230300018,
                  -2.987367629999881
                ],
                [
                  104.82206726200019,
                  -2.987355709999974
                ],
                [
                  104.822151184,
                  -2.987348316999885
                ],
                [
                  104.822151184,
                  -2.987518309999928
                ],
                [
                  104.82866668600019,
                  -2.987880228999927
                ],
                [
                  104.83228302000009,
                  -2.987880228999927
                ],
                [
                  104.83388500000012,
                  -2.98788
                ],
                [
                  104.83388900000011,
                  -2.987941
                ],
                [
                  104.83389010000019,
                  -2.987957220999817
                ],
                [
                  104.83389282100012,
                  -2.987999914999875
                ],
                [
                  104.83441162100007,
                  -2.989233254999874
                ],
                [
                  104.83203887900004,
                  -3.004414318999977
                ],
                [
                  104.82423400900012,
                  -3.01074385599992
                ],
                [
                  104.82309723000014,
                  -3.011661052999955
                ],
                [
                  104.816802978,
                  -3.024108171999842
                ],
                [
                  104.815558937,
                  -3.027015148999908
                ],
                [
                  104.81548700100012,
                  -3.027000998999938
                ],
                [
                  104.81546005400008,
                  -3.026996008999959
                ],
                [
                  104.81533485400001,
                  -3.026897669999869
                ],
                [
                  104.815208,
                  -3.026798
                ],
                [
                  104.81514218400008,
                  -3.026790628999834
                ],
                [
                  104.81508300000019,
                  -3.026783998999974
                ],
                [
                  104.81504101400009,
                  -3.02678
                ],
                [
                  104.8150073070002,
                  -3.02673405399986
                ],
                [
                  104.8149640800001,
                  -3.026675109999928
                ],
                [
                  104.81494100000015,
                  -3.026473999999837
                ],
                [
                  104.81481200000013,
                  -3.026372
                ],
                [
                  104.81467300000014,
                  -3.02646
                ],
                [
                  104.81465143900004,
                  -3.026473719999899
                ],
                [
                  104.81463414700005,
                  -3.026460211999847
                ],
                [
                  104.814618,
                  -3.026447
                ],
                [
                  104.814596,
                  -3.026429999999834
                ],
                [
                  104.814545,
                  -3.026389999999878
                ],
                [
                  104.814457,
                  -3.02625
                ],
                [
                  104.81443301700011,
                  -3.026224138999964
                ],
                [
                  104.81425496300011,
                  -3.02637199499992
                ],
                [
                  104.81421392700008,
                  -3.026366613999926
                ],
                [
                  104.81413290500018,
                  -3.026355932999934
                ],
                [
                  104.81404892000006,
                  -3.026297142999965
                ],
                [
                  104.81398800000017,
                  -3.026408
                ],
                [
                  104.813991,
                  -3.026454
                ],
                [
                  104.8139949740002,
                  -3.026509016999967
                ],
                [
                  104.81394199900018,
                  -3.026596999999867
                ],
                [
                  104.81390400000015,
                  -3.026661
                ],
                [
                  104.81396477200019,
                  -3.026715793999927
                ],
                [
                  104.81393999900018,
                  -3.026728998999886
                ],
                [
                  104.81389600000017,
                  -3.026752
                ],
                [
                  104.813882,
                  -3.026838
                ],
                [
                  104.813873,
                  -3.026894999999854
                ],
                [
                  104.81388,
                  -3.026916
                ],
                [
                  104.81389589600019,
                  -3.026962693999963
                ],
                [
                  104.81385970600019,
                  -3.027019576999919
                ],
                [
                  104.81382757300014,
                  -3.027073102999907
                ],
                [
                  104.81381753000011,
                  -3.027165190999824
                ],
                [
                  104.81381200100009,
                  -3.02721599399996
                ],
                [
                  104.81378600000016,
                  -3.027222999999879
                ],
                [
                  104.813748,
                  -3.027233
                ],
                [
                  104.81372833300009,
                  -3.027238845999875
                ],
                [
                  104.81357574400016,
                  -3.027225732999966
                ],
                [
                  104.81353822400007,
                  -3.027212584999916
                ],
                [
                  104.81346863900006,
                  -3.027188600999978
                ],
                [
                  104.81345971400015,
                  -3.027202539999962
                ],
                [
                  104.81341552700013,
                  -3.027270792999957
                ],
                [
                  104.813369013,
                  -3.02727369999991
                ],
                [
                  104.81332900000018,
                  -3.027276
                ],
                [
                  104.81323200000014,
                  -3.027282
                ],
                [
                  104.81322653300015,
                  -3.027308601999948
                ],
                [
                  104.81321699900002,
                  -3.027354999999886
                ],
                [
                  104.81305694600002,
                  -3.027509102999943
                ],
                [
                  104.81303500000018,
                  -3.027551
                ],
                [
                  104.8129878700002,
                  -3.027642202999857
                ],
                [
                  104.81295560600017,
                  -3.027691670999957
                ],
                [
                  104.81294187700018,
                  -3.0277105159999
                ],
                [
                  104.812885,
                  -3.027686
                ],
                [
                  104.81282000000016,
                  -3.027658
                ],
                [
                  104.81269100000014,
                  -3.027714
                ],
                [
                  104.81264600000014,
                  -3.02771
                ],
                [
                  104.812576,
                  -3.027705
                ],
                [
                  104.81253043900006,
                  -3.027702780999959
                ],
                [
                  104.81243100000012,
                  -3.027698
                ],
                [
                  104.812325,
                  -3.027759
                ],
                [
                  104.81229200000018,
                  -3.027774
                ],
                [
                  104.81224099500014,
                  -3.027798003999976
                ],
                [
                  104.81222954700007,
                  -3.027806861999977
                ],
                [
                  104.81217013600019,
                  -3.027852922999955
                ],
                [
                  104.81215700000018,
                  -3.027863
                ],
                [
                  104.81215000000014,
                  -3.027978
                ],
                [
                  104.812149,
                  -3.027998
                ],
                [
                  104.8121648280001,
                  -3.02803691999992
                ],
                [
                  104.8122100000001,
                  -3.028148000999977
                ],
                [
                  104.8122288140001,
                  -3.028268859999912
                ],
                [
                  104.81230198800012,
                  -3.028738925999903
                ],
                [
                  104.812364,
                  -3.028875
                ],
                [
                  104.812424,
                  -3.029005999999811
                ],
                [
                  104.8124619990001,
                  -3.029134999999826
                ],
                [
                  104.81259251500006,
                  -3.029556725999896
                ],
                [
                  104.8126219630002,
                  -3.029651878999971
                ],
                [
                  104.81259900000015,
                  -3.029801998999858
                ],
                [
                  104.8126250000002,
                  -3.029833
                ],
                [
                  104.81264084000009,
                  -3.02985178699987
                ],
                [
                  104.81266808200019,
                  -3.029884270999901
                ],
                [
                  104.81267299900014,
                  -3.029899000999876
                ],
                [
                  104.8127408700002,
                  -3.030085246999931
                ],
                [
                  104.812759,
                  -3.030135
                ],
                [
                  104.81277380000006,
                  -3.030159989999902
                ],
                [
                  104.81281999800012,
                  -3.030238003999955
                ],
                [
                  104.81280092300005,
                  -3.030269131999944
                ],
                [
                  104.81278676000005,
                  -3.030293098999891
                ],
                [
                  104.81277465900001,
                  -3.030313014999933
                ],
                [
                  104.81275367200016,
                  -3.030327836999845
                ],
                [
                  104.8127290000001,
                  -3.030344998999908
                ],
                [
                  104.81269747700003,
                  -3.030367870999953
                ],
                [
                  104.81267059100014,
                  -3.030411741999956
                ],
                [
                  104.81263,
                  -3.030478
                ],
                [
                  104.81263,
                  -3.03066
                ],
                [
                  104.81256900000017,
                  -3.030980999999883
                ],
                [
                  104.81240100000014,
                  -3.03119
                ],
                [
                  104.812424,
                  -3.031300999999871
                ],
                [
                  104.812441,
                  -3.031331
                ],
                [
                  104.812469,
                  -3.031379
                ],
                [
                  104.81255954200009,
                  -3.031510022999953
                ],
                [
                  104.81276200000013,
                  -3.031803
                ],
                [
                  104.81279000000018,
                  -3.031843
                ],
                [
                  104.81285435900008,
                  -3.031848413999967
                ],
                [
                  104.81289680800012,
                  -3.031851981999921
                ],
                [
                  104.8129216760002,
                  -3.031903706999913
                ],
                [
                  104.813138,
                  -3.032356999999877
                ],
                [
                  104.81320988700014,
                  -3.03250677199992
                ],
                [
                  104.813179016,
                  -3.032578228999967
                ],
                [
                  104.81320190400015,
                  -3.032653807999907
                ],
                [
                  104.81328582800012,
                  -3.032777307999936
                ],
                [
                  104.81331634500009,
                  -3.032846211999924
                ],
                [
                  104.81333923300002,
                  -3.033010721999972
                ],
                [
                  104.81350708100007,
                  -3.033465862999947
                ],
                [
                  104.81358337400007,
                  -3.033739090999916
                ],
                [
                  104.81361389100005,
                  -3.034046887999978
                ],
                [
                  104.81358337400007,
                  -3.03419947499998
                ],
                [
                  104.81352233900009,
                  -3.034364222999955
                ],
                [
                  104.81346893400018,
                  -3.03446936499995
                ],
                [
                  104.81333923300002,
                  -3.034672498999953
                ],
                [
                  104.8128433220001,
                  -3.035561560999895
                ],
                [
                  104.81275939900019,
                  -3.035693406999883
                ],
                [
                  104.81265258800016,
                  -3.035834073999979
                ],
                [
                  104.81227111800001,
                  -3.036462782999934
                ],
                [
                  104.81204986500012,
                  -3.036591528999963
                ],
                [
                  104.81181335399998,
                  -3.036669252999957
                ],
                [
                  104.81151580800008,
                  -3.037046432999944
                ],
                [
                  104.81127929700011,
                  -3.037160157999892
                ],
                [
                  104.81114959800016,
                  -3.037314890999937
                ],
                [
                  104.81110367000008,
                  -3.037420499999939
                ],
                [
                  104.8104512070002,
                  -3.038172754999948
                ],
                [
                  104.81024083800008,
                  -3.038415861999965
                ],
                [
                  104.80989799900004,
                  -3.038965
                ]
              ]
            ]
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "NAME_3": "Jakabaring",
          "NAME_4": "15 Ulu",
          "ID": 7,
          "STUNTING": 43,
          "WASTING":19,
          "LAT":104.785687,
          "LONG": -3.036545,
        },
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [
                  104.7824710000001,
                  -3.034955998999976
                ],
                [
                  104.78211159800003,
                  -3.037228495999955
                ],
                [
                  104.78157001000017,
                  -3.04065303699997
                ],
                [
                  104.78126526,
                  -3.039739131999966
                ],
                [
                  104.78060913200005,
                  -3.037860868999871
                ],
                [
                  104.77966308500004,
                  -3.035171747999868
                ],
                [
                  104.77937316900005,
                  -3.034353971999906
                ],
                [
                  104.77507781999998,
                  -3.0341694359999
                ],
                [
                  104.77464294499998,
                  -3.033514259999947
                ],
                [
                  104.77438354500003,
                  -3.032796858999916
                ],
                [
                  104.77426147400013,
                  -3.032401560999915
                ],
                [
                  104.77413177600005,
                  -3.031976937999957
                ],
                [
                  104.77403259300013,
                  -3.030798434999951
                ],
                [
                  104.77386474600019,
                  -3.02970385399982
                ],
                [
                  104.77241516000015,
                  -3.027162312999963
                ],
                [
                  104.77236175500008,
                  -3.026718616999915
                ],
                [
                  104.77211761400002,
                  -3.026331185999936
                ],
                [
                  104.7719421380001,
                  -3.026189088999956
                ],
                [
                  104.77163696400004,
                  -3.025981664999961
                ],
                [
                  104.77157592800017,
                  -3.025889872999926
                ],
                [
                  104.76804351800007,
                  -3.023038386999872
                ],
                [
                  104.76638794000007,
                  -3.02234268199993
                ],
                [
                  104.76582336400008,
                  -3.022167920999948
                ],
                [
                  104.76488495000007,
                  -3.022155522999924
                ],
                [
                  104.76390838700019,
                  -3.022129058999951
                ],
                [
                  104.7629241950001,
                  -3.022102832999906
                ],
                [
                  104.76194763200004,
                  -3.022091149999937
                ],
                [
                  104.76184082000009,
                  -3.022095917999877
                ],
                [
                  104.76134490900017,
                  -3.022163867999893
                ],
                [
                  104.76103210400015,
                  -3.022163867999893
                ],
                [
                  104.76053619400011,
                  -3.022208927999884
                ],
                [
                  104.7600021360002,
                  -3.022231338999973
                ],
                [
                  104.75963592600004,
                  -3.022321937999948
                ],
                [
                  104.75905609200004,
                  -3.022276400999885
                ],
                [
                  104.75846862700001,
                  -3.022095441999909
                ],
                [
                  104.7580413820001,
                  -3.021936892999975
                ],
                [
                  104.75740814200014,
                  -3.021710633999874
                ],
                [
                  104.7570495600001,
                  -3.021529673999908
                ],
                [
                  104.75671386700014,
                  -3.021393774999979
                ],
                [
                  104.75646972600009,
                  -3.021258115999899
                ],
                [
                  104.7561264040001,
                  -3.020986555999912
                ],
                [
                  104.75576782300004,
                  -3.020693063999886
                ],
                [
                  104.7556915290001,
                  -3.02066397599998
                ],
                [
                  104.75508117800013,
                  -3.020106314999907
                ],
                [
                  104.75464630000005,
                  -3.019459008999945
                ],
                [
                  104.75421142500005,
                  -3.018769026999962
                ],
                [
                  104.75413513100011,
                  -3.018208742999946
                ],
                [
                  104.75411987300009,
                  -3.018040894999899
                ],
                [
                  104.75290679900007,
                  -3.016532658999893
                ],
                [
                  104.75267791800007,
                  -3.016245125999944
                ],
                [
                  104.75218200700016,
                  -3.015547990999892
                ],
                [
                  104.75254058899998,
                  -3.015229462999912
                ],
                [
                  104.75282287700009,
                  -3.015063522999924
                ],
                [
                  104.75322723400006,
                  -3.014827726999954
                ],
                [
                  104.75392913900015,
                  -3.014210222999964
                ],
                [
                  104.75479889000007,
                  -3.013582705999909
                ],
                [
                  104.75515747100013,
                  -3.013159273999975
                ],
                [
                  104.75577545100015,
                  -3.013608931999954
                ],
                [
                  104.75656890800013,
                  -3.013886212999978
                ],
                [
                  104.75688934300013,
                  -3.013963936999914
                ],
                [
                  104.75766754200009,
                  -3.014418362999947
                ],
                [
                  104.758110047,
                  -3.015011070999947
                ],
                [
                  104.75872039799998,
                  -3.015596627999912
                ],
                [
                  104.75949859500014,
                  -3.016079187999878
                ],
                [
                  104.76010894800015,
                  -3.016555547999928
                ],
                [
                  104.76008606,
                  -3.016825912999934
                ],
                [
                  104.7611007700001,
                  -3.016405820999978
                ],
                [
                  104.76197052100008,
                  -3.015818355999954
                ],
                [
                  104.76251983700001,
                  -3.015033483999957
                ],
                [
                  104.76291656500018,
                  -3.014218330999938
                ],
                [
                  104.76351928600002,
                  -3.01487064399987
                ],
                [
                  104.76412200900006,
                  -3.015510796999934
                ],
                [
                  104.76438903900015,
                  -3.015878438999948
                ],
                [
                  104.76614379900002,
                  -3.016521215999944
                ],
                [
                  104.7674026480002,
                  -3.017269848999888
                ],
                [
                  104.7682571410001,
                  -3.018377542999872
                ],
                [
                  104.76992034900002,
                  -3.018921613999964
                ],
                [
                  104.77106475900007,
                  -3.018769978999956
                ],
                [
                  104.77471160800008,
                  -3.01959061499997
                ],
                [
                  104.775581359,
                  -3.019242523999935
                ],
                [
                  104.77613067700014,
                  -3.018472194999902
                ],
                [
                  104.77652740500014,
                  -3.017655609999906
                ],
                [
                  104.77653503400012,
                  -3.017545221999853
                ],
                [
                  104.77655029400017,
                  -3.017389296999909
                ],
                [
                  104.7767257700001,
                  -3.017226218999838
                ],
                [
                  104.77674102800012,
                  -3.017078159999926
                ],
                [
                  104.7769165040001,
                  -3.01690721499989
                ],
                [
                  104.77692413400018,
                  -3.016759394999951
                ],
                [
                  104.7770996100001,
                  -3.016605614999889
                ],
                [
                  104.77711486800013,
                  -3.016489744999831
                ],
                [
                  104.77728271600017,
                  -3.016402482999979
                ],
                [
                  104.77729797400019,
                  -3.016204118999894
                ],
                [
                  104.77747345000017,
                  -3.016083715999969
                ],
                [
                  104.77748108000009,
                  -3.015975712999932
                ],
                [
                  104.7776489260001,
                  -3.015885828999956
                ],
                [
                  104.7774963380001,
                  -3.015811680999946
                ],
                [
                  104.77756500200007,
                  -3.014960287999941
                ],
                [
                  104.77746582000003,
                  -3.014144181999825
                ],
                [
                  104.77737426800013,
                  -3.01330256499989
                ],
                [
                  104.77711486800013,
                  -3.012481449999825
                ],
                [
                  104.77684784000007,
                  -3.01172971699998
                ],
                [
                  104.77623748700012,
                  -3.011144161999937
                ],
                [
                  104.77545166000004,
                  -3.01084017799991
                ],
                [
                  104.77432251000005,
                  -3.010646343999952
                ],
                [
                  104.77334594700017,
                  -3.010580300999891
                ],
                [
                  104.7723770140002,
                  -3.010392666999905
                ],
                [
                  104.77159118700007,
                  -3.010032891999913
                ],
                [
                  104.77116394100011,
                  -3.009302852999895
                ],
                [
                  104.77105712900016,
                  -3.008541106999928
                ],
                [
                  104.77079009900007,
                  -3.007830618999947
                ],
                [
                  104.7705307,
                  -3.006996154999911
                ],
                [
                  104.77043151800012,
                  -3.006221293999943
                ],
                [
                  104.77017211999998,
                  -3.005414962999907
                ],
                [
                  104.76973724500016,
                  -3.004726170999959
                ],
                [
                  104.76943206800007,
                  -3.004492759999891
                ],
                [
                  104.76943969800016,
                  -3.004379509999865
                ],
                [
                  104.76982879700012,
                  -3.003564356999902
                ],
                [
                  104.77006530800003,
                  -3.002703188999874
                ],
                [
                  104.77013397300016,
                  -3.001891612999884
                ],
                [
                  104.7700271610002,
                  -3.001131294999936
                ],
                [
                  104.76976013100011,
                  -3.00039148299993
                ],
                [
                  104.76979065000006,
                  -3.000094413999875
                ],
                [
                  104.769683837,
                  -2.999319552999964
                ],
                [
                  104.76924896200018,
                  -2.998698710999861
                ],
                [
                  104.76863861100003,
                  -2.998113155999818
                ],
                [
                  104.76803588799999,
                  -2.997527598999966
                ],
                [
                  104.7669601450001,
                  -2.996673106999935
                ],
                [
                  104.76618194600007,
                  -2.996231792999936
                ],
                [
                  104.7655715950001,
                  -2.995687484999905
                ],
                [
                  104.76479339600013,
                  -2.995233058999816
                ],
                [
                  104.76418304400005,
                  -2.994702100999916
                ],
                [
                  104.76356506299999,
                  -2.994184494999899
                ],
                [
                  104.76295471200007,
                  -2.993654964999962
                ],
                [
                  104.76234436100009,
                  -2.993124007999938
                ],
                [
                  104.7621002190001,
                  -2.992914675999941
                ],
                [
                  104.76174163800005,
                  -2.992586374999917
                ],
                [
                  104.76260376000016,
                  -2.991992234999884
                ],
                [
                  104.76329040500019,
                  -2.991557121999961
                ],
                [
                  104.76380920400004,
                  -2.991226673999961
                ],
                [
                  104.76417541400014,
                  -2.991024255999832
                ],
                [
                  104.76566314600012,
                  -2.990291594999974
                ],
                [
                  104.76606750500014,
                  -2.989815711999938
                ],
                [
                  104.76615905700004,
                  -2.989708661999941
                ],
                [
                  104.76620927800002,
                  -2.98964835299995
                ],
                [
                  104.76631927500017,
                  -2.989516257999924
                ],
                [
                  104.76766204800015,
                  -2.988407373999848
                ],
                [
                  104.76795702100014,
                  -2.988727453999957
                ],
                [
                  104.76824188200015,
                  -2.989036560999921
                ],
                [
                  104.76840210000012,
                  -2.989284992999899
                ],
                [
                  104.76858520600013,
                  -2.989570617999902
                ],
                [
                  104.76874542299998,
                  -2.989769457999898
                ],
                [
                  104.76883697500011,
                  -2.989980697999897
                ],
                [
                  104.76888303200008,
                  -2.990037635999897
                ],
                [
                  104.769028,
                  -2.990216
                ],
                [
                  104.76916864100019,
                  -2.990404268999953
                ],
                [
                  104.769279,
                  -2.990552
                ],
                [
                  104.76930873200013,
                  -2.990653183999939
                ],
                [
                  104.76934100000017,
                  -2.990763
                ],
                [
                  104.76946300000014,
                  -2.990998999999874
                ],
                [
                  104.769623,
                  -2.991235
                ],
                [
                  104.7697052040001,
                  -2.991402652999966
                ],
                [
                  104.76977539100011,
                  -2.991545438999879
                ],
                [
                  104.76992034900002,
                  -2.991718053999932
                ],
                [
                  104.76997399700002,
                  -2.991781387999936
                ],
                [
                  104.76998900000012,
                  -2.991799
                ],
                [
                  104.77002026500014,
                  -2.991803465999908
                ],
                [
                  104.77007293800017,
                  -2.991810798999893
                ],
                [
                  104.77019500600011,
                  -2.991780996999978
                ],
                [
                  104.77034800000018,
                  -2.991714
                ],
                [
                  104.7704930000001,
                  -2.991664
                ],
                [
                  104.77055616900009,
                  -2.991668355999934
                ],
                [
                  104.77063800000019,
                  -2.991674
                ],
                [
                  104.770729,
                  -2.991707
                ],
                [
                  104.7708239870002,
                  -2.991777207999974
                ],
                [
                  104.77084400000018,
                  -2.991792
                ],
                [
                  104.77091200000012,
                  -2.991841
                ],
                [
                  104.771027,
                  -2.991987999999878
                ],
                [
                  104.771126,
                  -2.99205
                ],
                [
                  104.7713550000002,
                  -2.992186
                ],
                [
                  104.77150000000012,
                  -2.992281999999875
                ],
                [
                  104.771751,
                  -2.992408999999896
                ],
                [
                  104.77178344200007,
                  -2.992422516999966
                ],
                [
                  104.77191900000014,
                  -2.992479
                ],
                [
                  104.7720864960001,
                  -2.992570527999874
                ],
                [
                  104.77210200000019,
                  -2.992578999999864
                ],
                [
                  104.77214813300014,
                  -2.992637156999876
                ],
                [
                  104.772163391,
                  -2.992735386999925
                ],
                [
                  104.77217564400001,
                  -2.992750921999971
                ],
                [
                  104.772217,
                  -2.992803
                ],
                [
                  104.772339,
                  -2.992919
                ],
                [
                  104.77246100000019,
                  -2.993015
                ],
                [
                  104.77259100000015,
                  -2.993168
                ],
                [
                  104.77290236300001,
                  -2.993291146999979
                ],
                [
                  104.77317047100018,
                  -2.993396757999847
                ],
                [
                  104.77301025300011,
                  -2.99368143099997
                ],
                [
                  104.77350888300015,
                  -2.993991793999953
                ],
                [
                  104.77356704200008,
                  -2.99402799399985
                ],
                [
                  104.77391804700005,
                  -2.993977084999813
                ],
                [
                  104.77396704800009,
                  -2.99406498999997
                ],
                [
                  104.77411651500012,
                  -2.994333743999903
                ],
                [
                  104.7742233130001,
                  -2.994320048999953
                ],
                [
                  104.77433776800007,
                  -2.994305372999975
                ],
                [
                  104.7744979850001,
                  -2.994339466999861
                ],
                [
                  104.77503204300001,
                  -2.994605301999911
                ],
                [
                  104.77507782100008,
                  -2.994701624999891
                ],
                [
                  104.77585568400008,
                  -2.995206794999945
                ],
                [
                  104.77622993200009,
                  -2.994892057999948
                ],
                [
                  104.77623661300004,
                  -2.994907869999906
                ],
                [
                  104.77624864000006,
                  -2.994936338999935
                ],
                [
                  104.776259987,
                  -2.994963961999929
                ],
                [
                  104.77636718800011,
                  -2.995254754999905
                ],
                [
                  104.77657318000007,
                  -2.995803592999948
                ],
                [
                  104.77619934000006,
                  -2.995983838999848
                ],
                [
                  104.77698516900017,
                  -2.998408077999954
                ],
                [
                  104.77770996100008,
                  -2.998083114999815
                ],
                [
                  104.77923584000013,
                  -2.997158526999954
                ],
                [
                  104.77931976400015,
                  -2.997222184999885
                ],
                [
                  104.77966308500004,
                  -2.997472048999953
                ],
                [
                  104.77974700900006,
                  -2.997639893999917
                ],
                [
                  104.77978515600012,
                  -2.997757911999884
                ],
                [
                  104.77986144900012,
                  -2.997884750999901
                ],
                [
                  104.77990722600009,
                  -2.99798130999983
                ],
                [
                  104.78045654200008,
                  -2.999191044999918
                ],
                [
                  104.78065490700004,
                  -2.999222992999876
                ],
                [
                  104.78095245300017,
                  -2.999342440999897
                ],
                [
                  104.78159332300004,
                  -2.99946999499997
                ],
                [
                  104.78157043400006,
                  -2.999668596999925
                ],
                [
                  104.78169250500008,
                  -2.999708413999826
                ],
                [
                  104.78220367400002,
                  -2.999882696999919
                ],
                [
                  104.78224945099998,
                  -2.999731063999832
                ],
                [
                  104.78247833200004,
                  -2.999763965999875
                ],
                [
                  104.78293609600007,
                  -2.999891041999945
                ],
                [
                  104.78323364200014,
                  -2.999925850999944
                ],
                [
                  104.7835998540001,
                  -2.999952554999936
                ],
                [
                  104.78378295800013,
                  -3.000225304999958
                ],
                [
                  104.7838287350001,
                  -3.001219033999917
                ],
                [
                  104.78383636500001,
                  -3.002104520999922
                ],
                [
                  104.78366851800007,
                  -3.002756833999968
                ],
                [
                  104.78364562900003,
                  -3.003157614999907
                ],
                [
                  104.78376770000011,
                  -3.003378390999899
                ],
                [
                  104.78379821800013,
                  -3.003752230999908
                ],
                [
                  104.78384399300012,
                  -3.003847359999952
                ],
                [
                  104.78407287600015,
                  -3.003847359999952
                ],
                [
                  104.78413391200002,
                  -3.004176854999969
                ],
                [
                  104.78421783400006,
                  -3.004203556999869
                ],
                [
                  104.78422546400014,
                  -3.004285572999891
                ],
                [
                  104.78448486300005,
                  -3.004285572999891
                ],
                [
                  104.78466796900011,
                  -3.004343032999941
                ],
                [
                  104.78479766800007,
                  -3.004521131999923
                ],
                [
                  104.78491210900006,
                  -3.004733085999874
                ],
                [
                  104.78498077400013,
                  -3.00477647799994
                ],
                [
                  104.78569793800011,
                  -3.006392001999927
                ],
                [
                  104.78633117700002,
                  -3.006502626999918
                ],
                [
                  104.78649139500016,
                  -3.00744080599992
                ],
                [
                  104.78662109400011,
                  -3.007492302999935
                ],
                [
                  104.78694915900019,
                  -3.007398128999967
                ],
                [
                  104.78704834100006,
                  -3.007388830999901
                ],
                [
                  104.78712463400007,
                  -3.007358549999879
                ],
                [
                  104.78739166200012,
                  -3.007315396999957
                ],
                [
                  104.7877426140002,
                  -3.007184981999899
                ],
                [
                  104.78791809200015,
                  -3.007035254999948
                ],
                [
                  104.78790283100005,
                  -3.006907462999948
                ],
                [
                  104.78791046100014,
                  -3.006587980999882
                ],
                [
                  104.7880172740002,
                  -3.006429910999941
                ],
                [
                  104.78837585500008,
                  -3.006172656999979
                ],
                [
                  104.78858947800012,
                  -3.006152867999958
                ],
                [
                  104.78871154900014,
                  -3.006114958999945
                ],
                [
                  104.78891754100016,
                  -3.006233691999967
                ],
                [
                  104.78894806000011,
                  -3.006397246999938
                ],
                [
                  104.78896331800013,
                  -3.006590127999971
                ],
                [
                  104.78894806000011,
                  -3.006849527999975
                ],
                [
                  104.7890319820001,
                  -3.007026671999938
                ],
                [
                  104.78911590500007,
                  -3.007091998999954
                ],
                [
                  104.78929138100017,
                  -3.007150887999842
                ],
                [
                  104.78945922900004,
                  -3.007357358999968
                ],
                [
                  104.78964233500011,
                  -3.007871627999975
                ],
                [
                  104.78999328600008,
                  -3.008858441999905
                ],
                [
                  104.79017639200015,
                  -3.009156465999979
                ],
                [
                  104.79055786100014,
                  -3.009212016999925
                ],
                [
                  104.79067230300006,
                  -3.009405374999972
                ],
                [
                  104.7905731210002,
                  -3.009560584999917
                ],
                [
                  104.7904968250001,
                  -3.009677885999849
                ],
                [
                  104.7908554070001,
                  -3.010269165999944
                ],
                [
                  104.79100799600019,
                  -3.010388373999945
                ],
                [
                  104.79176330700005,
                  -3.011916637999889
                ],
                [
                  104.79185485900018,
                  -3.012093543999868
                ],
                [
                  104.79213714700012,
                  -3.01256346599996
                ],
                [
                  104.7926559440001,
                  -3.012517213999956
                ],
                [
                  104.79273224000002,
                  -3.012774705999846
                ],
                [
                  104.7929763790001,
                  -3.01376438199992
                ],
                [
                  104.793571472,
                  -3.015072105999934
                ],
                [
                  104.79429626500001,
                  -3.01620221099995
                ],
                [
                  104.79469299400012,
                  -3.016792296999881
                ],
                [
                  104.7949829100001,
                  -3.017787932999966
                ],
                [
                  104.79516601600017,
                  -3.018314599999826
                ],
                [
                  104.79524993800015,
                  -3.018555641999967
                ],
                [
                  104.79582214400006,
                  -3.01952504999997
                ],
                [
                  104.7959213270002,
                  -3.019778966999922
                ],
                [
                  104.79608917200017,
                  -3.020203588999891
                ],
                [
                  104.79570770200002,
                  -3.020359753999912
                ],
                [
                  104.79389953700007,
                  -3.021093367999867
                ],
                [
                  104.7871856700001,
                  -3.029229163999958
                ],
                [
                  104.78667427099998,
                  -3.029849921999926
                ],
                [
                  104.784912001,
                  -3.031987
                ],
                [
                  104.78462212400012,
                  -3.032339578999938
                ],
                [
                  104.7824710000001,
                  -3.034955998999976
                ]
              ]
            ]
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "NAME_3": "Seberang Ulu I",
          "NAME_4": "1 Ulu",
          "ID": 8,
          "STUNTING": 70,
          "WASTING":25,
          "LAT": 104.768855,
          "LONG": -3.016177
        },
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [
                  104.74868011500013,
                  -3.012448072999973
                ],
                [
                  104.749008178,
                  -3.011947869999972
                ],
                [
                  104.74942779600008,
                  -3.011301516999879
                ],
                [
                  104.74977874700011,
                  -3.010506391999911
                ],
                [
                  104.75031280500008,
                  -3.009600639999917
                ],
                [
                  104.75087738100007,
                  -3.00867414399994
                ],
                [
                  104.7517547600001,
                  -3.006406067999933
                ],
                [
                  104.75199127300004,
                  -3.005879877999917
                ],
                [
                  104.75241088799999,
                  -3.004760979999901
                ],
                [
                  104.75244140700016,
                  -3.004635809999911
                ],
                [
                  104.75273895300006,
                  -3.003875015999938
                ],
                [
                  104.75264739900013,
                  -3.003845929999954
                ],
                [
                  104.75265502900004,
                  -3.003760098999862
                ],
                [
                  104.75397491400008,
                  -3.001065969999956
                ],
                [
                  104.7540588380001,
                  -3.00110244699988
                ],
                [
                  104.75486755300017,
                  -2.999546765999924
                ],
                [
                  104.75524139300018,
                  -2.999032973999874
                ],
                [
                  104.75534057500005,
                  -2.998889444999918
                ],
                [
                  104.75593566800018,
                  -2.998148202999971
                ],
                [
                  104.75583648600013,
                  -2.998056410999936
                ],
                [
                  104.7568740850001,
                  -2.997055768999815
                ],
                [
                  104.75697326700009,
                  -2.996951579999973
                ],
                [
                  104.75733184800004,
                  -2.996476411999936
                ],
                [
                  104.75809478700018,
                  -2.995766638999896
                ],
                [
                  104.75798034600007,
                  -2.995595455999933
                ],
                [
                  104.75931549100011,
                  -2.99471545199998
                ],
                [
                  104.75975799500014,
                  -2.994456052999908
                ],
                [
                  104.75984954900008,
                  -2.99440193199996
                ],
                [
                  104.76015472300008,
                  -2.993929862999892
                ],
                [
                  104.76103210400015,
                  -2.993232011999964
                ],
                [
                  104.76137542800012,
                  -2.99298715599997
                ],
                [
                  104.76174163800005,
                  -2.992586374999917
                ],
                [
                  104.7621002190001,
                  -2.992914675999941
                ],
                [
                  104.76234436100009,
                  -2.993124007999938
                ],
                [
                  104.76295471200007,
                  -2.993654964999962
                ],
                [
                  104.76356506299999,
                  -2.994184494999899
                ],
                [
                  104.76418304400005,
                  -2.994702100999916
                ],
                [
                  104.76479339600013,
                  -2.995233058999816
                ],
                [
                  104.7655715950001,
                  -2.995687484999905
                ],
                [
                  104.76618194600007,
                  -2.996231792999936
                ],
                [
                  104.7669601450001,
                  -2.996673106999935
                ],
                [
                  104.76803588799999,
                  -2.997527598999966
                ],
                [
                  104.76863861100003,
                  -2.998113155999818
                ],
                [
                  104.76924896200018,
                  -2.998698710999861
                ],
                [
                  104.769683837,
                  -2.999319552999964
                ],
                [
                  104.76979065000006,
                  -3.000094413999875
                ],
                [
                  104.76976013100011,
                  -3.00039148299993
                ],
                [
                  104.7700271610002,
                  -3.001131294999936
                ],
                [
                  104.77013397300016,
                  -3.001891612999884
                ],
                [
                  104.77006530800003,
                  -3.002703188999874
                ],
                [
                  104.76982879700012,
                  -3.003564356999902
                ],
                [
                  104.76943969800016,
                  -3.004379509999865
                ],
                [
                  104.76943206800007,
                  -3.004492759999891
                ],
                [
                  104.76973724500016,
                  -3.004726170999959
                ],
                [
                  104.77017211999998,
                  -3.005414962999907
                ],
                [
                  104.77043151800012,
                  -3.006221293999943
                ],
                [
                  104.7705307,
                  -3.006996154999911
                ],
                [
                  104.77079009900007,
                  -3.007830618999947
                ],
                [
                  104.77105712900016,
                  -3.008541106999928
                ],
                [
                  104.77116394100011,
                  -3.009302852999895
                ],
                [
                  104.77159118700007,
                  -3.010032891999913
                ],
                [
                  104.7723770140002,
                  -3.010392666999905
                ],
                [
                  104.77334594700017,
                  -3.010580300999891
                ],
                [
                  104.77432251000005,
                  -3.010646343999952
                ],
                [
                  104.77545166000004,
                  -3.01084017799991
                ],
                [
                  104.77623748700012,
                  -3.011144161999937
                ],
                [
                  104.77684784000007,
                  -3.01172971699998
                ],
                [
                  104.77711486800013,
                  -3.012481449999825
                ],
                [
                  104.77737426800013,
                  -3.01330256499989
                ],
                [
                  104.77746582000003,
                  -3.014144181999825
                ],
                [
                  104.77756500200007,
                  -3.014960287999941
                ],
                [
                  104.7774963380001,
                  -3.015811680999946
                ],
                [
                  104.7776489260001,
                  -3.015885828999956
                ],
                [
                  104.77748108000009,
                  -3.015975712999932
                ],
                [
                  104.77747345000017,
                  -3.016083715999969
                ],
                [
                  104.77729797400019,
                  -3.016204118999894
                ],
                [
                  104.77728271600017,
                  -3.016402482999979
                ],
                [
                  104.77711486800013,
                  -3.016489744999831
                ],
                [
                  104.7770996100001,
                  -3.016605614999889
                ],
                [
                  104.77692413400018,
                  -3.016759394999951
                ],
                [
                  104.7769165040001,
                  -3.01690721499989
                ],
                [
                  104.77674102800012,
                  -3.017078159999926
                ],
                [
                  104.7767257700001,
                  -3.017226218999838
                ],
                [
                  104.77655029400017,
                  -3.017389296999909
                ],
                [
                  104.77653503400012,
                  -3.017545221999853
                ],
                [
                  104.77652740500014,
                  -3.017655609999906
                ],
                [
                  104.77613067700014,
                  -3.018472194999902
                ],
                [
                  104.775581359,
                  -3.019242523999935
                ],
                [
                  104.77471160800008,
                  -3.01959061499997
                ],
                [
                  104.77106475900007,
                  -3.018769978999956
                ],
                [
                  104.76992034900002,
                  -3.018921613999964
                ],
                [
                  104.7682571410001,
                  -3.018377542999872
                ],
                [
                  104.7674026480002,
                  -3.017269848999888
                ],
                [
                  104.76614379900002,
                  -3.016521215999944
                ],
                [
                  104.76438903900015,
                  -3.015878438999948
                ],
                [
                  104.76412200900006,
                  -3.015510796999934
                ],
                [
                  104.76351928600002,
                  -3.01487064399987
                ],
                [
                  104.76291656500018,
                  -3.014218330999938
                ],
                [
                  104.76251983700001,
                  -3.015033483999957
                ],
                [
                  104.76197052100008,
                  -3.015818355999954
                ],
                [
                  104.7611007700001,
                  -3.016405820999978
                ],
                [
                  104.76008606,
                  -3.016825912999934
                ],
                [
                  104.76010894800015,
                  -3.016555547999928
                ],
                [
                  104.75949859500014,
                  -3.016079187999878
                ],
                [
                  104.75872039799998,
                  -3.015596627999912
                ],
                [
                  104.758110047,
                  -3.015011070999947
                ],
                [
                  104.75766754200009,
                  -3.014418362999947
                ],
                [
                  104.75688934300013,
                  -3.013963936999914
                ],
                [
                  104.75656890800013,
                  -3.013886212999978
                ],
                [
                  104.75577545100015,
                  -3.013608931999954
                ],
                [
                  104.75515747100013,
                  -3.013159273999975
                ],
                [
                  104.75479889000007,
                  -3.013582705999909
                ],
                [
                  104.75392913900015,
                  -3.014210222999964
                ],
                [
                  104.75322723400006,
                  -3.014827726999954
                ],
                [
                  104.75282287700009,
                  -3.015063522999924
                ],
                [
                  104.75254058899998,
                  -3.015229462999912
                ],
                [
                  104.75218200700016,
                  -3.015547990999892
                ],
                [
                  104.75199890100009,
                  -3.015272377999906
                ],
                [
                  104.75161743100017,
                  -3.014706848999936
                ],
                [
                  104.75134277300003,
                  -3.014322280999977
                ],
                [
                  104.75098419099999,
                  -3.013915061999967
                ],
                [
                  104.7507171630001,
                  -3.013530491999916
                ],
                [
                  104.75058746300004,
                  -3.013371466999899
                ],
                [
                  104.75009155200013,
                  -3.013145685999973
                ],
                [
                  104.74912261900016,
                  -3.013038157999972
                ],
                [
                  104.74876403700017,
                  -3.012933015999977
                ],
                [
                  104.74868011500013,
                  -3.012909889999946
                ],
                [
                  104.7484817510001,
                  -3.012852191999968
                ],
                [
                  104.74868011500013,
                  -3.012448072999973
                ]
              ]
            ]
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "NAME_3": "Seberang Ulu II",
          "NAME_4": "11 Ulu",
          "ID": 9,
          "STUNTING": 72,
          "WASTING":26,
          "LAT": 104.792161,
          "LONG": -2.994176
        },
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [
                  104.80428314099998,
                  -2.996829985999966
                ],
                [
                  104.80422973600008,
                  -2.997316836999914
                ],
                [
                  104.80412292400013,
                  -2.998067616999947
                ],
                [
                  104.80416107300005,
                  -2.998277901999927
                ],
                [
                  104.80414581200012,
                  -2.999540090999915
                ],
                [
                  104.80413818400018,
                  -3.000109194999936
                ],
                [
                  104.80412292400013,
                  -3.000787733999971
                ],
                [
                  104.80411529600008,
                  -3.001122951999889
                ],
                [
                  104.80411529600008,
                  -3.001404285999968
                ],
                [
                  104.80419921900017,
                  -3.001539468999965
                ],
                [
                  104.80418395900011,
                  -3.002048015999947
                ],
                [
                  104.80417633100006,
                  -3.002691505999906
                ],
                [
                  104.80417633100006,
                  -3.003244161999874
                ],
                [
                  104.8041687010001,
                  -3.003854750999949
                ],
                [
                  104.8041534420002,
                  -3.004094123999948
                ],
                [
                  104.80413818400018,
                  -3.004357338999966
                ],
                [
                  104.80410003700013,
                  -3.005199670999957
                ],
                [
                  104.80538177500011,
                  -3.005441904999941
                ],
                [
                  104.80498504600007,
                  -3.005802391999907
                ],
                [
                  104.80509948700006,
                  -3.005908726999905
                ],
                [
                  104.80509948700006,
                  -3.00653266799992
                ],
                [
                  104.80494690000012,
                  -3.006462334999981
                ],
                [
                  104.80461120600006,
                  -3.006289719999927
                ],
                [
                  104.80430603000019,
                  -3.00624132199988
                ],
                [
                  104.80412292400013,
                  -3.006228208999971
                ],
                [
                  104.80412292400013,
                  -3.006568907999906
                ],
                [
                  104.80412292400013,
                  -3.007055282999943
                ],
                [
                  104.8044509890002,
                  -3.007068155999946
                ],
                [
                  104.80468750000017,
                  -3.007179975999918
                ],
                [
                  104.80495453000003,
                  -3.008232115999931
                ],
                [
                  104.80498504600007,
                  -3.008455752999964
                ],
                [
                  104.80457306000011,
                  -3.008497475999945
                ],
                [
                  104.80476379400005,
                  -3.009350537999921
                ],
                [
                  104.80438995400004,
                  -3.009452342999907
                ],
                [
                  104.80438995400004,
                  -3.010261297999875
                ],
                [
                  104.8043823230002,
                  -3.013921974999903
                ],
                [
                  104.80403137200005,
                  -3.013921736999976
                ],
                [
                  104.80398559500009,
                  -3.014214515999868
                ],
                [
                  104.80376434400017,
                  -3.015570639999964
                ],
                [
                  104.80367279,
                  -3.016170024999951
                ],
                [
                  104.80326080300011,
                  -3.016482351999912
                ],
                [
                  104.8030776970001,
                  -3.016983747999916
                ],
                [
                  104.80240631100014,
                  -3.017162560999964
                ],
                [
                  104.8019638070001,
                  -3.017513035999855
                ],
                [
                  104.80088806200018,
                  -3.017788885999948
                ],
                [
                  104.7998428350001,
                  -3.017896175999908
                ],
                [
                  104.79914856000016,
                  -3.018261432999964
                ],
                [
                  104.79932403600009,
                  -3.019167184999958
                ],
                [
                  104.79816436700014,
                  -3.019417761999875
                ],
                [
                  104.79629516700004,
                  -3.019345760999954
                ],
                [
                  104.7959213270002,
                  -3.019778966999922
                ],
                [
                  104.79582214400006,
                  -3.01952504999997
                ],
                [
                  104.79524993800015,
                  -3.018555641999967
                ],
                [
                  104.79516601600017,
                  -3.018314599999826
                ],
                [
                  104.7949829100001,
                  -3.017787932999966
                ],
                [
                  104.79469299400012,
                  -3.016792296999881
                ],
                [
                  104.79429626500001,
                  -3.01620221099995
                ],
                [
                  104.793571472,
                  -3.015072105999934
                ],
                [
                  104.7929763790001,
                  -3.01376438199992
                ],
                [
                  104.79273224000002,
                  -3.012774705999846
                ],
                [
                  104.7926559440001,
                  -3.012517213999956
                ],
                [
                  104.79213714700012,
                  -3.01256346599996
                ],
                [
                  104.79185485900018,
                  -3.012093543999868
                ],
                [
                  104.79176330700005,
                  -3.011916637999889
                ],
                [
                  104.79100799600019,
                  -3.010388373999945
                ],
                [
                  104.7908554070001,
                  -3.010269165999944
                ],
                [
                  104.7904968250001,
                  -3.009677885999849
                ],
                [
                  104.7905731210002,
                  -3.009560584999917
                ],
                [
                  104.79067230300006,
                  -3.009405374999972
                ],
                [
                  104.79055786100014,
                  -3.009212016999925
                ],
                [
                  104.79017639200015,
                  -3.009156465999979
                ],
                [
                  104.78999328600008,
                  -3.008858441999905
                ],
                [
                  104.78964233500011,
                  -3.007871627999975
                ],
                [
                  104.78945922900004,
                  -3.007357358999968
                ],
                [
                  104.78929138100017,
                  -3.007150887999842
                ],
                [
                  104.78911590500007,
                  -3.007091998999954
                ],
                [
                  104.7890319820001,
                  -3.007026671999938
                ],
                [
                  104.78894806000011,
                  -3.006849527999975
                ],
                [
                  104.78896331800013,
                  -3.006590127999971
                ],
                [
                  104.78894806000011,
                  -3.006397246999938
                ],
                [
                  104.78891754100016,
                  -3.006233691999967
                ],
                [
                  104.78871154900014,
                  -3.006114958999945
                ],
                [
                  104.78858947800012,
                  -3.006152867999958
                ],
                [
                  104.78837585500008,
                  -3.006172656999979
                ],
                [
                  104.7880172740002,
                  -3.006429910999941
                ],
                [
                  104.78791046100014,
                  -3.006587980999882
                ],
                [
                  104.78790283100005,
                  -3.006907462999948
                ],
                [
                  104.78791809200015,
                  -3.007035254999948
                ],
                [
                  104.7877426140002,
                  -3.007184981999899
                ],
                [
                  104.78739166200012,
                  -3.007315396999957
                ],
                [
                  104.78712463400007,
                  -3.007358549999879
                ],
                [
                  104.78704834100006,
                  -3.007388830999901
                ],
                [
                  104.78694915900019,
                  -3.007398128999967
                ],
                [
                  104.78662109400011,
                  -3.007492302999935
                ],
                [
                  104.78649139500016,
                  -3.00744080599992
                ],
                [
                  104.78633117700002,
                  -3.006502626999918
                ],
                [
                  104.78569793800011,
                  -3.006392001999927
                ],
                [
                  104.78498077400013,
                  -3.00477647799994
                ],
                [
                  104.78491210900006,
                  -3.004733085999874
                ],
                [
                  104.78479766800007,
                  -3.004521131999923
                ],
                [
                  104.78466796900011,
                  -3.004343032999941
                ],
                [
                  104.78448486300005,
                  -3.004285572999891
                ],
                [
                  104.78422546400014,
                  -3.004285572999891
                ],
                [
                  104.78421783400006,
                  -3.004203556999869
                ],
                [
                  104.78413391200002,
                  -3.004176854999969
                ],
                [
                  104.78407287600015,
                  -3.003847359999952
                ],
                [
                  104.78384399300012,
                  -3.003847359999952
                ],
                [
                  104.78379821800013,
                  -3.003752230999908
                ],
                [
                  104.78376770000011,
                  -3.003378390999899
                ],
                [
                  104.78364562900003,
                  -3.003157614999907
                ],
                [
                  104.78366851800007,
                  -3.002756833999968
                ],
                [
                  104.78383636500001,
                  -3.002104520999922
                ],
                [
                  104.7838287350001,
                  -3.001219033999917
                ],
                [
                  104.78378295800013,
                  -3.000225304999958
                ],
                [
                  104.7835998540001,
                  -2.999952554999936
                ],
                [
                  104.78323364200014,
                  -2.999925850999944
                ],
                [
                  104.78293609600007,
                  -2.999891041999945
                ],
                [
                  104.78247833200004,
                  -2.999763965999875
                ],
                [
                  104.78224945099998,
                  -2.999731063999832
                ],
                [
                  104.78220367400002,
                  -2.999882696999919
                ],
                [
                  104.78169250500008,
                  -2.999708413999826
                ],
                [
                  104.78157043400006,
                  -2.999668596999925
                ],
                [
                  104.78159332300004,
                  -2.99946999499997
                ],
                [
                  104.78095245300017,
                  -2.999342440999897
                ],
                [
                  104.78065490700004,
                  -2.999222992999876
                ],
                [
                  104.78045654200008,
                  -2.999191044999918
                ],
                [
                  104.77990722600009,
                  -2.99798130999983
                ],
                [
                  104.77986144900012,
                  -2.997884750999901
                ],
                [
                  104.77978515600012,
                  -2.997757911999884
                ],
                [
                  104.77974700900006,
                  -2.997639893999917
                ],
                [
                  104.77966308500004,
                  -2.997472048999953
                ],
                [
                  104.77931976400015,
                  -2.997222184999885
                ],
                [
                  104.77923584000013,
                  -2.997158526999954
                ],
                [
                  104.77770996100008,
                  -2.998083114999815
                ],
                [
                  104.77698516900017,
                  -2.998408077999954
                ],
                [
                  104.77619934000006,
                  -2.995983838999848
                ],
                [
                  104.77657318000007,
                  -2.995803592999948
                ],
                [
                  104.77636718800011,
                  -2.995254754999905
                ],
                [
                  104.776259987,
                  -2.994963961999929
                ],
                [
                  104.7760706900001,
                  -2.99507786699985
                ],
                [
                  104.77585568400008,
                  -2.995206794999945
                ],
                [
                  104.775800313,
                  -2.995239756999922
                ],
                [
                  104.77570343000008,
                  -2.995297431999973
                ],
                [
                  104.77533214500016,
                  -2.995520091999936
                ],
                [
                  104.77506256200019,
                  -2.99568176199989
                ],
                [
                  104.774963,
                  -2.995696999999893
                ],
                [
                  104.77487934200008,
                  -2.995747791999861
                ],
                [
                  104.77481339400015,
                  -2.995740307999938
                ],
                [
                  104.77473449700011,
                  -2.995731354999918
                ],
                [
                  104.77436828700019,
                  -2.995786189999933
                ],
                [
                  104.77371716100015,
                  -2.995660244999897
                ],
                [
                  104.77348327600004,
                  -2.995615004999877
                ],
                [
                  104.77325439500004,
                  -2.995568513999956
                ],
                [
                  104.77274371100003,
                  -2.995418652999888
                ],
                [
                  104.772453,
                  -2.995333
                ],
                [
                  104.77220908400017,
                  -2.995095081999921
                ],
                [
                  104.772163391,
                  -2.995001792999972
                ],
                [
                  104.77181620000016,
                  -2.994605656999966
                ],
                [
                  104.77147674600013,
                  -2.99421834899988
                ],
                [
                  104.77143147500004,
                  -2.994207160999906
                ],
                [
                  104.77139282200011,
                  -2.994197607999922
                ],
                [
                  104.77125183700008,
                  -2.994163829999877
                ],
                [
                  104.77114105300012,
                  -2.99413728699983
                ],
                [
                  104.77108934600005,
                  -2.994139261999976
                ],
                [
                  104.77100372400008,
                  -2.994142532999888
                ],
                [
                  104.77092742800016,
                  -2.994086502999892
                ],
                [
                  104.77085113500016,
                  -2.994066953999948
                ],
                [
                  104.77046966600011,
                  -2.993974684999955
                ],
                [
                  104.76945495600017,
                  -2.993138550999959
                ],
                [
                  104.76936677700007,
                  -2.993156042999885
                ],
                [
                  104.769279,
                  -2.993173
                ],
                [
                  104.769196,
                  -2.993127999999899
                ],
                [
                  104.76908350700006,
                  -2.993066467999938
                ],
                [
                  104.7688980100001,
                  -2.992964504999975
                ],
                [
                  104.76844024700017,
                  -2.99179983099998
                ],
                [
                  104.76835692100019,
                  -2.991587753999909
                ],
                [
                  104.76830291800007,
                  -2.991450308999902
                ],
                [
                  104.76819610500019,
                  -2.991528272999972
                ],
                [
                  104.76815033000003,
                  -2.991645335999976
                ],
                [
                  104.76810723200003,
                  -2.991741856999965
                ],
                [
                  104.76808166500012,
                  -2.991799115999925
                ],
                [
                  104.76805906800013,
                  -2.991849960999957
                ],
                [
                  104.76802800300015,
                  -2.991918992999956
                ],
                [
                  104.76774597200017,
                  -2.991605758999981
                ],
                [
                  104.76771069800009,
                  -2.991556790999937
                ],
                [
                  104.76754760699998,
                  -2.991330385999845
                ],
                [
                  104.76748819700009,
                  -2.991251995999846
                ],
                [
                  104.76747894400012,
                  -2.991239786999813
                ],
                [
                  104.76741255000013,
                  -2.991150998999899
                ],
                [
                  104.76725769100017,
                  -2.990943907999906
                ],
                [
                  104.76606750500014,
                  -2.989815711999938
                ],
                [
                  104.76615905700004,
                  -2.989708661999941
                ],
                [
                  104.76620927800002,
                  -2.98964835299995
                ],
                [
                  104.76631927500017,
                  -2.989516257999924
                ],
                [
                  104.76766204800015,
                  -2.988407373999848
                ],
                [
                  104.7681350700002,
                  -2.987964630999898
                ],
                [
                  104.76873016300016,
                  -2.987480400999971
                ],
                [
                  104.76972961400014,
                  -2.986667869999962
                ],
                [
                  104.77013397300016,
                  -2.986337899999967
                ],
                [
                  104.77049255300011,
                  -2.986006020999923
                ],
                [
                  104.7723770140002,
                  -2.984936951999941
                ],
                [
                  104.77272033700007,
                  -2.984730243999877
                ],
                [
                  104.774093629,
                  -2.984001159999934
                ],
                [
                  104.77436065600017,
                  -2.983839511999975
                ],
                [
                  104.77615356500013,
                  -2.983103035999875
                ],
                [
                  104.77700042800012,
                  -2.98275518399987
                ],
                [
                  104.7777252190001,
                  -2.982459067999912
                ],
                [
                  104.77862548899998,
                  -2.98258614599996
                ],
                [
                  104.78101348800016,
                  -2.982923506999953
                ],
                [
                  104.78218078600008,
                  -2.983087301999944
                ],
                [
                  104.78338623000019,
                  -2.983197926999935
                ],
                [
                  104.78636169400016,
                  -2.983617304999882
                ],
                [
                  104.79090118400006,
                  -2.984257459999924
                ],
                [
                  104.79962921100002,
                  -2.985488651999844
                ],
                [
                  104.80027008100012,
                  -2.985525130999918
                ],
                [
                  104.80326080300011,
                  -2.985560892999956
                ],
                [
                  104.80327606200007,
                  -2.986002920999852
                ],
                [
                  104.80328369199998,
                  -2.98614120499991
                ],
                [
                  104.80229950000012,
                  -2.990478038999925
                ],
                [
                  104.80158996500012,
                  -2.993346212999938
                ],
                [
                  104.80177307100013,
                  -2.993389129999912
                ],
                [
                  104.8016128540001,
                  -2.994017361999965
                ],
                [
                  104.80258178700007,
                  -2.994191884999907
                ],
                [
                  104.80438995400004,
                  -2.994632242999842
                ],
                [
                  104.80428314099998,
                  -2.996829985999966
                ]
              ],
              [
                [
                  104.77624864000006,
                  -2.994936338999935
                ],
                [
                  104.77623661300004,
                  -2.994907869999906
                ],
                [
                  104.776259987,
                  -2.994963961999929
                ],
                [
                  104.77624864000006,
                  -2.994936338999935
                ]
              ]
            ]
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "NAME_3": "Kalidoni",
          "NAME_4": "Bukit Sangkal",
          "ID": 10,
          "STUNTING": 37,
          "WASTING":56,
          "LAT": 104.811016,
          "LONG": -2.972796
        },
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [
                  104.854675,
                  -2.979518
                ],
                [
                  104.85471400000011,
                  -2.979556
                ],
                [
                  104.85474368100017,
                  -2.979585134999979
                ],
                [
                  104.85354688300009,
                  -2.980096798999853
                ],
                [
                  104.853378,
                  -2.980168999999876
                ],
                [
                  104.85336300600005,
                  -2.980179447999944
                ],
                [
                  104.85319040500008,
                  -2.980299559999935
                ],
                [
                  104.85203552100006,
                  -2.98110318199997
                ],
                [
                  104.85069274800009,
                  -2.982037542999933
                ],
                [
                  104.84900665200018,
                  -2.983102559999963
                ],
                [
                  104.8464584350001,
                  -2.984840631999873
                ],
                [
                  104.84377288900015,
                  -2.986397742999941
                ],
                [
                  104.84049615700008,
                  -2.988117700999965
                ],
                [
                  104.839943,
                  -2.988407999999879
                ],
                [
                  104.83773900000011,
                  -2.988075
                ],
                [
                  104.83544200000017,
                  -2.987729
                ],
                [
                  104.83473800000019,
                  -2.987706999999887
                ],
                [
                  104.834251,
                  -2.987691999999811
                ],
                [
                  104.83408379500014,
                  -2.987829870999917
                ],
                [
                  104.83402280100006,
                  -2.98788
                ],
                [
                  104.83388500000012,
                  -2.98788
                ],
                [
                  104.83228302000009,
                  -2.987880228999927
                ],
                [
                  104.82866668600019,
                  -2.987880228999927
                ],
                [
                  104.822151184,
                  -2.987518309999928
                ],
                [
                  104.822151184,
                  -2.987348316999885
                ],
                [
                  104.82206726200019,
                  -2.987355709999974
                ],
                [
                  104.82192230300018,
                  -2.987367629999881
                ],
                [
                  104.82164764500004,
                  -2.987391708999894
                ],
                [
                  104.82164764500004,
                  -2.986063478999881
                ],
                [
                  104.82169342000014,
                  -2.985790490999932
                ],
                [
                  104.82171630800013,
                  -2.985655544999872
                ],
                [
                  104.82247924900008,
                  -2.981021164999959
                ],
                [
                  104.82170105000012,
                  -2.981084107999834
                ],
                [
                  104.8219375610002,
                  -2.980569601999889
                ],
                [
                  104.82121276900011,
                  -2.980475424999895
                ],
                [
                  104.81909942600015,
                  -2.980571746999885
                ],
                [
                  104.8192062380001,
                  -2.979700325999886
                ],
                [
                  104.8192672730001,
                  -2.979187011999898
                ],
                [
                  104.81936645500008,
                  -2.978363275999925
                ],
                [
                  104.81934356699998,
                  -2.978293417999964
                ],
                [
                  104.8194351190001,
                  -2.978085994999901
                ],
                [
                  104.8197021490002,
                  -2.976949451999928
                ],
                [
                  104.81969451800018,
                  -2.976697920999925
                ],
                [
                  104.81966400200014,
                  -2.976506948999941
                ],
                [
                  104.81984710799998,
                  -2.976293801999873
                ],
                [
                  104.81977081200006,
                  -2.97621726999995
                ],
                [
                  104.81948852600016,
                  -2.976195334999886
                ],
                [
                  104.8191375720001,
                  -2.976227997999956
                ],
                [
                  104.81900787400019,
                  -2.976074218999827
                ],
                [
                  104.81878662100007,
                  -2.976124047999861
                ],
                [
                  104.81641387900004,
                  -2.976655720999872
                ],
                [
                  104.8161621100001,
                  -2.976786851999975
                ],
                [
                  104.81288146900016,
                  -2.978487013999882
                ],
                [
                  104.81227874800015,
                  -2.978903055999922
                ],
                [
                  104.80332946900012,
                  -2.984030245999975
                ],
                [
                  104.80250549300018,
                  -2.984265804999893
                ],
                [
                  104.802375794,
                  -2.984304188999886
                ],
                [
                  104.79951477000009,
                  -2.984447478999925
                ],
                [
                  104.79570770200002,
                  -2.984452485999896
                ],
                [
                  104.79557037300015,
                  -2.982977866999931
                ],
                [
                  104.79566192700008,
                  -2.981716870999946
                ],
                [
                  104.7951202390002,
                  -2.980928182999889
                ],
                [
                  104.79437255900012,
                  -2.979643105999969
                ],
                [
                  104.7943573010001,
                  -2.979547023999942
                ],
                [
                  104.79425048800005,
                  -2.97933149399995
                ],
                [
                  104.79444885300006,
                  -2.978969811999946
                ],
                [
                  104.79462432899999,
                  -2.977935075999937
                ],
                [
                  104.79455566500019,
                  -2.977637766999976
                ],
                [
                  104.79438018700006,
                  -2.977109431999963
                ],
                [
                  104.79444885300006,
                  -2.976740597999878
                ],
                [
                  104.79454040500019,
                  -2.976220607999949
                ],
                [
                  104.79457092300004,
                  -2.97518587199994
                ],
                [
                  104.79464721700015,
                  -2.974520445999872
                ],
                [
                  104.79481506300016,
                  -2.973406313999874
                ],
                [
                  104.79485321100009,
                  -2.972890138999958
                ],
                [
                  104.79492187500011,
                  -2.972845076999874
                ],
                [
                  104.79524993800015,
                  -2.972379207999893
                ],
                [
                  104.79550933800016,
                  -2.972038744999963
                ],
                [
                  104.79575347899998,
                  -2.971668004999913
                ],
                [
                  104.795768737,
                  -2.971497535999958
                ],
                [
                  104.79563903800005,
                  -2.969909429999916
                ],
                [
                  104.7954864510001,
                  -2.969122885999923
                ],
                [
                  104.79551696800007,
                  -2.968967437999936
                ],
                [
                  104.79553222600009,
                  -2.968802689999904
                ],
                [
                  104.79557037300015,
                  -2.968467949999933
                ],
                [
                  104.79558563300014,
                  -2.968270062999977
                ],
                [
                  104.79563903800005,
                  -2.967619894999928
                ],
                [
                  104.79566955500013,
                  -2.966853141999877
                ],
                [
                  104.79581451400014,
                  -2.965875385999936
                ],
                [
                  104.79383087100007,
                  -2.965276954999979
                ],
                [
                  104.79360199000007,
                  -2.965229510999961
                ],
                [
                  104.79270935099998,
                  -2.965104101999941
                ],
                [
                  104.79255676200006,
                  -2.965086935999977
                ],
                [
                  104.79258728000013,
                  -2.964903592999974
                ],
                [
                  104.79249572800018,
                  -2.964876413999946
                ],
                [
                  104.7928619380001,
                  -2.963264702999822
                ],
                [
                  104.7929992670002,
                  -2.962639568999975
                ],
                [
                  104.7930679330002,
                  -2.962107658999912
                ],
                [
                  104.79322052000009,
                  -2.961356400999932
                ],
                [
                  104.79157257100007,
                  -2.959587810999949
                ],
                [
                  104.79139709500015,
                  -2.959213255999884
                ],
                [
                  104.79119873000019,
                  -2.958927629999948
                ],
                [
                  104.79089355400015,
                  -2.958229779999954
                ],
                [
                  104.79029846100019,
                  -2.957769630999906
                ],
                [
                  104.7891693110002,
                  -2.956915855999966
                ],
                [
                  104.78910064700005,
                  -2.956840991999968
                ],
                [
                  104.78787231500019,
                  -2.955887794999967
                ],
                [
                  104.7857589730001,
                  -2.951054095999893
                ],
                [
                  104.785606384,
                  -2.950720785999863
                ],
                [
                  104.78546905500014,
                  -2.950689315999909
                ],
                [
                  104.78477478000013,
                  -2.950473068999884
                ],
                [
                  104.78398132200005,
                  -2.950301170999978
                ],
                [
                  104.783760072,
                  -2.950239419999889
                ],
                [
                  104.78315734900013,
                  -2.950073242999906
                ],
                [
                  104.78308105500008,
                  -2.950056791999884
                ],
                [
                  104.78042602600004,
                  -2.949510811999914
                ],
                [
                  104.77916717400012,
                  -2.949276447999978
                ],
                [
                  104.77769470300007,
                  -2.948955534999925
                ],
                [
                  104.77571106000016,
                  -2.949086665999914
                ],
                [
                  104.77536773600019,
                  -2.949107169999934
                ],
                [
                  104.77525329600013,
                  -2.949111459999926
                ],
                [
                  104.7742385860002,
                  -2.949161767999897
                ],
                [
                  104.7723770140002,
                  -2.949292898999943
                ],
                [
                  104.77120971600004,
                  -2.949456213999952
                ],
                [
                  104.7709655770002,
                  -2.949490069999968
                ],
                [
                  104.76930236900006,
                  -2.949812650999945
                ],
                [
                  104.76818847700008,
                  -2.950120686999924
                ],
                [
                  104.76793670600011,
                  -2.948501823999948
                ],
                [
                  104.76765442000004,
                  -2.946408510999845
                ],
                [
                  104.76737213100006,
                  -2.944338083999867
                ],
                [
                  104.76734924300013,
                  -2.944136856999933
                ],
                [
                  104.7672805790001,
                  -2.942751884999893
                ],
                [
                  104.76728820800014,
                  -2.942672967999954
                ],
                [
                  104.76731872600016,
                  -2.942071437999971
                ],
                [
                  104.76734161300004,
                  -2.940653800999939
                ],
                [
                  104.76738739000018,
                  -2.939142702999959
                ],
                [
                  104.7674179080002,
                  -2.93903970599996
                ],
                [
                  104.76838684100017,
                  -2.93609022999982
                ],
                [
                  104.76895141600005,
                  -2.936316012999953
                ],
                [
                  104.76898193400012,
                  -2.936188459999869
                ],
                [
                  104.76900482300016,
                  -2.936064718999944
                ],
                [
                  104.76922607400007,
                  -2.936132191999832
                ],
                [
                  104.76934814400005,
                  -2.936167715999943
                ],
                [
                  104.76950836200012,
                  -2.93621540099997
                ],
                [
                  104.76945495600017,
                  -2.936366319999934
                ],
                [
                  104.76984405500014,
                  -2.936611889999881
                ],
                [
                  104.77001953100006,
                  -2.936702488999856
                ],
                [
                  104.77010345399998,
                  -2.936567066999942
                ],
                [
                  104.7702560420002,
                  -2.936223745999939
                ],
                [
                  104.77043151800012,
                  -2.935896872999876
                ],
                [
                  104.77054596000005,
                  -2.93571829799987
                ],
                [
                  104.77068328900009,
                  -2.935585736999826
                ],
                [
                  104.77111816400014,
                  -2.935424803999922
                ],
                [
                  104.77149963500017,
                  -2.935314178999931
                ],
                [
                  104.77305603000008,
                  -2.934694289999868
                ],
                [
                  104.77326965300006,
                  -2.934608457999957
                ],
                [
                  104.77339935400005,
                  -2.934556722999957
                ],
                [
                  104.77363586500013,
                  -2.934594154999957
                ],
                [
                  104.77425384600002,
                  -2.934678315999975
                ],
                [
                  104.77464294499998,
                  -2.934755086999928
                ],
                [
                  104.77667236200011,
                  -2.935151575999953
                ],
                [
                  104.77718353300008,
                  -2.935225009999897
                ],
                [
                  104.77733612100008,
                  -2.934974669999974
                ],
                [
                  104.77745819100005,
                  -2.934771060999935
                ],
                [
                  104.77767944400017,
                  -2.934874772999876
                ],
                [
                  104.77780151300004,
                  -2.934930562999966
                ],
                [
                  104.77803802400013,
                  -2.935096500999919
                ],
                [
                  104.77809143100006,
                  -2.935008763999974
                ],
                [
                  104.77812957800006,
                  -2.934922694999955
                ],
                [
                  104.77820587200017,
                  -2.934978962999878
                ],
                [
                  104.77865600500002,
                  -2.93530726299997
                ],
                [
                  104.77922821000004,
                  -2.935249805999831
                ],
                [
                  104.77925872800006,
                  -2.935354709999956
                ],
                [
                  104.77931213400007,
                  -2.935493468999937
                ],
                [
                  104.77936553900014,
                  -2.935703276999959
                ],
                [
                  104.77976226700008,
                  -2.935506582999949
                ],
                [
                  104.77986908000014,
                  -2.935452937999912
                ],
                [
                  104.78056335500008,
                  -2.934915779999812
                ],
                [
                  104.78061675999999,
                  -2.934867382999869
                ],
                [
                  104.7809753410001,
                  -2.93482851899995
                ],
                [
                  104.78151702900016,
                  -2.934794186999966
                ],
                [
                  104.78164672800011,
                  -2.934804676999931
                ],
                [
                  104.78179168700012,
                  -2.934794901999851
                ],
                [
                  104.78195190500008,
                  -2.934828995999908
                ],
                [
                  104.78235626100013,
                  -2.934912919999931
                ],
                [
                  104.78366089000014,
                  -2.935185909999973
                ],
                [
                  104.78365325900018,
                  -2.93527817699993
                ],
                [
                  104.78393554700006,
                  -2.935329674999934
                ],
                [
                  104.78385162400014,
                  -2.936170578999963
                ],
                [
                  104.78379821800013,
                  -2.936777829999869
                ],
                [
                  104.78378295800013,
                  -2.93698978499998
                ],
                [
                  104.78388977000009,
                  -2.936992644999975
                ],
                [
                  104.78488159200009,
                  -2.937002419999885
                ],
                [
                  104.78494262700008,
                  -2.937539814999923
                ],
                [
                  104.78519439700011,
                  -2.937715768999851
                ],
                [
                  104.78535461400014,
                  -2.93773126699989
                ],
                [
                  104.7855148320001,
                  -2.937727688999928
                ],
                [
                  104.78557586700009,
                  -2.938091993999876
                ],
                [
                  104.7857437130001,
                  -2.938205718999882
                ],
                [
                  104.78575134300019,
                  -2.938285587999928
                ],
                [
                  104.78578185900005,
                  -2.938447237999981
                ],
                [
                  104.78591156000005,
                  -2.93842816199998
                ],
                [
                  104.78599548400007,
                  -2.93840193799997
                ],
                [
                  104.78603363100012,
                  -2.938692331999903
                ],
                [
                  104.78609466600011,
                  -2.93903279299991
                ],
                [
                  104.78620910700005,
                  -2.93982505799994
                ],
                [
                  104.78636932400008,
                  -2.939864634999935
                ],
                [
                  104.7864379880001,
                  -2.940246104999972
                ],
                [
                  104.78673553400017,
                  -2.940246342999956
                ],
                [
                  104.78665161200018,
                  -2.940361259999975
                ],
                [
                  104.78647613400005,
                  -2.940657853999937
                ],
                [
                  104.78674316400009,
                  -2.940691232999939
                ],
                [
                  104.78671264600007,
                  -2.940762995999933
                ],
                [
                  104.78754425100004,
                  -2.940846919999842
                ],
                [
                  104.78754425100004,
                  -2.940092801999981
                ],
                [
                  104.78829193200016,
                  -2.940064905999975
                ],
                [
                  104.78883361700014,
                  -2.939743995999891
                ],
                [
                  104.78940582400014,
                  -2.939534425999966
                ],
                [
                  104.78941345200008,
                  -2.939247130999888
                ],
                [
                  104.78964996299999,
                  -2.939226388999941
                ],
                [
                  104.7897415170001,
                  -2.938824890999967
                ],
                [
                  104.78984832700007,
                  -2.938740968999923
                ],
                [
                  104.79023742800001,
                  -2.939124106999941
                ],
                [
                  104.79032898000014,
                  -2.939219713999933
                ],
                [
                  104.79097747800017,
                  -2.938714502999972
                ],
                [
                  104.792144775,
                  -2.939690588999895
                ],
                [
                  104.79274749800004,
                  -2.940313815999957
                ],
                [
                  104.79274749800004,
                  -2.940521477999823
                ],
                [
                  104.7929763790001,
                  -2.940713643999914
                ],
                [
                  104.79476165699998,
                  -2.942208051999842
                ],
                [
                  104.79515075600011,
                  -2.942536114999882
                ],
                [
                  104.79531860400016,
                  -2.942909954999891
                ],
                [
                  104.7954177860002,
                  -2.94431209499993
                ],
                [
                  104.79559326100008,
                  -2.945221899999979
                ],
                [
                  104.79637908800015,
                  -2.945199011999932
                ],
                [
                  104.79644012400007,
                  -2.945973634999916
                ],
                [
                  104.79512786900011,
                  -2.945943831999955
                ],
                [
                  104.79471588000001,
                  -2.945842266999875
                ],
                [
                  104.794700622,
                  -2.946034430999873
                ],
                [
                  104.79455566500019,
                  -2.946023224999919
                ],
                [
                  104.7944259640002,
                  -2.94736766799997
                ],
                [
                  104.79673767000014,
                  -2.94780874199995
                ],
                [
                  104.79669189500004,
                  -2.948199271999954
                ],
                [
                  104.79641723700001,
                  -2.950143813999887
                ],
                [
                  104.7966384880001,
                  -2.950223444999835
                ],
                [
                  104.79687499900007,
                  -2.950308323999934
                ],
                [
                  104.79701232800016,
                  -2.950356482999894
                ],
                [
                  104.79698944200004,
                  -2.950514077999969
                ],
                [
                  104.79694366500007,
                  -2.95075011199998
                ],
                [
                  104.8041534420002,
                  -2.951806783999928
                ],
                [
                  104.80789947500006,
                  -2.952356576999932
                ],
                [
                  104.81262970000006,
                  -2.953040598999962
                ],
                [
                  104.813323975,
                  -2.953141688999949
                ],
                [
                  104.816314697,
                  -2.953577994999932
                ],
                [
                  104.8167343140002,
                  -2.953644514999894
                ],
                [
                  104.8188705450001,
                  -2.953984975999845
                ],
                [
                  104.82311248800016,
                  -2.954598426999951
                ],
                [
                  104.82353210500014,
                  -2.954685210999969
                ],
                [
                  104.82427215500013,
                  -2.954799173999959
                ],
                [
                  104.83097076400003,
                  -2.95580863899994
                ],
                [
                  104.83267211900005,
                  -2.956036566999956
                ],
                [
                  104.8347091670002,
                  -2.956345796999869
                ],
                [
                  104.83498382500017,
                  -2.957202196999958
                ],
                [
                  104.83625793400017,
                  -2.958346842999958
                ],
                [
                  104.83786773700007,
                  -2.959791897999878
                ],
                [
                  104.83834075900012,
                  -2.960217952999869
                ],
                [
                  104.83840179499998,
                  -2.960272312999962
                ],
                [
                  104.84105682400002,
                  -2.956978320999895
                ],
                [
                  104.84114837600015,
                  -2.956807375999972
                ],
                [
                  104.84197998000002,
                  -2.955803393999929
                ],
                [
                  104.8432312010001,
                  -2.954276084999947
                ],
                [
                  104.84358215300017,
                  -2.953836440999964
                ],
                [
                  104.84487152100007,
                  -2.952381848999892
                ],
                [
                  104.84819793700018,
                  -2.952403068999956
                ],
                [
                  104.84819793700018,
                  -2.953641414999936
                ],
                [
                  104.8469619760001,
                  -2.954466580999906
                ],
                [
                  104.847373963,
                  -2.956117630999927
                ],
                [
                  104.8548202520002,
                  -2.950805424999942
                ],
                [
                  104.8600997920002,
                  -2.947039127999972
                ],
                [
                  104.86280822800012,
                  -2.94998478899987
                ],
                [
                  104.864639415,
                  -2.951982388999966
                ],
                [
                  104.86468505900007,
                  -2.952273605999892
                ],
                [
                  104.86471204300017,
                  -2.952393540999878
                ],
                [
                  104.86475387400014,
                  -2.952578687999903
                ],
                [
                  104.86572265500013,
                  -2.956980943999952
                ],
                [
                  104.86459350500013,
                  -2.960233210999888
                ],
                [
                  104.86315155000011,
                  -2.963140487999851
                ],
                [
                  104.86273956300005,
                  -2.964179038999873
                ],
                [
                  104.86232757600015,
                  -2.965528726999935
                ],
                [
                  104.8622207640002,
                  -2.966567037999937
                ],
                [
                  104.86149597200006,
                  -2.968539952999947
                ],
                [
                  104.86119090900013,
                  -2.970193245999951
                ],
                [
                  104.860877991,
                  -2.97186279399989
                ],
                [
                  104.86005401700004,
                  -2.973108766999928
                ],
                [
                  104.85994118500014,
                  -2.973258578999889
                ],
                [
                  104.859428,
                  -2.973939
                ],
                [
                  104.858711,
                  -2.974666
                ],
                [
                  104.857986,
                  -2.975080999999875
                ],
                [
                  104.85735,
                  -2.976174999999898
                ],
                [
                  104.85728267800016,
                  -2.976291283999842
                ],
                [
                  104.8572616580002,
                  -2.976327417999812
                ],
                [
                  104.85643768300014,
                  -2.977573393999876
                ],
                [
                  104.85622086200004,
                  -2.977915031999885
                ],
                [
                  104.85571300000015,
                  -2.978715
                ],
                [
                  104.8554000000002,
                  -2.979546
                ],
                [
                  104.85501100000016,
                  -2.979584999999872
                ],
                [
                  104.8548658090001,
                  -2.979438079999966
                ],
                [
                  104.854744,
                  -2.979489
                ],
                [
                  104.854675,
                  -2.979518
                ]
              ]
            ]
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "NAME_3": "Sematang Borang",
          "NAME_4": "Karya Mulya",
          "ID": 11,
          "STUNTING": 22,
          "WASTING":17,
          "LAT": 104.83579254200004,
          "LONG": -2.9322311869999
        },
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [
                  104.83579254200004,
                  -2.9322311869999
                ],
                [
                  104.83588409400016,
                  -2.932177543999899
                ],
                [
                  104.84027099500014,
                  -2.929843664999964
                ],
                [
                  104.84751129200009,
                  -2.925991773999954
                ],
                [
                  104.8498458860002,
                  -2.924750327999902
                ],
                [
                  104.85141754200004,
                  -2.924948214999858
                ],
                [
                  104.85079903100007,
                  -2.93712406599991
                ],
                [
                  104.85045623700012,
                  -2.943872211999974
                ],
                [
                  104.84902191200001,
                  -2.944787024999869
                ],
                [
                  104.84614563000008,
                  -2.946624755999949
                ],
                [
                  104.84121704200015,
                  -2.950338602999921
                ],
                [
                  104.84487152100007,
                  -2.952381848999892
                ],
                [
                  104.84358215300017,
                  -2.953836440999964
                ],
                [
                  104.8432312010001,
                  -2.954276084999947
                ],
                [
                  104.84197998000002,
                  -2.955803393999929
                ],
                [
                  104.84114837600015,
                  -2.956807375999972
                ],
                [
                  104.84105682400002,
                  -2.956978320999895
                ],
                [
                  104.83840179499998,
                  -2.960272312999962
                ],
                [
                  104.83834075900012,
                  -2.960217952999869
                ],
                [
                  104.83786773700007,
                  -2.959791897999878
                ],
                [
                  104.83625793400017,
                  -2.958346842999958
                ],
                [
                  104.83498382500017,
                  -2.957202196999958
                ],
                [
                  104.8347091670002,
                  -2.956345796999869
                ],
                [
                  104.83267211900005,
                  -2.956036566999956
                ],
                [
                  104.83097076400003,
                  -2.95580863899994
                ],
                [
                  104.82427215500013,
                  -2.954799173999959
                ],
                [
                  104.82353210500014,
                  -2.954685210999969
                ],
                [
                  104.82311248800016,
                  -2.954598426999951
                ],
                [
                  104.8188705450001,
                  -2.953984975999845
                ],
                [
                  104.8167343140002,
                  -2.953644514999894
                ],
                [
                  104.816314697,
                  -2.953577994999932
                ],
                [
                  104.813323975,
                  -2.953141688999949
                ],
                [
                  104.81262970000006,
                  -2.953040598999962
                ],
                [
                  104.80789947500006,
                  -2.952356576999932
                ],
                [
                  104.8041534420002,
                  -2.951806783999928
                ],
                [
                  104.79694366500007,
                  -2.95075011199998
                ],
                [
                  104.79698944200004,
                  -2.950514077999969
                ],
                [
                  104.79701232800016,
                  -2.950356482999894
                ],
                [
                  104.79687499900007,
                  -2.950308323999934
                ],
                [
                  104.7966384880001,
                  -2.950223444999835
                ],
                [
                  104.79641723700001,
                  -2.950143813999887
                ],
                [
                  104.79669189500004,
                  -2.948199271999954
                ],
                [
                  104.79673767000014,
                  -2.94780874199995
                ],
                [
                  104.7944259640002,
                  -2.94736766799997
                ],
                [
                  104.79455566500019,
                  -2.946023224999919
                ],
                [
                  104.794700622,
                  -2.946034430999873
                ],
                [
                  104.79471588000001,
                  -2.945842266999875
                ],
                [
                  104.79512786900011,
                  -2.945943831999955
                ],
                [
                  104.79644012400007,
                  -2.945973634999916
                ],
                [
                  104.79637908800015,
                  -2.945199011999932
                ],
                [
                  104.79559326100008,
                  -2.945221899999979
                ],
                [
                  104.7954177860002,
                  -2.94431209499993
                ],
                [
                  104.79531860400016,
                  -2.942909954999891
                ],
                [
                  104.79515075600011,
                  -2.942536114999882
                ],
                [
                  104.79476165699998,
                  -2.942208051999842
                ],
                [
                  104.7929763790001,
                  -2.940713643999914
                ],
                [
                  104.79274749800004,
                  -2.940521477999823
                ],
                [
                  104.79274749800004,
                  -2.940313815999957
                ],
                [
                  104.792144775,
                  -2.939690588999895
                ],
                [
                  104.79097747800017,
                  -2.938714502999972
                ],
                [
                  104.79032898000014,
                  -2.939219713999933
                ],
                [
                  104.79023742800001,
                  -2.939124106999941
                ],
                [
                  104.78984832700007,
                  -2.938740968999923
                ],
                [
                  104.7897415170001,
                  -2.938824890999967
                ],
                [
                  104.78964996299999,
                  -2.939226388999941
                ],
                [
                  104.78941345200008,
                  -2.939247130999888
                ],
                [
                  104.78940582400014,
                  -2.939534425999966
                ],
                [
                  104.78883361700014,
                  -2.939743995999891
                ],
                [
                  104.78829193200016,
                  -2.940064905999975
                ],
                [
                  104.78754425100004,
                  -2.940092801999981
                ],
                [
                  104.78754425100004,
                  -2.940846919999842
                ],
                [
                  104.78671264600007,
                  -2.940762995999933
                ],
                [
                  104.78674316400009,
                  -2.940691232999939
                ],
                [
                  104.78647613400005,
                  -2.940657853999937
                ],
                [
                  104.78665161200018,
                  -2.940361259999975
                ],
                [
                  104.78673553400017,
                  -2.940246342999956
                ],
                [
                  104.78691864000007,
                  -2.94007277399993
                ],
                [
                  104.78755188000002,
                  -2.939467190999892
                ],
                [
                  104.78766632000008,
                  -2.939378738999949
                ],
                [
                  104.78865814200009,
                  -2.938647269999933
                ],
                [
                  104.78936767500011,
                  -2.938148736999949
                ],
                [
                  104.79211425800008,
                  -2.936227320999876
                ],
                [
                  104.79396820000017,
                  -2.934843538999871
                ],
                [
                  104.7931594850001,
                  -2.933779953999874
                ],
                [
                  104.7927856450001,
                  -2.933245181999951
                ],
                [
                  104.79249572800018,
                  -2.932944297999938
                ],
                [
                  104.7926330570001,
                  -2.932972430999939
                ],
                [
                  104.79339599600007,
                  -2.932432173999928
                ],
                [
                  104.79388427700007,
                  -2.933448075999877
                ],
                [
                  104.79407501300005,
                  -2.933475969999961
                ],
                [
                  104.794769288,
                  -2.934447764999902
                ],
                [
                  104.79541015500018,
                  -2.934065817999908
                ],
                [
                  104.79541015500018,
                  -2.933985948999975
                ],
                [
                  104.79555511500013,
                  -2.933834551999951
                ],
                [
                  104.79637908800015,
                  -2.933256624999956
                ],
                [
                  104.7968444820001,
                  -2.932833432999928
                ],
                [
                  104.797256469,
                  -2.932493448999935
                ],
                [
                  104.79766845600005,
                  -2.932386398999938
                ],
                [
                  104.79787445100015,
                  -2.932411907999949
                ],
                [
                  104.79801941000017,
                  -2.932242391999864
                ],
                [
                  104.798324584,
                  -2.931744575999971
                ],
                [
                  104.79891204900002,
                  -2.931022404999908
                ],
                [
                  104.79969787600015,
                  -2.930066584999963
                ],
                [
                  104.80035400400004,
                  -2.929261206999911
                ],
                [
                  104.80041504000013,
                  -2.929193256999895
                ],
                [
                  104.80050659200009,
                  -2.929126738999912
                ],
                [
                  104.80099487200016,
                  -2.928762196999855
                ],
                [
                  104.801246644,
                  -2.929284810999945
                ],
                [
                  104.80201721200018,
                  -2.93122386899995
                ],
                [
                  104.80838012800012,
                  -2.940015315999972
                ],
                [
                  104.81422424400017,
                  -2.938967226999921
                ],
                [
                  104.81420135600007,
                  -2.939426183999956
                ],
                [
                  104.814682006,
                  -2.944262980999952
                ],
                [
                  104.82186126700009,
                  -2.940169095999977
                ],
                [
                  104.83579254200004,
                  -2.9322311869999
                ]
              ]
            ]
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "NAME_3": "Sako",
          "NAME_4": "Sako",
          "ID": 12,
          "STUNTING": 27,
          "WASTING":21,
          "LAT": 104.78636932400008,
          "LONG": -2.939864634999935
        },
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [
                  104.78636932400008,
                  -2.939864634999935
                ],
                [
                  104.78620910700005,
                  -2.93982505799994
                ],
                [
                  104.78609466600011,
                  -2.93903279299991
                ],
                [
                  104.78603363100012,
                  -2.938692331999903
                ],
                [
                  104.78599548400007,
                  -2.93840193799997
                ],
                [
                  104.78591156000005,
                  -2.93842816199998
                ],
                [
                  104.78578185900005,
                  -2.938447237999981
                ],
                [
                  104.78575134300019,
                  -2.938285587999928
                ],
                [
                  104.7857437130001,
                  -2.938205718999882
                ],
                [
                  104.78557586700009,
                  -2.938091993999876
                ],
                [
                  104.7855148320001,
                  -2.937727688999928
                ],
                [
                  104.78535461400014,
                  -2.93773126699989
                ],
                [
                  104.78519439700011,
                  -2.937715768999851
                ],
                [
                  104.78494262700008,
                  -2.937539814999923
                ],
                [
                  104.78488159200009,
                  -2.937002419999885
                ],
                [
                  104.78388977000009,
                  -2.936992644999975
                ],
                [
                  104.78378295800013,
                  -2.93698978499998
                ],
                [
                  104.78379821800013,
                  -2.936777829999869
                ],
                [
                  104.78385162400014,
                  -2.936170578999963
                ],
                [
                  104.78393554700006,
                  -2.935329674999934
                ],
                [
                  104.78365325900018,
                  -2.93527817699993
                ],
                [
                  104.78366089000014,
                  -2.935185909999973
                ],
                [
                  104.78235626100013,
                  -2.934912919999931
                ],
                [
                  104.78195190500008,
                  -2.934828995999908
                ],
                [
                  104.78179168700012,
                  -2.934794901999851
                ],
                [
                  104.78164672800011,
                  -2.934804676999931
                ],
                [
                  104.78151702900016,
                  -2.934794186999966
                ],
                [
                  104.7809753410001,
                  -2.93482851899995
                ],
                [
                  104.78061675999999,
                  -2.934867382999869
                ],
                [
                  104.78056335500008,
                  -2.934915779999812
                ],
                [
                  104.77986908000014,
                  -2.935452937999912
                ],
                [
                  104.77976226700008,
                  -2.935506582999949
                ],
                [
                  104.77936553900014,
                  -2.935703276999959
                ],
                [
                  104.77931213400007,
                  -2.935493468999937
                ],
                [
                  104.77925872800006,
                  -2.935354709999956
                ],
                [
                  104.77922821000004,
                  -2.935249805999831
                ],
                [
                  104.77865600500002,
                  -2.93530726299997
                ],
                [
                  104.77820587200017,
                  -2.934978962999878
                ],
                [
                  104.77812957800006,
                  -2.934922694999955
                ],
                [
                  104.77809143100006,
                  -2.935008763999974
                ],
                [
                  104.77803802400013,
                  -2.935096500999919
                ],
                [
                  104.77780151300004,
                  -2.934930562999966
                ],
                [
                  104.77767944400017,
                  -2.934874772999876
                ],
                [
                  104.77745819100005,
                  -2.934771060999935
                ],
                [
                  104.77733612100008,
                  -2.934974669999974
                ],
                [
                  104.77718353300008,
                  -2.935225009999897
                ],
                [
                  104.77667236200011,
                  -2.935151575999953
                ],
                [
                  104.77464294499998,
                  -2.934755086999928
                ],
                [
                  104.77425384600002,
                  -2.934678315999975
                ],
                [
                  104.77363586500013,
                  -2.934594154999957
                ],
                [
                  104.77339935400005,
                  -2.934556722999957
                ],
                [
                  104.77326965300006,
                  -2.934608457999957
                ],
                [
                  104.77305603000008,
                  -2.934694289999868
                ],
                [
                  104.77149963500017,
                  -2.935314178999931
                ],
                [
                  104.77111816400014,
                  -2.935424803999922
                ],
                [
                  104.77068328900009,
                  -2.935585736999826
                ],
                [
                  104.77054596000005,
                  -2.93571829799987
                ],
                [
                  104.77043151800012,
                  -2.935896872999876
                ],
                [
                  104.7702560420002,
                  -2.936223745999939
                ],
                [
                  104.77010345399998,
                  -2.936567066999942
                ],
                [
                  104.77001953100006,
                  -2.936702488999856
                ],
                [
                  104.76984405500014,
                  -2.936611889999881
                ],
                [
                  104.76945495600017,
                  -2.936366319999934
                ],
                [
                  104.76950836200012,
                  -2.93621540099997
                ],
                [
                  104.76934814400005,
                  -2.936167715999943
                ],
                [
                  104.76922607400007,
                  -2.936132191999832
                ],
                [
                  104.76900482300016,
                  -2.936064718999944
                ],
                [
                  104.76898193400012,
                  -2.936188459999869
                ],
                [
                  104.76895141600005,
                  -2.936316012999953
                ],
                [
                  104.76838684100017,
                  -2.93609022999982
                ],
                [
                  104.76853942899999,
                  -2.935644388999947
                ],
                [
                  104.76892089900014,
                  -2.934736728999894
                ],
                [
                  104.76935577400013,
                  -2.933721780999974
                ],
                [
                  104.76978302000015,
                  -2.932481287999906
                ],
                [
                  104.77001190200008,
                  -2.931409357999939
                ],
                [
                  104.76978302000015,
                  -2.931430578999937
                ],
                [
                  104.76907348600002,
                  -2.93134045499994
                ],
                [
                  104.76874542299998,
                  -2.931287049999867
                ],
                [
                  104.76845550500019,
                  -2.93140745099987
                ],
                [
                  104.7659759510002,
                  -2.93232583899993
                ],
                [
                  104.76567077700014,
                  -2.93294739799984
                ],
                [
                  104.76556396400008,
                  -2.933221339999932
                ],
                [
                  104.76548767100007,
                  -2.933397530999969
                ],
                [
                  104.76535797200012,
                  -2.933356284999832
                ],
                [
                  104.76493835500014,
                  -2.933105467999951
                ],
                [
                  104.76493835500014,
                  -2.93301796899982
                ],
                [
                  104.76496124300007,
                  -2.932892082999956
                ],
                [
                  104.76469421400009,
                  -2.932790994999948
                ],
                [
                  104.76464843700012,
                  -2.932877063999968
                ],
                [
                  104.76332092200016,
                  -2.932638405999853
                ],
                [
                  104.76303100600012,
                  -2.932737349999968
                ],
                [
                  104.76238250800014,
                  -2.932526825999958
                ],
                [
                  104.76229858400012,
                  -2.932687042999873
                ],
                [
                  104.7622146600001,
                  -2.932773589999954
                ],
                [
                  104.7619857790001,
                  -2.933302878999939
                ],
                [
                  104.76155853200015,
                  -2.934134245999871
                ],
                [
                  104.7590789800002,
                  -2.933821678999948
                ],
                [
                  104.75904083300014,
                  -2.934035300999881
                ],
                [
                  104.75894927900003,
                  -2.934194325999897
                ],
                [
                  104.75890350400005,
                  -2.934408425999948
                ],
                [
                  104.7588729860002,
                  -2.934589861999939
                ],
                [
                  104.75880432200017,
                  -2.934682845999816
                ],
                [
                  104.75881195000011,
                  -2.934813022999947
                ],
                [
                  104.75888061600011,
                  -2.934937237999975
                ],
                [
                  104.75828552300015,
                  -2.934774160999893
                ],
                [
                  104.75800323500005,
                  -2.935013293999873
                ],
                [
                  104.75765991200018,
                  -2.935136794999892
                ],
                [
                  104.75705719000007,
                  -2.935081719999971
                ],
                [
                  104.75689697300004,
                  -2.935064076999936
                ],
                [
                  104.75705719000007,
                  -2.934168337999949
                ],
                [
                  104.75713348400012,
                  -2.933648824999977
                ],
                [
                  104.75720977800006,
                  -2.932898997999928
                ],
                [
                  104.75756836000005,
                  -2.930707930999972
                ],
                [
                  104.75699615500008,
                  -2.930400847999977
                ],
                [
                  104.75698852500011,
                  -2.930028915999969
                ],
                [
                  104.75697326700009,
                  -2.929701565999949
                ],
                [
                  104.75503540099999,
                  -2.9296257499999
                ],
                [
                  104.75443267800011,
                  -2.929598092999925
                ],
                [
                  104.7547531130001,
                  -2.925963877999948
                ],
                [
                  104.75465393100006,
                  -2.925902366999935
                ],
                [
                  104.75466156100015,
                  -2.925800799999877
                ],
                [
                  104.75422668500005,
                  -2.925564049999934
                ],
                [
                  104.754188538,
                  -2.924507139999946
                ],
                [
                  104.75405120900012,
                  -2.924512147999906
                ],
                [
                  104.75403594900007,
                  -2.924430130999951
                ],
                [
                  104.75386047300015,
                  -2.924465654999892
                ],
                [
                  104.75381469600018,
                  -2.924407957999904
                ],
                [
                  104.75361633200009,
                  -2.924161672999958
                ],
                [
                  104.753623962,
                  -2.924058913999886
                ],
                [
                  104.753479003,
                  -2.923993110999959
                ],
                [
                  104.75337982100018,
                  -2.923866271999941
                ],
                [
                  104.75344848600008,
                  -2.923399685999868
                ],
                [
                  104.75355529900008,
                  -2.923399685999868
                ],
                [
                  104.75379181000005,
                  -2.923502682999981
                ],
                [
                  104.75379181000005,
                  -2.923124074999919
                ],
                [
                  104.75379943800016,
                  -2.922934770999973
                ],
                [
                  104.75476074300019,
                  -2.923002481999958
                ],
                [
                  104.75508117800013,
                  -2.923040390999915
                ],
                [
                  104.75564575200013,
                  -2.923181532999877
                ],
                [
                  104.75566864000007,
                  -2.92311024699984
                ],
                [
                  104.75583648600013,
                  -2.923129557999914
                ],
                [
                  104.756477356,
                  -2.921077966999974
                ],
                [
                  104.75678253300015,
                  -2.920292376999896
                ],
                [
                  104.75646209799999,
                  -2.915867089999949
                ],
                [
                  104.75643157900004,
                  -2.915259360999869
                ],
                [
                  104.76282501300005,
                  -2.914481639999906
                ],
                [
                  104.76425933800016,
                  -2.914726018999943
                ],
                [
                  104.76580810600007,
                  -2.915414810999948
                ],
                [
                  104.7657852170002,
                  -2.914875745999836
                ],
                [
                  104.76671600300011,
                  -2.914960859999951
                ],
                [
                  104.76671600300011,
                  -2.91481614099996
                ],
                [
                  104.76737976200008,
                  -2.914840458999947
                ],
                [
                  104.7682724010001,
                  -2.915027855999881
                ],
                [
                  104.76853179900007,
                  -2.915081977999932
                ],
                [
                  104.76838684100017,
                  -2.915826082999956
                ],
                [
                  104.76981353800016,
                  -2.916215180999814
                ],
                [
                  104.76966857899998,
                  -2.917316674999824
                ],
                [
                  104.77130126999998,
                  -2.917515991999949
                ],
                [
                  104.77128601000015,
                  -2.919033287999923
                ],
                [
                  104.77254486100014,
                  -2.918971299999953
                ],
                [
                  104.77396392800006,
                  -2.918789863999962
                ],
                [
                  104.77391815100009,
                  -2.91855859799989
                ],
                [
                  104.77579498300008,
                  -2.917443035999952
                ],
                [
                  104.77590179400016,
                  -2.917103052999892
                ],
                [
                  104.77610015800002,
                  -2.917065858999933
                ],
                [
                  104.77637481600004,
                  -2.916739225999834
                ],
                [
                  104.77663421600016,
                  -2.916543483999874
                ],
                [
                  104.77673339800003,
                  -2.916375398999946
                ],
                [
                  104.77675628600014,
                  -2.916020869999898
                ],
                [
                  104.7771224980001,
                  -2.915974139999946
                ],
                [
                  104.77754974300018,
                  -2.915582418999861
                ],
                [
                  104.778640747,
                  -2.9149572849999
                ],
                [
                  104.77888488800005,
                  -2.914901494999924
                ],
                [
                  104.78042602600004,
                  -2.91509556799997
                ],
                [
                  104.78077697700019,
                  -2.915125846999899
                ],
                [
                  104.78168487500017,
                  -2.9152536389999
                ],
                [
                  104.78168487500017,
                  -2.915483473999927
                ],
                [
                  104.78165435800003,
                  -2.915684460999955
                ],
                [
                  104.7818450920002,
                  -2.915741918999913
                ],
                [
                  104.78212738000013,
                  -2.915837763999946
                ],
                [
                  104.78241729700005,
                  -2.915943145999961
                ],
                [
                  104.78266143800005,
                  -2.916067598999859
                ],
                [
                  104.78286743200005,
                  -2.916058063999969
                ],
                [
                  104.7830963130001,
                  -2.916048525999884
                ],
                [
                  104.78337860100015,
                  -2.916010140999902
                ],
                [
                  104.78360748199998,
                  -2.915838002999976
                ],
                [
                  104.783691406,
                  -2.915732620999961
                ],
                [
                  104.78374481100008,
                  -2.915665625999907
                ],
                [
                  104.78388214000017,
                  -2.915579556999887
                ],
                [
                  104.78399658300015,
                  -2.91536903399998
                ],
                [
                  104.78379821800013,
                  -2.915206193999893
                ],
                [
                  104.78350830000016,
                  -2.915005206999979
                ],
                [
                  104.78315734900013,
                  -2.914851904999978
                ],
                [
                  104.7832717890002,
                  -2.914315938999948
                ],
                [
                  104.78331756600016,
                  -2.913770436999926
                ],
                [
                  104.7833404550002,
                  -2.91266012199992
                ],
                [
                  104.78393554700006,
                  -2.912727354999959
                ],
                [
                  104.78411102300004,
                  -2.912765501999957
                ],
                [
                  104.78454589900014,
                  -2.912229776999936
                ],
                [
                  104.7849578860002,
                  -2.911588429999881
                ],
                [
                  104.78488922200017,
                  -2.910506963999921
                ],
                [
                  104.78514099200004,
                  -2.91007614099982
                ],
                [
                  104.78553772000004,
                  -2.909731626999928
                ],
                [
                  104.78601074200009,
                  -2.909693477999951
                ],
                [
                  104.78640747100013,
                  -2.909626482999897
                ],
                [
                  104.78684997600004,
                  -2.909597873999928
                ],
                [
                  104.7870254520002,
                  -2.909550188999901
                ],
                [
                  104.78764343400019,
                  -2.909136056999841
                ],
                [
                  104.78767395,
                  -2.908977508999953
                ],
                [
                  104.78791046100014,
                  -2.908790110999973
                ],
                [
                  104.7880706790001,
                  -2.908604381999965
                ],
                [
                  104.7881469720001,
                  -2.90846443099997
                ],
                [
                  104.78827667300004,
                  -2.908399104999944
                ],
                [
                  104.78836059500009,
                  -2.908296346999975
                ],
                [
                  104.78860473600014,
                  -2.90832447899993
                ],
                [
                  104.78878784199998,
                  -2.90808176999991
                ],
                [
                  104.7889404300002,
                  -2.908035277999886
                ],
                [
                  104.78904724200015,
                  -2.907801866999932
                ],
                [
                  104.78929138100017,
                  -2.907680748999951
                ],
                [
                  104.7894439710002,
                  -2.907568692999916
                ],
                [
                  104.78948211700015,
                  -2.907455442999947
                ],
                [
                  104.78943634000018,
                  -2.907223461999934
                ],
                [
                  104.78955078100017,
                  -2.90672898199989
                ],
                [
                  104.78960418800006,
                  -2.906572103999963
                ],
                [
                  104.78954315300007,
                  -2.906504868999889
                ],
                [
                  104.78963470500014,
                  -2.906238315999872
                ],
                [
                  104.78968048200011,
                  -2.905786274999969
                ],
                [
                  104.78962707400018,
                  -2.905655621999813
                ],
                [
                  104.78965759300013,
                  -2.905525207999858
                ],
                [
                  104.78975677500011,
                  -2.905422447999968
                ],
                [
                  104.78976440300005,
                  -2.905002592999949
                ],
                [
                  104.78990936300016,
                  -2.904853343999946
                ],
                [
                  104.78988647400013,
                  -2.90455460499993
                ],
                [
                  104.79000854500003,
                  -2.904416560999948
                ],
                [
                  104.79005432200017,
                  -2.904237269999953
                ],
                [
                  104.79018402100013,
                  -2.90415334599993
                ],
                [
                  104.79032134900012,
                  -2.904134749999912
                ],
                [
                  104.79039001500013,
                  -2.904029844999855
                ],
                [
                  104.80007934600008,
                  -2.90655040799993
                ],
                [
                  104.81105804400005,
                  -2.909407614999907
                ],
                [
                  104.80828857400002,
                  -2.91258597399991
                ],
                [
                  104.80632781900005,
                  -2.914838313999951
                ],
                [
                  104.80495453000003,
                  -2.918761729999915
                ],
                [
                  104.80386352599999,
                  -2.921854257999939
                ],
                [
                  104.79524231000005,
                  -2.921852827999885
                ],
                [
                  104.80050659200009,
                  -2.929126738999912
                ],
                [
                  104.80041504000013,
                  -2.929193256999895
                ],
                [
                  104.80035400400004,
                  -2.929261206999911
                ],
                [
                  104.79969787600015,
                  -2.930066584999963
                ],
                [
                  104.79891204900002,
                  -2.931022404999908
                ],
                [
                  104.798324584,
                  -2.931744575999971
                ],
                [
                  104.79801941000017,
                  -2.932242391999864
                ],
                [
                  104.79787445100015,
                  -2.932411907999949
                ],
                [
                  104.79766845600005,
                  -2.932386398999938
                ],
                [
                  104.797256469,
                  -2.932493448999935
                ],
                [
                  104.7968444820001,
                  -2.932833432999928
                ],
                [
                  104.79637908800015,
                  -2.933256624999956
                ],
                [
                  104.79555511500013,
                  -2.933834551999951
                ],
                [
                  104.79541015500018,
                  -2.933985948999975
                ],
                [
                  104.79541015500018,
                  -2.934065817999908
                ],
                [
                  104.794769288,
                  -2.934447764999902
                ],
                [
                  104.79407501300005,
                  -2.933475969999961
                ],
                [
                  104.79388427700007,
                  -2.933448075999877
                ],
                [
                  104.79339599600007,
                  -2.932432173999928
                ],
                [
                  104.7926330570001,
                  -2.932972430999939
                ],
                [
                  104.79249572800018,
                  -2.932944297999938
                ],
                [
                  104.7927856450001,
                  -2.933245181999951
                ],
                [
                  104.7931594850001,
                  -2.933779953999874
                ],
                [
                  104.79396820000017,
                  -2.934843538999871
                ],
                [
                  104.79211425800008,
                  -2.936227320999876
                ],
                [
                  104.78936767500011,
                  -2.938148736999949
                ],
                [
                  104.78865814200009,
                  -2.938647269999933
                ],
                [
                  104.78766632000008,
                  -2.939378738999949
                ],
                [
                  104.78755188000002,
                  -2.939467190999892
                ],
                [
                  104.78691864000007,
                  -2.94007277399993
                ],
                [
                  104.78673553400017,
                  -2.940246342999956
                ],
                [
                  104.7864379880001,
                  -2.940246104999972
                ],
                [
                  104.78636932400008,
                  -2.939864634999935
                ]
              ]
            ]
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "NAME_3": "Ilir Timur II",
          "NAME_4": "1 Ilir",
          "ID": 13,
          "STUNTING": 40,
          "WASTING":71,
          "LAT": 104.781465,
          "LONG": -2.962866
        },
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [
                  104.7777252190001,
                  -2.982459067999912
                ],
                [
                  104.77700042800012,
                  -2.98275518399987
                ],
                [
                  104.77615356500013,
                  -2.983103035999875
                ],
                [
                  104.77436065600017,
                  -2.983839511999975
                ],
                [
                  104.774093629,
                  -2.984001159999934
                ],
                [
                  104.77404022200011,
                  -2.98366022099998
                ],
                [
                  104.77283477800006,
                  -2.980622768999922
                ],
                [
                  104.77275848400012,
                  -2.98049902899993
                ],
                [
                  104.772605897,
                  -2.980091809999976
                ],
                [
                  104.772605897,
                  -2.979844569999955
                ],
                [
                  104.77264404300018,
                  -2.97953176499982
                ],
                [
                  104.77271270700015,
                  -2.979381798999952
                ],
                [
                  104.77280426100009,
                  -2.979316472999926
                ],
                [
                  104.77292633100006,
                  -2.979222058999937
                ],
                [
                  104.77301025300011,
                  -2.979222058999937
                ],
                [
                  104.77319335900012,
                  -2.979046344999972
                ],
                [
                  104.77320861800007,
                  -2.978909253999916
                ],
                [
                  104.77327728300014,
                  -2.978785752999897
                ],
                [
                  104.77349853600009,
                  -2.978727577999962
                ],
                [
                  104.77383422900004,
                  -2.978260040999942
                ],
                [
                  104.77397155800014,
                  -2.977949380999974
                ],
                [
                  104.77428436300016,
                  -2.977607727999953
                ],
                [
                  104.77442932200017,
                  -2.977476834999948
                ],
                [
                  104.77468872200012,
                  -2.977426051999942
                ],
                [
                  104.77480316200018,
                  -2.977398155999936
                ],
                [
                  104.77482605100005,
                  -2.977270601999976
                ],
                [
                  104.77463531400014,
                  -2.976957797999944
                ],
                [
                  104.77462768600009,
                  -2.976812362999965
                ],
                [
                  104.77474975500013,
                  -2.976165055999957
                ],
                [
                  104.77471923799999,
                  -2.976085185999921
                ],
                [
                  104.77455902100013,
                  -2.975896119999902
                ],
                [
                  104.77439880300005,
                  -2.975583314999881
                ],
                [
                  104.77438354500003,
                  -2.975466964999953
                ],
                [
                  104.7743072510001,
                  -2.975292443999876
                ],
                [
                  104.77416229200009,
                  -2.975321530999963
                ],
                [
                  104.77407836800006,
                  -2.97526335799995
                ],
                [
                  104.77401733300007,
                  -2.975103139999931
                ],
                [
                  104.77391815100009,
                  -2.974694251999949
                ],
                [
                  104.77383422900004,
                  -2.974461077999933
                ],
                [
                  104.77436065600017,
                  -2.974248885999941
                ],
                [
                  104.77439117500012,
                  -2.974169253999946
                ],
                [
                  104.7743072510001,
                  -2.974036455999965
                ],
                [
                  104.77427673300008,
                  -2.973618268999928
                ],
                [
                  104.77429962100018,
                  -2.973518608999939
                ],
                [
                  104.77438354500003,
                  -2.973498820999964
                ],
                [
                  104.77442169200009,
                  -2.973319529999969
                ],
                [
                  104.77433014000013,
                  -2.973266361999947
                ],
                [
                  104.77431488000013,
                  -2.973180054999887
                ],
                [
                  104.77442932200017,
                  -2.973040818999948
                ],
                [
                  104.77440643300014,
                  -2.972921370999813
                ],
                [
                  104.77445983900014,
                  -2.972808597999972
                ],
                [
                  104.77453613200015,
                  -2.972702264999896
                ],
                [
                  104.77450561600011,
                  -2.972549675999971
                ],
                [
                  104.77458190900012,
                  -2.972423552999942
                ],
                [
                  104.77478790400016,
                  -2.972282885999903
                ],
                [
                  104.77494049100011,
                  -2.972243308999907
                ],
                [
                  104.77500915500008,
                  -2.972187518999931
                ],
                [
                  104.775016785,
                  -2.972108362999961
                ],
                [
                  104.77510070800014,
                  -2.971969843999943
                ],
                [
                  104.77516937200011,
                  -2.971910715999968
                ],
                [
                  104.77517700200002,
                  -2.971834897999884
                ],
                [
                  104.77507019000006,
                  -2.971785306999948
                ],
                [
                  104.77506256200019,
                  -2.971673249999924
                ],
                [
                  104.77510070800014,
                  -2.971577642999932
                ],
                [
                  104.77519989100006,
                  -2.971517324999979
                ],
                [
                  104.77518463200016,
                  -2.971114158999853
                ],
                [
                  104.77486419700017,
                  -2.971020221999879
                ],
                [
                  104.77484893700017,
                  -2.970837830999926
                ],
                [
                  104.77447509700016,
                  -2.970796108999934
                ],
                [
                  104.77452850400005,
                  -2.970456360999947
                ],
                [
                  104.77487182600004,
                  -2.97047114399993
                ],
                [
                  104.77489471400014,
                  -2.970106601999873
                ],
                [
                  104.77498626800008,
                  -2.96947550699997
                ],
                [
                  104.77544403000013,
                  -2.968388555999923
                ],
                [
                  104.77504730100003,
                  -2.968012808999845
                ],
                [
                  104.77458953900003,
                  -2.967780351999977
                ],
                [
                  104.77287292400018,
                  -2.967082976999905
                ],
                [
                  104.77262878500017,
                  -2.966883897999935
                ],
                [
                  104.77245330700003,
                  -2.966498851999972
                ],
                [
                  104.77235412500016,
                  -2.965635776999875
                ],
                [
                  104.7723922740002,
                  -2.965197800999874
                ],
                [
                  104.77252197299998,
                  -2.964912412999979
                ],
                [
                  104.77278900100009,
                  -2.96447419999987
                ],
                [
                  104.77300262500017,
                  -2.963943242999903
                ],
                [
                  104.77300262500017,
                  -2.963346004999892
                ],
                [
                  104.77285766600016,
                  -2.962781667999934
                ],
                [
                  104.77303314200009,
                  -2.962532996999926
                ],
                [
                  104.77381896900005,
                  -2.962984562999907
                ],
                [
                  104.7741012570001,
                  -2.963222980999944
                ],
                [
                  104.77436065600017,
                  -2.962879894999958
                ],
                [
                  104.77455139100005,
                  -2.962670087999925
                ],
                [
                  104.77441406200018,
                  -2.962535141999922
                ],
                [
                  104.77500915500008,
                  -2.961980580999978
                ],
                [
                  104.77561950800003,
                  -2.962301252999964
                ],
                [
                  104.77598571800019,
                  -2.962625980999974
                ],
                [
                  104.7764282230001,
                  -2.962311028999977
                ],
                [
                  104.77668762300004,
                  -2.962078809999923
                ],
                [
                  104.77683258000008,
                  -2.96192073799989
                ],
                [
                  104.77694702200012,
                  -2.961810827999955
                ],
                [
                  104.77711486800013,
                  -2.961626051999929
                ],
                [
                  104.77728271600017,
                  -2.961368798999899
                ],
                [
                  104.77739715600006,
                  -2.961246250999977
                ],
                [
                  104.77728271600017,
                  -2.961076258999924
                ],
                [
                  104.77706146300011,
                  -2.960796354999957
                ],
                [
                  104.77718353300008,
                  -2.960651397999868
                ],
                [
                  104.77729034400011,
                  -2.960516451999979
                ],
                [
                  104.77720642000008,
                  -2.960401296999976
                ],
                [
                  104.77718353300008,
                  -2.960266350999973
                ],
                [
                  104.77731323200004,
                  -2.960116623999852
                ],
                [
                  104.777137757,
                  -2.959861993999937
                ],
                [
                  104.77706909099999,
                  -2.959496021999939
                ],
                [
                  104.77738189800004,
                  -2.959432123999875
                ],
                [
                  104.7776489260001,
                  -2.959335088999921
                ],
                [
                  104.77769470300007,
                  -2.959060190999935
                ],
                [
                  104.77784729000018,
                  -2.959080456999914
                ],
                [
                  104.77796936000016,
                  -2.959041357999979
                ],
                [
                  104.77812957800006,
                  -2.959048985999971
                ],
                [
                  104.77791595500008,
                  -2.958216188999927
                ],
                [
                  104.77769470300007,
                  -2.958296297999937
                ],
                [
                  104.77761077900004,
                  -2.957824467999899
                ],
                [
                  104.7776489260001,
                  -2.957201481999959
                ],
                [
                  104.77774810800014,
                  -2.956884621999961
                ],
                [
                  104.77779388500011,
                  -2.956733226999972
                ],
                [
                  104.77811432000004,
                  -2.956621646999963
                ],
                [
                  104.77839660600017,
                  -2.956589220999945
                ],
                [
                  104.7784805300002,
                  -2.956392288999893
                ],
                [
                  104.77865600500002,
                  -2.95641970499986
                ],
                [
                  104.77870178199998,
                  -2.956221580999966
                ],
                [
                  104.7785491950001,
                  -2.956192731999977
                ],
                [
                  104.7785491950001,
                  -2.955529212999977
                ],
                [
                  104.77863311700008,
                  -2.95510745099989
                ],
                [
                  104.77895355200008,
                  -2.954534291999892
                ],
                [
                  104.77953338600008,
                  -2.953508615999965
                ],
                [
                  104.77966308500004,
                  -2.953592061999927
                ],
                [
                  104.77980804400005,
                  -2.953474521999965
                ],
                [
                  104.78079986600005,
                  -2.953057764999869
                ],
                [
                  104.7816391,
                  -2.952138899999966
                ],
                [
                  104.78152465700009,
                  -2.951844215999927
                ],
                [
                  104.78219604600008,
                  -2.951580523999951
                ],
                [
                  104.78240966800018,
                  -2.951342819999979
                ],
                [
                  104.78210449200014,
                  -2.950504779999903
                ],
                [
                  104.78280639700006,
                  -2.950471878999963
                ],
                [
                  104.78307342500011,
                  -2.950317858999938
                ],
                [
                  104.78308105500008,
                  -2.950056791999884
                ],
                [
                  104.78315734900013,
                  -2.950073242999906
                ],
                [
                  104.783760072,
                  -2.950239419999889
                ],
                [
                  104.78398132200005,
                  -2.950301170999978
                ],
                [
                  104.78477478000013,
                  -2.950473068999884
                ],
                [
                  104.78546905500014,
                  -2.950689315999909
                ],
                [
                  104.785606384,
                  -2.950720785999863
                ],
                [
                  104.7857589730001,
                  -2.951054095999893
                ],
                [
                  104.78787231500019,
                  -2.955887794999967
                ],
                [
                  104.78910064700005,
                  -2.956840991999968
                ],
                [
                  104.7891693110002,
                  -2.956915855999966
                ],
                [
                  104.79029846100019,
                  -2.957769630999906
                ],
                [
                  104.79089355400015,
                  -2.958229779999954
                ],
                [
                  104.79119873000019,
                  -2.958927629999948
                ],
                [
                  104.79139709500015,
                  -2.959213255999884
                ],
                [
                  104.79157257100007,
                  -2.959587810999949
                ],
                [
                  104.79322052000009,
                  -2.961356400999932
                ],
                [
                  104.7930679330002,
                  -2.962107658999912
                ],
                [
                  104.7929992670002,
                  -2.962639568999975
                ],
                [
                  104.7928619380001,
                  -2.963264702999822
                ],
                [
                  104.79249572800018,
                  -2.964876413999946
                ],
                [
                  104.79258728000013,
                  -2.964903592999974
                ],
                [
                  104.79255676200006,
                  -2.965086935999977
                ],
                [
                  104.79270935099998,
                  -2.965104101999941
                ],
                [
                  104.79360199000007,
                  -2.965229510999961
                ],
                [
                  104.79383087100007,
                  -2.965276954999979
                ],
                [
                  104.79581451400014,
                  -2.965875385999936
                ],
                [
                  104.79566955500013,
                  -2.966853141999877
                ],
                [
                  104.79563903800005,
                  -2.967619894999928
                ],
                [
                  104.79558563300014,
                  -2.968270062999977
                ],
                [
                  104.79557037300015,
                  -2.968467949999933
                ],
                [
                  104.79553222600009,
                  -2.968802689999904
                ],
                [
                  104.79551696800007,
                  -2.968967437999936
                ],
                [
                  104.7954864510001,
                  -2.969122885999923
                ],
                [
                  104.79563903800005,
                  -2.969909429999916
                ],
                [
                  104.795768737,
                  -2.971497535999958
                ],
                [
                  104.79575347899998,
                  -2.971668004999913
                ],
                [
                  104.79550933800016,
                  -2.972038744999963
                ],
                [
                  104.79524993800015,
                  -2.972379207999893
                ],
                [
                  104.79492187500011,
                  -2.972845076999874
                ],
                [
                  104.79485321100009,
                  -2.972890138999958
                ],
                [
                  104.79481506300016,
                  -2.973406313999874
                ],
                [
                  104.79464721700015,
                  -2.974520445999872
                ],
                [
                  104.79457092300004,
                  -2.97518587199994
                ],
                [
                  104.79454040500019,
                  -2.976220607999949
                ],
                [
                  104.79444885300006,
                  -2.976740597999878
                ],
                [
                  104.79438018700006,
                  -2.977109431999963
                ],
                [
                  104.79455566500019,
                  -2.977637766999976
                ],
                [
                  104.79462432899999,
                  -2.977935075999937
                ],
                [
                  104.79444885300006,
                  -2.978969811999946
                ],
                [
                  104.79425048800005,
                  -2.97933149399995
                ],
                [
                  104.7943573010001,
                  -2.979547023999942
                ],
                [
                  104.79437255900012,
                  -2.979643105999969
                ],
                [
                  104.7951202390002,
                  -2.980928182999889
                ],
                [
                  104.79566192700008,
                  -2.981716870999946
                ],
                [
                  104.79557037300015,
                  -2.982977866999931
                ],
                [
                  104.79570770200002,
                  -2.984452485999896
                ],
                [
                  104.79951477000009,
                  -2.984447478999925
                ],
                [
                  104.802375794,
                  -2.984304188999886
                ],
                [
                  104.80250549300018,
                  -2.984265804999893
                ],
                [
                  104.80332946900012,
                  -2.984030245999975
                ],
                [
                  104.81227874800015,
                  -2.978903055999922
                ],
                [
                  104.81288146900016,
                  -2.978487013999882
                ],
                [
                  104.8161621100001,
                  -2.976786851999975
                ],
                [
                  104.81641387900004,
                  -2.976655720999872
                ],
                [
                  104.81878662100007,
                  -2.976124047999861
                ],
                [
                  104.81900787400019,
                  -2.976074218999827
                ],
                [
                  104.8191375720001,
                  -2.976227997999956
                ],
                [
                  104.81948852600016,
                  -2.976195334999886
                ],
                [
                  104.81977081200006,
                  -2.97621726999995
                ],
                [
                  104.81984710799998,
                  -2.976293801999873
                ],
                [
                  104.81966400200014,
                  -2.976506948999941
                ],
                [
                  104.81969451800018,
                  -2.976697920999925
                ],
                [
                  104.8197021490002,
                  -2.976949451999928
                ],
                [
                  104.8194351190001,
                  -2.978085994999901
                ],
                [
                  104.81934356699998,
                  -2.978293417999964
                ],
                [
                  104.81936645500008,
                  -2.978363275999925
                ],
                [
                  104.8192672730001,
                  -2.979187011999898
                ],
                [
                  104.8192062380001,
                  -2.979700325999886
                ],
                [
                  104.81909942600015,
                  -2.980571746999885
                ],
                [
                  104.82121276900011,
                  -2.980475424999895
                ],
                [
                  104.8219375610002,
                  -2.980569601999889
                ],
                [
                  104.82170105000012,
                  -2.981084107999834
                ],
                [
                  104.82247924900008,
                  -2.981021164999959
                ],
                [
                  104.82171630800013,
                  -2.985655544999872
                ],
                [
                  104.82169342000014,
                  -2.985790490999932
                ],
                [
                  104.82103729300013,
                  -2.985773085999938
                ],
                [
                  104.82040405200007,
                  -2.985883950999948
                ],
                [
                  104.81818390000007,
                  -2.985798596999928
                ],
                [
                  104.81803894100005,
                  -2.985792873999969
                ],
                [
                  104.81793975800008,
                  -2.985792873999969
                ],
                [
                  104.81514740000017,
                  -2.985702751999952
                ],
                [
                  104.80326080300011,
                  -2.985560892999956
                ],
                [
                  104.80027008100012,
                  -2.985525130999918
                ],
                [
                  104.79962921100002,
                  -2.985488651999844
                ],
                [
                  104.79090118400006,
                  -2.984257459999924
                ],
                [
                  104.78636169400016,
                  -2.983617304999882
                ],
                [
                  104.78338623000019,
                  -2.983197926999935
                ],
                [
                  104.78218078600008,
                  -2.983087301999944
                ],
                [
                  104.78101348800016,
                  -2.982923506999953
                ],
                [
                  104.77862548899998,
                  -2.98258614599996
                ],
                [
                  104.7777252190001,
                  -2.982459067999912
                ]
              ]
            ]
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "NAME_3": "Ilir Timur III",
          "NAME_4": "10 Ilir",
          "ID": 14,
          "STUNTING": 22,
          "WASTING":36,
          "LAT": 104.76966094900007,
          "LONG": -2.985002039999927
        },
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [
                  104.76966094900007,
                  -2.985002039999927
                ],
                [
                  104.76891326900005,
                  -2.984101534999979
                ],
                [
                  104.76821136600012,
                  -2.983400581999831
                ],
                [
                  104.76803588799999,
                  -2.983275890999892
                ],
                [
                  104.7679595940001,
                  -2.983208894999962
                ],
                [
                  104.76774597200017,
                  -2.982914446999928
                ],
                [
                  104.7674026480002,
                  -2.982547043999887
                ],
                [
                  104.7673339850001,
                  -2.982452154999976
                ],
                [
                  104.7669601450001,
                  -2.982197045999897
                ],
                [
                  104.76676178000008,
                  -2.98202943799987
                ],
                [
                  104.76652526900017,
                  -2.981876134999879
                ],
                [
                  104.76622009300013,
                  -2.981551884999874
                ],
                [
                  104.7659683230001,
                  -2.981322526999918
                ],
                [
                  104.7655792230002,
                  -2.9816370019999
                ],
                [
                  104.76541137700019,
                  -2.981775760999938
                ],
                [
                  104.76523590100004,
                  -2.981566190999843
                ],
                [
                  104.76516723600014,
                  -2.981587646999913
                ],
                [
                  104.76499939000013,
                  -2.981296300999873
                ],
                [
                  104.76487732000015,
                  -2.981160401999944
                ],
                [
                  104.76473999100006,
                  -2.980939626999941
                ],
                [
                  104.76458740300006,
                  -2.980802534999896
                ],
                [
                  104.76481628400006,
                  -2.98045802099989
                ],
                [
                  104.76498413200011,
                  -2.979864120999878
                ],
                [
                  104.76498413200011,
                  -2.97959732899983
                ],
                [
                  104.76504516500006,
                  -2.979356526999879
                ],
                [
                  104.76486968900014,
                  -2.97905898099998
                ],
                [
                  104.76456451500007,
                  -2.978539703999957
                ],
                [
                  104.76448059100011,
                  -2.978578328999959
                ],
                [
                  104.76415252800001,
                  -2.978759764999893
                ],
                [
                  104.7639923100001,
                  -2.978843210999969
                ],
                [
                  104.76366424500003,
                  -2.978444814999932
                ],
                [
                  104.76339721700015,
                  -2.978093147999971
                ],
                [
                  104.76345062300015,
                  -2.978013276999945
                ],
                [
                  104.76354980500014,
                  -2.977950810999914
                ],
                [
                  104.76332092200016,
                  -2.97768211399989
                ],
                [
                  104.76312256000006,
                  -2.977394817999937
                ],
                [
                  104.7636566170001,
                  -2.977162121999868
                ],
                [
                  104.76372528100012,
                  -2.977129935999869
                ],
                [
                  104.7637176500001,
                  -2.976901529999964
                ],
                [
                  104.76374816900005,
                  -2.976776123999969
                ],
                [
                  104.76387786800007,
                  -2.976445673999933
                ],
                [
                  104.76388549800015,
                  -2.976361274999931
                ],
                [
                  104.76380157400013,
                  -2.976313114999869
                ],
                [
                  104.76374816900005,
                  -2.976217507999934
                ],
                [
                  104.76333618200016,
                  -2.976025343999936
                ],
                [
                  104.76325225800014,
                  -2.975783824999951
                ],
                [
                  104.76030731300011,
                  -2.975505589999955
                ],
                [
                  104.75968933100012,
                  -2.97549056899993
                ],
                [
                  104.75956726100014,
                  -2.975448846999939
                ],
                [
                  104.75965118500017,
                  -2.975229501999934
                ],
                [
                  104.75968933100012,
                  -2.974619627999971
                ],
                [
                  104.75966644300019,
                  -2.974191664999921
                ],
                [
                  104.75968170100003,
                  -2.973433016999934
                ],
                [
                  104.75961303800011,
                  -2.973117349999939
                ],
                [
                  104.75943756200019,
                  -2.972367286999884
                ],
                [
                  104.76010894800015,
                  -2.972342728999877
                ],
                [
                  104.7602310170002,
                  -2.97239136599984
                ],
                [
                  104.76109314000001,
                  -2.970263241999874
                ],
                [
                  104.76081848200005,
                  -2.970000266999875
                ],
                [
                  104.76051330500007,
                  -2.96946692399996
                ],
                [
                  104.76038360600012,
                  -2.969092368999895
                ],
                [
                  104.76034546000017,
                  -2.968973397999832
                ],
                [
                  104.76027679400016,
                  -2.968778371999861
                ],
                [
                  104.76034546000017,
                  -2.967717646999915
                ],
                [
                  104.76029205200018,
                  -2.967209337999975
                ],
                [
                  104.75961303800011,
                  -2.965881585999966
                ],
                [
                  104.75978851400009,
                  -2.964872120999928
                ],
                [
                  104.75973510800009,
                  -2.964751719999924
                ],
                [
                  104.75954437200011,
                  -2.964285850999943
                ],
                [
                  104.75954437200011,
                  -2.964100359999975
                ],
                [
                  104.75952911400009,
                  -2.963203190999877
                ],
                [
                  104.75962066600005,
                  -2.962222814999961
                ],
                [
                  104.75997924800004,
                  -2.962114809999889
                ],
                [
                  104.76011657700013,
                  -2.961909769999977
                ],
                [
                  104.76015472300008,
                  -2.961840391999942
                ],
                [
                  104.76016998400002,
                  -2.961685656999975
                ],
                [
                  104.76036071800019,
                  -2.961560487999861
                ],
                [
                  104.7603912350001,
                  -2.961459158999901
                ],
                [
                  104.76040649500015,
                  -2.961325167999917
                ],
                [
                  104.76046753000014,
                  -2.961211681999941
                ],
                [
                  104.76071166900016,
                  -2.961050031999889
                ],
                [
                  104.76079559300018,
                  -2.960762500999977
                ],
                [
                  104.76076507700014,
                  -2.960686206999981
                ],
                [
                  104.76084137000015,
                  -2.960140226999954
                ],
                [
                  104.76081085200013,
                  -2.960036991999914
                ],
                [
                  104.76084137000015,
                  -2.959671734999972
                ],
                [
                  104.76061248700012,
                  -2.959408282999959
                ],
                [
                  104.76045227000009,
                  -2.95930528599996
                ],
                [
                  104.76037597600003,
                  -2.959183214999882
                ],
                [
                  104.76016998400002,
                  -2.958960531999935
                ],
                [
                  104.76000976600011,
                  -2.958708524999963
                ],
                [
                  104.75983429000013,
                  -2.958429096999851
                ],
                [
                  104.75978088300008,
                  -2.958275794999963
                ],
                [
                  104.75969696000016,
                  -2.958140372999935
                ],
                [
                  104.75968170100003,
                  -2.958020924999914
                ],
                [
                  104.75963592600004,
                  -2.957872867999924
                ],
                [
                  104.75916290200018,
                  -2.957439660999967
                ],
                [
                  104.75913238500004,
                  -2.957336187999942
                ],
                [
                  104.75926971400014,
                  -2.957130670999959
                ],
                [
                  104.7592391980001,
                  -2.956815958999869
                ],
                [
                  104.75919342100013,
                  -2.956629274999955
                ],
                [
                  104.75952911400009,
                  -2.955687998999963
                ],
                [
                  104.7591705320001,
                  -2.955189704999952
                ],
                [
                  104.75926971400014,
                  -2.955047845999957
                ],
                [
                  104.75933075000006,
                  -2.954817294999941
                ],
                [
                  104.759521484,
                  -2.954653739999912
                ],
                [
                  104.75975799500014,
                  -2.954007863999948
                ],
                [
                  104.75945282000004,
                  -2.953283547999888
                ],
                [
                  104.75935363800016,
                  -2.952535152999872
                ],
                [
                  104.75922393700017,
                  -2.952116488999877
                ],
                [
                  104.75654602100013,
                  -2.952373265999881
                ],
                [
                  104.75515747100013,
                  -2.952506063999977
                ],
                [
                  104.75495147700019,
                  -2.952491043999942
                ],
                [
                  104.7548141480001,
                  -2.952175378999868
                ],
                [
                  104.75479125900006,
                  -2.951942204999909
                ],
                [
                  104.75476837100013,
                  -2.951679229999911
                ],
                [
                  104.75527954000006,
                  -2.950996398999905
                ],
                [
                  104.75521087700014,
                  -2.950770139999861
                ],
                [
                  104.75497436500012,
                  -2.950123786999939
                ],
                [
                  104.7548370360002,
                  -2.949491022999894
                ],
                [
                  104.75476074300019,
                  -2.949132203999966
                ],
                [
                  104.75469970800003,
                  -2.948838709999904
                ],
                [
                  104.757530213,
                  -2.948192119999874
                ],
                [
                  104.75786590600018,
                  -2.948077678999937
                ],
                [
                  104.75806427000003,
                  -2.948040484999979
                ],
                [
                  104.75796508800005,
                  -2.947563884999965
                ],
                [
                  104.7578811640002,
                  -2.947186469999906
                ],
                [
                  104.75755310100016,
                  -2.945638179999946
                ],
                [
                  104.75869751000005,
                  -2.94524335899996
                ],
                [
                  104.7594299320001,
                  -2.944976328999928
                ],
                [
                  104.7594299320001,
                  -2.944329022999909
                ],
                [
                  104.75938415500013,
                  -2.944173573999933
                ],
                [
                  104.75941467300015,
                  -2.943978071999936
                ],
                [
                  104.75945282000004,
                  -2.943861961999971
                ],
                [
                  104.7593612660001,
                  -2.943545578999931
                ],
                [
                  104.7601623540001,
                  -2.943217515999947
                ],
                [
                  104.76042938100011,
                  -2.942948340999976
                ],
                [
                  104.76068115300012,
                  -2.941384552999978
                ],
                [
                  104.76061248700012,
                  -2.941242455999884
                ],
                [
                  104.7603683480001,
                  -2.940838097999972
                ],
                [
                  104.75992584200009,
                  -2.940256357999829
                ],
                [
                  104.75976562500006,
                  -2.939877986999875
                ],
                [
                  104.759521484,
                  -2.93921852099993
                ],
                [
                  104.7586822520002,
                  -2.939342974999931
                ],
                [
                  104.75846862700001,
                  -2.939375161999919
                ],
                [
                  104.75798797700003,
                  -2.939445494999973
                ],
                [
                  104.75786590600018,
                  -2.936880826999868
                ],
                [
                  104.75790405300006,
                  -2.936771391999969
                ],
                [
                  104.75790405300006,
                  -2.936464309999963
                ],
                [
                  104.75814056400014,
                  -2.936358927999947
                ],
                [
                  104.75877380400016,
                  -2.935196637999979
                ],
                [
                  104.75914764400017,
                  -2.935010671999919
                ],
                [
                  104.75888061600011,
                  -2.934937237999975
                ],
                [
                  104.75881195000011,
                  -2.934813022999947
                ],
                [
                  104.75880432200017,
                  -2.934682845999816
                ],
                [
                  104.7588729860002,
                  -2.934589861999939
                ],
                [
                  104.75890350400005,
                  -2.934408425999948
                ],
                [
                  104.75894927900003,
                  -2.934194325999897
                ],
                [
                  104.75904083300014,
                  -2.934035300999881
                ],
                [
                  104.7590789800002,
                  -2.933821678999948
                ],
                [
                  104.76155853200015,
                  -2.934134245999871
                ],
                [
                  104.7619857790001,
                  -2.933302878999939
                ],
                [
                  104.7622146600001,
                  -2.932773589999954
                ],
                [
                  104.76229858400012,
                  -2.932687042999873
                ],
                [
                  104.76238250800014,
                  -2.932526825999958
                ],
                [
                  104.76303100600012,
                  -2.932737349999968
                ],
                [
                  104.76332092200016,
                  -2.932638405999853
                ],
                [
                  104.76464843700012,
                  -2.932877063999968
                ],
                [
                  104.76469421400009,
                  -2.932790994999948
                ],
                [
                  104.76496124300007,
                  -2.932892082999956
                ],
                [
                  104.76493835500014,
                  -2.93301796899982
                ],
                [
                  104.76493835500014,
                  -2.933105467999951
                ],
                [
                  104.76535797200012,
                  -2.933356284999832
                ],
                [
                  104.76548767100007,
                  -2.933397530999969
                ],
                [
                  104.76556396400008,
                  -2.933221339999932
                ],
                [
                  104.76567077700014,
                  -2.93294739799984
                ],
                [
                  104.7659759510002,
                  -2.93232583899993
                ],
                [
                  104.76845550500019,
                  -2.93140745099987
                ],
                [
                  104.76874542299998,
                  -2.931287049999867
                ],
                [
                  104.76907348600002,
                  -2.93134045499994
                ],
                [
                  104.76978302000015,
                  -2.931430578999937
                ],
                [
                  104.77001190200008,
                  -2.931409357999939
                ],
                [
                  104.76978302000015,
                  -2.932481287999906
                ],
                [
                  104.76935577400013,
                  -2.933721780999974
                ],
                [
                  104.76892089900014,
                  -2.934736728999894
                ],
                [
                  104.76853942899999,
                  -2.935644388999947
                ],
                [
                  104.76838684100017,
                  -2.93609022999982
                ],
                [
                  104.7674179080002,
                  -2.93903970599996
                ],
                [
                  104.76738739000018,
                  -2.939142702999959
                ],
                [
                  104.76734161300004,
                  -2.940653800999939
                ],
                [
                  104.76731872600016,
                  -2.942071437999971
                ],
                [
                  104.76728820800014,
                  -2.942672967999954
                ],
                [
                  104.7672805790001,
                  -2.942751884999893
                ],
                [
                  104.76734924300013,
                  -2.944136856999933
                ],
                [
                  104.76737213100006,
                  -2.944338083999867
                ],
                [
                  104.76765442000004,
                  -2.946408510999845
                ],
                [
                  104.76793670600011,
                  -2.948501823999948
                ],
                [
                  104.76818847700008,
                  -2.950120686999924
                ],
                [
                  104.76930236900006,
                  -2.949812650999945
                ],
                [
                  104.7709655770002,
                  -2.949490069999968
                ],
                [
                  104.77120971600004,
                  -2.949456213999952
                ],
                [
                  104.7723770140002,
                  -2.949292898999943
                ],
                [
                  104.7742385860002,
                  -2.949161767999897
                ],
                [
                  104.77525329600013,
                  -2.949111459999926
                ],
                [
                  104.77536773600019,
                  -2.949107169999934
                ],
                [
                  104.77571106000016,
                  -2.949086665999914
                ],
                [
                  104.77769470300007,
                  -2.948955534999925
                ],
                [
                  104.77916717400012,
                  -2.949276447999978
                ],
                [
                  104.78042602600004,
                  -2.949510811999914
                ],
                [
                  104.78308105500008,
                  -2.950056791999884
                ],
                [
                  104.78307342500011,
                  -2.950317858999938
                ],
                [
                  104.78280639700006,
                  -2.950471878999963
                ],
                [
                  104.78210449200014,
                  -2.950504779999903
                ],
                [
                  104.78240966800018,
                  -2.951342819999979
                ],
                [
                  104.78219604600008,
                  -2.951580523999951
                ],
                [
                  104.78152465700009,
                  -2.951844215999927
                ],
                [
                  104.7816391,
                  -2.952138899999966
                ],
                [
                  104.78079986600005,
                  -2.953057764999869
                ],
                [
                  104.77980804400005,
                  -2.953474521999965
                ],
                [
                  104.77966308500004,
                  -2.953592061999927
                ],
                [
                  104.77953338600008,
                  -2.953508615999965
                ],
                [
                  104.77895355200008,
                  -2.954534291999892
                ],
                [
                  104.77863311700008,
                  -2.95510745099989
                ],
                [
                  104.7785491950001,
                  -2.955529212999977
                ],
                [
                  104.7785491950001,
                  -2.956192731999977
                ],
                [
                  104.77870178199998,
                  -2.956221580999966
                ],
                [
                  104.77865600500002,
                  -2.95641970499986
                ],
                [
                  104.7784805300002,
                  -2.956392288999893
                ],
                [
                  104.77839660600017,
                  -2.956589220999945
                ],
                [
                  104.77811432000004,
                  -2.956621646999963
                ],
                [
                  104.77779388500011,
                  -2.956733226999972
                ],
                [
                  104.77774810800014,
                  -2.956884621999961
                ],
                [
                  104.7776489260001,
                  -2.957201481999959
                ],
                [
                  104.77761077900004,
                  -2.957824467999899
                ],
                [
                  104.77769470300007,
                  -2.958296297999937
                ],
                [
                  104.77791595500008,
                  -2.958216188999927
                ],
                [
                  104.77812957800006,
                  -2.959048985999971
                ],
                [
                  104.77796936000016,
                  -2.959041357999979
                ],
                [
                  104.77784729000018,
                  -2.959080456999914
                ],
                [
                  104.77769470300007,
                  -2.959060190999935
                ],
                [
                  104.7776489260001,
                  -2.959335088999921
                ],
                [
                  104.77738189800004,
                  -2.959432123999875
                ],
                [
                  104.77706909099999,
                  -2.959496021999939
                ],
                [
                  104.777137757,
                  -2.959861993999937
                ],
                [
                  104.77731323200004,
                  -2.960116623999852
                ],
                [
                  104.77718353300008,
                  -2.960266350999973
                ],
                [
                  104.77720642000008,
                  -2.960401296999976
                ],
                [
                  104.77729034400011,
                  -2.960516451999979
                ],
                [
                  104.77718353300008,
                  -2.960651397999868
                ],
                [
                  104.77706146300011,
                  -2.960796354999957
                ],
                [
                  104.77728271600017,
                  -2.961076258999924
                ],
                [
                  104.77739715600006,
                  -2.961246250999977
                ],
                [
                  104.77728271600017,
                  -2.961368798999899
                ],
                [
                  104.77711486800013,
                  -2.961626051999929
                ],
                [
                  104.77694702200012,
                  -2.961810827999955
                ],
                [
                  104.77683258000008,
                  -2.96192073799989
                ],
                [
                  104.77668762300004,
                  -2.962078809999923
                ],
                [
                  104.7764282230001,
                  -2.962311028999977
                ],
                [
                  104.77598571800019,
                  -2.962625980999974
                ],
                [
                  104.77561950800003,
                  -2.962301252999964
                ],
                [
                  104.77500915500008,
                  -2.961980580999978
                ],
                [
                  104.77441406200018,
                  -2.962535141999922
                ],
                [
                  104.77455139100005,
                  -2.962670087999925
                ],
                [
                  104.77436065600017,
                  -2.962879894999958
                ],
                [
                  104.7741012570001,
                  -2.963222980999944
                ],
                [
                  104.77381896900005,
                  -2.962984562999907
                ],
                [
                  104.77303314200009,
                  -2.962532996999926
                ],
                [
                  104.77285766600016,
                  -2.962781667999934
                ],
                [
                  104.77300262500017,
                  -2.963346004999892
                ],
                [
                  104.77300262500017,
                  -2.963943242999903
                ],
                [
                  104.77278900100009,
                  -2.96447419999987
                ],
                [
                  104.77252197299998,
                  -2.964912412999979
                ],
                [
                  104.7723922740002,
                  -2.965197800999874
                ],
                [
                  104.77235412500016,
                  -2.965635776999875
                ],
                [
                  104.77245330700003,
                  -2.966498851999972
                ],
                [
                  104.77262878500017,
                  -2.966883897999935
                ],
                [
                  104.77287292400018,
                  -2.967082976999905
                ],
                [
                  104.77458953900003,
                  -2.967780351999977
                ],
                [
                  104.77504730100003,
                  -2.968012808999845
                ],
                [
                  104.77544403000013,
                  -2.968388555999923
                ],
                [
                  104.77498626800008,
                  -2.96947550699997
                ],
                [
                  104.77489471400014,
                  -2.970106601999873
                ],
                [
                  104.77487182600004,
                  -2.97047114399993
                ],
                [
                  104.77452850400005,
                  -2.970456360999947
                ],
                [
                  104.77447509700016,
                  -2.970796108999934
                ],
                [
                  104.77484893700017,
                  -2.970837830999926
                ],
                [
                  104.77486419700017,
                  -2.971020221999879
                ],
                [
                  104.77518463200016,
                  -2.971114158999853
                ],
                [
                  104.77519989100006,
                  -2.971517324999979
                ],
                [
                  104.77510070800014,
                  -2.971577642999932
                ],
                [
                  104.77506256200019,
                  -2.971673249999924
                ],
                [
                  104.77507019000006,
                  -2.971785306999948
                ],
                [
                  104.77517700200002,
                  -2.971834897999884
                ],
                [
                  104.77516937200011,
                  -2.971910715999968
                ],
                [
                  104.77510070800014,
                  -2.971969843999943
                ],
                [
                  104.775016785,
                  -2.972108362999961
                ],
                [
                  104.77500915500008,
                  -2.972187518999931
                ],
                [
                  104.77494049100011,
                  -2.972243308999907
                ],
                [
                  104.77478790400016,
                  -2.972282885999903
                ],
                [
                  104.77458190900012,
                  -2.972423552999942
                ],
                [
                  104.77450561600011,
                  -2.972549675999971
                ],
                [
                  104.77453613200015,
                  -2.972702264999896
                ],
                [
                  104.77445983900014,
                  -2.972808597999972
                ],
                [
                  104.77440643300014,
                  -2.972921370999813
                ],
                [
                  104.77442932200017,
                  -2.973040818999948
                ],
                [
                  104.77431488000013,
                  -2.973180054999887
                ],
                [
                  104.77433014000013,
                  -2.973266361999947
                ],
                [
                  104.77442169200009,
                  -2.973319529999969
                ],
                [
                  104.77438354500003,
                  -2.973498820999964
                ],
                [
                  104.77429962100018,
                  -2.973518608999939
                ],
                [
                  104.77427673300008,
                  -2.973618268999928
                ],
                [
                  104.7743072510001,
                  -2.974036455999965
                ],
                [
                  104.77439117500012,
                  -2.974169253999946
                ],
                [
                  104.77436065600017,
                  -2.974248885999941
                ],
                [
                  104.77383422900004,
                  -2.974461077999933
                ],
                [
                  104.77391815100009,
                  -2.974694251999949
                ],
                [
                  104.77401733300007,
                  -2.975103139999931
                ],
                [
                  104.77407836800006,
                  -2.97526335799995
                ],
                [
                  104.77416229200009,
                  -2.975321530999963
                ],
                [
                  104.7743072510001,
                  -2.975292443999876
                ],
                [
                  104.77438354500003,
                  -2.975466964999953
                ],
                [
                  104.77439880300005,
                  -2.975583314999881
                ],
                [
                  104.77455902100013,
                  -2.975896119999902
                ],
                [
                  104.77471923799999,
                  -2.976085185999921
                ],
                [
                  104.77474975500013,
                  -2.976165055999957
                ],
                [
                  104.77462768600009,
                  -2.976812362999965
                ],
                [
                  104.77463531400014,
                  -2.976957797999944
                ],
                [
                  104.77482605100005,
                  -2.977270601999976
                ],
                [
                  104.77480316200018,
                  -2.977398155999936
                ],
                [
                  104.77468872200012,
                  -2.977426051999942
                ],
                [
                  104.77442932200017,
                  -2.977476834999948
                ],
                [
                  104.77428436300016,
                  -2.977607727999953
                ],
                [
                  104.77397155800014,
                  -2.977949380999974
                ],
                [
                  104.77383422900004,
                  -2.978260040999942
                ],
                [
                  104.77349853600009,
                  -2.978727577999962
                ],
                [
                  104.77327728300014,
                  -2.978785752999897
                ],
                [
                  104.77320861800007,
                  -2.978909253999916
                ],
                [
                  104.77319335900012,
                  -2.979046344999972
                ],
                [
                  104.77301025300011,
                  -2.979222058999937
                ],
                [
                  104.77292633100006,
                  -2.979222058999937
                ],
                [
                  104.77280426100009,
                  -2.979316472999926
                ],
                [
                  104.77271270700015,
                  -2.979381798999952
                ],
                [
                  104.77264404300018,
                  -2.97953176499982
                ],
                [
                  104.772605897,
                  -2.979844569999955
                ],
                [
                  104.772605897,
                  -2.980091809999976
                ],
                [
                  104.77275848400012,
                  -2.98049902899993
                ],
                [
                  104.77283477800006,
                  -2.980622768999922
                ],
                [
                  104.77404022200011,
                  -2.98366022099998
                ],
                [
                  104.774093629,
                  -2.984001159999934
                ],
                [
                  104.77272033700007,
                  -2.984730243999877
                ],
                [
                  104.7723770140002,
                  -2.984936951999941
                ],
                [
                  104.77049255300011,
                  -2.986006020999923
                ],
                [
                  104.77026367200011,
                  -2.985725640999931
                ],
                [
                  104.76966094900007,
                  -2.985002039999927
                ]
              ]
            ]
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "NAME_3": "Bukit Kecil",
          "NAME_4": "19 Ilir",
          "ID": 15,
          "STUNTING": 32,
          "WASTING":20,
          "LAT": 104.75778961200007,
          "LONG": -2.995309114999884
        },
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [
                  104.75778961200007,
                  -2.995309114999884
                ],
                [
                  104.75788879400011,
                  -2.995252131999962
                ],
                [
                  104.75690460100014,
                  -2.993528603999891
                ],
                [
                  104.7568054190001,
                  -2.993211506999955
                ],
                [
                  104.75688171500019,
                  -2.993198155999949
                ],
                [
                  104.756538391,
                  -2.992495775999885
                ],
                [
                  104.75634765700005,
                  -2.992130755999881
                ],
                [
                  104.75620269800004,
                  -2.991760253999871
                ],
                [
                  104.75522613500016,
                  -2.992158174999929
                ],
                [
                  104.75510406400008,
                  -2.992257594999899
                ],
                [
                  104.75410461500007,
                  -2.992776870999876
                ],
                [
                  104.75386810400016,
                  -2.992825030999882
                ],
                [
                  104.75369262800018,
                  -2.992313861999946
                ],
                [
                  104.75363159200009,
                  -2.992259024999953
                ],
                [
                  104.75347137500012,
                  -2.992139100999964
                ],
                [
                  104.7533874510001,
                  -2.99209260899994
                ],
                [
                  104.75330352700007,
                  -2.992002009999908
                ],
                [
                  104.7531967170001,
                  -2.991902827999979
                ],
                [
                  104.75307464600002,
                  -2.991764545999956
                ],
                [
                  104.7527618420001,
                  -2.991791724999928
                ],
                [
                  104.75267791800007,
                  -2.991772888999947
                ],
                [
                  104.75212097200017,
                  -2.991649388999861
                ],
                [
                  104.752052306,
                  -2.992227315999969
                ],
                [
                  104.7519607540001,
                  -2.992335080999908
                ],
                [
                  104.75115203900003,
                  -2.992451905999928
                ],
                [
                  104.75099182100013,
                  -2.992479324999977
                ],
                [
                  104.75032043500016,
                  -2.992985010999973
                ],
                [
                  104.74988555900006,
                  -2.993972301999861
                ],
                [
                  104.74975585900017,
                  -2.994275568999967
                ],
                [
                  104.74961090200014,
                  -2.9942669859999
                ],
                [
                  104.74901580900001,
                  -2.993951796999852
                ],
                [
                  104.74872589000012,
                  -2.993833540999901
                ],
                [
                  104.74821472100018,
                  -2.993672132999961
                ],
                [
                  104.7479248050002,
                  -2.993938922999916
                ],
                [
                  104.74759674000012,
                  -2.993943928999897
                ],
                [
                  104.74712371800007,
                  -2.993716715999938
                ],
                [
                  104.74703979400005,
                  -2.993662357999881
                ],
                [
                  104.74681854300013,
                  -2.993468760999917
                ],
                [
                  104.74641418400017,
                  -2.993143320999934
                ],
                [
                  104.74629974400011,
                  -2.993018387999882
                ],
                [
                  104.74600982600009,
                  -2.992718218999926
                ],
                [
                  104.7458038330002,
                  -2.99275278999994
                ],
                [
                  104.74524688700012,
                  -2.993380546999902
                ],
                [
                  104.74463653600014,
                  -2.992935655999872
                ],
                [
                  104.74440002500006,
                  -2.992753267999888
                ],
                [
                  104.74417877200011,
                  -2.992581844999904
                ],
                [
                  104.74385070900007,
                  -2.992017030999818
                ],
                [
                  104.74378204400017,
                  -2.991351841999915
                ],
                [
                  104.7437515270002,
                  -2.991263150999941
                ],
                [
                  104.74370575000006,
                  -2.990895509999916
                ],
                [
                  104.74361419600012,
                  -2.989467620999903
                ],
                [
                  104.74357604900007,
                  -2.989151000999925
                ],
                [
                  104.74357604900007,
                  -2.988993643999947
                ],
                [
                  104.74356079100005,
                  -2.988877057999957
                ],
                [
                  104.74357604900007,
                  -2.988548517999959
                ],
                [
                  104.7437362670002,
                  -2.988227605999896
                ],
                [
                  104.74400329600019,
                  -2.987739562999934
                ],
                [
                  104.74415588400018,
                  -2.987271070999952
                ],
                [
                  104.74423217700019,
                  -2.98673772799998
                ],
                [
                  104.7442398070001,
                  -2.986629962999928
                ],
                [
                  104.74433899000007,
                  -2.986099957999954
                ],
                [
                  104.74459075900018,
                  -2.984295605999876
                ],
                [
                  104.74483490000006,
                  -2.983395813999948
                ],
                [
                  104.74531555300013,
                  -2.981978892999962
                ],
                [
                  104.74630737200005,
                  -2.980212687999938
                ],
                [
                  104.74687194800003,
                  -2.979754923999963
                ],
                [
                  104.74742889400011,
                  -2.979538200999912
                ],
                [
                  104.7476577750001,
                  -2.979445218999956
                ],
                [
                  104.74777984600018,
                  -2.979393481999921
                ],
                [
                  104.74797821000004,
                  -2.979910135999944
                ],
                [
                  104.74805450400015,
                  -2.98010659199997
                ],
                [
                  104.74895477300015,
                  -2.979568719999861
                ],
                [
                  104.74922180100003,
                  -2.9798805719999
                ],
                [
                  104.74927520800009,
                  -2.980005502999916
                ],
                [
                  104.74933624400018,
                  -2.980096576999927
                ],
                [
                  104.74903869700017,
                  -2.980449675999921
                ],
                [
                  104.74911499100006,
                  -2.980525254999975
                ],
                [
                  104.74926757800017,
                  -2.980667590999929
                ],
                [
                  104.74974823000014,
                  -2.980366706999973
                ],
                [
                  104.75004577700014,
                  -2.98069596299996
                ],
                [
                  104.75062560900017,
                  -2.981103658999928
                ],
                [
                  104.75082397400018,
                  -2.981326101999969
                ],
                [
                  104.75070953300019,
                  -2.981432676999873
                ],
                [
                  104.75052642700012,
                  -2.981610774999979
                ],
                [
                  104.75074768000007,
                  -2.981938122999964
                ],
                [
                  104.75080871500006,
                  -2.982039927999949
                ],
                [
                  104.75110626200006,
                  -2.982105970999896
                ],
                [
                  104.75152587900004,
                  -2.982117891999906
                ],
                [
                  104.75202942000004,
                  -2.98183274299987
                ],
                [
                  104.75272369500004,
                  -2.980997084999899
                ],
                [
                  104.7528381350001,
                  -2.980771063999953
                ],
                [
                  104.75321197500011,
                  -2.980635881999945
                ],
                [
                  104.75369262800018,
                  -2.980452536999962
                ],
                [
                  104.75524139300018,
                  -2.979474305999929
                ],
                [
                  104.75723266600016,
                  -2.982373953999968
                ],
                [
                  104.7575149540001,
                  -2.982778787999962
                ],
                [
                  104.75783538700006,
                  -2.983485697999924
                ],
                [
                  104.75723266600016,
                  -2.983555793999869
                ],
                [
                  104.75726318300013,
                  -2.983782529999814
                ],
                [
                  104.7573089600001,
                  -2.984200716999965
                ],
                [
                  104.75776672400013,
                  -2.984244345999912
                ],
                [
                  104.75764465300006,
                  -2.984444617999884
                ],
                [
                  104.75733184800004,
                  -2.984840391999853
                ],
                [
                  104.75730133000019,
                  -2.985074997999902
                ],
                [
                  104.75801086500019,
                  -2.9852671619999
                ],
                [
                  104.75801086500019,
                  -2.985644816999979
                ],
                [
                  104.75790405300006,
                  -2.985884188999876
                ],
                [
                  104.75787353600009,
                  -2.986224888999914
                ],
                [
                  104.75786590600018,
                  -2.986315250999951
                ],
                [
                  104.75798797700003,
                  -2.986337421999906
                ],
                [
                  104.75809478700018,
                  -2.986357927999904
                ],
                [
                  104.758110047,
                  -2.986530540999979
                ],
                [
                  104.75806427000003,
                  -2.986842632999981
                ],
                [
                  104.75804901200001,
                  -2.986932039999886
                ],
                [
                  104.75830841200019,
                  -2.986943959999905
                ],
                [
                  104.75853729200014,
                  -2.986960171999897
                ],
                [
                  104.7589416510001,
                  -2.986960171999897
                ],
                [
                  104.75930023100011,
                  -2.986834047999878
                ],
                [
                  104.75943756200019,
                  -2.986915588999977
                ],
                [
                  104.75952911400009,
                  -2.986910103999946
                ],
                [
                  104.75976562500006,
                  -2.986867188999952
                ],
                [
                  104.75994110099998,
                  -2.98679494799984
                ],
                [
                  104.76013183700013,
                  -2.986615179999887
                ],
                [
                  104.76033019900007,
                  -2.986574172999951
                ],
                [
                  104.76103210400015,
                  -2.987757443999897
                ],
                [
                  104.76094055200019,
                  -2.98796916099991
                ],
                [
                  104.760948181,
                  -2.988194226999951
                ],
                [
                  104.76112365600005,
                  -2.988318681999942
                ],
                [
                  104.76134490900017,
                  -2.988326309999877
                ],
                [
                  104.7617950450001,
                  -2.989062784999874
                ],
                [
                  104.76224517900005,
                  -2.989806889999898
                ],
                [
                  104.76329040500019,
                  -2.991557121999961
                ],
                [
                  104.76260376000016,
                  -2.991992234999884
                ],
                [
                  104.76174163800005,
                  -2.992586374999917
                ],
                [
                  104.76137542800012,
                  -2.99298715599997
                ],
                [
                  104.76103210400015,
                  -2.993232011999964
                ],
                [
                  104.76015472300008,
                  -2.993929862999892
                ],
                [
                  104.75984954900008,
                  -2.99440193199996
                ],
                [
                  104.75975799500014,
                  -2.994456052999908
                ],
                [
                  104.75931549100011,
                  -2.99471545199998
                ],
                [
                  104.75798034600007,
                  -2.995595455999933
                ],
                [
                  104.75778961200007,
                  -2.995309114999884
                ]
              ]
            ]
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "NAME_3": "Ilir Timur I",
          "NAME_4": "13 Ilir",
          "ID": 16,
          "STUNTING": 19,
          "WASTING":15,
          "LAT": 104.746632,
          "LONG": -2.970815,
        },
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [
                  104.76112365600005,
                  -2.988318681999942
                ],
                [
                  104.760948181,
                  -2.988194226999951
                ],
                [
                  104.76094055200019,
                  -2.98796916099991
                ],
                [
                  104.76103210400015,
                  -2.987757443999897
                ],
                [
                  104.76033019900007,
                  -2.986574172999951
                ],
                [
                  104.76013183700013,
                  -2.986615179999887
                ],
                [
                  104.75994110099998,
                  -2.98679494799984
                ],
                [
                  104.75976562500006,
                  -2.986867188999952
                ],
                [
                  104.75952911400009,
                  -2.986910103999946
                ],
                [
                  104.75943756200019,
                  -2.986915588999977
                ],
                [
                  104.75930023100011,
                  -2.986834047999878
                ],
                [
                  104.7589416510001,
                  -2.986960171999897
                ],
                [
                  104.75853729200014,
                  -2.986960171999897
                ],
                [
                  104.75830841200019,
                  -2.986943959999905
                ],
                [
                  104.75804901200001,
                  -2.986932039999886
                ],
                [
                  104.75806427000003,
                  -2.986842632999981
                ],
                [
                  104.758110047,
                  -2.986530540999979
                ],
                [
                  104.75809478700018,
                  -2.986357927999904
                ],
                [
                  104.75798797700003,
                  -2.986337421999906
                ],
                [
                  104.75786590600018,
                  -2.986315250999951
                ],
                [
                  104.75787353600009,
                  -2.986224888999914
                ],
                [
                  104.75790405300006,
                  -2.985884188999876
                ],
                [
                  104.75801086500019,
                  -2.985644816999979
                ],
                [
                  104.75801086500019,
                  -2.9852671619999
                ],
                [
                  104.75730133000019,
                  -2.985074997999902
                ],
                [
                  104.75733184800004,
                  -2.984840391999853
                ],
                [
                  104.75764465300006,
                  -2.984444617999884
                ],
                [
                  104.75776672400013,
                  -2.984244345999912
                ],
                [
                  104.7573089600001,
                  -2.984200716999965
                ],
                [
                  104.75726318300013,
                  -2.983782529999814
                ],
                [
                  104.75723266600016,
                  -2.983555793999869
                ],
                [
                  104.75783538700006,
                  -2.983485697999924
                ],
                [
                  104.7575149540001,
                  -2.982778787999962
                ],
                [
                  104.75723266600016,
                  -2.982373953999968
                ],
                [
                  104.75524139300018,
                  -2.979474305999929
                ],
                [
                  104.75369262800018,
                  -2.980452536999962
                ],
                [
                  104.75321197500011,
                  -2.980635881999945
                ],
                [
                  104.7528381350001,
                  -2.980771063999953
                ],
                [
                  104.75272369500004,
                  -2.980997084999899
                ],
                [
                  104.75202942000004,
                  -2.98183274299987
                ],
                [
                  104.75152587900004,
                  -2.982117891999906
                ],
                [
                  104.75110626200006,
                  -2.982105970999896
                ],
                [
                  104.75080871500006,
                  -2.982039927999949
                ],
                [
                  104.75074768000007,
                  -2.981938122999964
                ],
                [
                  104.75052642700012,
                  -2.981610774999979
                ],
                [
                  104.75070953300019,
                  -2.981432676999873
                ],
                [
                  104.75082397400018,
                  -2.981326101999969
                ],
                [
                  104.75062560900017,
                  -2.981103658999928
                ],
                [
                  104.75004577700014,
                  -2.98069596299996
                ],
                [
                  104.74974823000014,
                  -2.980366706999973
                ],
                [
                  104.74926757800017,
                  -2.980667590999929
                ],
                [
                  104.74911499100006,
                  -2.980525254999975
                ],
                [
                  104.74903869700017,
                  -2.980449675999921
                ],
                [
                  104.74933624400018,
                  -2.980096576999927
                ],
                [
                  104.74927520800009,
                  -2.980005502999916
                ],
                [
                  104.74922180100003,
                  -2.9798805719999
                ],
                [
                  104.74895477300015,
                  -2.979568719999861
                ],
                [
                  104.74805450400015,
                  -2.98010659199997
                ],
                [
                  104.74797821000004,
                  -2.979910135999944
                ],
                [
                  104.74777984600018,
                  -2.979393481999921
                ],
                [
                  104.7476577750001,
                  -2.979445218999956
                ],
                [
                  104.74742889400011,
                  -2.979538200999912
                ],
                [
                  104.74687194800003,
                  -2.979754923999963
                ],
                [
                  104.7467956550002,
                  -2.979528664999918
                ],
                [
                  104.74666595400004,
                  -2.979170320999913
                ],
                [
                  104.74640655400003,
                  -2.978244304999976
                ],
                [
                  104.74604034400011,
                  -2.97771453799993
                ],
                [
                  104.74575805600006,
                  -2.977426528999956
                ],
                [
                  104.74558258000008,
                  -2.977203368999881
                ],
                [
                  104.74517822200005,
                  -2.976994991999959
                ],
                [
                  104.74534606900016,
                  -2.976744651999979
                ],
                [
                  104.745529175,
                  -2.976125240999977
                ],
                [
                  104.7458038330002,
                  -2.975741862999939
                ],
                [
                  104.74616241500019,
                  -2.974866866999946
                ],
                [
                  104.74544525100004,
                  -2.974302052999917
                ],
                [
                  104.74513244700006,
                  -2.973645686999873
                ],
                [
                  104.74508667000009,
                  -2.973545312999931
                ],
                [
                  104.7449340820001,
                  -2.972968577999893
                ],
                [
                  104.744880675,
                  -2.97244024299988
                ],
                [
                  104.74439239500009,
                  -2.971863508999945
                ],
                [
                  104.74364471500007,
                  -2.971370935999971
                ],
                [
                  104.74316406200018,
                  -2.970541953999941
                ],
                [
                  104.74269867000004,
                  -2.969616888999951
                ],
                [
                  104.74227142300015,
                  -2.968775986999901
                ],
                [
                  104.7419128410001,
                  -2.968364954999913
                ],
                [
                  104.74195098799999,
                  -2.96819710699998
                ],
                [
                  104.74183654700005,
                  -2.968166112999825
                ],
                [
                  104.74164581300005,
                  -2.968130825999935
                ],
                [
                  104.74111938400011,
                  -2.968016147999947
                ],
                [
                  104.74034881500006,
                  -2.967886924999902
                ],
                [
                  104.73888397200005,
                  -2.967613219999976
                ],
                [
                  104.73863220200019,
                  -2.967646835999915
                ],
                [
                  104.73764801100003,
                  -2.967716454999902
                ],
                [
                  104.737419128,
                  -2.967725037999912
                ],
                [
                  104.73583984400011,
                  -2.967825889999915
                ],
                [
                  104.73429870700005,
                  -2.967510699999934
                ],
                [
                  104.73433685300017,
                  -2.967436073999977
                ],
                [
                  104.73468017500016,
                  -2.966781377999894
                ],
                [
                  104.73482513400018,
                  -2.966494797999928
                ],
                [
                  104.73612213200016,
                  -2.964020728999913
                ],
                [
                  104.73661041300016,
                  -2.963062285999968
                ],
                [
                  104.73546600400005,
                  -2.963213920999976
                ],
                [
                  104.73527526800007,
                  -2.963236331999951
                ],
                [
                  104.73516845700004,
                  -2.962739466999949
                ],
                [
                  104.73503875700015,
                  -2.962725877999901
                ],
                [
                  104.73504638700007,
                  -2.962581633999946
                ],
                [
                  104.73502349800003,
                  -2.961838960999899
                ],
                [
                  104.734992982,
                  -2.961622953999949
                ],
                [
                  104.7343063350001,
                  -2.96165370999995
                ],
                [
                  104.73434448199998,
                  -2.961393117999933
                ],
                [
                  104.73427581800019,
                  -2.960471867999956
                ],
                [
                  104.73420715300011,
                  -2.960135221999963
                ],
                [
                  104.73405456500006,
                  -2.959931611999821
                ],
                [
                  104.73382568400007,
                  -2.959633825999958
                ],
                [
                  104.73365020900019,
                  -2.959737777999862
                ],
                [
                  104.7332916270002,
                  -2.959805488999962
                ],
                [
                  104.73307800200018,
                  -2.959516047999898
                ],
                [
                  104.73199462900016,
                  -2.959299087999966
                ],
                [
                  104.73107147300016,
                  -2.959162473999868
                ],
                [
                  104.73086547800011,
                  -2.95929694199998
                ],
                [
                  104.73063659700006,
                  -2.959082126999874
                ],
                [
                  104.73051452700008,
                  -2.958993672999952
                ],
                [
                  104.73023986900012,
                  -2.958873032999975
                ],
                [
                  104.72924804700011,
                  -2.959403991999864
                ],
                [
                  104.72853088400018,
                  -2.959909915999958
                ],
                [
                  104.72808837800017,
                  -2.959491968999941
                ],
                [
                  104.72677612400014,
                  -2.960021495999968
                ],
                [
                  104.72665405300006,
                  -2.959813356999973
                ],
                [
                  104.72655487100002,
                  -2.959841012999959
                ],
                [
                  104.72600555300005,
                  -2.960070608999956
                ],
                [
                  104.7258148190001,
                  -2.959886789999928
                ],
                [
                  104.72564697400014,
                  -2.959705828999972
                ],
                [
                  104.72552490300006,
                  -2.959567546999892
                ],
                [
                  104.72466278000007,
                  -2.960207462999904
                ],
                [
                  104.72436523400012,
                  -2.960015295999881
                ],
                [
                  104.72415161100014,
                  -2.959922313999925
                ],
                [
                  104.72325897200011,
                  -2.959367512999961
                ],
                [
                  104.72402191200013,
                  -2.958820342999957
                ],
                [
                  104.72467041000016,
                  -2.958415268999943
                ],
                [
                  104.72563171300004,
                  -2.957665205999945
                ],
                [
                  104.72612762400013,
                  -2.957309006999878
                ],
                [
                  104.72745513900009,
                  -2.956033944999888
                ],
                [
                  104.7285461420002,
                  -2.955627679999964
                ],
                [
                  104.72978973300019,
                  -2.955141067999932
                ],
                [
                  104.730880737,
                  -2.954111336999972
                ],
                [
                  104.73262024000013,
                  -2.953838823999831
                ],
                [
                  104.73348999100006,
                  -2.953898666999976
                ],
                [
                  104.73435974200004,
                  -2.953596352999909
                ],
                [
                  104.73464202800011,
                  -2.953499315999977
                ],
                [
                  104.73516845700004,
                  -2.954844950999927
                ],
                [
                  104.7362670890002,
                  -2.957764385999894
                ],
                [
                  104.73712921200018,
                  -2.959209202999887
                ],
                [
                  104.73720550500019,
                  -2.959311007999872
                ],
                [
                  104.73731231800008,
                  -2.959518432999914
                ],
                [
                  104.7381973270002,
                  -2.960800647999974
                ],
                [
                  104.73832702599998,
                  -2.960693597999978
                ],
                [
                  104.73857116700003,
                  -2.96094250699997
                ],
                [
                  104.73960113500016,
                  -2.961759567999877
                ],
                [
                  104.74026489300019,
                  -2.962306737999938
                ],
                [
                  104.74032592700007,
                  -2.96235728299996
                ],
                [
                  104.74040222200011,
                  -2.96239447499994
                ],
                [
                  104.74077606300006,
                  -2.962586641999962
                ],
                [
                  104.742599488,
                  -2.963521002999869
                ],
                [
                  104.74294280900006,
                  -2.96369624099998
                ],
                [
                  104.74642181500019,
                  -2.96545004799998
                ],
                [
                  104.74875640900007,
                  -2.96690368599991
                ],
                [
                  104.74888610800002,
                  -2.967077493999966
                ],
                [
                  104.748931885,
                  -2.966980217999946
                ],
                [
                  104.74946594200009,
                  -2.967903612999976
                ],
                [
                  104.74954986600005,
                  -2.968028543999878
                ],
                [
                  104.74959564100004,
                  -2.96812629599998
                ],
                [
                  104.74996948300009,
                  -2.968777656999976
                ],
                [
                  104.751052857,
                  -2.97112107199996
                ],
                [
                  104.75119781600017,
                  -2.971428393999929
                ],
                [
                  104.75130462600015,
                  -2.971668719999911
                ],
                [
                  104.75135040300012,
                  -2.971751450999932
                ],
                [
                  104.75228881700014,
                  -2.971208572999956
                ],
                [
                  104.7524642950001,
                  -2.971100090999926
                ],
                [
                  104.7528991710002,
                  -2.970888614999922
                ],
                [
                  104.75334930400004,
                  -2.970699308999883
                ],
                [
                  104.75354003799998,
                  -2.970610141999884
                ],
                [
                  104.75410461500007,
                  -2.970384358999866
                ],
                [
                  104.75444030800008,
                  -2.970080374999952
                ],
                [
                  104.75453185999999,
                  -2.969985485999814
                ],
                [
                  104.7549057,
                  -2.969546317999971
                ],
                [
                  104.75527191200013,
                  -2.968980788999886
                ],
                [
                  104.75577545100015,
                  -2.96833086099997
                ],
                [
                  104.75587463300019,
                  -2.968255041999896
                ],
                [
                  104.75657653800005,
                  -2.967808483999875
                ],
                [
                  104.75669097900004,
                  -2.967724561999887
                ],
                [
                  104.75739288400013,
                  -2.968404291999889
                ],
                [
                  104.75755310100016,
                  -2.96858119999996
                ],
                [
                  104.75788879400011,
                  -2.968934535999892
                ],
                [
                  104.75815582200016,
                  -2.969228982999937
                ],
                [
                  104.75827026300016,
                  -2.969353675999855
                ],
                [
                  104.75932311900004,
                  -2.971877096999833
                ],
                [
                  104.75938415500013,
                  -2.972154616999944
                ],
                [
                  104.75943756200019,
                  -2.972367286999884
                ],
                [
                  104.75961303800011,
                  -2.973117349999939
                ],
                [
                  104.75968170100003,
                  -2.973433016999934
                ],
                [
                  104.75966644300019,
                  -2.974191664999921
                ],
                [
                  104.75968933100012,
                  -2.974619627999971
                ],
                [
                  104.75965118500017,
                  -2.975229501999934
                ],
                [
                  104.75956726100014,
                  -2.975448846999939
                ],
                [
                  104.75968933100012,
                  -2.97549056899993
                ],
                [
                  104.76030731300011,
                  -2.975505589999955
                ],
                [
                  104.76325225800014,
                  -2.975783824999951
                ],
                [
                  104.76333618200016,
                  -2.976025343999936
                ],
                [
                  104.76374816900005,
                  -2.976217507999934
                ],
                [
                  104.76380157400013,
                  -2.976313114999869
                ],
                [
                  104.76388549800015,
                  -2.976361274999931
                ],
                [
                  104.76387786800007,
                  -2.976445673999933
                ],
                [
                  104.76374816900005,
                  -2.976776123999969
                ],
                [
                  104.7637176500001,
                  -2.976901529999964
                ],
                [
                  104.76372528100012,
                  -2.977129935999869
                ],
                [
                  104.7636566170001,
                  -2.977162121999868
                ],
                [
                  104.76312256000006,
                  -2.977394817999937
                ],
                [
                  104.76332092200016,
                  -2.97768211399989
                ],
                [
                  104.76354980500014,
                  -2.977950810999914
                ],
                [
                  104.76345062300015,
                  -2.978013276999945
                ],
                [
                  104.76339721700015,
                  -2.978093147999971
                ],
                [
                  104.76366424500003,
                  -2.978444814999932
                ],
                [
                  104.7639923100001,
                  -2.978843210999969
                ],
                [
                  104.76415252800001,
                  -2.978759764999893
                ],
                [
                  104.76448059100011,
                  -2.978578328999959
                ],
                [
                  104.76456451500007,
                  -2.978539703999957
                ],
                [
                  104.76486968900014,
                  -2.97905898099998
                ],
                [
                  104.76504516500006,
                  -2.979356526999879
                ],
                [
                  104.76498413200011,
                  -2.97959732899983
                ],
                [
                  104.76498413200011,
                  -2.979864120999878
                ],
                [
                  104.76481628400006,
                  -2.98045802099989
                ],
                [
                  104.76458740300006,
                  -2.980802534999896
                ],
                [
                  104.76473999100006,
                  -2.980939626999941
                ],
                [
                  104.76487732000015,
                  -2.981160401999944
                ],
                [
                  104.76499939000013,
                  -2.981296300999873
                ],
                [
                  104.76516723600014,
                  -2.981587646999913
                ],
                [
                  104.76523590100004,
                  -2.981566190999843
                ],
                [
                  104.76541137700019,
                  -2.981775760999938
                ],
                [
                  104.7655792230002,
                  -2.9816370019999
                ],
                [
                  104.7659683230001,
                  -2.981322526999918
                ],
                [
                  104.76622009300013,
                  -2.981551884999874
                ],
                [
                  104.76652526900017,
                  -2.981876134999879
                ],
                [
                  104.76676178000008,
                  -2.98202943799987
                ],
                [
                  104.7669601450001,
                  -2.982197045999897
                ],
                [
                  104.7673339850001,
                  -2.982452154999976
                ],
                [
                  104.7674026480002,
                  -2.982547043999887
                ],
                [
                  104.76774597200017,
                  -2.982914446999928
                ],
                [
                  104.7679595940001,
                  -2.983208894999962
                ],
                [
                  104.76803588799999,
                  -2.983275890999892
                ],
                [
                  104.76821136600012,
                  -2.983400581999831
                ],
                [
                  104.76891326900005,
                  -2.984101534999979
                ],
                [
                  104.76966094900007,
                  -2.985002039999927
                ],
                [
                  104.77026367200011,
                  -2.985725640999931
                ],
                [
                  104.77049255300011,
                  -2.986006020999923
                ],
                [
                  104.77013397300016,
                  -2.986337899999967
                ],
                [
                  104.76972961400014,
                  -2.986667869999962
                ],
                [
                  104.76873016300016,
                  -2.987480400999971
                ],
                [
                  104.7681350700002,
                  -2.987964630999898
                ],
                [
                  104.76766204800015,
                  -2.988407373999848
                ],
                [
                  104.76631927500017,
                  -2.989516257999924
                ],
                [
                  104.76620927800002,
                  -2.98964835299995
                ],
                [
                  104.76615905700004,
                  -2.989708661999941
                ],
                [
                  104.76606750500014,
                  -2.989815711999938
                ],
                [
                  104.76566314600012,
                  -2.990291594999974
                ],
                [
                  104.76417541400014,
                  -2.991024255999832
                ],
                [
                  104.76380920400004,
                  -2.991226673999961
                ],
                [
                  104.76329040500019,
                  -2.991557121999961
                ],
                [
                  104.76224517900005,
                  -2.989806889999898
                ],
                [
                  104.7617950450001,
                  -2.989062784999874
                ],
                [
                  104.76134490900017,
                  -2.988326309999877
                ],
                [
                  104.76112365600005,
                  -2.988318681999942
                ]
              ]
            ]
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "NAME_3": "Kemuning",
          "NAME_4": "20 Ilir Ii",
          "ID": 17,
          "STUNTING": 24,
          "WASTING":6,
          "LAT": 104.749224,
          "LONG": -2.952684,
        },
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [
                  104.74875640900007,
                  -2.96690368599991
                ],
                [
                  104.74642181500019,
                  -2.96545004799998
                ],
                [
                  104.74294280900006,
                  -2.96369624099998
                ],
                [
                  104.742599488,
                  -2.963521002999869
                ],
                [
                  104.74077606300006,
                  -2.962586641999962
                ],
                [
                  104.74040222200011,
                  -2.96239447499994
                ],
                [
                  104.74032592700007,
                  -2.96235728299996
                ],
                [
                  104.74026489300019,
                  -2.962306737999938
                ],
                [
                  104.73960113500016,
                  -2.961759567999877
                ],
                [
                  104.73857116700003,
                  -2.96094250699997
                ],
                [
                  104.73832702599998,
                  -2.960693597999978
                ],
                [
                  104.7381973270002,
                  -2.960800647999974
                ],
                [
                  104.73731231800008,
                  -2.959518432999914
                ],
                [
                  104.73720550500019,
                  -2.959311007999872
                ],
                [
                  104.73712921200018,
                  -2.959209202999887
                ],
                [
                  104.7362670890002,
                  -2.957764385999894
                ],
                [
                  104.73516845700004,
                  -2.954844950999927
                ],
                [
                  104.73464202800011,
                  -2.953499315999977
                ],
                [
                  104.73443603500004,
                  -2.953157186999931
                ],
                [
                  104.73468017500016,
                  -2.952983378999932
                ],
                [
                  104.73489379900008,
                  -2.952875851999977
                ],
                [
                  104.73554992800007,
                  -2.95246982499998
                ],
                [
                  104.73588562100008,
                  -2.953113794999979
                ],
                [
                  104.73597717299998,
                  -2.953046081999958
                ],
                [
                  104.73716735900007,
                  -2.952147482999976
                ],
                [
                  104.73696899400005,
                  -2.951479910999979
                ],
                [
                  104.73751831000004,
                  -2.951146125999969
                ],
                [
                  104.73821258500004,
                  -2.950818061999826
                ],
                [
                  104.73924255300017,
                  -2.950829505999877
                ],
                [
                  104.73996734700012,
                  -2.950847863999968
                ],
                [
                  104.74037933400018,
                  -2.950847863999968
                ],
                [
                  104.74081420900018,
                  -2.950635193999972
                ],
                [
                  104.74108123700006,
                  -2.950483559999952
                ],
                [
                  104.7411804190001,
                  -2.950420855999823
                ],
                [
                  104.74151611300016,
                  -2.950128077999921
                ],
                [
                  104.74158477800006,
                  -2.950016497999854
                ],
                [
                  104.74153900100009,
                  -2.949946403999888
                ],
                [
                  104.74189758300008,
                  -2.949424981999925
                ],
                [
                  104.74255371100003,
                  -2.94882488199994
                ],
                [
                  104.74270629800014,
                  -2.948627232999911
                ],
                [
                  104.74287414600019,
                  -2.948449373999949
                ],
                [
                  104.74349975500013,
                  -2.947944879999966
                ],
                [
                  104.74360656800019,
                  -2.947170256999811
                ],
                [
                  104.7438049320001,
                  -2.947178363999967
                ],
                [
                  104.74483490000006,
                  -2.947092294999948
                ],
                [
                  104.74504089300012,
                  -2.947086809999917
                ],
                [
                  104.74504852300004,
                  -2.947214840999948
                ],
                [
                  104.7456665040001,
                  -2.947091817999876
                ],
                [
                  104.74607086100008,
                  -2.946997403999887
                ],
                [
                  104.74606323300014,
                  -2.946903466999913
                ],
                [
                  104.74647522000004,
                  -2.946697711999946
                ],
                [
                  104.74691009500009,
                  -2.946550607999939
                ],
                [
                  104.74661254900013,
                  -2.945978880999974
                ],
                [
                  104.74652862500011,
                  -2.945812462999925
                ],
                [
                  104.74651336700009,
                  -2.945266007999976
                ],
                [
                  104.74605560300006,
                  -2.944293021999954
                ],
                [
                  104.74716186500012,
                  -2.944703340999979
                ],
                [
                  104.74745941100002,
                  -2.944804430999966
                ],
                [
                  104.74745941100002,
                  -2.944384335999871
                ],
                [
                  104.747444153,
                  -2.944232701999852
                ],
                [
                  104.74726867700008,
                  -2.944165228999964
                ],
                [
                  104.74708557100018,
                  -2.944119690999912
                ],
                [
                  104.74689483700007,
                  -2.943009376999953
                ],
                [
                  104.74686431800012,
                  -2.942826985999886
                ],
                [
                  104.74680328300013,
                  -2.942531346999942
                ],
                [
                  104.74675750800014,
                  -2.942318676999946
                ],
                [
                  104.74754333500005,
                  -2.942138195999917
                ],
                [
                  104.74743652300009,
                  -2.941726206999931
                ],
                [
                  104.74738311700014,
                  -2.941454886999963
                ],
                [
                  104.74736785900012,
                  -2.941256046999854
                ],
                [
                  104.74737548900004,
                  -2.941051482999967
                ],
                [
                  104.74771118199999,
                  -2.941166399999872
                ],
                [
                  104.74800109900008,
                  -2.941009520999955
                ],
                [
                  104.74903869700017,
                  -2.940445423999904
                ],
                [
                  104.74918365400015,
                  -2.939896105999935
                ],
                [
                  104.74923706100003,
                  -2.939678907999905
                ],
                [
                  104.74926757800017,
                  -2.939567802999875
                ],
                [
                  104.74925232000015,
                  -2.93931460399989
                ],
                [
                  104.74926757800017,
                  -2.938923834999969
                ],
                [
                  104.74942779600008,
                  -2.93835568399993
                ],
                [
                  104.74954223600014,
                  -2.938316583999892
                ],
                [
                  104.74978637700019,
                  -2.938273906999939
                ],
                [
                  104.75014495900001,
                  -2.938138483999921
                ],
                [
                  104.75025940000017,
                  -2.938090800999873
                ],
                [
                  104.75053405700004,
                  -2.938210248999894
                ],
                [
                  104.75076294000007,
                  -2.938279151999836
                ],
                [
                  104.75097656300011,
                  -2.938153266999961
                ],
                [
                  104.75108337300003,
                  -2.937978028999964
                ],
                [
                  104.75116729700005,
                  -2.937885521999931
                ],
                [
                  104.75130462600015,
                  -2.937885521999931
                ],
                [
                  104.7517776480002,
                  -2.937958239999944
                ],
                [
                  104.75204467800006,
                  -2.93643927599993
                ],
                [
                  104.75242614800004,
                  -2.936356782999951
                ],
                [
                  104.75259399400005,
                  -2.936322688999951
                ],
                [
                  104.7532043450002,
                  -2.936270475999891
                ],
                [
                  104.75346374499998,
                  -2.936351774999878
                ],
                [
                  104.75356292700002,
                  -2.936423300999934
                ],
                [
                  104.75421905500014,
                  -2.935929536999936
                ],
                [
                  104.75592041000016,
                  -2.936094282999875
                ],
                [
                  104.75627136100013,
                  -2.936053275999939
                ],
                [
                  104.7563858040001,
                  -2.936039447999974
                ],
                [
                  104.7569351200001,
                  -2.935792922999894
                ],
                [
                  104.75768280000011,
                  -2.935693740999966
                ],
                [
                  104.75778961200007,
                  -2.935668944999975
                ],
                [
                  104.75839233300013,
                  -2.935655354999881
                ],
                [
                  104.758453369,
                  -2.935497521999878
                ],
                [
                  104.75849151500017,
                  -2.935338496999975
                ],
                [
                  104.75877380400016,
                  -2.935196637999979
                ],
                [
                  104.75814056400014,
                  -2.936358927999947
                ],
                [
                  104.75790405300006,
                  -2.936464309999963
                ],
                [
                  104.75790405300006,
                  -2.936771391999969
                ],
                [
                  104.75786590600018,
                  -2.936880826999868
                ],
                [
                  104.75798797700003,
                  -2.939445494999973
                ],
                [
                  104.75846862700001,
                  -2.939375161999919
                ],
                [
                  104.7586822520002,
                  -2.939342974999931
                ],
                [
                  104.759521484,
                  -2.93921852099993
                ],
                [
                  104.75976562500006,
                  -2.939877986999875
                ],
                [
                  104.75992584200009,
                  -2.940256357999829
                ],
                [
                  104.7603683480001,
                  -2.940838097999972
                ],
                [
                  104.76061248700012,
                  -2.941242455999884
                ],
                [
                  104.76068115300012,
                  -2.941384552999978
                ],
                [
                  104.76042938100011,
                  -2.942948340999976
                ],
                [
                  104.7601623540001,
                  -2.943217515999947
                ],
                [
                  104.7593612660001,
                  -2.943545578999931
                ],
                [
                  104.75945282000004,
                  -2.943861961999971
                ],
                [
                  104.75941467300015,
                  -2.943978071999936
                ],
                [
                  104.75938415500013,
                  -2.944173573999933
                ],
                [
                  104.7594299320001,
                  -2.944329022999909
                ],
                [
                  104.7594299320001,
                  -2.944976328999928
                ],
                [
                  104.75869751000005,
                  -2.94524335899996
                ],
                [
                  104.75755310100016,
                  -2.945638179999946
                ],
                [
                  104.7578811640002,
                  -2.947186469999906
                ],
                [
                  104.75796508800005,
                  -2.947563884999965
                ],
                [
                  104.75806427000003,
                  -2.948040484999979
                ],
                [
                  104.75786590600018,
                  -2.948077678999937
                ],
                [
                  104.757530213,
                  -2.948192119999874
                ],
                [
                  104.75469970800003,
                  -2.948838709999904
                ],
                [
                  104.75476074300019,
                  -2.949132203999966
                ],
                [
                  104.7548370360002,
                  -2.949491022999894
                ],
                [
                  104.75497436500012,
                  -2.950123786999939
                ],
                [
                  104.75521087700014,
                  -2.950770139999861
                ],
                [
                  104.75527954000006,
                  -2.950996398999905
                ],
                [
                  104.75476837100013,
                  -2.951679229999911
                ],
                [
                  104.75479125900006,
                  -2.951942204999909
                ],
                [
                  104.7548141480001,
                  -2.952175378999868
                ],
                [
                  104.75495147700019,
                  -2.952491043999942
                ],
                [
                  104.75515747100013,
                  -2.952506063999977
                ],
                [
                  104.75654602100013,
                  -2.952373265999881
                ],
                [
                  104.75922393700017,
                  -2.952116488999877
                ],
                [
                  104.75935363800016,
                  -2.952535152999872
                ],
                [
                  104.75945282000004,
                  -2.953283547999888
                ],
                [
                  104.75975799500014,
                  -2.954007863999948
                ],
                [
                  104.759521484,
                  -2.954653739999912
                ],
                [
                  104.75933075000006,
                  -2.954817294999941
                ],
                [
                  104.75926971400014,
                  -2.955047845999957
                ],
                [
                  104.7591705320001,
                  -2.955189704999952
                ],
                [
                  104.75952911400009,
                  -2.955687998999963
                ],
                [
                  104.75919342100013,
                  -2.956629274999955
                ],
                [
                  104.7592391980001,
                  -2.956815958999869
                ],
                [
                  104.75926971400014,
                  -2.957130670999959
                ],
                [
                  104.75913238500004,
                  -2.957336187999942
                ],
                [
                  104.75916290200018,
                  -2.957439660999967
                ],
                [
                  104.75963592600004,
                  -2.957872867999924
                ],
                [
                  104.75968170100003,
                  -2.958020924999914
                ],
                [
                  104.75969696000016,
                  -2.958140372999935
                ],
                [
                  104.75978088300008,
                  -2.958275794999963
                ],
                [
                  104.75983429000013,
                  -2.958429096999851
                ],
                [
                  104.76000976600011,
                  -2.958708524999963
                ],
                [
                  104.76016998400002,
                  -2.958960531999935
                ],
                [
                  104.76037597600003,
                  -2.959183214999882
                ],
                [
                  104.76045227000009,
                  -2.95930528599996
                ],
                [
                  104.76061248700012,
                  -2.959408282999959
                ],
                [
                  104.76084137000015,
                  -2.959671734999972
                ],
                [
                  104.76081085200013,
                  -2.960036991999914
                ],
                [
                  104.76084137000015,
                  -2.960140226999954
                ],
                [
                  104.76076507700014,
                  -2.960686206999981
                ],
                [
                  104.76079559300018,
                  -2.960762500999977
                ],
                [
                  104.76071166900016,
                  -2.961050031999889
                ],
                [
                  104.76046753000014,
                  -2.961211681999941
                ],
                [
                  104.76040649500015,
                  -2.961325167999917
                ],
                [
                  104.7603912350001,
                  -2.961459158999901
                ],
                [
                  104.76036071800019,
                  -2.961560487999861
                ],
                [
                  104.76016998400002,
                  -2.961685656999975
                ],
                [
                  104.76015472300008,
                  -2.961840391999942
                ],
                [
                  104.76011657700013,
                  -2.961909769999977
                ],
                [
                  104.75997924800004,
                  -2.962114809999889
                ],
                [
                  104.75962066600005,
                  -2.962222814999961
                ],
                [
                  104.75952911400009,
                  -2.963203190999877
                ],
                [
                  104.75954437200011,
                  -2.964100359999975
                ],
                [
                  104.75954437200011,
                  -2.964285850999943
                ],
                [
                  104.75973510800009,
                  -2.964751719999924
                ],
                [
                  104.75978851400009,
                  -2.964872120999928
                ],
                [
                  104.75961303800011,
                  -2.965881585999966
                ],
                [
                  104.76029205200018,
                  -2.967209337999975
                ],
                [
                  104.76034546000017,
                  -2.967717646999915
                ],
                [
                  104.76027679400016,
                  -2.968778371999861
                ],
                [
                  104.76034546000017,
                  -2.968973397999832
                ],
                [
                  104.76038360600012,
                  -2.969092368999895
                ],
                [
                  104.76051330500007,
                  -2.96946692399996
                ],
                [
                  104.76081848200005,
                  -2.970000266999875
                ],
                [
                  104.76109314000001,
                  -2.970263241999874
                ],
                [
                  104.7602310170002,
                  -2.97239136599984
                ],
                [
                  104.76010894800015,
                  -2.972342728999877
                ],
                [
                  104.75943756200019,
                  -2.972367286999884
                ],
                [
                  104.75938415500013,
                  -2.972154616999944
                ],
                [
                  104.75932311900004,
                  -2.971877096999833
                ],
                [
                  104.75827026300016,
                  -2.969353675999855
                ],
                [
                  104.75815582200016,
                  -2.969228982999937
                ],
                [
                  104.75788879400011,
                  -2.968934535999892
                ],
                [
                  104.75755310100016,
                  -2.96858119999996
                ],
                [
                  104.75739288400013,
                  -2.968404291999889
                ],
                [
                  104.75669097900004,
                  -2.967724561999887
                ],
                [
                  104.75657653800005,
                  -2.967808483999875
                ],
                [
                  104.75587463300019,
                  -2.968255041999896
                ],
                [
                  104.75577545100015,
                  -2.96833086099997
                ],
                [
                  104.75527191200013,
                  -2.968980788999886
                ],
                [
                  104.7549057,
                  -2.969546317999971
                ],
                [
                  104.75453185999999,
                  -2.969985485999814
                ],
                [
                  104.75444030800008,
                  -2.970080374999952
                ],
                [
                  104.75410461500007,
                  -2.970384358999866
                ],
                [
                  104.75354003799998,
                  -2.970610141999884
                ],
                [
                  104.75334930400004,
                  -2.970699308999883
                ],
                [
                  104.7528991710002,
                  -2.970888614999922
                ],
                [
                  104.7524642950001,
                  -2.971100090999926
                ],
                [
                  104.75228881700014,
                  -2.971208572999956
                ],
                [
                  104.75135040300012,
                  -2.971751450999932
                ],
                [
                  104.75130462600015,
                  -2.971668719999911
                ],
                [
                  104.75119781600017,
                  -2.971428393999929
                ],
                [
                  104.751052857,
                  -2.97112107199996
                ],
                [
                  104.74996948300009,
                  -2.968777656999976
                ],
                [
                  104.74959564100004,
                  -2.96812629599998
                ],
                [
                  104.74954986600005,
                  -2.968028543999878
                ],
                [
                  104.74946594200009,
                  -2.967903612999976
                ],
                [
                  104.748931885,
                  -2.966980217999946
                ],
                [
                  104.74888610800002,
                  -2.967077493999966
                ],
                [
                  104.74875640900007,
                  -2.96690368599991
                ]
              ]
            ]
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "NAME_3": "Ilir Barat II",
          "NAME_4": "27 Ilir",
          "ID": 18,
          "STUNTING": 21,
          "WASTING":16,
          "LAT": 104.74767303600004,
          "LONG": -3.007394550999948
        },
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [
                  104.74767303600004,
                  -3.007394550999948
                ],
                [
                  104.74623107800011,
                  -3.006714104999958
                ],
                [
                  104.74494171100008,
                  -3.007059335999884
                ],
                [
                  104.74465179400016,
                  -3.006526707999967
                ],
                [
                  104.7443771360002,
                  -3.006674527999962
                ],
                [
                  104.74447631900011,
                  -3.007167815999821
                ],
                [
                  104.73886871300016,
                  -3.009505271999956
                ],
                [
                  104.73880004800003,
                  -3.009318113999939
                ],
                [
                  104.73800659300002,
                  -3.007187366999915
                ],
                [
                  104.73814392200012,
                  -3.006631135999896
                ],
                [
                  104.73823547400008,
                  -3.006196737999858
                ],
                [
                  104.73876190100003,
                  -3.006244659999822
                ],
                [
                  104.7391281130001,
                  -3.006242512999904
                ],
                [
                  104.7390594470001,
                  -3.006004570999949
                ],
                [
                  104.738990784,
                  -3.005829810999955
                ],
                [
                  104.73887634300007,
                  -3.005574702999979
                ],
                [
                  104.73874664300018,
                  -3.004679918999955
                ],
                [
                  104.73880767800017,
                  -3.004553794999879
                ],
                [
                  104.73888397200005,
                  -3.004473208999968
                ],
                [
                  104.73902130100015,
                  -3.003990648999945
                ],
                [
                  104.73965454000006,
                  -3.00404357899987
                ],
                [
                  104.73995208700006,
                  -3.004065512999887
                ],
                [
                  104.74015808100006,
                  -3.004087685999878
                ],
                [
                  104.7402572630001,
                  -3.004022835999933
                ],
                [
                  104.7403182990002,
                  -3.003980635999881
                ],
                [
                  104.740417481,
                  -3.003917215999877
                ],
                [
                  104.74056244000002,
                  -3.003978490999884
                ],
                [
                  104.74065399200015,
                  -3.003863572999876
                ],
                [
                  104.74069976900012,
                  -3.003759860999935
                ],
                [
                  104.74073791600017,
                  -3.003454923999925
                ],
                [
                  104.7408065790001,
                  -3.003341673999955
                ],
                [
                  104.74088287299998,
                  -3.003221035999957
                ],
                [
                  104.74085998400017,
                  -3.003152607999937
                ],
                [
                  104.74082946800013,
                  -3.003060577999975
                ],
                [
                  104.74071502700014,
                  -3.002916811999967
                ],
                [
                  104.74054718000019,
                  -3.002708911999946
                ],
                [
                  104.74032592700007,
                  -3.002599715999963
                ],
                [
                  104.74033355700004,
                  -3.002491473999953
                ],
                [
                  104.74026489300019,
                  -3.002324342999884
                ],
                [
                  104.74028778000007,
                  -3.002089977999958
                ],
                [
                  104.74038696200006,
                  -3.002064465999979
                ],
                [
                  104.7404861440001,
                  -3.002059935999966
                ],
                [
                  104.7405548100001,
                  -3.002031564999925
                ],
                [
                  104.74058532600014,
                  -3.001936912999895
                ],
                [
                  104.74053955099998,
                  -3.001849173999915
                ],
                [
                  104.74050140500003,
                  -3.001628398999969
                ],
                [
                  104.74050903300014,
                  -3.001556872999913
                ],
                [
                  104.7404861440001,
                  -3.00143766399998
                ],
                [
                  104.74063873400013,
                  -3.00121188199995
                ],
                [
                  104.74066925000017,
                  -3.001110313999902
                ],
                [
                  104.74054718000019,
                  -3.001093148999928
                ],
                [
                  104.7403945920002,
                  -3.001127004999944
                ],
                [
                  104.74031066800018,
                  -3.001098870999954
                ],
                [
                  104.74021148600013,
                  -3.001047848999974
                ],
                [
                  104.740119934,
                  -3.001076219999845
                ],
                [
                  104.74005889900002,
                  -3.00113272599998
                ],
                [
                  104.73998260500014,
                  -3.001030920999824
                ],
                [
                  104.73985290500002,
                  -3.000946043999818
                ],
                [
                  104.73973846400008,
                  -3.000900744999967
                ],
                [
                  104.73957824700005,
                  -3.000861167999972
                ],
                [
                  104.73941802900009,
                  -3.000793218999945
                ],
                [
                  104.73921203700013,
                  -3.000810144999946
                ],
                [
                  104.73908233600014,
                  -3.000718355999879
                ],
                [
                  104.73899841200011,
                  -3.000702857999954
                ],
                [
                  104.73895263700018,
                  -3.000781058999848
                ],
                [
                  104.73885345500014,
                  -3.000870704999954
                ],
                [
                  104.73878479000001,
                  -3.000836610999897
                ],
                [
                  104.73873138400006,
                  -3.000679731999981
                ],
                [
                  104.73876190100003,
                  -3.000585794999949
                ],
                [
                  104.73873901500008,
                  -3.000439642999936
                ],
                [
                  104.73867034900007,
                  -3.000401018999924
                ],
                [
                  104.73859405500014,
                  -3.000351665999915
                ],
                [
                  104.73851776200013,
                  -3.000279902999921
                ],
                [
                  104.73858642500005,
                  -3.000189303999889
                ],
                [
                  104.73857116700003,
                  -3.000058174999879
                ],
                [
                  104.73849487300009,
                  -2.999691724999934
                ],
                [
                  104.73854065000006,
                  -2.999617336999904
                ],
                [
                  104.73851776200013,
                  -2.999459026999887
                ],
                [
                  104.73844909600012,
                  -2.999346016999937
                ],
                [
                  104.73829650900007,
                  -2.99933266599993
                ],
                [
                  104.7382736200002,
                  -2.99925112699998
                ],
                [
                  104.73799896300011,
                  -2.999138116999973
                ],
                [
                  104.73777771000005,
                  -2.999017953999896
                ],
                [
                  104.73764801100003,
                  -2.998842715999956
                ],
                [
                  104.7371978750001,
                  -2.999778269999922
                ],
                [
                  104.73685455400005,
                  -2.999437092999926
                ],
                [
                  104.73586273200004,
                  -2.998770474999844
                ],
                [
                  104.73540496800018,
                  -2.998678444999939
                ],
                [
                  104.73482513400018,
                  -2.998181581999972
                ],
                [
                  104.73452758700012,
                  -2.99737453399996
                ],
                [
                  104.73388672000016,
                  -2.996985673999973
                ],
                [
                  104.73371887200011,
                  -2.996908902999905
                ],
                [
                  104.73350524900007,
                  -2.996790886999918
                ],
                [
                  104.7335891730001,
                  -2.996494769999913
                ],
                [
                  104.73358154300018,
                  -2.996068000999969
                ],
                [
                  104.73360443200005,
                  -2.995771168999909
                ],
                [
                  104.73355865500008,
                  -2.995070218999842
                ],
                [
                  104.73355865500008,
                  -2.994967936999899
                ],
                [
                  104.73352813800011,
                  -2.994841575999942
                ],
                [
                  104.73336029000012,
                  -2.993981122999969
                ],
                [
                  104.7334594720001,
                  -2.992907284999944
                ],
                [
                  104.73392486600011,
                  -2.992610930999945
                ],
                [
                  104.73454284600007,
                  -2.99187040399994
                ],
                [
                  104.73458862300004,
                  -2.991814613999964
                ],
                [
                  104.73484802200011,
                  -2.991113423999934
                ],
                [
                  104.7354354850001,
                  -2.989670752999928
                ],
                [
                  104.73585510200013,
                  -2.989805696999895
                ],
                [
                  104.73622131400003,
                  -2.989894628999878
                ],
                [
                  104.73666381800007,
                  -2.989835023999944
                ],
                [
                  104.73719024700017,
                  -2.989735125999914
                ],
                [
                  104.73736572300015,
                  -2.989679335999938
                ],
                [
                  104.73785400300005,
                  -2.989497184999948
                ],
                [
                  104.73791503900009,
                  -2.989578485999914
                ],
                [
                  104.73979949900007,
                  -2.988041401999851
                ],
                [
                  104.73996734700012,
                  -2.987949369999967
                ],
                [
                  104.74173736500018,
                  -2.987478494999891
                ],
                [
                  104.74237060500019,
                  -2.987329243999966
                ],
                [
                  104.742530822,
                  -2.987317322999957
                ],
                [
                  104.74415588400018,
                  -2.987271070999952
                ],
                [
                  104.74400329600019,
                  -2.987739562999934
                ],
                [
                  104.7437362670002,
                  -2.988227605999896
                ],
                [
                  104.74357604900007,
                  -2.988548517999959
                ],
                [
                  104.74356079100005,
                  -2.988877057999957
                ],
                [
                  104.74357604900007,
                  -2.988993643999947
                ],
                [
                  104.74357604900007,
                  -2.989151000999925
                ],
                [
                  104.74361419600012,
                  -2.989467620999903
                ],
                [
                  104.74370575000006,
                  -2.990895509999916
                ],
                [
                  104.7437515270002,
                  -2.991263150999941
                ],
                [
                  104.74378204400017,
                  -2.991351841999915
                ],
                [
                  104.74385070900007,
                  -2.992017030999818
                ],
                [
                  104.74417877200011,
                  -2.992581844999904
                ],
                [
                  104.74440002500006,
                  -2.992753267999888
                ],
                [
                  104.74463653600014,
                  -2.992935655999872
                ],
                [
                  104.74524688700012,
                  -2.993380546999902
                ],
                [
                  104.7458038330002,
                  -2.99275278999994
                ],
                [
                  104.74600982600009,
                  -2.992718218999926
                ],
                [
                  104.74629974400011,
                  -2.993018387999882
                ],
                [
                  104.74641418400017,
                  -2.993143320999934
                ],
                [
                  104.74681854300013,
                  -2.993468760999917
                ],
                [
                  104.74703979400005,
                  -2.993662357999881
                ],
                [
                  104.74712371800007,
                  -2.993716715999938
                ],
                [
                  104.74759674000012,
                  -2.993943928999897
                ],
                [
                  104.7479248050002,
                  -2.993938922999916
                ],
                [
                  104.74821472100018,
                  -2.993672132999961
                ],
                [
                  104.74872589000012,
                  -2.993833540999901
                ],
                [
                  104.74901580900001,
                  -2.993951796999852
                ],
                [
                  104.74961090200014,
                  -2.9942669859999
                ],
                [
                  104.74975585900017,
                  -2.994275568999967
                ],
                [
                  104.74988555900006,
                  -2.993972301999861
                ],
                [
                  104.75032043500016,
                  -2.992985010999973
                ],
                [
                  104.75099182100013,
                  -2.992479324999977
                ],
                [
                  104.75115203900003,
                  -2.992451905999928
                ],
                [
                  104.7519607540001,
                  -2.992335080999908
                ],
                [
                  104.752052306,
                  -2.992227315999969
                ],
                [
                  104.75212097200017,
                  -2.991649388999861
                ],
                [
                  104.75267791800007,
                  -2.991772888999947
                ],
                [
                  104.7527618420001,
                  -2.991791724999928
                ],
                [
                  104.75307464600002,
                  -2.991764545999956
                ],
                [
                  104.7531967170001,
                  -2.991902827999979
                ],
                [
                  104.75330352700007,
                  -2.992002009999908
                ],
                [
                  104.7533874510001,
                  -2.99209260899994
                ],
                [
                  104.75347137500012,
                  -2.992139100999964
                ],
                [
                  104.75363159200009,
                  -2.992259024999953
                ],
                [
                  104.75369262800018,
                  -2.992313861999946
                ],
                [
                  104.75386810400016,
                  -2.992825030999882
                ],
                [
                  104.75410461500007,
                  -2.992776870999876
                ],
                [
                  104.75510406400008,
                  -2.992257594999899
                ],
                [
                  104.75522613500016,
                  -2.992158174999929
                ],
                [
                  104.75620269800004,
                  -2.991760253999871
                ],
                [
                  104.75634765700005,
                  -2.992130755999881
                ],
                [
                  104.756538391,
                  -2.992495775999885
                ],
                [
                  104.75688171500019,
                  -2.993198155999949
                ],
                [
                  104.7568054190001,
                  -2.993211506999955
                ],
                [
                  104.75690460100014,
                  -2.993528603999891
                ],
                [
                  104.75788879400011,
                  -2.995252131999962
                ],
                [
                  104.75778961200007,
                  -2.995309114999884
                ],
                [
                  104.75798034600007,
                  -2.995595455999933
                ],
                [
                  104.75809478700018,
                  -2.995766638999896
                ],
                [
                  104.75733184800004,
                  -2.996476411999936
                ],
                [
                  104.75697326700009,
                  -2.996951579999973
                ],
                [
                  104.7568740850001,
                  -2.997055768999815
                ],
                [
                  104.75583648600013,
                  -2.998056410999936
                ],
                [
                  104.75593566800018,
                  -2.998148202999971
                ],
                [
                  104.75534057500005,
                  -2.998889444999918
                ],
                [
                  104.75524139300018,
                  -2.999032973999874
                ],
                [
                  104.75486755300017,
                  -2.999546765999924
                ],
                [
                  104.7540588380001,
                  -3.00110244699988
                ],
                [
                  104.75397491400008,
                  -3.001065969999956
                ],
                [
                  104.75265502900004,
                  -3.003760098999862
                ],
                [
                  104.75264739900013,
                  -3.003845929999954
                ],
                [
                  104.75273895300006,
                  -3.003875015999938
                ],
                [
                  104.75244140700016,
                  -3.004635809999911
                ],
                [
                  104.75241088799999,
                  -3.004760979999901
                ],
                [
                  104.75199127300004,
                  -3.005879877999917
                ],
                [
                  104.7517547600001,
                  -3.006406067999933
                ],
                [
                  104.75087738100007,
                  -3.00867414399994
                ],
                [
                  104.75038909800008,
                  -3.008471248999911
                ],
                [
                  104.74980163600014,
                  -3.008167981999975
                ],
                [
                  104.74767303600004,
                  -3.007394550999948
                ]
              ]
            ]
          ]
        }
      }
    ]
  }
  
  const [selected, setSelected] = useState(null)
  let mapRef;
  // const COLOR_8 = "#800026";
  // const COLOR_7 = "#BD0026";
  // const COLOR_6 = "#E31A1C";
  // const COLOR_5 = "#FC4E2A";
  // const COLOR_4 = "#FD8D3C";
  // const COLOR_3 = "#FEB24C";
  // const COLOR_2 = "#FED976";
  // const COLOR_1 = "#FFEDA0";

  // const getColor =(d)=>{
  //   return d > 60 ? '#800026' :
  //          d > 50  ? '#BD0026' :
  //          d > 40  ? '#E31A1C' :
  //          d > 30  ? '#FC4E2A' :
  //          d > 20   ? '#FD8D3C' :
  //          d > 10   ? '#FEB24C' :
  //          d > 1   ? '#FED976' :
  //                     '#FFEDA0';
  // }

  //wasting
  const COLOR_6 = "#767776";
  const COLOR_5 = "#bab9b9";
  const COLOR_4 = "#fcfdfc";
  
  //stunting
  const COLOR_3 = "#9b6c71";
  const COLOR_2 = "#ecad9a";
  const COLOR_1 = "#f1f5dd";

  const getColor =(d)=>{
    if(sebaran === 0 ){
      return  d > 53   ? '#9b6c71' :
             d > 35   ? '#ecad9a' :
             d > 14   ? '#f1f5dd' :
                        '#FFEDA0';
    }
    if(sebaran === 1){
      return  d > 268   ? '#9b6c71' :
             d > 138   ? '#ecad9a' :
             d > 5   ? '#f1f5dd' :
                        '#FFEDA0';
    }
  }

  const style =(feature) => {
    return {
      fillColor: sebaran === 0 ? getColor(feature.properties.STUNTING):getColor(feature.properties.WASTING),
      weight: 1,
      opacity: 1,
      color: "#989693",
      // dashArray: "3",
      fillOpacity: 0.8
    };
  }

  const zoomToFeature = (e) =>{
    mapRef.fitBounds(e.target.getBounds());
  }

  const highlightFeature =(e) =>{
    var layer = e.target

    layer.setStyle({
      weight: 2,
      color: "#DF1995",
      dashArray: "",
      fillOpacity: 1,
    });
    
    if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
      layer.bringToFront();

      const { NAME_3, PERSEN, STUNTING, WASTING } = e.target.feature.properties;

      setSelected({
        kecamatan: NAME_3,
        stunting: STUNTING,
        wasting:WASTING,
        persen: PERSEN
      });
    }

  }

  const resetHighlight= (e)=> {
    setSelected(null)
    e.target.setStyle(style(e.target.feature));
  }
   
  const onEachFeature= (feature, layer)=> {
      layer.on({
      mouseover: highlightFeature,
      mouseout: resetHighlight,
      click: zoomToFeature
    });
  }

  var generateRandInt = function() {
    return Math.floor( Math.random() * 200000 ) + 1;
};

const [sebaran, setSebaran] = useState(0)

 const handleSebaran =(id)=>{
  setSebaran(id)
 }

  return (
    <div className='container peta-section pt-5' style={{maxWidth:'84%'}}>
      <h4 className='display-5' style={{lineHeight:0.8}}>Peta Sebaran Stunting & Wasting<br></br>
          <span className='aboutus-text' style={{fontWeight:'600'}}>Peta Sebaran Stunting per kecamatan dengan warna pekat menggambarkan jumlah stunting terbesar.</span>
        <hr/>
      </h4>
      <div className="panel mx-auto">
        
        {/* <div className="panel__header"> */}
          {/* <h1>Stunting by Kecamatan daerah Palembang</h1>
          <p>
            Map which areas color are a visualization
            of a statistical data, this provide an easy measurement across
            regions.
          </p> */}
        {/* </div>  */}
        
        <div className="panel__map">
          {/* <button
            onClick={() => {
            mapRef.flyToBounds([
              [ -2.873167753999894, 104.71038055400015],
              [-2.873857258999919, 104.71345520000017]
              ]);
            }}
            className="full-extent"
          /> */}
          
          {selected &&
            <div className='info'>
              <h3>{selected.kecamatan}
              </h3>
              {sebaran === 0 &&
              <p className='display-4'>Stunting: {selected.stunting}</p>
              }
              {sebaran === 1 &&
              <p className='display-4'>Wasting: {selected.wasting}</p>
              }
            </div>
          }
          <div className='sebaran'>
            <input type="radio" id="stunting" name="sebaran" value="Stunting" defaultChecked/>
            <label htmlFor="stunting" onClick={()=>handleSebaran(0)}>Stunting</label><br/>
            <input type="radio" id="wasting" name="sebaran" value="Wasting"/>
            <label htmlFor="wasting" onClick={()=>handleSebaran(1)}>Wasting</label><br/>  
          </div>

          <div className="legend">
            {/* <div style={{ "--color": COLOR_1 }}>0 - 1</div>
            <div style={{ "--color": COLOR_2 }}>1 - 10</div>
            <div style={{ "--color": COLOR_3 }}>10 - 20</div>
            <div style={{ "--color": COLOR_4 }}>20 - 30</div>
            <div style={{ "--color": COLOR_5 }}>30 - 40</div>
            <div style={{ "--color": COLOR_6 }}>40 - 50</div>
            <div style={{ "--color": COLOR_7 }}>50 - 60</div>
            <div style={{ "--color": COLOR_8 }}>60+</div> */}

            { sebaran === 0 &&  <> 
            <div style={{ "--color": COLOR_1 }}>15 - 34</div>
            <div style={{ "--color": COLOR_2 }}>35 - 53</div>
            <div style={{ "--color": COLOR_3 }}>54 - 72</div>
            </>
            }

            { sebaran === 1 &&  <> 
            <div style={{ "--color": COLOR_4 }}>6 - 137</div>
            <div style={{ "--color": COLOR_5 }}>138 - 267</div>
            <div style={{ "--color": COLOR_6 }}>268 - 398</div>
            </>
            }

          </div>
          { sebaran === 0 && 
            <MapContainer
              style={{ width: "100%", height: "100%" }}
              zoom={10}
              zoomControl={false}
              scrollWheelZoom={true}
              maxZoom={12}
              animate={true}
              easeLinearity={0.35}
              center = {[ -2.955305,104.517139]}
              whenReady={e => {
                mapRef = e.target;
                e.target.flyToBounds([
                  [-2.9549685,104.7629646],
                  [-2.9814581,104.7972366,]
                ]);
              }}
            >

              <TileLayer 
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <ZoomControl position='bottomright' />

              {/* harus pakai component baru bisa show right data when hover */}
              { data &&  <GeoJsonWithUpdates 
                data={data} style={style} onEachFeature={onEachFeature} />
              }

              {
                data.features.map((item, index)=>{
                  if(!item.properties.hasOwnProperty('LAT')){
                    return false
                  } 
                  return  <Marker position={[item.properties.LONG,item.properties.LAT]}
                    key={item.properties.ID}
                      icon={
                      L.divIcon({
                        className:'div-icon',
                        iconSize: [45, 18],
                        iconAnchor: [10, 41],
                        html: `<div style='background:#000;color:#fff;border:0;text-align:center;border-radius:5px;'>
                            <span style='font-size:8px;'>${item.properties.NAME_3}</span>, ${item.properties.STUNTING}</div>`,
                      })}
                      >
                    </Marker> 
                })
              }
            </MapContainer>
          }

          { sebaran === 1 && 
            <MapContainer
              style={{ width: "100%", height: "100%" }}
              zoom={10}
              zoomControl={false}
              scrollWheelZoom={true}
              maxZoom={12}
              animate={true}
              easeLinearity={0.35}
              center = {[ -2.955305,104.517139]}
              whenReady={e => {
                mapRef = e.target;
                e.target.flyToBounds([
                  [-2.9549685,104.7629646],
                  [-2.9814581,104.7972366,]
                ]);
              }}
            >

              <TileLayer 
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <ZoomControl position='bottomright' />

              {/* harus pakai component baru bisa show right data when hover */}
              { data &&  <GeoJsonWithUpdates 
                data={data} style={style} onEachFeature={onEachFeature} />
              }

              {
                data.features.map((item, index)=>{
                  if(!item.properties.hasOwnProperty('LAT')){
                    return false
                  } 
                  return  <Marker position={[item.properties.LONG,item.properties.LAT]}
                    key={item.properties.ID}
                      icon={
                      L.divIcon({
                        className:'div-icon',
                        iconSize: [45, 18],
                        iconAnchor: [10, 41],
                        html: `<div style='background:#000;color:#fff;border:0;text-align:center;border-radius:5px;'>
                            <span style='font-size:8px;'>${item.properties.NAME_3}</span>, ${item.properties.WASTING}</div>`,
                      })}
                      >
                    </Marker> 
                })
              }
            </MapContainer>
          }

        </div>

        <div className="panel__footer">
          <ul>
            <li>
              <a href="https://reactjs.org/">#EPPGM, Dinas Kesehatan Provinsi Sumatera Selatan - Akses 19 Februari 2023</a>
            </li>
            {/* <li>
              <a href="https://leafletjs.com/">Leaflet</a>
            </li>
            <li>
              <a href="https://react-leaflet.js.org/">React Leaflet</a>
            </li>
            <li>
              <a href="https://www.openstreetmap.org/">OpenStreetMap</a>
            </li>
            <li>
              <a href="https://gadm.org/">Region Data by GADM</a>
            </li>
            <li>
              <a href="https://carto.com/location-data-services/basemaps/">
                Maptiles by CARTO
              </a>
            </li> */}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default LeafletMap


export const MapDiv =(props)=>{
  console.log(props.x)
  return <div className='info'>Testing</div>
}