import React from 'react';
import ReactDOM from 'react-dom/client';
// import './bootstrap.css'
import './index.css';
import App from './App';
// import Lightbox from 'bs5-lightbox';

const root = ReactDOM.createRoot(document.getElementById('root'));
// const options = {
//   keyboard: true,
//   size: 'fullscreen'
// };

//  document.querySelectorAll('.my-lightbox-toggle').forEach((el) => el.addEventListener('click', (e) => {
//   e.preventDefault();
//   const lightbox = new Lightbox(el, options);
//   lightbox.show();
// }));

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

