import background from '../../assets/images/background.jpg'
import LeafletMap from '../../components/leaflet/LeafletMap'
import Teams from '../../components/teams/Teams'
import AboutUs from '../../components/abouts/AboutUs'
import Poster from '../../components/posters/Poster'
import Leaflet from '../../components/leaflet_brosur/Leaflet'
import './home.css'
import logoUnsri from '../../assets/images/logo_unsri.png'
import logoPalembang from '../../assets/images/logo_palembang.png'
import { useEffect } from 'react'
import backgroundvideo from '../../assets/images/background_video.mp4'

const Home = () => {
  return (
    <div className='mt-4 pt-5'>
        <section className='text-center section-mob-back' style={{maxWidth:'100%', margin:'auto'}}>
          <div className='video-header'>
            <video autoPlay loop muted playsInline className="back-video">
              <source src={backgroundvideo} type="video/mp4" />
            </video>
          </div>
        </section>
      
        <section id='about' className='bg-light'>
          <AboutUs />
        </section>
        
        <div className='container group-article'>
          <section id='leaflet' style={{maxWidth:'90%', margin:'auto', marginTop:'20px'}}>
            <Leaflet />
          </section>
          <section style={{maxWidth:'90%', margin:'auto'}}>
              <Poster />
          </section>
        </div>        
        <section id='peta' className='bg-light pb-5 mt-5'  style={{margin:'auto'}}>
          {/* sementara matiin makan memory */}
          <LeafletMap items = {""}  zoom={10} center={{ lat: 	-2.9891, lng: 	104.7554 }}/>
        </section>

        <section id='team' style={{maxWidth:'84%',margin:'auto'}}>
          <h4 className='display-5 pt-5' style={{lineHeight:0.8}}>Research Team<br></br>
            <span className='aboutus-text' style={{fontWeight:'600'}}>Profile Research Team Kampung Bebas Stunting & Wasting</span>
          </h4>
          <hr/>
          <Teams />
        </section>
    </div>
  )
}
  

export default Home
