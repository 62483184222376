import { useState } from 'react'
import { Link } from 'react-router-dom'
import './petaStatic.css'

const images = [
  {id:'1',title:'Sebaran Kasus Stunting', desc:'<p>Wilayah sebaran kasus stunting &nbsp;dibagi menjadi 3 kategori, dimana masing-masing kategori disimbolkan dengan gradasi warna. Daerah yang sangat tinggi disimbolkan dengan warna yang lebih pekat/gelap sedangkan daerah yang rendah disimbolkan dengan warna yang lebih terang/pudar. Dari pemetaan diatas dapat dilihat sebaran kasus stunting pada masing-masing kecamatan di Kota Palembang. Daerah yang merupakan kategori tinggi sebaran stunting adalah Kecamatan Seberang Ulu II dengan jumlah kasus sebanyak 72 kasus dan Seberang Ulu I dengan jumlah kasus sebanyak 70 kasus. dilanjutkan pada daerah sebaran kategori sedang, yaitu kecamatan kertapati, kecamatan Ilir Barat I, Kecamatan Jakabaring, Kecamatan Plaju, Kecamatan Kalidoni, dan Ilir Timur II. Sedangkan 10 Kecamatan lainnya tergolong kategori wilayah rendah.</p>',imageName: require('../../assets/images/petastatic/kecamatan/1.stunting.png'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'2',title:'Sebaran kasus stunting berdasarkan wilayah kerja', desc:'<p>Dari pemetaan diatas, dapat diketahui bahwa wilayah kerja puskesmas dengan kategori tinggi kasus stunting terdapat pada Puskesmas Kertapati, Puskesmas 1 Ulu dan puskesmas taman bacaan. Dilanjutkan wilayah kerja dengan kategori sedang terdapat pada Puskesmas OPI, Puskesmas Gandus, Puskesmas Punti Kayu, Puskesmas Nagaswidak, Puskesmas Plaju, Puskesmas Kalidoni, Puskesmas Sematang Borang, Puskesmas Sosial dan Puskesmas Punti Kayu. Sedangkan 29 Puskesmas Lainnya merupakan wilayah rendah sebaran kasus stunting.</p>',imageName: require('../../assets/images/petastatic/wilayahkerja/1.wilayahkerjastunting.png'), tag: 'posyandu angrek bulan 12 ulu'},
  {id:'3',title:'Sebaran Prevalensi Stunting berdasarkan wilayah kerja ', desc:'Pemetaan Diatas merupakan prevalensi terjadinya stunting berdasarkan perhitungan kasus per jumlah balita yang diukur pada masing-masing puskesmas. dari pemetaan tersebut dapat diketahui bahwa Wilayah Puskesmas yang memiliki Prevalensi Stunting tinggi terdapat pada Puskesmas 1 Ulu, sedangkan 41 Puskesmas lainnya merupakan wilayah rendah prevalensi stunting.',imageName: require('../../assets/images/petastatic/wilayahkerja/UPDATE8JULIStunting.png'), tag: 'posyandu angrek bulan 12 ulu'},
  ]

const Stunting =()=> {
  const [noItem, setNoItem] = useState(3)
  const sliceItems = images.slice(0, noItem)

  const loadMore =()=> setNoItem(noItem + noItem)
  

  return(
    <>
    <h2><strong>Stunting</strong><br/>
    </h2>
    <div className="row row-cols-2 g-3">
      {sliceItems.map(item=>(
        <div className="col" key={item.title}>
          <div className="card" style={{boxShadow:'none'}}>
            <h4 className='m-2 ps-2'>{item.title}</h4>
            <a href={`${item.imageName}`}>
              <img src={item.imageName} 
                className={`cursor-pointer card-img-top zoom`}  
                alt="" loading='lazy'/>
            </a>
            <div className="card-body">
              <p className='card-text' dangerouslySetInnerHTML={{__html:item.desc}} />
            </div>
          </div>
        </div>
      ))}
    </div>
    <div className='text-center d-block mt-5 mb-5'>
      {noItem < images.length &&
        <button 
          className='btn btn-success'
          onClick={()=>loadMore()}>
            Load More Stunting
        </button>
      }
  </div>
  </>
  )
}

export default Stunting