
//menus
const MENUS = Object.freeze([
  { NAME: 'HOME',TO:'/' },
  { NAME: 'BLOG & ARTIKEL',TO:'/blogs'},
  { NAME: 'WHAT WE DO',TO:'/#about' },
  { NAME: 'LEAFLET & POSTER',TO:'/#leaflet' },
  { NAME: 'PETA SEBARAN STUNTING & WASTING',TO:'/#peta' },
  { NAME: 'RESEARCH TEAM',TO:'/#team'},
])
 
export { MENUS}