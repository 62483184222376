
import React, {  useEffect, useRef } from "react";
import { GeoJSON, GeoJSONProps } from "react-leaflet";
import { GeoJSON as LeafletGeoJSON } from "leaflet";

//source: https://github.com/PaulLeCam/react-leaflet/issues/332
export default function GeoJsonWithUpdates(props) {
  const geoJsonLayerRef = useRef(LeafletGeoJSON | null)

  useEffect(() => {
    const layer = geoJsonLayerRef.current
  
    if (layer) {
      layer.clearLayers().addData(props.data);
      // clearLayers() seems to remove the `pathOptions`, `style` and `interactive` prop as well
      // Resetting it here
      if (props.pathOptions) {
        layer.setStyle(props.pathOptions);

      } else if (props.style) {
        layer.setStyle(props.style)
      }
    }
  }, [props.data, props.pathOptions, props.style])

  return <GeoJSON {...props} ref={geoJsonLayerRef} />
}