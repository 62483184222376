import React,{useEffect, useState} from 'react'
import './header.css'
import logo from '../../assets/images/logo.png'
import { MENUS } from '../../enums/constants'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import scrollToElement from 'scroll-to-element'

const Header = () => {
  const [index, setIndex] = useState(0)
  const navigate = useNavigate()
  const location = useLocation().pathname.split('/')[1]


  useEffect(()=>{
    if(index === '1'){
      navigate('/blogs')
    }
  },[])

  const handleIndex = (e, i)=>{
    let nav = '/'

    setIndex(i)
    if(i === 0) nav = '/'
    if(i === 1) {
      nav = '/blogs'
      e.preventDefault()
    }
    if(i === 2) nav = '/#about'
    if(i === 3) nav = '/#leaflet'
    if(i === 4) nav = '/#peta'
    if(i === 5) nav = '/#team'
    
    navigate(nav)
  }

  const listMenu = MENUS.map((menu, i) => {
    return(
        <a key={i} href={menu.TO} className={`p-3 text-dark ${i === index ? ' mn-active':null}`} 
            onClick={(e)=>handleIndex(e, i)}>
              <span style={{fontSize:'14px'}}>{menu.NAME}</span>
        </a>
        // <Link to={'/about#about'}>{menu.NAME}</Link>
    )
}) 

// https://github.com/rafgraph/react-router-hash-link

  return (
    <> 
    {location !=='admin' &&  
      <header>
        <div className='d-flex flex-column flex-md-row align-items-center fixed-top gray-color'>   
            <a href='/' className='my-0 mx-5 text-center' > 
              <img src={logo} alt='kampung bebas stunting' width={100} />
            </a>
            {/* <h5 className="my-0 font-weight-normal text-center" style={{border:'1px solid black'}}>
            <span style={{color:'red', fontWeight:'900'}}>KAMPUNGBEBAS
          <span style={{color:'#138fa0'}}>STUNTING</span></span></h5> */}

          <nav className="my-2 ms-md-0 mr-md-3 text-center">
            { listMenu }
            {/* <span className='dropdown'>
            <a className="p-3 text-dark dropdown-toggle" id="dropdownMenu2" 
              data-toggle="dropdown" aria-haspopup="true" 
              aria-expanded="false" data-bs-toggle="dropdown"   href="#support">ARTICLES
            </a>
            <div className="dropdown-menu"aria-labelledby="dropdownMenu2">
              <button className="dropdown-item items" type="button">What is Stunting</button>
              <a href='#articles' className="dropdown-item items" type="button" >Download Articles</a>
              <button className="dropdown-item items" type="button">Something else here</button>
            </div>
            </span> */}
          </nav>
          {/* <a className="btn btn-outline-primary" href="#">Sign up</a> */}
        </div>
      </header>
    }
    {location ==='admin' && 
      <div>Side Nav
          
        
      </div>
    }
    </>
  )
}

export default Header
